import React from 'react';
import { Button } from "../../style/styles";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { theme } from "../../style/theme";

export interface HealthGuideButtonProps {
  color: string;
  scaleText?: boolean;
}

export interface HealthGuideButtonStateProps {
  isUserAuthenticated: boolean;
}

export const HealthGuideButton = ({color, scaleText = false, isUserAuthenticated} : HealthGuideButtonProps & HealthGuideButtonStateProps) => {
  const navigate = useNavigate();

  const title = 'HEALTH GUIDE';
  return !scaleText
    ? <WideActionButton sx={{backgroundColor: color}} onClick={() => navigate('/healthGuide')}>{title}</WideActionButton>
    : <TextScalingWideActionButton sx={{backgroundColor: color}} onClick={() => navigate('/healthGuide')}>{title}</TextScalingWideActionButton>

};

const WideActionButton = styled(Button)`
  font-weight: 500;
  border: none;
  margin-bottom: 8rem;
  background-color: #FF6600;
  white-space: nowrap;
  min-width: fit-content;
`;

const TextScalingWideActionButton = styled(WideActionButton)`
  font-size: 1.8rem;
  ${theme.breakpoints.down('lg')} {
    font-size: 0.8rem;
  }
  ${theme.breakpoints.down('md')} {
    font-size: 0.6rem;
  }
  ${theme.breakpoints.down('sm')} {
    font-size: 1.2rem;
    margin-bottom: 0;
  }
`;