import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { BoldText, Button, ContentSection } from '../../style/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { theme } from '../../style/theme';
import { HealthQuestionnaire } from '../../models/HealthQuestionnaire';
import { ImportantDocuments } from './ImportantDocuments';
import { Loading } from '../shared/Loading';
import { useNavigate } from 'react-router-dom';
import { HealthQuestionnaireValidator } from '../../utils/validators';
import { useAuth } from '../shared/AuthContext';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Link from "@mui/material/Link";
import Bugsnag from "@bugsnag/js";
import { YesNoQuestion } from './YesNoQuestion';
import InjectableSelectionContainer from '../../containers/checkout/InjectableSelectionContainer';
import GoalCaptureContainer from "../../containers/GoalCaptureContainer";
import { ImageUploader } from '../shared/imageUploader/ImageUploader';
import { ApiCallState } from '../../redux/WebAppState';
import { DatePicker } from '../shared/DatePicker';

export interface QuestionnaireProps {
  responses: HealthQuestionnaire;
  formSubmitted: boolean;
  formSuccess: boolean;
  submitError?: Error;
  productVariants: boolean;
  semaglutide?: boolean;
  purchasingCall: ApiCallState;
}

export interface QuestionnaireDispatchProps {
  updateResponses: (field: keyof HealthQuestionnaire, value: string | string[] | File) => void;
  submitForm: (username: string) => void;
  cleanup: () => void;
  checkout: () => void;
}

export const parseDayOrMonth = (datePart: number): string => {

  let partString = '';
  if (datePart < 10) {
    partString = `0${datePart}`;
  } else {
    partString = datePart.toString();
  }

  return partString;
}

//TODO: Test this. It's not simple because Prismic is involved.
export const QuestionnairePage = ({ responses, formSubmitted, formSuccess, submitError, productVariants, semaglutide, purchasingCall, updateResponses, submitForm, cleanup, checkout }: QuestionnaireProps & QuestionnaireDispatchProps) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [loading, setLoading] = useState(false);
  const [formError, setFormError] = useState('');
  const [filename, setFilename] = useState('');
  const [viewPrivacyPopOut, setViewPrivacyPopOut] = useState(false);
  const [displaySupportEmail, setDisplaySupportEmail] = useState(false);
  const navigate = useNavigate();
  const auth = useAuth();
  const updateResponse = (fieldName: keyof HealthQuestionnaire) => (event: ChangeEvent<HTMLInputElement>) => {
    updateResponses(fieldName, event.target.value);
  }
  const updateBirthdate = (newValue: string) => {
    updateResponses('birthDate', newValue);
  }

  const handleError = (error: Error) => {
    console.log(error);
    Bugsnag.notify(error);
    setLoading(false);

    if (error.name === 'CheckoutError') {
      setFormError('There was an error attempting to checkout.');
      setDisplaySupportEmail(true);
    } else {
      setFormError(transformErrorMessage(error.message, setDisplaySupportEmail));
    }
  }

  useEffect(() => {
    return () => {
      // cleanup when component unmounts
      setFormError('');
      setDisplaySupportEmail(false);
      cleanup();
    };
  }, []);

  useEffect(() => {
    if (!formSubmitted) {
      setLoading(false);
    }
  }, [formSubmitted]);

  useEffect(() => {
    if (formSuccess) {
      checkout();
    }
  }, [formSuccess]);

  useEffect(() => {
    if (submitError?.message === 'Hard stop condition met') {
      navigate('/ineligible', { replace: true });
    } else if (submitError) {
      handleError(submitError);
    }
  }, [submitError]);

  useEffect(() => {
    if (purchasingCall.isLoading) {
      setLoading(true);
    }
    if (purchasingCall.apiError) {
      handleError(purchasingCall.apiError);
    }
  },[purchasingCall]);

  const updateIdFile = (file: string) => {
    updateResponses('photoId', file);
  }

  const updateDocuments = (documentId: string, status: boolean) => {
    const acceptedDocuments = status
      ? [...responses.acceptedDocuments, documentId]
      : [...responses.acceptedDocuments].filter(doc => doc.toLowerCase() !== documentId.toLowerCase());

    updateResponses('acceptedDocuments', acceptedDocuments);
  }

  const submitHandler = (e: FormEvent) => {
    e.preventDefault();
    if (loading) return;

    setFormError('');
    setDisplaySupportEmail(false);
    setLoading(true);
    const validationResult = new HealthQuestionnaireValidator().isFormValid(responses);

    if (!validationResult.isValid) {
      setFormError(validationResult.errors!.join('; '));
      setLoading(false);
      return;
    }

    submitForm(auth.user!.username);
  }

  const getMaxBirthDate = () => {
    const maxBirthdate = new Date();
    maxBirthdate.setFullYear(maxBirthdate.getFullYear() - 18);

    return `${maxBirthdate.getFullYear()}-${parseDayOrMonth(maxBirthdate.getMonth()+1)}-${parseDayOrMonth(maxBirthdate.getDate())}`;
  };

  const maxBirthDate = getMaxBirthDate();

  return (
    <>
      <ContentSection>
        <Typography variant={'h1'} align={'center'} gutterBottom><b>Step 2 of 4:</b> Upload your photo ID & tell us a bit about your health history.</Typography>
        <Typography variant={'h2'} align={'center'} gutterBottom>Why? To confirm you're a real person & to get your medical practitioner up to speed for your virtual one-on-one. Cool? Cool. Let's do this. (All fields required.)</Typography>
        <Clickable onClick={() => setViewPrivacyPopOut(true)}>Is my information secure?</Clickable>
        <Dialog
          open={viewPrivacyPopOut}
          onClose={() => setViewPrivacyPopOut(false)}
          scroll={'paper'}
        >
          <DialogTitle>Your Privacy is a Big Deal</DialogTitle>
          <DialogContent dividers sx={{padding: '1rem'}}>
            <Typography>Rest assured, all the information you share with us will be encrypted, transmitted, & secured in a HIPAA-compliant storage environment</Typography>
          </DialogContent>
          <DialogActions>
            <Clickable onClick={() => setViewPrivacyPopOut(false)}>Close</Clickable>
          </DialogActions>
        </Dialog>
        <form onSubmit={submitHandler}>
          <Grid container padding={isMobile ? 0 : 2}>
            <Grid item xs={12} lg={5} padding={1}>
              <TextField type={'text'} label={'Full Name'} fullWidth value={responses.fullName} onChange={updateResponse('fullName')} required />
            </Grid>
            <Grid item xs={12} lg={2} padding={1}>
              <TextField select label={'Biological Sex'} value={responses.gender} fullWidth onChange={updateResponse('gender')} required>
                <MenuItem value={'male'}>Male</MenuItem>
                <MenuItem value={'female'}>Female</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} lg={5} padding={1}>
              <DatePicker label={'Date of Birth *'} maxDate={maxBirthDate} updateForm={updateBirthdate} ></DatePicker>
            </Grid>
            <Grid item xs={12} md={7} xl={6} padding={1} container direction={'column'}>
              <Typography>Height</Typography>
              <Grid item container>
                <PaddedTextField fullWidth={isMobile} type={'number'} inputProps={{ min: 0}} label={'ft'} value={responses.heightFeet} onChange={updateResponse('heightFeet')} required />
                <PaddedTextField fullWidth={isMobile} type={'number'} inputProps={{ min: 0}} label={'in'} sx={{ marginLeft: isMobile ? '0' : '1rem' }} value={responses.heightInches} onChange={updateResponse('heightInches')} required />
              </Grid>
            </Grid>
            <Grid item xs={12} md={5} xl={6} padding={1} container direction={'column'}>
              <Typography>Weight</Typography>
              <PaddedTextField type={'number'} inputProps={{ min: 0}} label={'lbs'} sx={{ maxWidth: isMobile ? '100%' : '250px' }} value={responses.weight} onChange={updateResponse('weight')} required />
            </Grid>
            <GoalCaptureContainer />
          </Grid>
          <Grid container>
            <Grid container padding={isMobile ? 0 : 2} item xs={12}>
              <Grid container direction={'column'} padding={1}>
                <Grid item>
                  <BoldTextPaddingBottom variant={'h2'} gutterBottom>Upload a copy of your government-issued photo ID.</BoldTextPaddingBottom>
                </Grid>
                <ImageUploader
                  WrapperButton={UploadButton}
                  buttonText={'Upload Photo Id'}
                  filename={filename}
                  filenamePlaceholder={'No file selected'}
                  fileResultHandler={updateIdFile}
                  setFilename={setFilename}
                  disabled={false}
                  justifyRight={false}
                />
              </Grid>
              <Grid item xs={12} container alignItems={'center'} padding={1}>
                <Grid item>
                  <BoldTextPaddingBottom variant={'h2'} gutterBottom>Tell us a little bit about yourself.</BoldTextPaddingBottom>
                </Grid>
                <YesNoQuestion label={'Are you pregnant or breast feeding?'} value={responses.pregnant} changeFunction={updateResponse('pregnant')} />
              </Grid>
              <Grid container direction={'column'} padding={1}>
                <Grid item>
                  <BoldText gutterBottom>Have you ever had any of the following medical conditions?</BoldText>
                </Grid>
                <Grid item container direction={'column'}>
                  <YesNoQuestion label={'Type 1 Diabetes'} value={responses.t1Diabetes} changeFunction={updateResponse('t1Diabetes')} />
                  <YesNoQuestion label={'Type 2 Diabetes'} value={responses.t2Diabetes} changeFunction={updateResponse('t2Diabetes')} background={'#F2F2F2'} />
                  <YesNoQuestion label={'Are you insulin dependent?'} value={responses.insulinDependent} changeFunction={updateResponse('insulinDependent')} />
                  <YesNoQuestion label={'Cancer (current)'} value={responses.cancerCurrent} changeFunction={updateResponse('cancerCurrent')} background={'#F2F2F2'} />
                  <YesNoQuestion label={'Cancer (in remission for less than 7 years)'} value={responses.cancerLessThan7Years}
                    changeFunction={updateResponse('cancerLessThan7Years')} />
                  <YesNoQuestion label={'Cancer (in remission for more than 7 years)'} value={responses.cancerMoreThan7Years}
                    changeFunction={updateResponse('cancerMoreThan7Years')} background={'#F2F2F2'} />
                  <YesNoQuestion label={'Cardiovascular Disease'} value={responses.cardio} changeFunction={updateResponse('cardio')} />
                  <YesNoQuestion label={'Stroke'} value={responses.stroke} changeFunction={updateResponse('stroke')} background={'#F2F2F2'} />
                  <YesNoQuestion label={'Liver Problems'} value={responses.liver} changeFunction={updateResponse('liver')} />
                  <YesNoQuestion label={'Gallbladder Problems'} value={responses.gallbladder} changeFunction={updateResponse('gallbladder')} background={'#F2F2F2'} />
                  <YesNoQuestion label={'Kidney Problems'} value={responses.kidney} changeFunction={updateResponse('kidney')} />
                  <YesNoQuestion label={'Thyroid Problems'} value={responses.thyroid} changeFunction={updateResponse('thyroid')} background={'#F2F2F2'} />

                  { !!semaglutide &&
                    <>
                      <YesNoQuestion label={'Medullary Thyroid Cancer'} value={responses.medullaryThyroidCancer} changeFunction={updateResponse('medullaryThyroidCancer')} />
                      <YesNoQuestion label={'Multiple Endocrine Neoplasia'} value={responses.multipleEndocrineNeoplasia} changeFunction={updateResponse('multipleEndocrineNeoplasia')} background={'#F2F2F2'} />
                    </>
                  }
                </Grid>
              </Grid>
              { !!semaglutide &&
                <Grid container direction={'column'} padding={1}>
                  <Grid item>
                    <BoldText gutterBottom>Has anyone in your family been diagnosed with any of the following medical conditions?</BoldText>
                  </Grid>
                  <Grid item container direction={'column'}>
                    <YesNoQuestion label={'Medullary Thyroid Cancer'} value={responses.familyMedullaryThyroidCancer} changeFunction={updateResponse('familyMedullaryThyroidCancer')} />
                    <YesNoQuestion label={'Multiple Endocrine Neoplasia'} value={responses.familyMultipleEndocrineNeoplasia} changeFunction={updateResponse('familyMultipleEndocrineNeoplasia')} background={'#F2F2F2'} />
                  </Grid>
                </Grid>
              }
              <Grid container direction={'column'} padding={1}>
                <Grid item>
                  <BoldText gutterBottom>Have you been diagnosed with any of the following mental health conditions?</BoldText>
                </Grid>
                <Grid item container direction={'column'}>
                  <YesNoQuestion label={'Depression'} value={responses.depression} changeFunction={updateResponse('depression')} />
                  <YesNoQuestion label={'Anxiety'} value={responses.anxiety} changeFunction={updateResponse('anxiety')} background={'#F2F2F2'} />
                  <YesNoQuestion label={'Bipolar Disorder'} value={responses.bipolar} changeFunction={updateResponse('bipolar')} />
                  <YesNoQuestion label={'Schizophrenia'} value={responses.schizophrenia} changeFunction={updateResponse('schizophrenia')} background={'#F2F2F2'} />
                  <YesNoQuestion label={'Borderline Personality Disorder'} value={responses.borderline} changeFunction={updateResponse('borderline')} />
                </Grid>
              </Grid>
              { !!semaglutide &&
                <Grid container direction={'column'} padding={1}>
                  <Grid item>
                    <BoldText gutterBottom>Are you currently using any other GLP-1 peptides?</BoldText>
                  </Grid>
                  <RadioGroup row value={responses.glp1Peptides} onChange={updateResponse('glp1Peptides')}>
                    <FormControlLabel label={'Yes'} control={<Radio />} value={'yes'} />
                    <FormControlLabel label={'No'} control={<Radio />} value={'no'} />
                  </RadioGroup>
                </Grid>
              }
              <Grid container direction={'column'} padding={1}>
                <Grid item>
                  <BoldText gutterBottom>Are you currently taking any prescription or over-the-counter medications?</BoldText>
                </Grid>
                <RadioGroup row value={responses.medications} onChange={updateResponse('medications')}>
                  <FormControlLabel label={'Yes'} control={<Radio />} value={'yes'} />
                  <FormControlLabel label={'No'} control={<Radio />} value={'no'} />
                </RadioGroup>
                {responses.medications === 'yes' && <>
                  <PaddedTextField fullWidth multiline label={'Please list medications'} value={responses.medicationDetail} onChange={updateResponse('medicationDetail')} />
                </>}
              </Grid>
              {productVariants &&
                <InjectableSelectionContainer />
              }
              <ImportantDocuments documentIds={responses.importantDocuments} documentAcceptanceChanged={updateDocuments} />
              {formError && displaySupportEmail
              ?
                <ErrorContainer>
                  <ErrorMessage>{formError}</ErrorMessage>
                  <ErrorMessage>If this continues, email support at <a href='mailto:support@goalsrx.com'>support@goalsrx.com</a> for assistance.</ErrorMessage>
                </ErrorContainer>
              :
                <ErrorMessage>{formError}</ErrorMessage>
              }
              <Grid container justifyContent={'center'}>
                <SubmitButton type={'submit'}>{loading ? <Loading /> : 'Submit'}</SubmitButton>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </ContentSection>
    </>
  );
};

const ErrorContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ErrorMessage = styled(Typography)`
  color: red;
  margin: 2rem auto 0;
`;

const SubmitButton = styled(Button)`
  margin-top: 5rem;
`;

const PaddedTextField = styled(TextField)`
  margin-top: 1rem;
`;

const UploadButton = styled(Button)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 20rem;
  height: 4rem;
  background: #000000;
  color: #FFFFFF;
  cursor: pointer;
  font-weight: 500;
  padding: 1.5rem 2rem;
  margin: 0 2rem 0 0;
  text-transform: uppercase;
`;

const BoldTextPaddingBottom = styled(Typography)`
  font-weight: 600;
  padding-bottom: 2rem;
`;

const Clickable = styled(Link)`
  cursor: pointer;
`;

function transformErrorMessage(errorMessage: string, setDisplaySupportEmail: (newState: boolean) => void): string {
  switch (errorMessage) {
    case 'Hard stop condition met':
      return 'It looks like you are ineligible at this time';
    case 'Missing required fields':
      return 'All form fields are required';
    case 'All documents must be accepted':
      return 'All documents must be accepted to continue';
    case 'Unable to process image':
    case 'Photo ID Upload Failed':
      setDisplaySupportEmail(true);
      return 'ID file could not be uploaded. Please try a different image.';
    default:
      setDisplaySupportEmail(true);
      console.log(`There was an error submitting the form: ${errorMessage}`);
      return 'There was an error submitting the form.';
  }
}
