import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { WebAppState } from '../../redux/WebAppState';
import { UserProfilePage, UserProfilePageDispatchProps, UserProfilePageStateProps } from "../../components/userProfile/UserProfilePage";
import { UserProfile } from "../../models/UserProfile";
import { UPDATE_PHOTO_ID } from '../../redux/actions';

const mapStateToProps: MapStateToProps<UserProfilePageStateProps, {}, WebAppState> = state => ({
  userProfile: state.user.profile || {} as UserProfile,
  userProfileApi: state.system.apiCalls.getUserProfile,
  photoIdUpdateApi: state.system.apiCalls.updatePhotoId
});

const mapDispatchToProps: MapDispatchToProps<UserProfilePageDispatchProps, {}> = dispatch => ({
  updatePhotoId: (file: string) => dispatch({type: UPDATE_PHOTO_ID, data: file})
});

export default connect(mapStateToProps, mapDispatchToProps)(UserProfilePage);