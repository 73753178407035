import { connect, MapStateToProps } from "react-redux";
import { WebAppState } from "../redux/WebAppState";
import { MenuCore, MenuCoreStateProps } from "../components/shared/authDisplay/hamburgerMenu/MenuCore";

function doCognitoGroupsIncludeRole(state: WebAppState, role: string) {
  return !!state.user.user
  && !!state.user.user.nativeUser
  && !!state.user.user.nativeUser.getSignInUserSession()
  && !!state.user.user.nativeUser.getSignInUserSession().idToken.payload['cognito:groups']
  && state.user.user.nativeUser.getSignInUserSession().idToken.payload['cognito:groups'].includes(role);
}

const mapStateToProps: MapStateToProps<MenuCoreStateProps, {}, WebAppState> = (state: WebAppState) => ({
  isAdmin: doCognitoGroupsIncludeRole(state, 'Admin'),
  isPhysician: doCognitoGroupsIncludeRole(state, 'Doctor'),
  userName: state.user.user?.name ?? state.user.user?.username ?? '',
  program: state.user.subscription?.name,
});

export const MenuCoreContainer = connect(mapStateToProps)(MenuCore);