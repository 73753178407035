import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { ContentSection } from "../../style/styles";
import { Product, ProductBase } from '../../models/Product';
import { formatExpiration, formatPhoneNumber, formatZip } from '../../utils/displayUtils';
import { useAuth } from "../shared/AuthContext";
import { useAllPrismicDocumentsByUIDs } from "@prismicio/react";
import { styled } from "@mui/material/styles";
import { CheckoutRequest } from '../../models/CheckoutRequest';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { AddressForm } from "./checkoutForm/AddressForm";
import { ContactInfoForm } from "./checkoutForm/ContactInfoForm";
import { CreditCardInfoForm } from "./checkoutForm/CreditCardInfoForm";
import { CheckoutOrderDetails } from "./checkoutForm/CheckoutOrderDetails";
import { Promo } from "../../models/Promo";

export interface CheckoutPageProps {
  cart?: Product;
  formError?: string;
  isLoading: boolean;
  purchasePrice?: number;
  promo?: Promo;
  getStateProduct: (name:string) => ProductBase | undefined;
}

export interface CheckoutPageDispatchProps {
  placeOrder: (request: CheckoutRequest, navigate: NavigateFunction) => void;
}

// This could still be more reduxified by moving all of this local state to the redux store - but that's not overly important
export const CheckoutPage = ({cart, formError, isLoading, purchasePrice, promo, getStateProduct, placeOrder}: CheckoutPageProps & CheckoutPageDispatchProps) => {
  const [shippingFirstName, setShippingFirstName] = useState('');
  const [shippingLastName, setShippingLastName] = useState('');
  const [shippingAddressLineOne, setShippingAddressLineOne] = useState('');
  const [shippingAddressLineTwo, setShippingAddressLineTwo] = useState('');
  const [shippingAddressCity, setShippingAddressCity] = useState('');
  const [shippingAddressState, setShippingAddressState] = useState('');
  const [shippingAddressZip, setShippingZip] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [expiration, setExpiration] = useState('');
  const [cvc, setCvc] = useState('');
  const [viewTCs, setViewTCs] = useState(false);
  const [documents, { state }] = useAllPrismicDocumentsByUIDs('important_document', ['terms']);
  const [billingSameAsShipping, setBillingSameAsShipping] = useState(false);
  const [billingFirstName, setBillingFirstName] = useState('');
  const [billingLastName, setBillingLastName] = useState('');
  const [billingAddressLineOne, setBillingAddressLineOne] = useState('');
  const [billingAddressLineTwo, setBillingAddressLineTwo] = useState('');
  const [billingAddressCity, setBillingAddressCity] = useState('');
  const [billingAddressState, setBillingAddressState] = useState('');
  const [billingAddressZip, setBillingAddressZip] = useState('');
  const [productOptions, setProductOptions] = useState([] as number[]);

  const navigate = useNavigate();
  const auth = useAuth();

  const stateProduct = getStateProduct(cart?.name!);

  const programInformation = () => {
  };
  const setProductOption = (optionId: number, value: boolean) => {
    const filteredOptions = productOptions.filter(option => option !== optionId);
    value && filteredOptions.push(optionId);
    setProductOptions(filteredOptions);
  };

  const buildAndSubmitRequest = () => {
    const shippingAddress = {
      'firstName': shippingFirstName,
      'lastName': shippingLastName,
      'addressLineOne': shippingAddressLineOne,
      'addressLineTwo': shippingAddressLineTwo,
      'city': shippingAddressCity,
      'addressState': shippingAddressState,
      'zip': formatZip(shippingAddressZip)
    };

    let body: CheckoutRequest = {
      'username': !!auth.user ? auth.user.username : "",
      'productId': !!stateProduct ? String(stateProduct.id) : "",
      'amount': !!purchasePrice ? String(purchasePrice) : "",
      'promoCode': !!promo ? promo.code : "",
      'shippingAddress': shippingAddress,
      'billingAddress': billingSameAsShipping ? shippingAddress : {
        'firstName': billingFirstName,
        'lastName': billingLastName,
        'addressLineOne': billingAddressLineOne,
        'addressLineTwo': billingAddressLineTwo,
        'city': billingAddressCity,
        'addressState': billingAddressState,
        'zip': formatZip(billingAddressZip)
      },
      'email': email,
      'phoneNumber': formatPhoneNumber(phoneNumber),
      'cardNumber': cardNumber,
      'expiration': formatExpiration(expiration),
      'cvc': cvc,
      'options': productOptions
    };

    placeOrder(body, navigate);
  };

  return (
    <>
      <ContentSection>
        <Typography variant={'h1'} gutterBottom><b>Step 3 of 4:</b> Confirm your shipping & billing information.</Typography>
        <Typography variant={'h2'} gutterBottom>A hold for your first monthly program fee will be placed on your preferred payment method. (Don't worry, it won't hit your account until after you meet with a GoalsRx medical practitioner.)</Typography>
        <Grid container justifyContent={'space-between'}>
          <OrderForm item container xs={12} sm={7} direction={'column'} spacing={2}>
            <AddressForm
              formHeaderText={'Delivery Information'}
              firstName={shippingFirstName} setFirstName={setShippingFirstName}
              lastName={shippingLastName} setLastName={setShippingLastName}
              addressLineOne={shippingAddressLineOne} setAddressLineOne={setShippingAddressLineOne}
              addressLineTwo={shippingAddressLineTwo} setAddressLineTwo={setShippingAddressLineTwo}
              city={shippingAddressCity} setCity={setShippingAddressCity}
              state={shippingAddressState} setState={setShippingAddressState}
              zipcode={shippingAddressZip} setZipcode={setShippingZip}
            />
            <ContactInfoForm
              email={email} setEmail={setEmail}
              phoneNumber={phoneNumber} setPhoneNumber={setPhoneNumber}
            />
            <CreditCardInfoForm
              cardNumber={cardNumber} setCardNumber={setCardNumber}
              expiration={expiration} setExpiration={setExpiration}
              cvc={cvc} setCvc={setCvc}
            />
            <Grid item container justifyContent='center' spacing={2}>
              <Grid item container>
                <Grid item container xs={12} sm={5}>
                  <BillingAddressLabel>Billing Address</BillingAddressLabel>
                </Grid>
                <Grid item container xs={12} sm={7}>
                  <FormControlLabel
                    control={<Checkbox onChange={(e) => setBillingSameAsShipping(e.target.checked)} />}
                    label={ <SameAsShippingLabel>Same as Shipping Address</SameAsShippingLabel> }
                  />
                </Grid>
              </Grid>
              { !billingSameAsShipping &&
                <AddressForm
                  firstName={billingFirstName} setFirstName={setBillingFirstName}
                  lastName={billingLastName} setLastName={setBillingLastName}
                  addressLineOne={billingAddressLineOne} setAddressLineOne={setBillingAddressLineOne}
                  addressLineTwo={billingAddressLineTwo} setAddressLineTwo={setBillingAddressLineTwo}
                  city={billingAddressCity} setCity={setBillingAddressCity}
                  state={billingAddressState} setState={setBillingAddressState}
                  zipcode={billingAddressZip} setZipcode={setBillingAddressZip}
                />
              }
            </Grid>
            <Grid item container>
              <ShippingText>Free standard shipping on all orders</ShippingText>
            </Grid>
          </OrderForm>
          <CheckoutOrderDetails
            programInformation={programInformation}
            setProductOption={setProductOption}
            productInCart={cart}
            stateProduct={stateProduct}
            formError={formError}
            placeOrder={buildAndSubmitRequest}
            isLoading={isLoading}
            tcPrismicDocState={state}
            documents={documents}
            viewTCs={viewTCs}
            setViewTCs={setViewTCs}
          />
        </Grid>
      </ContentSection>
    </>
  );
}

const OrderForm = styled(Grid)``;

export const Header = styled(Typography)`
  font-size: 2.4rem;
  font-weight: 500;
`;

export const Label = styled(Typography)`
  font-size: 1.4rem;
  font-weight: 500;
  color: #999999;
  text-transform: uppercase;
`;

const SameAsShippingLabel = styled(Typography)`
  font-size: 1.2rem;
  font-weight: 400;
  color: #999999;
  text-transform: uppercase;
`;

const BillingAddressLabel = styled(Typography)`
  font-size: 1.6rem;
  font-weight: 500;
  color: #000000;
  text-transform: uppercase;
  padding-top: 1rem;
`;

export const Input = styled('input')`
  font-size: 1.8rem;
  line-height: 2.7rem;
  background: #F7F7F7;
  border-radius: .3rem;
  border: none;
  padding: 1.2rem;
  width: 100%;
`;

const ShippingText = styled(Typography)`
  font-size: 1.6rem;
  font-weight: 600;
  margin-top: 2rem;
  margin-bottom: 6rem;
`;

export const Box = styled(Grid)`
  background: #F7FBFF;
  padding: 2.4rem;
  margin: 0 0 2rem 0;
  width: 100%;
`;