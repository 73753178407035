import Grid from '@mui/material/Grid';
import { Button, ErrorText, Input, Label } from '../../style/styles';
import React, { FormEvent, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { useAuth } from '../shared/AuthContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { Loading } from '../shared/Loading';
import Typography from "@mui/material/Typography";

export interface SignInFormDispatchProps {
  loginUser: (login: Function, navigate: () => void) => void;
  setTempUsernameAndPassword: (username: string, password: string) => void;
}

export interface SignInFormProps {
  isLoadingLogin?: boolean;
  isLoadingProfile?: boolean;
  error?: Error;
}

export const SignInForm = ({ isLoadingLogin, isLoadingProfile, error, loginUser, setTempUsernameAndPassword }: SignInFormProps & SignInFormDispatchProps) => {
  const [emailAddress, setEmailAddress] = useState('');
  const [password, setPassword] = useState('');
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as {
    from?: Location;
  }

  const redirectPath = state?.from?.pathname || '/';

  const handleSubmit = () => (event: FormEvent) => {
    event.preventDefault();

    if (isLoadingLogin || isLoadingProfile) return;
    loginUser(() => auth.signIn(emailAddress, password), () => navigate(redirectPath, {replace: true}));
  };

  useEffect(() => {
    if(!!error && error.name === "UserNotConfirmedException"){
      setTempUsernameAndPassword(emailAddress, password);
      navigate('/confirmSignUp');
    }
  }, [error]);

  return (
    <form onSubmit={handleSubmit()}>
      <Grid sx={{width: '34rem'}} container direction={'column'} alignItems={'center'}>
        <Grid item xs={11} container direction={'column'} padding={1}>
          <Label>E-mail Address</Label>
          <Input type={'text'} value={emailAddress} onChange={(e) => setEmailAddress(e.target.value)} />
        </Grid>
        <Grid item xs={11} container direction={'column'} padding={1}>
          <Label>Password</Label>
          <Input type={'password'} value={password} onChange={(e) => setPassword(e.target.value)} />
        </Grid>
        {/* this should cover our error bases for now -- but if anything besides a bad username/pw
        will need to get escalated to us for diagnosis cuz no other error will display */}
        {error && <ErrorText>Incorrect e-mail address or password. Please try again.</ErrorText>}
        <SignInButton type={'submit'}>{isLoadingLogin || isLoadingProfile ? <Loading /> : 'Sign In'}</SignInButton>
      </Grid>
    </form>
  );
};

const SignInButton = styled(Button)`
  margin: 2rem 0 0;
`;