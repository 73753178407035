import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { useState } from "react";
import { User } from "../../../models/User";
import logo from "../../../assets/goalsrx-circle-logo.png";
import { HamburgerMenuContainer } from "../../../containers/HamburgerMenuContainer";
import hamburgerMenu from "../../../assets/home/hamburgerMenu.svg";
import { styled } from "@mui/material/styles";
import { SmallIcon } from "../../../style/styles";
import { theme } from "../../../style/theme";
import { getProgramIcon } from "./hamburgerMenu/ProgramBlock";
import { MOBILE_HEADER_BREAKPOINT } from "../Constants";

export interface ProfileIconProps {
  user: User;
  program?: string;
  loading: boolean;
}

export const ProfileIcon = ({user, program, loading}: ProfileIconProps) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down(MOBILE_HEADER_BREAKPOINT));
  const displayName = !!user.name && user.name.length > 0 ? user.name : user.username;
  const icon = getProgramIcon(program);

  const mobileView = () => {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'row-reverse', alignItems: 'center'}}>
        <WhiteHamburger src={hamburgerMenu} onClick={() => setMenuOpen(true)}/>
      </Box>
    );
  }

  const desktopView = () => {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'row-reverse', alignItems: 'center'}}>
        <IconButton sx={{p: 0}} onClick={() => setMenuOpen(true)}>
          <Avatar alt="GoalsRx Logo" src={logo} />
          <Typography color={'white'} marginLeft={'1rem'} minWidth={75} textAlign={'left'}>{displayName}</Typography>
          {program &&
            <ProgramLogo src={icon} />
          }
        </IconButton>
      </Box>
    )
  }

  return (
    <>
      {loading || !user.nativeUser || !user.nativeUser.attributes || !user.nativeUser.attributes.email_verified
        ?
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
          <Skeleton variant={'circular'} animation={'wave'} sx={{ bgcolor: 'grey.900' }} height={40} width={40} />
          <Skeleton variant={'rectangular'} animation={'wave'} height={24} width={75} sx={{ bgcolor: 'grey.900', marginLeft: '1rem' }} />
        </Box>
        : menuOpen
        ? <HamburgerMenuContainer menuOpen={menuOpen} handleClose={() => setMenuOpen(false)} />
        : isMobile
          ? mobileView()
          : desktopView()
      }
    </>
  );
}

const WhiteHamburger = styled('img')`
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(360deg) brightness(102%) contrast(102%);
  cursor: pointer;
`;

const ProgramLogo = styled(SmallIcon)`
  margin-top: .25rem;
  margin-left: 1rem;
`;