import React from "react";
import { Product } from '../models/Product';

import burnHeader from '../assets/burn/burn-header.png';
import burnHeaderMobile from '../assets/burn/burn-header-mobile.jpg';
import burnText from '../assets/burn/burn-large-type.svg';
import burnLogo from '../assets/burn/burn-header-logo.svg';
import burnThumbnail from '../assets/burn/burn-thumbnail.png';
import burnProductLineup from '../assets/burn/burn-lineup.png';
import burnMedicineBottle from '../assets/burn/Burn-AOD-DHEA-Naltrexone-bottle.png';
import burnGlut4 from '../assets/burn/Burn-Glut4.png';
import burnB12 from '../assets/burn/Burn-B12-bottle-vial.png';
import burnGlut4Facts from '../assets/burn/Burn-Glut4-facts.png';
import burnRingIcon from '../assets/burn/burn-icon.svg';
import burnHealthGuideHeaderImage from '../assets/burn/burn-health-guide-header.jpg';
import burnVial from '../assets/burn/Burn-vial.png';

import buildHeader from '../assets/build/build-header.png';
import buildHeaderMobile from '../assets/build/build-header-mobile.jpg';
import buildText from '../assets/build/build-large-type.svg';
import buildLogo from '../assets/build/build-header-logo.svg';
import buildThumbnail from '../assets/build/build-thumbnail.png';
import buildProductLineup from '../assets/build/build-lineup.png';
import buildMedicineBottle from '../assets/build/build-MK677-DIM-bottle.png';
import buildEndure from '../assets/build/build-endure.png';
import buildInjectable from '../assets/build/build-large-vial-and-syringe.png';
import buildRevive from '../assets/build/build-revive.png';
import buildEndureFacts from '../assets/build/Build-Endure-facts.png';
import buildReviveFacts from '../assets/build/build-revive-facts.png';
import buildIcon from '../assets/build/build-icon.svg';
import buildHealthGuideHeaderImage from '../assets/build/build-health-guide-header.png';

import thriveHeader from '../assets/thrive/thrive-header.png';
import thriveHeaderMobile from '../assets/thrive/thrive-header-mobile.jpg';
import thriveText from '../assets/thrive/thrive-large-type.svg';
import thriveLogo from '../assets/thrive/thrive-header-logo.svg';
import thriveThumbnail from '../assets/thrive/thrive-thumbnail.png';
import thriveProductLineup from '../assets/thrive/thrive-lineup.png';
import thriveGlutathione from '../assets/goalsrx-troche.png';
import thrivePure from '../assets/thrive/thrive-pure.png';
import thriveInjectables from '../assets/thrive/thrive-large-and-small-vial-and-syringe.png';
import thriveB12 from '../assets/thrive/thrive-B12.png';
import thriveVitality from '../assets/thrive/thrive-vitality.png';
import thrivePureFacts from '../assets/thrive/thrive-pure-facts.png';
import thriveVitalityFacts from '../assets/thrive/thrive-vitality-facts.png';
import thriveHealthGuideHeaderImage from '../assets/thrive/thrive-health-guide-header.jpg';

import thriveIcon from '../assets/thrive/thrive-icon.png';

import Typography from '@mui/material/Typography';
import { styled } from "@mui/material/styles";

const DescriptionItem = styled(Typography)`
  font-weight: 300;
  margin: .5rem 0 .5rem;
`;

const LongDescription = styled(Typography)`
  font-weight: 300;
  font-size: 1.2rem;
  color: #666666;
`;

interface ProductEntries {
  [name: string]: Product;
}

const BurnAntiObesity = () => (
  <>
    <DescriptionItem>Lowers Appetite</DescriptionItem>
    <DescriptionItem>Reduces Food Intake</DescriptionItem>
    <DescriptionItem>Decreases Food Cravings</DescriptionItem>
    <DescriptionItem>Stabilizes Blood Sugar Levels</DescriptionItem>
  </>
)

const BurnFoodAddictionManagement = () => (
  <>
    <DescriptionItem>Alleviates Food Cravings</DescriptionItem>
    <DescriptionItem>Decreases Hunger</DescriptionItem>
    <DescriptionItem>Boosts Energy</DescriptionItem>
    <DescriptionItem>Reduces Insulin Resistance</DescriptionItem>
  </>
)

const BurnWeightLossCompound = () => (
  <>
    <DescriptionItem>Greater Feeling of Energy</DescriptionItem>
    <DescriptionItem>Liver Detoxification</DescriptionItem>
    <DescriptionItem>Increases Fat Metabolism</DescriptionItem>
    <DescriptionItem>Reduces Semaglutide Associated Nausea</DescriptionItem>
  </>
)

const BurnFatBurningHormone = () => (
  <>
    <DescriptionItem>Increases Metabolism</DescriptionItem>
    <DescriptionItem>Improves Lean Body Mass</DescriptionItem>
    <DescriptionItem>Healthier Thyroid Function</DescriptionItem>
    <DescriptionItem>Promotes Weight Loss</DescriptionItem>
  </>
)

const BurnBloodSugarSupport = () => (
  <>
    <DescriptionItem>Promotes Healthy Glucose</DescriptionItem>
    <DescriptionItem>Improves Insulin Function</DescriptionItem>
    <DescriptionItem>Supports Metabolic Wellness</DescriptionItem>
    <DescriptionItem>Stabilizes Energy Levels</DescriptionItem>
  </>
)

const BuildGrowthHormone = () => (
  <>
    <DescriptionItem>Promotes Natural Production of Growth Hormone</DescriptionItem>
    <DescriptionItem>Increases Lean Muscle Mass</DescriptionItem>
    <DescriptionItem>Decreases Body Fat Percentage</DescriptionItem>
    <DescriptionItem>Increases Appetite</DescriptionItem>
  </>
)

const BuildInjectableAminoAcids = () => (
  <>
    <DescriptionItem>Increases Blood Flow to Active Muscle</DescriptionItem>
    <DescriptionItem>Stimulates Growth Hormone Release</DescriptionItem>
    <DescriptionItem>Guards Against Muscle Breakdown</DescriptionItem>
    <DescriptionItem>Accelerates Muscle Recovery</DescriptionItem>
  </>
)

const BuildMusclePerformance = () => (
  <>
    <DescriptionItem>Enhances Muscular Endurance</DescriptionItem>
    <DescriptionItem>Increases Nitric Oxide Naturally</DescriptionItem>
    <DescriptionItem>Improves Power Output</DescriptionItem>
    <DescriptionItem>Minimizes Muscle Breakdown</DescriptionItem>
  </>
)

const BuildNaturalAntiInflammatory = () => (
  <>
    <DescriptionItem>Relieves Muscle Soreness</DescriptionItem>
    <DescriptionItem>Decreases Joint Pain</DescriptionItem>
    <DescriptionItem>Reduces Inflammation</DescriptionItem>
    <DescriptionItem>Accelerates Recovery</DescriptionItem>
  </>
)

const BuildHormonalBalance = () => (
  <>
    <DescriptionItem>Balances Estrogen</DescriptionItem>
    <DescriptionItem>Supports Free Testosterone</DescriptionItem>
    <DescriptionItem>Improves Fat Loss</DescriptionItem>
    <DescriptionItem>Possible Anti-Cancer Effects</DescriptionItem>
  </>
)

const ThriveGrowthHormoneReleasingPeptide = () => (
  <>
    <DescriptionItem>Fights Several Signs of Aging</DescriptionItem>
    <DescriptionItem>Improves Lean Muscle Mass</DescriptionItem>
    <DescriptionItem>Reduces Body Fat</DescriptionItem>
    <DescriptionItem>Increases Energy and Strength</DescriptionItem>
  </>
)

const ThriveMasterAntioxidant = () => (
  <>
    <DescriptionItem>Protects Mitochondria</DescriptionItem>
    <DescriptionItem>Reduces Oxidative Stress</DescriptionItem>
    <DescriptionItem>Supports Immune Function</DescriptionItem>
    <DescriptionItem>Decreases Inflammation</DescriptionItem>
  </>
)

const ThriveEnergyVitamins = () => (
  <>
    <DescriptionItem>Greater Feeling of Energy</DescriptionItem>
    <DescriptionItem>Liver Detoxification</DescriptionItem>
    <DescriptionItem>Increases Fat Metabolism</DescriptionItem>
    <DescriptionItem>Promotes Healthy Blood Cells</DescriptionItem>
  </>
)

const ThriveCellularHealth = () => (
  <>
    <DescriptionItem>NAD+ Booster</DescriptionItem>
    <DescriptionItem>Repairs Damaged DNA</DescriptionItem>
    <DescriptionItem>Fuels Metabolic Reactions</DescriptionItem>
    <DescriptionItem>Protects Heart and Brain Cells</DescriptionItem>
  </>
)

const ThriveCellularEnergy = () => (
  <>
    <DescriptionItem>Improves Mitochondria Function</DescriptionItem>
    <DescriptionItem>Helps Lower inflammation</DescriptionItem>
    <DescriptionItem>Combats Oxidative Stress</DescriptionItem>
    <DescriptionItem>Significantly Improves Memory</DescriptionItem>
  </>
)

export const PRODUCTS: ProductEntries = {
  burn: {
    name: 'Burn',
    displayInfo: {
      image: burnHeader,
      mobileImage: burnHeaderMobile,
      healthGuideHeaderImage: burnHealthGuideHeaderImage,
      primaryColor: '#FF6600',
      actionButtonColor: '#FF6600',
      heroSectionContent: {
        title: burnText,
        subtitle: 'Your Rx for long-term weight loss & metabolic wellness.',
        price: '349',
        terms: '1 Month to Start. No Obligations. Cancel Anytime.',
        detailText: 'Burn unhealthy fat. Bust through plateaus. Look great naked.',
        thumbnail: burnThumbnail,
        logo: burnLogo,
      },
      details: {
        title: 'Get Burn.',
        subtitle: 'Get healthy.',
        detailText: 'Fad diets. Juice cleanses. Crossfit frenzies. You\'ve tried everything and nothing seems to stick but your sticky bun addiction. We get it. Shedding poundage is hard. Our Burn formulas work in tandem with diet and exercise plans to optimize your body\'s fat burning abilities, ensuring the efforts you invest yield big returns. Did we mention you\'ll look amazing naked?',
        price: '349',
        terms: '1 Month to Start. No Obligations. Cancel Anytime.',
        productImage: burnProductLineup
      },
      productContents: {
        icon: burnRingIcon,
        details: [{
          image: burnVial,
          linkText: 'Semaglutide',
          title: 'Semaglutide',
          subtitle: 'Weight Loss Medication',
          shortDescription: (<BurnAntiObesity />),
          fullDescription: (<LongDescription>Semaglutide is an anti-obesity medication used for long-term weight management. It reduces food intake by lowering appetite and slowing down digestion in the stomach. This minor delay in gastric emptying improves glycemic control and increases the feeling of fullness. Semaglutide has been shown to reduce hunger, food cravings, and unhealthy body fat. (These statements have not been evaluated or approved by the FDA. Results may vary from patient to patient)</LongDescription>)
        }, {
          image: burnMedicineBottle,
          linkText: 'Low-Dose Naltrexone (LDN)',
          title: 'Low-Dose Naltrexone (LDN)',
          subtitle: 'Food Addiction Management',
          shortDescription: (<BurnFoodAddictionManagement />),
          fullDescription: (<LongDescription>LDN helps people lose weight by blocking hunger signals to the brain and reducing the desire for food. Treating intense food cravings and addictions makes healthy dietary changes more manageable. The medicine also contributes to weight loss through several other beneficial mechanisms. (These statements have not been evaluated or approved by the FDA. Results may vary from patient to patient)</LongDescription>)
        }, {
          image: burnB12,
          linkText: 'B12 Pills or B12 MIC Shot',
          title: 'B12 Pills or B12 MIC Shot',
          subtitle: 'Weight Loss Compound',
          shortDescription: (<BurnWeightLossCompound />),
          fullDescription: (<LongDescription>Vitamin B12 improves the breakdown of fatty acids in the liver. It is casually referred to as the "energy vitamin" since it provides a noticeable energy and metabolism boost. Increasing B12 can also lower cholesterol and triglyceride levels. (These statements have not been evaluated or approved by the FDA. Results may vary from patient to patient)</LongDescription>)
        }, {
          image: burnMedicineBottle,
          linkText: '7-Keto-DHEA Capsules',
          title: '7-Keto-DHEA',
          subtitle: 'Fat Burning Pro-hormone',
          shortDescription: (<BurnFatBurningHormone />),
          fullDescription: (<LongDescription>7-Keto-DHEA is a supplement used to improve metabolism and boost fat loss. Clinical research suggests that 7-Keto can protect resting metabolic rate while dieting. One study found that 7-Keto not only prevented the decrease in metabolism that is normally associated with a reduced-calorie diet but also increased metabolism by 1.4% above baseline levels. (These statements have not been evaluated or approved by the FDA. Results may vary from patient to patient)</LongDescription>)
        }, {
          image: burnGlut4,
          linkText: 'Glut4',
          supplementFactImage: burnGlut4Facts,
          title: 'Glut4 – Blood Sugar Support',
          subtitle: 'Chromium, Bitter Melon Extract, Lion’s Mane, Alpha Lipoic Acid',
          shortDescription: (<BurnBloodSugarSupport />),
          fullDescription: (<LongDescription>Glut4 contains a blend of 4 ingredients, clinically proven to help maintain healthy blood sugar levels. Controlling blood glucose can help stabilize energy levels and prevent sugar from being converted into triglycerides that are stored away in adipose tissue. (These statements have not been evaluated or approved by the FDA. Results may vary from patient to patient)</LongDescription>)
        }]
      },
      goalsToCapture: [
        {
          key: 'TargetWeight',
          type: 'number',
          prompt: 'Target Weight',
          label: 'lbs'
        },
        {
          key: 'History',
          type: 'text',
          prompt: "Tell us about your health and fitness journey so far."
        },
        {
          key: 'Wand',
          type: 'text',
          prompt: "If you had a magic wand what's one thing you would change about your health?"
        }
      ]
    },
    semaglutide: true,
  },
  build: {
    name: 'Build',
    displayInfo: {
      image: buildHeader,
      mobileImage: buildHeaderMobile,
      healthGuideHeaderImage: buildHealthGuideHeaderImage,
      primaryColor: '#666666',
      actionButtonColor: '#86C660',
      details: {
        title: 'Get Build.',
        subtitle: 'Get ripped.',
        detailText: 'Want to look and feel like an athlete without the sketchy steroids? Level up your lift game with Build. Custom, doctor-approved supplements and medications. Nutritional coaching. Aggressive strength training regimens. This comprehensive plan is your GPS to fast gains and peak performance. You might even pick up a six pack on the way.',
        price: '349',
        terms: '1 Month to Start. No Obligations. Cancel Anytime.',
        productImage: buildProductLineup
      },
      heroSectionContent: {
        title: buildText,
        subtitle: 'Your Rx for more muscle mass & peak athletic performance.',
        price: '349',
        terms: '1 Month to Start. No Obligations. Cancel Anytime.',
        detailText: 'Explosive endurance. Extra reps at the gym. Gains, baby.',
        thumbnail: buildThumbnail,
        logo: buildLogo
      },
      productContents: {
        icon: buildIcon,
        details: [{
          image: buildMedicineBottle,
          linkText: 'Ibutamoren (MK-677)',
          title: 'Ibutamoren (MK-677)',
          subtitle: 'Growth Hormone Peptide',
          shortDescription: (<BuildGrowthHormone />),
          fullDescription: (<LongDescription>Ibutamoren, also called MK 677, is a growth hormone secretagogue. It is known to substantially increase growth hormone (GH) and IGF-1 levels in the body.  These hormones help build muscle mass, boost metabolism, and burn fat. GH stimulates metabolic processes in cells that accelerate healing after an injury and repair muscle tissue after intense exercise. (These statements have not been evaluated or approved by the FDA. Results may vary from patient to patient)</LongDescription>)
        }, {
          image: buildInjectable,
          linkText: 'GAC Injection Blend',
          title: 'Injectable Amino Acids',
          subtitle: 'Glutamine, Arginine, and L-Carnitine',
          shortDescription: (<BuildInjectableAminoAcids />),
          fullDescription: (<LongDescription>GAC injections are composed of three amino acids: Glutamine, Arginine, and L-Carnitine. This powerful triad increases blood flow to skeletal muscle and stimulates growth hormone release from the pituitary gland. GAC injections improve exercise performance and muscle recovery when taken before a workout. (These statements have not been evaluated or approved by the FDA. Results may vary from patient to patient)</LongDescription>)
        }, {
          image: buildEndure,
          linkText: 'Endure',
          supplementFactImage: buildEndureFacts,
          title: 'Endure – Muscle Performance',
          subtitle: 'Epicatechin, ATP, HMB',
          shortDescription: (<BuildMusclePerformance />),
          fullDescription: (<LongDescription>Endure contains a potent blend of 3 ingredients, clinically proven to help build muscle. Epicatechin is a flavanol found in dark chocolate known to enhance muscle hypertrophy by increasing follistatin and decreasing myostatin. ATP is the primary intracellular energy source. ATP supplementation can reduce muscle fatigue and increase peak power output. HMB acts as an anti-catabolic agent, minimizing muscle protein breakdown with intense exercise. (These statements have not been evaluated or approved by the FDA. Results may vary from patient to patient)</LongDescription>)
        }, {
          image: buildRevive,
          linkText: 'Revive',
          supplementFactImage: buildReviveFacts,
          title: 'Revive – Natural anti-inflammatory',
          subtitle: 'Curcumin, Bromelain, Boswellia Gum Extract, Ginger Root Extract, Piperine',
          shortDescription: (<BuildNaturalAntiInflammatory />),
          fullDescription: (<LongDescription>Revive contains a powerful blend of natural anti-inflammatory agents. Clinical research has shown these compounds to have strong anti-inflammatory properties similar to NSAIDs (non-steroidal anti-inflammatory drugs) without harsh side effects. (These statements have not been evaluated or approved by the FDA. Results may vary from patient to patient)</LongDescription>)
        }, {
          image: buildMedicineBottle,
          linkText: 'Diindolylmethane (DIM)',
          title: 'Diindolylmethane (DIM)',
          subtitle: 'Hormonal Balance',
          shortDescription: (<BuildHormonalBalance />),
          fullDescription: (<LongDescription>DIM is a compound created when you digest cruciferous vegetables, such as broccoli or Brussels sprouts. DIM helps to balance estrogen levels in both men and women. Since estrogen plays a vital role in regulating muscle hypertrophy and fat storage, DIM supplementation can aid muscle growth and fat loss. Limited studies suggest that DIM may help reduce prostate enlargement, protect against certain cancers, and treat hormonal acne. (These statements have not been evaluated or approved by the FDA. Results may vary from patient to patient)</LongDescription>)
        }]
      },
      goalsToCapture: [
        {
          key: 'BulkUp',
          type: 'checkbox',
          prompt: "Bulk Up"
        },
        {
          key: 'BuildStrength',
          type: 'checkbox',
          prompt: "Build Strength"
        },
        {
          key: 'History',
          type: 'text',
          prompt: "Tell us about your health and fitness journey so far."
        },
        {
          key: 'Wand',
          type: 'text',
          prompt: "If you had a magic wand what's one thing you would change about your health?"
        }
      ]
    }
  },
  thrive: {
    name: 'Thrive',
    displayInfo: {
      image: thriveHeader,
      mobileImage: thriveHeaderMobile,
      healthGuideHeaderImage: thriveHealthGuideHeaderImage,
      primaryColor: '#FFCC33',
      actionButtonColor: '#FFCC33',
      details: {
        title: 'Get Thrive.',
        subtitle: 'Feel Brand New.',
        detailText: "Cellular health rules. It keeps your battery charged and your body thriving. Sadly, cells get lazier as we age. Thrive's curation of premium peptides and nutraceuticals was engineered to detox your body and remotivate your cells so you look and feel younger. Enjoy a boost in metabolism, sleep quality, a youthful glow and more energy. And yes, jaws will drop when you tell people your age.",
        price: '349',
        terms: '1 Month to Start. No Obligations. Cancel Anytime.',
        productImage: thriveProductLineup
      },
      heroSectionContent: {
        title: thriveText,
        subtitle: 'Your Rx for aging in reverse.',
        price: '349',
        terms: '1 Month to Start. No Obligations. Cancel Anytime.',
        detailText: 'Boost anti-aging cells. Motivate your metabolism. Look younger.',
        thumbnail: thriveThumbnail,
        logo: thriveLogo
      },
      productContents: {
        icon: thriveIcon,
        details: [{
          image: thriveInjectables,
          linkText: 'Ipamorelin',
          title: 'Ipamorelin',
          subtitle: 'Growth Hormone Releasing Peptide',
          shortDescription: (<ThriveGrowthHormoneReleasingPeptide />),
          fullDescription: (<LongDescription>Ipamorelin is a growth hormone-releasing peptide (GHRP) that acts as a powerful anti-aging therapy. The natural secretion of growth hormone (GH) declines substantially with aging. Ipamorelin stimulates the release of GH from the pituitary gland. Restoring production can increase muscle mass, decrease body fat, improve sleep quality and provide a more youthful appearance of skin. (These statements have not been evaluated or approved by the FDA. Results may vary from patient to patient)</LongDescription>)
        }, {
          image: thriveGlutathione,
          linkText: 'Glutathione Troches',
          title: 'Glutathione',
          subtitle: 'Master Antioxidant',
          shortDescription: (<ThriveMasterAntioxidant />),
          fullDescription: (<LongDescription>Glutathione is the body’s master antioxidant. It helps stop the chain reactions created by free radicals that break down cellular walls. Cells use glutathione as a powerful shield against oxidative stress from diet, toxins, and lifestyle. Glutathione supplementation supports the health of almost all cells in the body. The benefits include enhanced detoxification, immunity, and overall cell function. (These statements have not been evaluated or approved by the FDA. Results may vary from patient to patient)</LongDescription>)
        }, {
          image: thriveB12,
          linkText: 'Bioboost B12 Shot or Pills',
          title: 'Bioboost B12',
          subtitle: 'Energy Vitamins',
          shortDescription: (<ThriveEnergyVitamins />),
          fullDescription: (<LongDescription>Bioboost contains a blend of B vitamins and amino acids. B vitamins help the body break down fatty acids and make more energy. B12 is casually referred to as the "energy vitamin" since it provides a noticeable energy and metabolism boost. Increasing B vitamins can lower cholesterol and triglyceride levels. Amino acids combine to form proteins in the body. These essential molecules are the building blocks of life. (These statements have not been evaluated or approved by the FDA. Results may vary from patient to patient)</LongDescription>)
        }, {
          image: thriveVitality,
          linkText: 'Vitality',
          supplementFactImage: thriveVitalityFacts,
          title: 'Vitality – Cellular Health',
          subtitle: 'Metabolic Regulator',
          shortDescription: (<ThriveCellularHealth />),
          fullDescription: (<LongDescription>Nicotinamide riboside is a precursor molecule for the biosynthesis of nicotinamide adenine dinucleotide, or NAD+, a coenzyme that participates in the production of cellular energy and repair. NAD+ depletion is one of the hallmarks of aging and likely contributes to the progression of a wide range of age-related diseases. Clinical research suggests that increasing NAD+ levels via supplementation can slow or even reverse several aspects of aging and delay the progression of age-related diseases. (These statements have not been evaluated or approved by the FDA. Results may vary from patient to patient)</LongDescription>)
        }, {
          image: thrivePure,
          linkText: 'Pure',
          supplementFactImage: thrivePureFacts,
          title: 'Pure – Cellular Energy',
          subtitle: 'PQQ (Pyrroloquinoline quinone) and CoQ10',
          shortDescription: (<ThriveCellularEnergy />),
          fullDescription: (<LongDescription>PQQ (Pyrroloquinoline Quinone) and CoQ10 (Coenzyme Q10) are potent antioxidants that protect cells from damaging free radicals. PQQ alone is more effective than vitamin C at neutralizing excessive free radicals. PQQ is known to support the development of new mitochondria through a process called mitochondrial biogenesis. Mitochondria are cellular powerhouses that create the energy needed by all cells in the body. The number of functional mitochondria declines progressively with aging, and researchers can now link mitochondrial dysfunction with nearly every age-related disease. (These statements have not been evaluated or approved by the FDA. Results may vary from patient to patient)</LongDescription>)
        }]
      },
      goalsToCapture: [
        {
          key: 'FeelYounger',
          type: 'checkbox',
          prompt: "Feel Younger"
        },
        {
          key: 'AntiAging',
          type: 'checkbox',
          prompt: "Anti-aging"
        },
        {
          key: 'FeelHealthier',
          type: 'checkbox',
          prompt: "Feel Healthier"
        },
        {
          key: 'BeLeaner',
          type: 'checkbox',
          prompt: "Be Leaner"
        },
        {
          key: 'SleepBetter',
          type: 'checkbox',
          prompt: "Sleep Better"
        },
        {
          key: 'History',
          type: 'text',
          prompt: "Tell us about your health and fitness journey so far."
        },
        {
          key: 'Wand',
          type: 'text',
          prompt: "If you had a magic wand what's one thing you would change about your health?"
        }
      ]
    }
  }
};
