import { HealthGuideContentWorkoutSlice } from "../../../models/HealthGuideDocument";
import { getTextFromRTF } from "../../../utils/PrismicUtils";
import { WorkoutDetail } from "./WorkoutDetail";
import React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { theme } from "../../../style/theme";

export const WorkoutDetailRow = (props: { workoutSlice: HealthGuideContentWorkoutSlice }) => {
  return(
    <WorkoutDetailRowWrapper>
      {!!props.workoutSlice.sets && !!getTextFromRTF(props.workoutSlice.sets) &&
      <WorkoutDetail name="Sets" field={props.workoutSlice.sets} />}
      {!!props.workoutSlice.reps && !!getTextFromRTF(props.workoutSlice.reps) &&
        <WorkoutDetail name="Reps" field={props.workoutSlice.reps} />}
      {!!props.workoutSlice.rpe && !!getTextFromRTF(props.workoutSlice.rpe) &&
        <WorkoutDetail name="RPE" field={props.workoutSlice.rpe} />}
      {!!props.workoutSlice.percent && !!getTextFromRTF(props.workoutSlice.percent) &&
        <WorkoutDetail name="Percent" field={props.workoutSlice.percent} />}
      {!!props.workoutSlice.tempo && !!getTextFromRTF(props.workoutSlice.tempo) &&
        <WorkoutDetail name="Tempo" field={props.workoutSlice.tempo} />}
      {!!props.workoutSlice.rest && !!getTextFromRTF(props.workoutSlice.rest) &&
        <WorkoutDetail name="Rest" field={props.workoutSlice.rest} />}
    </WorkoutDetailRowWrapper>
  );
}

const WorkoutDetailRowWrapper = styled(Box)`
  margin: 0 -1rem 0 -1rem;
  display: inline-flex;
  flex-grow: 1;
  ${theme.breakpoints.down('sm')} {
    flex-direction: column;
  }
`;