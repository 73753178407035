import React from 'react';

interface SvgProps {
  width: string;
  height: string;
  fill: string;
}

export const Quote = ({width, height, fill}: SvgProps) => (
  <svg width={width} height={height} viewBox="0 0 54 41" fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path d="M0 23.6236C0 7.34955 18.8987 0 21.8129 0C22.9733 0 24.8929 0.192488 24.8929 1.74989C24.8929 3.10606 23.3572 4.26099 22.0048 4.83846C15.8362 8.51323 10.4266 13.5879 10.4266 18.7676C10.4266 24.761 13.5153 25.7672 19.1517 27.5171C20.8968 27.9021 23.7761 28.287 23.7761 33.318C23.7761 37.5702 19.3437 41.0525 14.9026 41.0525C4.82501 41 0 32.8805 0 23.6236ZM28.9239 23.0373C28.9239 6.57085 47.6394 0 50.7193 0C52.0717 0 54 0.192488 54 1.74989C54 3.10606 52.4644 4.26099 50.9113 4.83846C44.5507 8.51323 39.5686 13.5879 39.5686 18.7676C39.5686 24.761 42.6573 25.7672 48.0582 27.5171C49.8032 27.9021 53.0751 28.287 53.0751 33.318C53.0751 37.5702 48.4421 41.0525 44.0097 41.0525C33.9496 41 28.9239 32.4868 28.9239 23.0111V23.0373Z"/>
  </svg>
);