import * as prismic from "@prismicio/client";
import { all, put, select, takeEvery } from "redux-saga/effects";
import {
  CANCEL_SUBSCRIPTION_FAILED,
  CHECKOUT_FAILED,
  GET_PRODUCTS_FAILED,
  GET_PROMO_VALUE_FAILED,
  HEALTH_GUIDE_DOCUMENT_FAILED,
  HEALTH_GUIDE_FAILED,
  HEALTH_PROFILE_REVIEW_FAILED,
  GET_ACTIVE_QUESTIONNAIRES_FAILED,
  REPORT_ERROR,
  SET_CMS_CLIENT,
  SET_CMS_SERVICE,
  SET_ERROR_HANDLER,
  SET_HTTP_CLIENT,
  SET_SHOPIFY_CLIENT,
  SET_STORAGE_SERVICE,
  SET_USER,
  SUBMIT_QUESTIONNAIRE_FAILED,
  SUBSCRIBE_NEWSLETTER_FAILED,
  USER_LOGIN_FAILED,
  USER_PROFILE_FAILED,
} from "../actions";
import { HttpClient } from '../../service/HttpClient';
import { AnyAction } from 'redux';
import { User } from "src/models/User";
import { CmsService } from "../../service/CmsService";
import { AwsStorageService } from '../../service/StorageService';
import { ErrorHandler } from "../../service/ErrorHandler";
import { WebAppState } from "../WebAppState";
import { buildClient, Client } from 'shopify-buy';

// This can be used as a poor man's DI container very easily
export function* initSystem(handler: ErrorHandler) {
  yield all([
    prepareErrorHandling(handler),
    prepareCmsClient(),
    prepareHttpClient(),
    prepareStorageService(),
    prepareShopifyClient(),
    setUserWatcher(),
    reportErrorWatcher()
  ]);
}

export function* prepareErrorHandling(handler: ErrorHandler) {
  yield put({type: SET_ERROR_HANDLER, data: handler});
}

export function* prepareCmsClient() {
  const repositoryName = 'goalsrx-website';
  const endpoint = prismic.getEndpoint(repositoryName);

  //TODO: Don't use prismic client outside of the CmsService
  const client = prismic.createClient(endpoint, {});
  yield put({type: SET_CMS_CLIENT, data: client});
  const cmsService = new CmsService(client);
  yield put({type: SET_CMS_SERVICE, data: cmsService});
}

export function* prepareHttpClient(user?: User) {
  const client = new HttpClient(user);
  yield put({type: SET_HTTP_CLIENT, data: client});
}

export function* prepareStorageService() {
  const service = new AwsStorageService();
  yield put({ type: SET_STORAGE_SERVICE, data: service });
}

export function* prepareShopifyClient() {
  const shopifyKey = process.env.SHOPIFY_KEY || '';
  const shopifyDomain = process.env.SHOPIFY_DOMAIN || '';
  const client = buildClient({
    storefrontAccessToken: shopifyKey,
    domain: shopifyDomain
  });

  yield put({ type: SET_SHOPIFY_CLIENT, data: client});
}

function* setUserWatcher() {
  yield takeEvery(SET_USER, setUserOnHttpClient);
}

export function* setUserOnHttpClient(setUserAction: AnyAction) {
  yield* prepareHttpClient(setUserAction.data);
}

export function* reportErrorWatcher() {
  const ERROR_ACTIONS = [
    REPORT_ERROR,
    HEALTH_GUIDE_FAILED,
    HEALTH_GUIDE_DOCUMENT_FAILED,
    GET_ACTIVE_QUESTIONNAIRES_FAILED,
    HEALTH_PROFILE_REVIEW_FAILED,
    GET_PRODUCTS_FAILED,
    SUBMIT_QUESTIONNAIRE_FAILED,
    CHECKOUT_FAILED,
    USER_LOGIN_FAILED,
    SUBSCRIBE_NEWSLETTER_FAILED,
    USER_PROFILE_FAILED,
    CANCEL_SUBSCRIPTION_FAILED,
    GET_PROMO_VALUE_FAILED
  ]

  yield takeEvery((action: AnyAction) => ERROR_ACTIONS.includes(action.type), reportError);
}

export function* reportError(reportErrorAction: AnyAction) {
  const errorHandler: ErrorHandler = yield select((state: WebAppState) => state.system.errorHandler);
  errorHandler.reportError(reportErrorAction.data);
}
