import React, { PropsWithChildren } from "react";
import { StaticViewportResolver } from "../StaticViewportResolver";
import { StyledIFrame, StyledIFrameMobile, TitleWrapper, VideoTile, VideoTileMobile, VideoWrapper } from "./embeddedVideoStyles";

export interface VideoLinkProps {
  url: string
}

export const getVideoIdFromUrl = (url: string) => {
  url = url.replace(/https:\/\//g, '');
  return url.split('youtu.be/')[1];
}

export const EmbeddedYouTubeVideo = ({children, url}: PropsWithChildren<VideoLinkProps>) => {
  if ( !url ) return null;
  const videoId = getVideoIdFromUrl(url);
  if ( !videoId ) return null;

  return (
    <StaticViewportResolver
      mobileView={mobileView(videoId, children)}
      desktopView={desktopView(videoId, children)}
    />
  );
}

const desktopView = (videoId: string, children: React.ReactNode) => {
  return <VideoTile key={videoId} item xs>
      <VideoWrapper>
        <StyledIFrame src={`https://www.youtube-nocookie.com/embed/${videoId}`} allowFullScreen />
      </VideoWrapper>
    { !!children ? <TitleWrapper>{children}</TitleWrapper> : null }
    </VideoTile>
}

const mobileView = (videoId: string, children: React.ReactNode) => {
  return <VideoTileMobile key={videoId}>
      <VideoWrapper>
        <StyledIFrameMobile src={`https://www.youtube-nocookie.com/embed/${videoId}`} allowFullScreen />
      </VideoWrapper>
    { !!children ? <TitleWrapper>{children}</TitleWrapper> : null }
    </VideoTileMobile>
}
