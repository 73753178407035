import { Grid, styled, Typography, useMediaQuery } from "@mui/material";
import React, { useState } from "react"
import { AffiliateLink } from "../../models/AffiliateLink";
import { Gray16, GrayBold16, RoundedContainer } from "./userProfileStyles";
import CopyAllIcon from '@mui/icons-material/CopyAll';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { theme } from "../../style/theme";
import QRCode from "react-qr-code";
import { PaddedCopyAllIcon, PaddingTaskAltIcon } from "../../style/styles";

export interface AffiliateLinkDisplayProps {
  affiliateLink: AffiliateLink
}

export const AffiliateLinkDisplay = ({ affiliateLink }: AffiliateLinkDisplayProps) => {
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'));
  const [isCopied, setIsCopied] = useState(false);

  const onCopyClick = () => {
    setIsCopied(true);
    navigator.clipboard.writeText(affiliateLink.url);
    setTimeout(() => {
      setIsCopied(false);
    }, 750);
  }

  return (
    <RoundedContainer item container md={7.67} xs={12} direction={"column"} gap={3}>
      <Grid container direction={'row'}>
        <Typography variant={"h2"}>My Referral Link</Typography>
      </Grid>
      <Grid item container justifyContent={'space-between'} wrap={'nowrap'} direction={isMobile ? 'column' : 'row'}>
        <GrayBold16>{"Link"}</GrayBold16>
        <FlexGrid item wrap={isMobile ? 'wrap' : 'nowrap'} direction={isMobile ? 'column' : 'row'}>
          {isMobile
          ?
            <Gray10>{affiliateLink.url}</Gray10>
          :
            <Gray16>{affiliateLink.url}</Gray16>
          }
          {isCopied
            ? <PaddingTaskAltIcon />
            : <PaddedCopyAllIcon onClick={onCopyClick} />
          }
        </FlexGrid>
      </Grid>
      <Grid xs={12} item container>
        <QRCodeWrapper value={affiliateLink.url} size={235}/>
      </Grid>
    </RoundedContainer>
  );
}

const FlexGrid = styled(Grid)`
  display: flex;
  margin-left: 1rem;
`;

const QRCodeWrapper = styled(QRCode)`
  height: auto;
  min-width: 23.5rem;
`;

const Gray10 = styled(Gray16)`
  font-size: 14px;
  overflow-wrap: break-word;
`