import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { WebAppState } from '../../redux/WebAppState';
import { PrismicSection, PrismicSectionDispatchProps, PrismicSectionStateProps } from "../../components/shared/prismic/PrismicSection";
import { HEALTH_GUIDE_DOCUMENT_SET_SUB_SECTION_INDEX } from "../../redux/actions";

const mapStateToProps: MapStateToProps<PrismicSectionStateProps, {}, WebAppState> = state => ({
  subsectionIndex: state.healthGuide.navigation?.subSectionIndex ?? 0
});

const mapDispatchToProps: MapDispatchToProps<PrismicSectionDispatchProps, {}> = dispatch => ({
  setSelectedIndex: (index) => dispatch({type: HEALTH_GUIDE_DOCUMENT_SET_SUB_SECTION_INDEX, data: index}),
});

export default connect(mapStateToProps, mapDispatchToProps)(PrismicSection);