import Grid from '@mui/material/Grid';
import { styled } from "@mui/material/styles";
import { PrismicRichText } from "@prismicio/react";
import { RichTextField } from "@prismicio/types"
import React from "react";
import { useNavigate } from "react-router-dom";
import { healthGuideTextRenderer } from '../../utils/displayUtils';
import { healthGuideTheme, theme } from "../../style/theme";
import useMediaQuery from "@mui/material/useMediaQuery";
import nutritionLogo from "../../assets/healthguide/nutrition-logo.svg";
import workoutLogo from "../../assets/healthguide/workout-logo.svg";
import lifestyleLogo from "../../assets/healthguide/lifestyle-logo.svg";
import { ThemeProvider } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

export interface DocumentTileProps {
  title?: RichTextField,
  description?: RichTextField,
  backgroundImage?: string,
  mobileBackgroundImage?: string,
  documentId?: string,
  documentType: string,
  showDescription: boolean
}

interface DocumentTypeLogo {
  [name: string]: string;
}

export const DOCUMENT_TYPE_LOGOS: DocumentTypeLogo = {
  nutrition: nutritionLogo,
  workout: workoutLogo,
  lifestyle: lifestyleLogo
}

export const DocumentTile = ({ title, description, backgroundImage, mobileBackgroundImage, documentId, documentType, showDescription }: DocumentTileProps) => {
  const navigate = useNavigate();
  const renderTile = title && description && backgroundImage && mobileBackgroundImage && documentId;

  const navigateFunc = () => navigate(`/healthGuide/${documentId}`);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const image = (isMobile && showDescription) ? mobileBackgroundImage : backgroundImage;

  const documentTypeImage = DOCUMENT_TYPE_LOGOS[documentType];

  return (
    <ThemeProvider theme={healthGuideTheme}>
      {renderTile &&
        <WrapperGrid $isSquare={!showDescription}>
          <ImgWrapper src={image} $isSquare={!showDescription} />
          <OverlayDiv />
          <TileGrid container direction={'column'} justifyContent={'flex-start'} onClick={navigateFunc} $isSquare={!showDescription}>
            <TextWrapper item>
              <PrismicRichText field={title} components={healthGuideTextRenderer} />
            </TextWrapper>
            { showDescription &&
              <>
                <Dash/>
                <TextWrapper item>
                  <PrismicRichText field={description} />
                </TextWrapper>
              </>
            }
            <DocumentType>
              <DocumentTypeImage src={documentTypeImage}/>
              <Typography variant={'body2'}>{documentType.toUpperCase()}</Typography>
            </DocumentType>
          </TileGrid>
        </WrapperGrid>
      }
    </ThemeProvider>
  );
}

interface WrapperGridProps {
  $isSquare?: boolean;
}

const WrapperGrid = styled(Grid)<WrapperGridProps>`
  position: relative;
  width: 250px;
  height: 250px;
  border-radius: 9px;
  overflow: hidden;
  ${theme.breakpoints.down('sm')} {
    width: ${props => props.$isSquare ? 150 : 345}px;
    height: ${props => props.$isSquare ? 150 : 165}px;
  }
`;

const ImgWrapper = styled('img')<WrapperGridProps>`
  position: absolute;
  border-radius: inherit;
  width: 250px;
  height: 250px;
  ${theme.breakpoints.down('sm')} {
    width: ${props => props.$isSquare ? 150 : 345}px;
    height: ${props => props.$isSquare ? 150 : 165}px;
  }
  z-index: -2;
`;

const OverlayDiv = styled('div')`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.65);
  border-radius: inherit;
  z-index: -1;
  font-weight: bold;
`;

const TileGrid = styled(Grid)<WrapperGridProps>`
  height: 100%;
  width: 100%;
  padding: ${props => props.$isSquare ? '10px' : '25px 25px 10px 25px'};
  color: #FFFFFF;
  cursor: pointer;
`;

const TextWrapper = styled(Grid)`
  margin-top: 0.4rem;
`;

const Dash = styled('hr')`
  margin-left: 0;
  border-top: none;
  width: 1.7rem;
`;

const DocumentType = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  left: 1.2rem;
  bottom: 1.2rem;
`;

const DocumentTypeImage = styled('img')`
  margin-right: 0.4rem;
  height: 2.2rem;
  width: 2.2rem;
`;
