import {connect, MapDispatchToProps, MapStateToProps} from 'react-redux';
import { WebAppState } from '../../redux/WebAppState';
import { HealthGuideContent, HealthGuideContentDispatchProps, HealthGuideContentProps } from '../../components/healthguide/HealthGuideContent';
import { LOAD_HEALTH_GUIDE_DOCUMENT } from "../../redux/actions";

const mapStateToProps: MapStateToProps<HealthGuideContentProps, {}, WebAppState> = state => ({
  documentApi: state.system.apiCalls.getHealthGuideDocument,
  document: state.healthGuide.selectedDocument
});

const mapDispatchToProps: MapDispatchToProps<HealthGuideContentDispatchProps, {}> = dispatch => ({
  loadDocument: (docId: string) => dispatch({type: LOAD_HEALTH_GUIDE_DOCUMENT, data: docId }),
});


export default connect(mapStateToProps, mapDispatchToProps)(HealthGuideContent);