import React from 'react';
import { Navigate } from 'react-router-dom';
import { Product } from '../../models/Product';

export interface RequireProductProps {
  children: any;
}

export interface RequireProductStateProps {
  selectedProduct?: Product;
}

export const RequireProduct = ({children, selectedProduct}: RequireProductProps & RequireProductStateProps) => {
  if (!selectedProduct) {
    return (<Navigate to={'/'} replace />);
  } else {
    return children;
  }
};