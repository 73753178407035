import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { WebAppState } from '../../redux/WebAppState';
import { SET_NONINJECTABLE_SELECTION } from '../../redux/actions';
import { InjectableSelection, InjectableSelectionDispatchProps, InjectableSelectionProps } from '../../components/checkout/InjectableSelection';

const mapStateToProps: MapStateToProps<InjectableSelectionProps, {}, WebAppState> = (state: WebAppState) => ({
  nonInjectableOption: state.user.nonInjectableSelected == undefined ? '' : state.user.nonInjectableSelected ? 'pill' : 'injectable',
});

const mapDispatchToProps: MapDispatchToProps<InjectableSelectionDispatchProps, {}> = dispatch => ({
  setNonInjectableOption: ((isNonInjectable?:boolean) => dispatch({ type: SET_NONINJECTABLE_SELECTION, data: isNonInjectable}))
});

export default connect(mapStateToProps, mapDispatchToProps)(InjectableSelection);
