import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import React, { MouseEvent } from "react";
import { BoxRowCenteredItem, H2Text } from "../../../../style/styles";
import { ProgramBlock } from "./ProgramBlock";
import LogoutIcon from '@mui/icons-material/Logout';
import { isFeatureEnabled, USER_PROFILE } from "../../../../utils/FeatureUtils";

export interface MenuProps {
  navItemClick: (url: string)  => (e:MouseEvent) => void;
  logOutClick: (e: MouseEvent) => void;
  logo?: string;
}

export interface MenuCoreStateProps {
  isAdmin: boolean;
  isPhysician: boolean;
  userName: string;
  program?: string;
}

export const MenuCore = ({ navItemClick, logOutClick, userName, program, logo, isAdmin, isPhysician }: MenuProps & MenuCoreStateProps) => (
  <>
    <BoxRowCenteredItem>
      <MenuAvatar alt="GoalsRx Logo" src={logo} />
      <DoubleDecker>
        <UserNameText>{userName}</UserNameText>
        <ProgramBlock program={program}/>
      </DoubleDecker>
    </BoxRowCenteredItem>
    
    <MenuDivider />

    { isAdmin &&
      <BoxRowCenteredClickableItem onClick={(e) => { window.location.href=`https://${process.env.SHOPIFY_DOMAIN}/admin/orders`}}>
        <GrxLinkText>Order Management</GrxLinkText>
      </BoxRowCenteredClickableItem>
    }

    { isPhysician &&
      <BoxRowCenteredClickableItem onClick={navItemClick('/physician')}>
        <GrxLinkText>Health Profile Review</GrxLinkText>
      </BoxRowCenteredClickableItem>
    }

    { (isAdmin || isPhysician) &&
      <MenuDivider />
    }

    { (isFeatureEnabled(USER_PROFILE) && !!userName && userName.length > 0) &&
      <BoxRowCenteredClickableItem onClick={navItemClick('/profile')}>
        <LinkText>User Profile</LinkText>
      </BoxRowCenteredClickableItem>
    }

    <BoxRowCenteredClickableItem onClick={navItemClick('/about')}>
      <LinkText>What Is GoalsRx?</LinkText>
    </BoxRowCenteredClickableItem>
    <BoxRowCenteredClickableItem onClick={navItemClick('/healthGuide')}>
      <LinkText>Health Guide</LinkText>
    </BoxRowCenteredClickableItem>
    <BoxRowCenteredClickableItem onClick={navItemClick('/faq')}>
      <LinkText>FAQ</LinkText>
    </BoxRowCenteredClickableItem>

    <BoxRowCenteredClickableItem onClick={logOutClick}>
      <LinkText>Sign Out</LinkText>
      <LogoutIcon sx={{ marginLeft: '1rem', color: 'white'}} />
    </BoxRowCenteredClickableItem>
  </>
);

const MenuAvatar = styled(Avatar)`
  height: 6rem;
  width: 6rem;
`;

const UserNameText = styled(H2Text)`
  color: white;
  margin-left: 1rem;
`;

const DoubleDecker = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const MenuDivider = styled(Divider)`
  border-color: white;
  width: 45px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
  margin-bottom: 2rem;
  border-bottom-width: 2px;
`;

const BoxRowCenteredClickableItem = styled(BoxRowCenteredItem)`
  cursor: pointer;
`;

const LinkText = styled(Typography)`
  font-size: 2rem;
  text-transform: uppercase;
  cursor: pointer;
  color: white;
`;

const GrxLinkText = styled(LinkText)`
  color: #99DAFF;
`;