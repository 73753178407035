import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { initializeStore } from './redux/store';
import { AppContainer } from "./containers/AppContainer";
import { theme } from "./style/theme";
import { ThemeProvider } from "@mui/material/styles";
import { AwsInitializer } from './service/AwsInitializer';
import { BugsnagErrorHandler, DummyErrorHandler, ErrorHandler } from "./service/ErrorHandler";

const mountNode = document.getElementById('root');

let errorHandler: ErrorHandler;
if (process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'staging') {
  errorHandler = new DummyErrorHandler();
} else {
  errorHandler = new BugsnagErrorHandler();
}
const ErrorBoundary = errorHandler.getErrorBoundary();

AwsInitializer.initialize();

ReactDOM.render(
  <ErrorBoundary>
    <Provider store={initializeStore(errorHandler)}>
      <ThemeProvider theme={theme}>
        <AppContainer/>
      </ThemeProvider>
    </Provider>
  </ErrorBoundary>
, mountNode);