import Grid from "@mui/material/Grid";
import React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { Subscription } from "../../models/Subscription";
import { titleCase } from "../../utils/displayUtils";
import { Bold18, Font16, Gray16, Red16 } from "./userProfileStyles";
import Link from "@mui/material/Link";

export interface SubscriptionStatusStateProps {
  subscription: Subscription,
}

export const SubscriptionStatus = ({ subscription }: SubscriptionStatusStateProps) => (
  <Grid item container xs direction={"column"} justifyContent={"space-between"}>
    <Bold18>{subscription.name} by GoalsRx</Bold18>
    <Grid item container gap={1}>
      <Gray16>Status: </Gray16>
      { subscription.status === 'active' ? <Green16>{titleCase(subscription.status)}</Green16> : <Red16>{titleCase(subscription.status)}</Red16> }
    </Grid>
    { subscription.status === 'active' ? <Link16 href={subscription.editLink}>Edit Subscription</Link16> : undefined }
  </Grid>
)

const Green16 = styled(Font16)`
  color: #0FB520;
`;

const Link16 = styled(Link)`
  font-size: 1.6rem;
`;