import React from "react";
import { ContentSection } from "../../style/styles";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";

export const PaymentSuccessPage = () => {
  const calendlyUrl = "https://calendly.com/goalsrx/healthinterview";

  return (
    <>
      <ContentSection>
        <Typography variant={'h1'} gutterBottom><b>Step 4 of 4:</b> Connect with a GoalsRx medical practitioner.</Typography>
        <Typography variant={'h2'} gutterBottom>Click below to schedule your virtual kickoff appointment! We'll process your first month's payment after that.</Typography>
        <Link href={calendlyUrl}>Schedule Appointment</Link>
      </ContentSection>
    </>
  );
}

const Link = styled('a')`
  font-size: 2.4rem;
`;