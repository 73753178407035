import React, { useEffect } from 'react';
import { Loading } from "../shared/Loading";
import { HealthGuideContentSection } from "../../style/styles";
import { styled, ThemeProvider } from "@mui/material/styles";
import { HealthGuideHeader } from './HealthGuideHeader';
import { HealthGuideDocumentMetadata } from 'src/models/HealthGuideDocument';
import { DocumentGrid } from './DocumentGrid';
import HealthGuideNavigationBannerContainer from "../../containers/healthguide/HealthGuideNavigationBannerContainer";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { PRODUCTS } from "../../cms/ProductEntries";
import { themes } from "../../utils/displayUtils";
import buildHealthGuideHeader from '../../assets/build/build-health-guide-header.png';
import allProductsIcon from "../../assets/three-products-icon.png";
import shield from '../../assets/healthguide/shield.svg';
import useMediaQuery from "@mui/material/useMediaQuery";
import { theme } from "../../style/theme";

export interface HealthGuideProps {
  isSubscriptionLoading: boolean;
  isLoading: boolean;
  product: string;
  healthGuideDocs?: HealthGuideDocumentMetadata[],
  filter?: string;
}

export interface HealthGuideDispatchProps {
  loadHealthGuideDocs: () => void;
}

export const HealthGuide = ({ isSubscriptionLoading, isLoading, product, healthGuideDocs, loadHealthGuideDocs, filter }: HealthGuideProps & HealthGuideDispatchProps) => {
  const backgroundImage = PRODUCTS[product]?.displayInfo.healthGuideHeaderImage ?? buildHealthGuideHeader;
  const productIcon = PRODUCTS[product]?.displayInfo.productContents.icon ?? allProductsIcon;
  const productName = PRODUCTS[product]?.name ?? '';

  useEffect(() => {
    !isSubscriptionLoading &&
    loadHealthGuideDocs();
  }, [isSubscriptionLoading])

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const showTwoColumnView = !filter && isMobile;

  return (
    <ThemeProvider theme={themes[(product).toLowerCase()]}>
      { isLoading || isSubscriptionLoading
        ? <Loading/>
        : <>
          <HealthGuideHeader backgroundImage={backgroundImage}>
            <GridTop container justifyContent={'center'} alignItems={'center'}>
              <ProductIcon src={productIcon}/>
              {productName &&
                <UppercaseTypography variant={'h1'}>{productName}</UppercaseTypography>
              }
              <Shield src={shield}/>
              <Typography variant={'h2'}>Health Guide</Typography>
            </GridTop>
          </HealthGuideHeader>
          <HealthGuideNavigationBannerContainer/>
          <HealthGuideContentSection>
            {!isLoading
              ? <DocumentGrid documents={healthGuideDocs} isLoading={isLoading} showTwoColumnView={showTwoColumnView}/>
              : <Loading/>
            }
          </HealthGuideContentSection>
        </>
      }
    </ThemeProvider>
  );
}

const UppercaseTypography = styled(Typography)`
  text-transform: uppercase;
  margin-right: 1.3rem;
`;

const GridTop = styled(Grid)`
  margin-bottom: 1rem;
  color: white;
`;

const ProductIcon = styled('img')`
  height: 2.4rem;
  width: 2.4rem;
  margin-right: 1.1rem;
`;

const Shield = styled('img')`
  margin-right: 0.3rem;
`;