import { Promo } from "../../../models/Promo";

export const calcPrice = (price:number, promo?:Promo) => {
  let calculatedPrice = price;

  if (calculatedPrice > 0 && !!promo && promo.value) {
    calculatedPrice = calculatedPrice - promo.value;
  }

  return calculatedPrice < 0 ? 0 : calculatedPrice;
}