import useMediaQuery from "@mui/material/useMediaQuery";
import React, { MouseEvent } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../AuthContext";
import { theme } from "../../../../style/theme";
import { MobileHamburger } from "./MobileHamburger";
import { DesktopHamburger } from "./DesktopHamburger";

export interface HamburgerMenuParentProps {
  menuOpen: boolean;
  handleClose: () => void;
}

export interface HamburgerMenuParentDispatchProps {
  logoutUser: (logout: Function, navigate: () => void) => void;
}

export interface HamburgerMenuProps {
  navItemClick: (url: string)  => (e:MouseEvent) => void;
  logOutClick: (e: MouseEvent) => void;
  handleClose: () => void;
  menuOpen: boolean;
}

export const HamburgerMenuParent = ({ menuOpen, handleClose, logoutUser }:HamburgerMenuParentProps & HamburgerMenuParentDispatchProps) => {
  const navigate = useNavigate();
  const auth = useAuth();
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'));
  
  const navItemClick = (path: string) => (e: MouseEvent) => {
    e.preventDefault();
    navigate(path, {replace: true});
    handleClose();
  }

  const logOutClick = (e: MouseEvent) => {
    e.preventDefault();
    logoutUser(() => auth.signOut(), () => navigate('/', {replace: true}));
  }

  return (
    <>
      {isMobile
        ? <MobileHamburger 
            navItemClick={navItemClick}
            logOutClick={logOutClick}
            handleClose={handleClose}
            menuOpen={menuOpen}
          />
        : <DesktopHamburger 
            navItemClick={navItemClick}
            logOutClick={logOutClick}
            handleClose={handleClose}
            menuOpen={menuOpen}
          />
      }
    </>
  );
}