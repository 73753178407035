import * as Actions from "./actions";
import { SEND_RESCHEDULE_EMAIL } from "./actions";

export const GET_PRODUCTS = 'getProducts';
export const GET_ACTIVE_QUESTIONNAIRES = 'getActiveQuestionnaires';
export const GET_HEALTH_PROFILE = 'getHealthProfile';
export const HEALTH_PROFILE_REVIEW = 'healthProfileReview';
export const GET_HEALTH_GUIDE_DOCUMENT = 'getHealthGuideDocument';
export const SUBMIT_QUESTIONNAIRE = 'submitQuestionnaire';
export const CHECKOUT = 'checkout';
export const GET_HEALTH_GUIDE = 'getHealthGuide';
export const SEARCH_HEALTH_GUIDE = 'searchHealthGuide';
export const GET_USER_PROFILE = 'getUserProfile';
export const USER_LOGIN = 'userLogin';
export const SUBSCRIBE_NEWSLETTER = 'subscribeNewsletter';
export const GET_PROMO_VALUE = 'getPromoValue';
export const CREATE_USER = 'createUser';
export const UPDATE_PHOTO_ID = 'updatePhotoId';
export const PURCHASE_PRODUCT = 'purchaseProduct';

export const mapActionToApiCall = (action: string) => {
  switch (action) {
    case Actions.GET_PRODUCTS_SUCCESS:
    case Actions.GET_PRODUCTS_FAILED:
      return GET_PRODUCTS;
    case Actions.GET_ACTIVE_QUESTIONNAIRES_SUCCESS:
    case Actions.GET_ACTIVE_QUESTIONNAIRES_FAILED:
      return GET_ACTIVE_QUESTIONNAIRES;
    case Actions.GET_HEALTH_PROFILE_SUCCESS:
    case Actions.GET_HEALTH_PROFILE_FAILED:
      return GET_HEALTH_PROFILE;
    case Actions.HEALTH_PROFILE_REVIEW_SUCCESS:
    case Actions.HEALTH_PROFILE_REVIEW_FAILED:
      return HEALTH_PROFILE_REVIEW;
    case Actions.HEALTH_GUIDE_DOCUMENT_RECEIVED:
    case Actions.HEALTH_GUIDE_DOCUMENT_FAILED:
      return GET_HEALTH_GUIDE_DOCUMENT;
    case Actions.SUBMIT_QUESTIONNAIRE_SUCCESS:
    case Actions.SUBMIT_QUESTIONNAIRE_FAILED:
      return SUBMIT_QUESTIONNAIRE;
    case Actions.CHECKOUT_FAILED:
    case Actions.CHECKOUT_SUCCESS:
      return CHECKOUT;
    case Actions.HEALTH_GUIDE_FAILED:
    case Actions.HEALTH_GUIDE_RECEIVED:
      return GET_HEALTH_GUIDE;
    case Actions.USER_PROFILE_SUCCESS:
    case Actions.USER_PROFILE_SKIPPED:
    case Actions.USER_PROFILE_FAILED:
      return GET_USER_PROFILE;
    case Actions.USER_LOGIN_SUCCESS:
    case Actions.USER_LOGIN_FAILED:
      return USER_LOGIN;
    case Actions.SUBSCRIBE_NEWSLETTER_SUCCESS:
    case Actions.SUBSCRIBE_NEWSLETTER_FAILED:
      return SUBSCRIBE_NEWSLETTER;
    case Actions.GET_PROMO_VALUE_SUCCESS:
    case Actions.GET_PROMO_VALUE_FAILED:
        return GET_PROMO_VALUE;
    case Actions.CREATE_USER_SUCCESS:
    case Actions.CREATE_USER_FAILED:
      return CREATE_USER;
    case Actions.UPDATE_PHOTO_ID_SUCCESS:
    case Actions.UPDATE_PHOTO_ID_FAIL:
      return UPDATE_PHOTO_ID;
    case Actions.SEND_RESCHEDULE_EMAIL_SUCCESS:
    case Actions.SEND_RESCHEDULE_EMAIL_FAILED:
      return SEND_RESCHEDULE_EMAIL;
    case Actions.PURCHASE_PRODUCT:
    case Actions.PURCHASE_PRODUCT_FAILED:
      return PURCHASE_PRODUCT;
    default:
      return '';
  }
}