import {styled} from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import React from "react";

interface TestimonialPropsWrapper {
  props: TestimonialProps
}
export interface TestimonialProps {
  testimonial: string,
  signature: string
}

export const TestimonialBlock = ({props}: TestimonialPropsWrapper) => {
  return <>
    <Testimonial>{props.testimonial}</Testimonial>
    <Signature>{props.signature}</Signature>
  </>
}

const Testimonial = styled(Typography)`
  font-size: 1.8rem;
  font-weight: 300;
  line-height: 2.8rem;
  margin-top: 2.8rem;
  margin-bottom: 2.8rem;
`;

const Signature = styled(Typography)`
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 2rem;
`;