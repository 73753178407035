import { AnyAction, Reducer } from "redux";
import { DEFAULT_APP_STATE, UserState } from "../WebAppState";
import * as Actions from "../actions";
import { GoalResponse } from "../../models/Goal";

export const userReducer: Reducer<UserState> = ((state = DEFAULT_APP_STATE.user, action: AnyAction): UserState => {
  switch (action.type) {
    case Actions.SELECT_PRODUCT:
      return {
        ...state,
        intake: {
          ...state?.intake,
          questionnaireResponses: {
            ...state?.intake.questionnaireResponses,
            importantDocuments: getImportantDocuments(action.name),
          },
          goalResponses: []
        }
      };
    case Actions.SET_SELECTED_PRODUCT:
      return {
        ...state,
        selectedProduct: action.data
      };
    case Actions.SET_NONINJECTABLE_SELECTION:
      return {
        ...state,
        nonInjectableSelected: action.data
      };
    case Actions.SET_TEMP_PASSWORD:
      return {
        ...state,
        temporaryPassword: action.data,
      };
    case Actions.CLEAR_TEMP_PASSWORD:
      return {
        ...state,
        temporaryPassword: undefined,
      };
    case Actions.UPDATE_QUESTIONNAIRE:
      return {
        ...state,
        intake: {
          ...state?.intake,
          questionnaireResponses: {
            ...state?.intake.questionnaireResponses,
            ...action.data,
          }
        }
      };
    case Actions.UPDATE_GOAL_RESPONSES:
      return {
        ...state,
        intake: {
          ...state?.intake,
          goalResponses: updateGoalResponses(state?.intake.goalResponses, action.data)
        }
      }
    case Actions.SUBMIT_QUESTIONNAIRE_SUCCESS:
      return {
        ...state,
        intake: {
          ...state?.intake,
          success: true,
        },
        birthdate: action.data
      };
    case Actions.CLEANUP_QUESTIONNAIRE:
      return {
        ...state,
        nonInjectableSelected: undefined,
        intake: {
          ...DEFAULT_APP_STATE.user.intake,
          success: false,
          questionnaireSubmitted: false,
        }
      };
    case Actions.SET_USER:
      return {
        ...state,
        user: action.data,
      };
    case Actions.SET_USER_NAME:
      return {
        ...state,
        user: {
          ...state.user!,
          name: action.data ?? '',
        }
      };
    case Actions.SET_SUBSCRIPTION:
      return {
        ...state,
        subscription: {
          ...action.data
        }
      };
    case Actions.USER_LOGOUT:
      return {
        ...DEFAULT_APP_STATE.user
      };
    case Actions.CHECKOUT_SUCCESS:
      return {
        ...state,
        purchasePrice: action.data
      };
    case Actions.GET_PROMO_VALUE_SUCCESS:
      return {
        ...state,
        promo: action.data
      };
    case Actions.SET_PURCHASE_PRICE:
      return {
        ...state,
        purchasePrice: action.data
      };
    case Actions.USER_PROFILE_SUCCESS:
      return {
        ...state,
        profile: action.data
      }
    default:
      return state;
  }
});

function getImportantDocuments(selectedProductName: string) {
  const mapping: { [name: string]: string[] } = {
    burn: ['icw-peptide-aod-9604', 'privacy-policy', 'telehealth-consent', 'terms'],
    build: ['icw-ibutamoren', 'injection-consent', 'privacy-policy', 'telehealth-consent', 'terms'],
    thrive: ['icw-ipamorelin', 'injection-consent', 'privacy-policy', 'telehealth-consent', 'terms'],
  };

  return mapping[selectedProductName.toLowerCase()];
}

function updateGoalResponses(currentResponses: GoalResponse[], newGoalResponse: GoalResponse) {
  const existingIndex = currentResponses.findIndex(goalResponse => goalResponse.goal.key === newGoalResponse.goal.key);
  if (existingIndex >= 0) {
    if (newGoalResponse.goal.type === 'checkbox') {
      currentResponses.splice(existingIndex, 1);
    } else {
      currentResponses[existingIndex] = newGoalResponse;
    }
  } else {
    currentResponses.push(newGoalResponse);
  }

  return [...currentResponses];
}
