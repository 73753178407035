export const USER_PROFILE = 'userProfile';

interface EnvironmentFeatures {
  [name: string]: string[];
}

export const environmentFeatures: EnvironmentFeatures = {
  staging: [
    USER_PROFILE
  ],
  production: [USER_PROFILE]
}

export const isFeatureEnabled = (feature: string) => {
  const environment = process.env.NODE_ENV || 'production';
  return environment === 'development' ||
    (!!environmentFeatures[environment] && environmentFeatures[environment].filter(envFeature => envFeature === feature).length === 1);
}