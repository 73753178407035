import { Subscription } from "../../models/Subscription";
import Grid from "@mui/material/Grid";
import { PRODUCTS } from "../../cms/ProductEntries";
import Typography from "@mui/material/Typography";
import { Product } from "../../models/Product";
import { SubscriptionOption } from "../../models/SubscriptionOption";
import { Promo } from "../../models/Promo";
import React from "react";
import { styled } from "@mui/material/styles";
import { SubscriptionStatus } from "./SubscriptionStatus";
import { Bold18, Font16, Gray16, Line, Red16, RoundedContainer } from "./userProfileStyles";

interface SubscriptionDetailProps {
  subscription: Subscription
}
export const SubscriptionDetail = ({ subscription }: SubscriptionDetailProps) => {
  const product = PRODUCTS[subscription.name.toLowerCase()];
  return (
    <SubscriptionDetailWrapper container direction={"column"} gap={3}>
      <Typography variant={"h2"}>Subscription</Typography>
      <Line/>
      <Grid item container justifyContent={"space-between"} alignItems={"center"} gap={2}>
        <ProductBoxWrapper item container xs={12} md={5} justifyContent={"space-between"} gap={2}>
          { !!product ? <LogoBox product={product}/> : undefined }
          { !!product ? <SubscriptionStatus subscription={subscription} /> : undefined }
        </ProductBoxWrapper>
        {subscription.options &&
          <ProductOptions options={subscription.options || []} />
        }
        <PriceDetail subscription={subscription} />
      </Grid>
      <Line/>
      <SubscriptionRecurrence subscription={subscription}/>
    </SubscriptionDetailWrapper>
  )
}

interface SubscriptionRecurrenceProps { subscription: Subscription }
const SubscriptionRecurrence = ({ subscription }: SubscriptionRecurrenceProps) => (
  <Grid item container gap={3}>
    <Font16>Order recurrence: {subscription.orderInterval}</Font16>
    <Gray16>Start date: {subscription.startDate}</Gray16>
    { !!subscription.endDate ? <Gray16>End date: {subscription.endDate}</Gray16> : undefined }
  </Grid>
)

interface LogoBoxProps { product: Product }
const LogoBox = ({ product }: LogoBoxProps) => {
  return (
    <LogoBoxWrapper item container gap={1} alignItems={"center"} wrap={'nowrap'}>
      <img src={product.displayInfo.productContents.icon}/>
      <ProductLogoName variant={"h2"}>{product.name?.toUpperCase()}</ProductLogoName>
    </LogoBoxWrapper>
  )
}

interface ProductOptionsProps { options: SubscriptionOption[] }
const ProductOptions = ({ options }: ProductOptionsProps) => (
  <Grid item container xs={12} sm={7.5} md={3.5} direction={"column"} justifyContent={"space-between"}>
    <Bold18>Product Options</Bold18>
    { options.length === 1 && !options[0].name ? <GrayNarrow16>None</GrayNarrow16> :
      options.map((option) =>
        <div key={option.name}>
          <GrayNarrow16>Name: {option.name}</GrayNarrow16>
          <GrayNarrow16>Description: {option.description}</GrayNarrow16>
        </div>
      )}
  </Grid>
)

interface PriceDetailProps { subscription: Subscription }
const PriceDetail = ({ subscription }: PriceDetailProps) => (
  <Grid item container xs={12} sm={3.5} md={1.8} direction={"column"} justifyContent={"space-between"}>
    <Bold18>Price: ${subscription.price}</Bold18>
    <ProductDiscounts discount={subscription.promoCode} />
  </Grid>
)

interface ProductDiscountsProps { discount: string | undefined }
const ProductDiscounts = ({ discount }: ProductDiscountsProps) => (
  <>
    <GrayNarrow16>Promotions</GrayNarrow16>
    { !discount ? <GrayNarrow16>None</GrayNarrow16> :
        <div>
          <RedNarrow16>Code: {discount}</RedNarrow16>
        </div>
      }
  </>
)

const SubscriptionDetailWrapper = styled(RoundedContainer)`
  width: 100%;
`;

const GrayNarrow16 = styled(Gray16)`
  font-weight: 300;
`;

const RedNarrow16 = styled(Red16)`
  font-weight: 300;
`;

const ProductBoxWrapper = styled(Grid)`
  background-color: #F4F4F4;
  padding: 2rem;
`;

const LogoBoxWrapper = styled(Grid)`
  background-color: white;
  padding: 3.5rem 2.2rem;
  width: fit-content;
`;

const ProductLogoName = styled(Typography)`
  font-size: 2.8rem;
  color: black;
`;