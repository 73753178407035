import Grid from '@mui/material/Grid';
import { styled } from "@mui/material/styles";
import Skeleton from '@mui/material/Skeleton';
import React from "react";
import { DocumentTile } from "./DocumentTile";
import { HealthGuideDocumentMetadata } from "src/models/HealthGuideDocument";
import { theme } from "../../style/theme";

export interface DocumentGridProps {
  documents: HealthGuideDocumentMetadata[] | undefined,
  isLoading: boolean,
  showTwoColumnView: boolean
}

export const DocumentGrid = ({ documents, isLoading, showTwoColumnView }: DocumentGridProps) => {
  return (
    <GridWrapper container justifyContent={'center'} alignItems={'center'} gap={showTwoColumnView ? 1 : 4}>
      {!documents || isLoading ?
        <Skeleton
          variant={'rectangular'}
          animation={'wave'}
          height={250}
          width={250}
        />
        :
        documents.map(item =>
          <DocumentTile
            key={item.id}
            title={item.title}
            description={item.description}
            backgroundImage={item.tileImgSrc}
            mobileBackgroundImage={item.mobileTileImgSrc}
            documentId={item.id}
            documentType={getDocumentType(item.tags)}
            showDescription={!showTwoColumnView}
          />
        )
      }
    </GridWrapper>
  );
}

const GridWrapper = styled(Grid)`
  z-index: 0;
  margin-top: 32.9rem;
  ${theme.breakpoints.down('lg')} {
    margin-top: 28.9rem;
  }
  ${theme.breakpoints.down('sm')} {
    margin-top: 32rem;
  }
`;

const getDocumentType = (tags?: string[]) => {
  if (!!tags && tags.includes('nutrition')) {
    return 'nutrition';
  }
  if (!!tags && tags.includes('workout')) {
    return 'workout';
  }
  return 'lifestyle';
}
