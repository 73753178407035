import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import SignInFormContainer from '../../containers/account/SignInFormContainer';
import CreateAccountFormContainer from '../../containers/account/CreateAccountFormContainer';
import { ForgotPasswordForm } from "./ForgotPasswordForm";
import Button from "@mui/material/Button";

export const CreateAccountPage = () => {
  const [userHasAccount, setUserHasAccount] = useState(true);
  const [forgotPassword, setForgotPassword] = useState(false);

  const setForgotPasswordFlow = () => {
    setForgotPassword(true);
    setUserHasAccount(true);
  }

  const setCreateAccountFlow = () => {
    setForgotPassword(false);
    setUserHasAccount(false);
  }

  const setLoginFlow = () => {
    setForgotPassword(false);
    setUserHasAccount(true);
  }

  return (
    <Grid container justifyContent={'center'} alignItems={'center'} direction={'column'}>
      {!userHasAccount ?
        <>
          <CreateAccountFormContainer />
          <Toggle onClick={setLoginFlow}>I already have an account</Toggle>
        </>
        :
        forgotPassword ?
          <>
            <ForgotPasswordForm />
            <Toggle onClick={setLoginFlow}>I know my password</Toggle>
            <Toggle onClick={setCreateAccountFlow}>I don't have an account</Toggle>
          </>
        :
        <Grid container justifyContent={'center'} alignItems={'center'} direction={'column'}>
          <Typography variant={'h1'} gutterBottom sx={{ marginTop: '3rem' }}>Sign in</Typography>
          <Grid container justifyContent={'center'} alignItems={'center'}>
            <Typography>Don't have an account?</Typography>
            <Button sx={{p: '0 0 0 1rem'}} onClick={setCreateAccountFlow}>Create an account</Button>
          </Grid>
          <SignInFormContainer />
          <Toggle onClick={setForgotPasswordFlow}>I forgot my password</Toggle>
        </Grid>
      }
    </Grid>
  );
};

const Toggle = styled(Button)`
  margin: 2rem 0 0;
  cursor: pointer;
`;