import { Product, ProductBase } from '../models/Product';
import { HealthQuestionnaire } from '../models/HealthQuestionnaire';
import { HealthProfile } from '../models/HealthProfile';
import { User } from '../models/User';
import { Client } from '@prismicio/client';
import { HttpClient } from '../service/HttpClient';
import { HealthGuideDocument, HealthGuideDocumentMetadata } from '../models/HealthGuideDocument';
import { CmsService } from '../service/CmsService';
import { Subscription } from '../models/Subscription';
import { Promo } from '../models/Promo';
import { StorageService } from '../service/StorageService';
import { UserProfile } from '../models/UserProfile';
import { ErrorHandler } from '../service/ErrorHandler';
import { GoalResponse } from '../models/Goal';
import { ActiveQuestionnaire } from '../models/ActiveQuestionnaire';

export interface WebAppState {
  physician: PhysicianState;
  user: UserState;
  system: SystemState;
  healthGuide: HealthGuideState;
}

export interface PhysicianState {
  activeQuestionnaires: ActiveQuestionnaire[]
  healthProfile?: HealthProfile;
}

export interface UserState {
  user?: User;
  selectedProduct?: Product;
  nonInjectableSelected?: boolean;
  temporaryPassword?: string;
  intake: {
    questionnaireSubmitted: boolean;
    questionnaireResponses: HealthQuestionnaire;
    goalResponses: GoalResponse[];
    success: boolean;
  }
  subscription?: Subscription;
  purchasePrice?: number;
  promo?: Promo;
  profile?: UserProfile;
  birthdate?: string;
}

export interface ApiCalls {
  [apiCall: string]: ApiCallState,
}

export interface ApiCallState {
  isLoading: boolean;
  apiError?: Error;
}

export interface SystemState {
  errorHandler?: ErrorHandler;
  products: ProductBase[];
  cmsClient?: Client;
  cmsService?: CmsService;
  httpClient?: HttpClient;
  apiCalls: ApiCalls;
  storageService?: StorageService;
  shopifyClient?: ShopifyBuy.Client;
}

export interface HealthGuideState {
  contentTypes: string[];
  documentList: HealthGuideDocumentMetadata[];
  filter?: string;
  selectedDocument?: HealthGuideDocument;
  navigation?: HealthGuideNavigationState;
  programFilter?: string;
}

export interface HealthGuideNavigationState {
  sectionIndex?: number;
  subSectionIndex?: number;
}

export const DEFAULT_APP_STATE: WebAppState = {
  physician: {
    activeQuestionnaires: []
  },
  system: {
    products: [],
    apiCalls: {
      getActiveQuestionnaires: {isLoading: false},
      getHealthProfile: {isLoading: false},
      healthProfileReview: {isLoading: false},
      healthGuideDocument: {isLoading: false},
      submitQuestionnaire: {isLoading: false},
      getHealthGuide: {isLoading: false},
      getHealthGuideDocument: {isLoading: false},
      getUserProfile: {isLoading: false},
      userLogin: {isLoading: false},
      subscribeNewsletter: {isLoading: false},
      getPromoValue: {isLoading: false},
      getProducts: {isLoading: false},
      createUser: {isLoading: false},
      updatePhotoId: {isLoading: false},
      sendRescheduleEmail: {isLoading: false},
      purchaseProduct: {isLoading: false}
    }
  },
  healthGuide: {
    contentTypes: [],
    documentList: []
  },
  user: {
    nonInjectableSelected: undefined,
    intake: {
      questionnaireSubmitted: false,
      success: false,
      questionnaireResponses: {
        fullName: '',
        birthDate: '',
        gender: '',
        heightFeet: 0,
        heightInches: 0,
        weight: 0,
        pregnant: '',
        t1Diabetes: '',
        t2Diabetes: '',
        insulinDependent: '',
        cancerCurrent: '',
        cancerLessThan7Years: '',
        cancerMoreThan7Years: '',
        cardio: '',
        stroke: '',
        liver: '',
        gallbladder: '',
        kidney: '',
        thyroid: '',
        depression: '',
        anxiety: '',
        bipolar: '',
        schizophrenia: '',
        borderline: '',
        medications: '',
        medicationDetail: '',
        medullaryThyroidCancer: '',
        familyMedullaryThyroidCancer: '',
        multipleEndocrineNeoplasia: '',
        familyMultipleEndocrineNeoplasia: '',
        glp1Peptides: '',
        importantDocuments: [],
        acceptedDocuments: [],
        photoId: null
      },
      goalResponses: []
    },
  },
}
