import { connect, MapStateToProps } from "react-redux";
import { AuthDisplay, AuthDisplayProps } from "../components/shared/authDisplay/AuthDisplay";
import { WebAppState } from "src/redux/WebAppState";


const mapStateToProps: MapStateToProps<AuthDisplayProps, {}, WebAppState> = (state: WebAppState) => ({
  user: state.user.user,
  program: state.user.subscription?.name,
  loading: state.system.apiCalls.getUserProfile.isLoading
});

export const AuthDisplayContainer = connect(mapStateToProps)(AuthDisplay);