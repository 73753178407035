import { styled } from "@mui/material";
import React, { ElementType } from "react";
import { ApiCallState } from "../../../redux/WebAppState";
import { UploadStatus } from "./UploadStatus";
import { UploadInput } from "./UploadInput";

export interface ImageUploaderProps {
  WrapperButton: ElementType; // styled 'Button' component defined in parent
  buttonText: string;
  filename: string; // stored in parent component state
  filenamePlaceholder: string;
  fileResultHandler: (file: string) => void; // what the parent wants to do with the file
  setFilename: (fileName: string) => void;
  disabled: boolean; // disable during uploads
  justifyRight: boolean; // button on right
  photoIdUpdateApi?: ApiCallState | undefined
};

export const ImageUploader = ({ WrapperButton, buttonText, filename, filenamePlaceholder, fileResultHandler, setFilename, disabled, justifyRight, photoIdUpdateApi }: ImageUploaderProps) => {
  return (
    <>
      {!!justifyRight 
        ? <UploaderGridRight>
            <UploadStatus filename={filename} filenamePlaceholder={filenamePlaceholder} photoIdUpdateApi={photoIdUpdateApi} />
            <UploadInput WrapperButton={WrapperButton} setFilename={setFilename} fileResultHandler={fileResultHandler} buttonText={buttonText} disabled={disabled} />
          </UploaderGridRight>
        : <UploaderGridLeft>
            <UploadInput WrapperButton={WrapperButton} setFilename={setFilename} fileResultHandler={fileResultHandler} buttonText={buttonText} disabled={disabled} />
            <UploadStatus filename={filename} filenamePlaceholder={filenamePlaceholder} photoIdUpdateApi={photoIdUpdateApi} />
          </UploaderGridLeft>
      }
    </>
  )
}

const UploaderGridLeft = styled('div')`
  display: inline-flex;
  align-items: unset;
  align-items: center;
  max-width: fit-content;
  min-height: 43px;
`;

const UploaderGridRight = styled(UploaderGridLeft)`
  margin-left: auto;
`;
