import Grid from '@mui/material/Grid';
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React, {useEffect, useState} from "react";
import { priceFormatter } from "../../../utils/displayUtils";
import { LineItem } from "../LineItem";
import CircleExclamationIcon from "../../../assets/circle-exclamation-icon.svg";
import { Product, ProductBase } from "../../../models/Product";
import { Box } from "../CheckoutPage";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Info } from "@mui/icons-material";
import Link from "@mui/material/Link";
import { Promo } from "../../../models/Promo";
import { calcPrice } from "./priceHelpers";
import { Loading } from "../../shared/Loading";
import PromoEntryContainer from "../../../containers/checkout/PromoEntryContainer";

export interface CartCheckoutSummaryProps {
  programInformation: () => void;
  setProductOption: (optionId: number, value: boolean) => void;
  productInCart?: Product;
  stateProduct?: ProductBase;
}

export interface CartCheckoutSummaryStateProps {
  promo?: Promo;
  promoLoading: boolean;
}

export interface CartCheckoutSummaryDispatchProps {
  updatePurchasePrice: (price:number) => void;
}

export const CartCheckoutSummary = ({programInformation, setProductOption, productInCart, stateProduct, promo, promoLoading, updatePurchasePrice} : CartCheckoutSummaryProps & CartCheckoutSummaryStateProps & CartCheckoutSummaryDispatchProps) => {
  const [viewProductOptionPopOut, setViewProductOptionPopOut] = useState(false);
  const [popOutTitle, setPopOutTitle] = useState('');
  const [popOutDescription, setPopOutDescription] = useState('');

  const productPrice = !!stateProduct && !!stateProduct.price ? stateProduct.price : 0;
  const totalPrice = calcPrice(productPrice, promo);

  useEffect(() => {
    updatePurchasePrice(totalPrice);
  }, [promo]);

  const displayPopOut = (title: string, description: string) => {
    setPopOutTitle(title);
    setPopOutDescription(description);
    setViewProductOptionPopOut(true);
  }

  return (
    <Box item container direction={'column'} spacing={2}>
      <ItemBox item container direction={'column'}>
        <ProgramSection item container spacing={2}>
          <Grid item><Header>Your Program</Header></Grid>
          <Grid item><img src={CircleExclamationIcon} onClick={programInformation}/></Grid>
        </ProgramSection>
        <GrayLine item>
          <LineItem item={`${productInCart?.name} by GoalsRx`} price={priceFormatter.format(productPrice)} />
        </GrayLine>
        {/* {!!productInCart && !!productInCart.checkoutOptions &&
          <Grid item>
            <ProductOptionsHeader>Product Options</ProductOptionsHeader>
            { productInCart.checkoutOptions.map( option =>
              <Grid container alignItems={'center'} key={option.id}>
                <FormControlLabel
                  id={`${option.id}`}
                  control={<Checkbox onChange={(e) => setProductOption(option.id, e.target.checked)} />}
                  label={<ProductOption>{option.name}</ProductOption>}
                />
                <SmallInfo onClick={() => displayPopOut(option.name, option.description)}/>
                <Dialog
                  open={viewProductOptionPopOut}
                  onClose={() => setViewProductOptionPopOut(false)}
                  scroll={'paper'}
                >
                  <DialogTitle>{popOutTitle}</DialogTitle>
                  <DialogContent dividers sx={{padding: '1rem'}}>
                    <Typography>{popOutDescription}</Typography>
                  </DialogContent>
                  <DialogActions>
                    <Clickable onClick={() => setViewProductOptionPopOut(false)}>Close</Clickable>
                  </DialogActions>
                </Dialog>
              </Grid>
            )}
          </Grid>
        } */}
      </ItemBox>
      {promoLoading
        ? <Loading />
        : <>
          <LineItem item={"Subtotal"} price={priceFormatter.format(productPrice)} />
          {!!promo &&
            <LineItem item={`Promo: ${promo.code}`} price={`(${priceFormatter.format(promo.value)})`} />
          }
          <LineItem item={"Shipping"} price={"$0.00"} />
          <LineItem item={"Sales Tax"} price={"Included"} />
          <LineItem item={"Total"} price={priceFormatter.format(totalPrice)} />
          <PromoEntryContainer />
        </>
      }
    </Box>
  );
};

const ItemBox = styled(Grid)`
  background: #FFFFFF;
  padding: 3.4rem 1.6rem 3rem 1.6rem;
  margin: 0 0 1.4rem 0;
`;

const ProgramSection = styled(Grid)`
  padding-top: 2rem;
  padding-bottom: 1rem;
`;

const GrayLine = styled(Grid)`
  border-top: 1px;
  border-color: #CCCCCC;
  border-top-style: solid;
  padding-top: 2rem;
`;

const Header = styled(Typography)`
  font-size: 2.4rem;
  font-weight: 500;
`;

const ProductOptionsHeader = styled(Typography)`
  font-size: 1.8rem;
  font-weight: 500;
`;

const ProductOption = styled(Typography)`
  font-size: 1.6rem;
`;

const SmallInfo = styled(Info)`
  height: 2rem;
  width: 2rem;
`;

const Clickable = styled(Link)`
  cursor: pointer;
`;