import { Grid } from "@mui/material";
import React from "react";
import { Gray16, GrayBold16 } from "./userProfileStyles";

export interface ProfileTextRowProps {
  rowLabel: string;
  rowContent: string;
}

export const ProfileTextRow = ({ rowLabel, rowContent }: ProfileTextRowProps) => {
  return (
    <Grid item container justifyContent={'space-between'}>
      <GrayBold16>{rowLabel}</GrayBold16>
      <Gray16>{rowContent}</Gray16>
    </Grid>
  );
}