import React, { FormEvent, useEffect, useState } from 'react';
import { Button, ContentSection, Input, Label } from '../../../style/styles';
import Grid from '@mui/material/Grid';
import { Loading } from '../../shared/Loading';
import { styled } from '@mui/material/styles';
import { useAuth } from '../../shared/AuthContext';
import { useLocation, useNavigate } from 'react-router-dom';
import Typography from "@mui/material/Typography";
import { SignUpConfirmationError } from '../../../models/SignUpConfirmationError';
import { SignupConfirmationErrorMessage } from './SignUpConfirmationErrorMessage';
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

export interface SignUpConfirmationPageProps {
  temporaryPassword: string;
  username: string;
}

export interface SignUpConfirmationPageDispatchProps {
  reportError: (error: Error) => void,
  clearTemporaryPassword: () => void,
  createUser: (username: string, referralId?: string) => void,
  dispatchLoginSuccess: () => void,
}

export const SignUpConfirmationPage = ({temporaryPassword, username, reportError, clearTemporaryPassword, createUser, dispatchLoginSuccess}: SignUpConfirmationPageProps & SignUpConfirmationPageDispatchProps) => {
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState('');
  const [error, setError] = useState<SignUpConfirmationError | null>(null);
  const [successOpen, setSuccessOpen] = React.useState(false);

  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as {
    redirectPath?: string;
  }
  const redirectPath = state?.redirectPath || '/';

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    setError(null);
    setLoading(true);

    try {
      const uname = !!auth.user ? auth.user.username : username;
      const referralCode = localStorage.getItem('referralCode') ?? undefined;
      await auth.confirmSignUp(uname, code)
        .then(() => auth.signIn(uname, temporaryPassword))
        .then(clearTemporaryPassword)
        .then(() => createUser(uname, referralCode))
        .then(dispatchLoginSuccess)
        .then(() => navigate(redirectPath))
        .catch(err => {
          handleError(err);
        });
    } catch (err) {
      // this should only happen in the event someone is navigating directly to /confirmSignUp 
      // after their session has expired (`auth.user` is undefined)
      handleError(err);
    }
  };

  const handleError = (error: Error | any) => {
    reportError(error);
    setLoading(false);
    setCode('');

    if (error.name === 'CodeMismatchException') {
      setError(new SignUpConfirmationError(true));
    } else {
      setError(new SignUpConfirmationError(false));
    }
  }

  //Kick the user to login if we lost their unverified-username or their sign-up-auth-stuff
  // A user might wind up here if they hit this page directly or they refresh while they're on this page
  useEffect(() => {
    if(!auth.user && !username) {
      navigate('/login');
    }
  }, []);

  const resendVerificationCode = () => {
    const uname = !!auth.user ? auth.user.username : username;
    auth.resendVerificationCode(uname);
    setSuccessOpen(true);
  }

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setSuccessOpen(false);
  };

  return (
    <>
      <Snackbar open={successOpen} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          Verification code sent!
        </Alert>
      </Snackbar>
      <ContentSection>
        <Typography variant={'h1'} gutterBottom><b>Step 1 of 4:</b> Verify Your Email Address</Typography>
        <Typography variant={'h2'} gutterBottom>Enter the 6-digit verification code we sent to your email.</Typography>
      </ContentSection>
      <Grid container justifyContent={'center'} alignItems={'center'} direction={'column'}>
        <form onSubmit={handleSubmit}>
          <Grid container direction={'column'} alignItems={'center'}>
            <Grid item xs={11} sm={6} md={4} lg={3} container direction={'column'} padding={1}>
              <Label>Verification Code</Label>
              <Input data-testid={'code-input'} type={'text'} value={code} onChange={(e) => setCode(e.target.value)} required />
            </Grid>
            <ConfirmSignUpButton type={'submit'}>{loading ? <Loading /> : 'Create Account'}</ConfirmSignUpButton>
            <Toggle onClick={resendVerificationCode}>I need another code</Toggle>
            {error &&
              <SignupConfirmationErrorMessage canRetry={error.retry} />
            }
          </Grid>
        </form>
      </Grid>
    </>
  );
}

const ConfirmSignUpButton = styled(Button)`
  margin: 2rem 0 0;
`;

const Toggle = styled(Typography)`
  margin: 2rem 0 0;
  cursor: pointer;
`;