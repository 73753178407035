import { JSXMapSerializer } from '@prismicio/react';
import Typography from '@mui/material/Typography';
import React from 'react';
import ListItem from "@mui/material/ListItem";
import { Theme } from "@mui/material/styles";
import { buildTheme, burnTheme, thriveTheme, freeTheme } from "../style/theme";
import { Address } from "../models/Address";
import Grid from "@mui/material/Grid";
import { EmbeddedYouTubeVideo } from "../components/shared/embeddedVideo/EmbeddedYouTubeVideo";
import { EmbeddedVideo } from '../components/shared/embeddedVideo/EmbeddedVideo';

export const priceFormatter = new Intl.NumberFormat('en-US',
  {
    style: 'currency', currency: 'USD',
    minimumFractionDigits: 2,
  });

export const prismicToMuiRenderer: JSXMapSerializer = {
  heading1: ({ children }) => <Typography variant={'h1'} gutterBottom>{children}</Typography>,
  paragraph: ({ children }) => <Typography gutterBottom>{children}</Typography>,
}

function jankifyUrl(url: string) {
  const videoId = url.split('watch?v=')[1];
  return `https://youtu.be/${videoId}`;
}

//TODO: Put these in as we need them. This is a comprehensive list of everything in Prismic.
// These probably shouldn't all be Typography
export const healthGuideTextRenderer: JSXMapSerializer = {
  heading1: ({ children }) => <Typography variant={'h1'}>{children}</Typography>,
  heading2: ({ children }) => <Typography variant={'h2'}>{children}</Typography>,
  heading3: ({ children }) => <Typography variant={'h3'}>{children}</Typography>,
  heading4: ({ children }) => <Typography variant={'h4'}>{children}</Typography>,
  // heading5: ({ children }) => <Typography variant={'h5'}>{children}</Typography>,
  heading6: ({ children }) => <Typography>{children}</Typography>,
  paragraph: ({ children }) => <Typography>{children}</Typography>,
  // preformatted: ({ children }) => <Typography>{children}</Typography>,
  // strong: ({ children }) => <Typography variant={'h6'}>{children}</Typography>,
  // em: ({ children }) => <Typography variant={'h6'}>{children}</Typography>,
  listItem: ({ children }) => <ListItem>{children}</ListItem>,
  oListItem: ({ children }) => <ListItem sx={{listStyleType: 'decimal'}}>{children}</ListItem>,
  // list: ({ children }) => <List>{children}</List>,
  // oList: ({ children }) => <List>{children}</List>,
  // image: ({ children }) => <Typography variant={'h6'}>{children}</Typography>,
  embed: ({ node }) =>  {
    if (node.oembed.embed_url.includes('youtube')) {
      return <Grid item container xs={12} md={8}><EmbeddedYouTubeVideo url={jankifyUrl(node.oembed.embed_url)}/></Grid>
    } else if (node.oembed.embed_url.includes('vimeo')) {
      return <Grid item container xs={12} md={8}><EmbeddedVideo url={node.oembed.embed_url}/></Grid>
    }
    return <Grid item container xs={12} md={8}> <div style={{overflow: 'auto'}} dangerouslySetInnerHTML={{__html: node.oembed.html as any}} /></Grid>
  },
  // hyperlink: ({ children }) => <Typography variant={'h6'}>{children}</Typography>,
  // label: ({ children }) => <Typography>{children}</Typography>,
  // span: ({ children }) => <Typography>{children}</Typography>
}

export function formatZip(value: string) {
  let zip = value.replace(/\D/g, '');

  if (zip.length === 5) {
    return zip;
  }
  if (zip.length === 9) {
    return zip.substring(0,5) + '-' + zip.substring(5, 9);
  }

  return '';
}

export function formatExpiration(value: string) {
  let expiration = value.replace(/\D/g, '');

  if (expiration.length === 4) {
    return expiration;
  }
  if (expiration.length === 3) {
    return '0' + expiration;
  }

  return '';
}

export function formatPhoneNumber(value: string, dashes: boolean = false) {
  if (!value || value.length === 0) { return value; }
  const formatted = value.replace(/\D/g, '');
  if (dashes) {
    const segments = formatted.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (segments) {
      return '' + segments[1] + '-' + segments[2] + '-' + segments[3];
    }
  }

  return formatted;
}

export function formatAddress(address: Address) {
  if (!address || Object.keys(address).length === 0) { return '' }
  return address.address1 + (!!address.address2 ? ' ' + address.address2 : '') + ', ' + address.city + ', ' + address.state + ' ' + address.zip;
}

export function formatShortDate(dateString: string) {
  const monthNames =["Jan","Feb","Mar","Apr",
    "May","Jun","Jul","Aug",
    "Sep", "Oct","Nov","Dec"];

  const date = new Date(dateString);
  const day = date.getDate();
  const monthIndex = date.getMonth();

  return `${monthNames[monthIndex]} ${day}`;
}

export function titleCase(str: string) {
  return str.toLowerCase().split(' ').map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
}

export interface ThemeIndex {
  [name: string]: Theme
}

export const themes: ThemeIndex = {
  burn: burnTheme,
  build: buildTheme,
  thrive: thriveTheme,
  free: freeTheme
}