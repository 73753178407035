import { Auth } from 'aws-amplify';
import { AwsUser, User } from '../models/User';

export interface AuthService {
  signIn: (username: string, password: string) => Promise<User>;
  signOut: () => Promise<void>;
  signUp: (email: string, password: string) => Promise<User>;
  confirmAccount: (username: string, verificationCode: string) => Promise<void>;
  resendVerificationCode: (email: string) => Promise<void>;
  initiatePasswordReset: (email: string) => Promise<void>;
  completePasswordReset: (email: string, verificationCode: string, newPassword: string) => Promise<string>;
  getCurrentUser(): Promise<User>;
}

export function getAuthService(): AuthService {
  // Added this so that we don't hate life when we come back to testing
  return new AwsAuthService();
}

class AwsAuthService implements AuthService {
  constructor() {
  }

  getCurrentUser(): Promise<AwsUser> {
    return Auth.currentAuthenticatedUser()
      .then(user => new AwsUser(user));
  }

  signIn(username: string, password: string): Promise<AwsUser> {
    return Auth.signIn(username, password)
      .then(result => new AwsUser(result));
  }

  signOut(): Promise<void> {
    return Auth.signOut();
  }

  signUp(email: string, password: string): Promise<AwsUser> {
    return Auth.signUp({
      username: email,
      password,
      attributes: {
        email,
      }
    })
      .then(result => new AwsUser(result.user));
  }

  confirmAccount(username: string, verificationCode: string): Promise<void> {
    return Auth.confirmSignUp(username, verificationCode);
  }

  resendVerificationCode(email: string) {
    return Auth.resendSignUp(email);
  }

  initiatePasswordReset(email: string): Promise<void> {
    return Auth.forgotPassword(email);
  }

  completePasswordReset(email: string, verificationCode: string, newPassword: string): Promise<string> {
    return Auth.forgotPasswordSubmit(email, verificationCode, newPassword);
  }
}