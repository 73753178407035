import { CognitoUser } from 'amazon-cognito-identity-js';

export interface User {
  username: string;
  nativeUser: any;
  name?: string;
}

export class AwsUser implements User {
  readonly username: string;

  constructor(readonly nativeUser: CognitoUser) {
    this.username = nativeUser.getUsername();
  }
}