import { RichTextField } from "@prismicio/types";
import { PrismicRichText } from "@prismicio/react";
import { healthGuideTextRenderer } from "../../../utils/displayUtils";
import React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { theme } from "../../../style/theme";

export const WorkoutDetail = (props: { name: string, field: RichTextField }) => {
  return <WorkoutDetailBox>
          <Bold>{props.name}: </Bold>
          <PrismicRichText field={props.field} components={healthGuideTextRenderer}/>
        </WorkoutDetailBox>
  ;
}

const WorkoutDetailBox = styled(Box)`
  margin: 0 1rem;
  display: inline-flex;
  flex-grow: 1;
  ${theme.breakpoints.down('sm')} {
    display: grid;
    grid-template-columns: 70px auto;
    border: 1px solid #C4C4C4;
    border-right: 0px;
    border-left: 0px;
    border-top: 0px;
  }
`;

const Bold = styled(Typography)`
  font-weight: 500;
  margin-right: 0.5rem;
`;