import { Grid, Link, styled } from "@mui/material";
import React from "react";
import { ErrorMessage } from "../../../style/styles";


interface SignupConfirmationErrorMessageProps {
  canRetry: boolean;
}

export const SignupConfirmationErrorMessage = ({canRetry}: SignupConfirmationErrorMessageProps) => {
  if (canRetry) {
    return <ErrorMessage>{'Code Not Accepted -- Please Try Again'}</ErrorMessage>;
  } else {
    return <Grid container direction={'column'}>
            <ErrorMessage>{'Code Not Accepted'}</ErrorMessage>
            <CenteredLink href='mailto:support@grx.com'>{'Email Us for Help'}</CenteredLink>
           </Grid>;
  }
}

const CenteredLink = styled(Link)`
  text-align: center;
`