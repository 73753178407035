import { createTheme, responsiveFontSizes, ThemeOptions } from '@mui/material/styles';

const defaultThemeOptions: ThemeOptions = {
  typography: {
    allVariants: {
      fontFamily: 'Poppins, sans-serif',
    },
    h1: {
      fontSize: '3.6rem',
      fontWeight: 300,
    },
    h2: {
      fontSize: '2.4rem',
      fontWeight: 300,
    },
    h3: {
      fontSize: '2.4rem',
      fontWeight: 300
    },
    body1: {
      fontSize: '1.2rem',
      fontWeight: 300,
    },
    body2: {
      fontSize: '2rem',
      fontWeight: 500,
    },
    htmlFontSize: 10,
  },
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: {
          width: '100%',
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
        InputLabelProps: {
          shrink: true,
        },
        InputProps: {
          style: {
            fontSize: 16
          },
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          border: 'none',
          backgroundColor: 'white',
          color: 'black',
          '&.Mui-selected, &.Mui-selected:hover': {
            backgroundColor: '#4B4B4B',
            color: 'white',
          },
          '&.Mui:hover': {
            backgroundColor: 'white'
          }
        }
      }
    }
  }
}

//These are because Jeremy is finally fed up with not having a real theme
const saneThemeOptions: ThemeOptions = {
  typography: {
    allVariants: {
      fontFamily: 'Poppins, sans-serif',
    },
    h1: {  //material h4
      fontSize: '3.4rem',
      fontWeight: 300,
    },
    h2: {  //material h5
      fontSize: '2.4rem',
      fontWeight: 300,
    },
    h3: {  //material h6
      fontSize: '2.0rem',
      fontWeight: 500
    },
    body1: {
      fontSize: '1.6rem',
      fontWeight: 300,
    },
    body2: {
      fontSize: '1.4rem',
      fontWeight: 300,
    },
    htmlFontSize: 10,
  }
}

//These are from Ying's design
const healthGuideThemeOptions = {
  ...defaultThemeOptions,
  typography: {
    ...defaultThemeOptions.typography,
    h1: {
      fontSize: '3.5rem',
      fontWeight: 400,
      lineHeight: 1
    },
    h2: {
      fontSize: '2.3rem',
      fontWeight: 400,
      lineHeight: 1
    },
    h3: {
      fontSize: '1.6rem',
      fontWeight: 500,
      lineHeight: 1.5
    },
    h4: {
      fontSize: '1.3rem',
      fontWeight: 500,
      lineHeight: 2
    },
    body1: {
      fontSize: '1.3rem',
      fontWeight: 400,
      lineHeight: 1.5
    },
    body2: {
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: 1.5
    }
  }
}

const healthGuideDocumentThemeOptions = {
  ...defaultThemeOptions,
  typography: {
    ...defaultThemeOptions.typography,
    h1: {
      fontSize: '3.6rem',
      fontWeight: 500,
      lineHeight: 1.333
    },
    h2: {
      fontSize: '3.2rem',
      fontWeight: 500,
      lineHeight: 1.5,
    },
    h3: {
      fontSize: '2.4rem',
      fontWeight: 500,
      lineHeight: 1.667,
    },
    h4: {
      fontSize: '1.6rem',
      fontWeight: 500,
      lineHeight: 2,
    },
    body1: {
      fontSize: '1.4rem',
      fontWeight: 300,
      lineHeight: 2.286,
    },
  },
  components: {
    MuiListItem: {
      styleOverrides: {
        root: {
          display: 'list-item',
          listStyleType: 'initial',
          listStylePosition: 'inside' as 'inside',
          fontSize: '1.4rem',
          fontWeight: 300,
          lineHeight: 1.5,
        }
      }
    }
  }
}

const workoutDocumentThemeOptions = {
  ...defaultThemeOptions,
  typography: {
    ...defaultThemeOptions.typography,
    h1: {
      fontSize: '4.8rem',
      fontWeight: 300,
    },
    h2: {
      fontSize: '3.2rem',
      fontWeight: 300,
    },
    h3: {
      fontSize: '2.1rem',
      fontWeight: 300,
    },
    h4: {
      fontSize: '1.8rem',
      fontWeight: 600,
    },
    body1: {
      fontSize: '1.8rem',
      fontWeight: 300,
    },
  },
}

const createThemeOptions = (color: string) => {
  return {
    ...defaultThemeOptions,
    palette: {
      primary: {
        main: color,
      }
    },
    components: {
      ...defaultThemeOptions.components,
      MuiToggleButton: {
        styleOverrides: {
          root: {
            border: 'none',
            backgroundColor: '#DFDFDF',
            color: 'black',
            '&.Mui-selected, &.Mui-selected:hover': {
              backgroundColor: color,
              color: 'white',
            },
            //&& allows us to actually override what MUI is doing with these styles
            //It's worth noting that this style modifies margin-left, but there's no way to undo that in the theme
            '&&.MuiToggleButtonGroup-grouped:not(:first-of-type)': {
              borderRadius: '0.7rem',
              border: 'none',
            },
            '&&.MuiToggleButtonGroup-grouped:not(:last-of-type)': {
              borderRadius: '0.7rem'
            },
          }
        }
      }
    }
  }
}

const createNavbarTheme = (color: string) => {
  return {
    ...defaultThemeOptions,
    palette: {
      primary: {
        main: color,
      }
    },
    components: {
      ...defaultThemeOptions.components,
      MuiToggleButton: {
        styleOverrides: {
          root: {
            border: 'none',
            backgroundColor: 'transparent',
            color: '#808080',
            '&.Mui-selected, &.Mui-selected:hover': {
              color: 'black',
              backgroundColor: 'transparent'
            },
            //&& allows us to actually override what MUI is doing with these styles
            //It's worth noting that this style modifies margin-left, but there's no way to undo that in the theme
            '&&.MuiToggleButtonGroup-grouped': {
              borderRadius: 0,
            },
            '&&.MuiToggleButtonGroup-grouped:not(:last-of-type)': {
              borderRight: '1px solid #DFDFDF',
            },
          }
        }
      }
    }
  }
}

export const theme = responsiveFontSizes(createTheme(defaultThemeOptions));
export const saneTheme = responsiveFontSizes(createTheme(saneThemeOptions));

export const healthGuideTheme = responsiveFontSizes(createTheme(healthGuideThemeOptions));
export const healthGuideDocumentTheme = responsiveFontSizes(createTheme(healthGuideDocumentThemeOptions));
export const workoutDocumentTheme = responsiveFontSizes(createTheme(workoutDocumentThemeOptions));

export const burnTheme = responsiveFontSizes(createTheme(createThemeOptions('#FF6600')));
export const buildTheme = responsiveFontSizes(createTheme(createThemeOptions('#666666')));
export const thriveTheme = responsiveFontSizes(createTheme(createThemeOptions('#FFCC33')));
export const freeTheme = responsiveFontSizes(createTheme(createThemeOptions('#709004')));

export const navbarTheme = responsiveFontSizes(createTheme(createNavbarTheme('#DFDFDF')));