import React from 'react';

export const ThreeIcons = () => (
  <svg width="79" height="73" viewBox="0 0 79 73" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M36.8223 7.68269C38.9438 7.68269 40.6636 5.96286 40.6636 3.84134C40.6636 1.71983 38.9438 0 36.8223 0C34.7008 0 32.981 1.71983 32.981 3.84134C32.981 5.96286 34.7008 7.68269 36.8223 7.68269Z" fill="#FF6600"/>
    <path d="M36.8223 33.4745C38.9438 33.4745 40.6636 31.7546 40.6636 29.6331C40.6636 27.5116 38.9438 25.7918 36.8223 25.7918C34.7008 25.7918 32.981 27.5116 32.981 29.6331C32.981 31.7546 34.7008 33.4745 36.8223 33.4745Z" fill="#FF6600"/>
    <path d="M27.7017 11.4582C29.8232 11.4582 31.543 9.73839 31.543 7.61688C31.543 5.49536 29.8232 3.77553 27.7017 3.77553C25.5802 3.77553 23.8604 5.49536 23.8604 7.61688C23.8604 9.73839 25.5802 11.4582 27.7017 11.4582Z" fill="#FF6600"/>
    <path d="M45.9429 29.6991C48.0644 29.6991 49.7843 27.9793 49.7843 25.8577C49.7843 23.7362 48.0644 22.0164 45.9429 22.0164C43.8214 22.0164 42.1016 23.7362 42.1016 25.8577C42.1016 27.9793 43.8214 29.6991 45.9429 29.6991Z" fill="#FF6600"/>
    <path d="M23.9263 20.5786C26.0478 20.5786 27.7677 18.8587 27.7677 16.7372C27.7677 14.6157 26.0478 12.8959 23.9263 12.8959C21.8048 12.8959 20.085 14.6157 20.085 16.7372C20.085 18.8587 21.8048 20.5786 23.9263 20.5786Z" fill="#FF6600"/>
    <path d="M49.7183 20.5786C51.8398 20.5786 53.5596 18.8587 53.5596 16.7372C53.5596 14.6157 51.8398 12.8959 49.7183 12.8959C47.5968 12.8959 45.877 14.6157 45.877 16.7372C45.877 18.8587 47.5968 20.5786 49.7183 20.5786Z" fill="#FF6600"/>
    <path d="M27.7017 29.6991C29.8232 29.6991 31.543 27.9793 31.543 25.8577C31.543 23.7362 29.8232 22.0164 27.7017 22.0164C25.5802 22.0164 23.8604 23.7362 23.8604 25.8577C23.8604 27.9793 25.5802 29.6991 27.7017 29.6991Z" fill="#FF6600"/>
    <path d="M45.9429 11.4582C48.0644 11.4582 49.7843 9.73839 49.7843 7.61688C49.7843 5.49536 48.0644 3.77553 45.9429 3.77553C43.8214 3.77553 42.1016 5.49536 42.1016 7.61688C42.1016 9.73839 43.8214 11.4582 45.9429 11.4582Z" fill="#FF6600"/>
    <path d="M62.2628 61.8395C64.4675 61.8395 66.2547 60.0522 66.2547 57.8476C66.2547 55.643 64.4675 53.8558 62.2628 53.8558C60.0582 53.8558 58.271 55.643 58.271 57.8476C58.271 60.0522 60.0582 61.8395 62.2628 61.8395Z" fill="#FFCC33"/>
    <path d="M62.2628 48.1531C64.4675 48.1531 66.2547 46.3659 66.2547 44.1613C66.2547 41.9566 64.4675 40.1694 62.2628 40.1694C60.0582 40.1694 58.271 41.9566 58.271 44.1613C58.271 46.3659 60.0582 48.1531 62.2628 48.1531Z" fill="#FFCC33"/>
    <path d="M49.5172 57.4142C51.7219 57.4142 53.5091 55.627 53.5091 53.4224C53.5091 51.2177 51.7219 49.4305 49.5172 49.4305C47.3126 49.4305 45.5254 51.2177 45.5254 53.4224C45.5254 55.627 47.3126 57.4142 49.5172 57.4142Z" fill="#FFCC33"/>
    <path d="M54.3874 72.3951C56.592 72.3951 58.3792 70.6078 58.3792 68.4032C58.3792 66.1986 56.592 64.4114 54.3874 64.4114C52.1827 64.4114 50.3955 66.1986 50.3955 68.4032C50.3955 70.6078 52.1827 72.3951 54.3874 72.3951Z" fill="#FFCC33"/>
    <path d="M70.1378 72.3951C72.3425 72.3951 74.1297 70.6078 74.1297 68.4032C74.1297 66.1986 72.3425 64.4114 70.1378 64.4114C67.9332 64.4114 66.146 66.1986 66.146 68.4032C66.146 70.6078 67.9332 72.3951 70.1378 72.3951Z" fill="#FFCC33"/>
    <path d="M75.008 57.4142C77.2126 57.4142 78.9998 55.627 78.9998 53.4224C78.9998 51.2177 77.2126 49.4305 75.008 49.4305C72.8033 49.4305 71.0161 51.2177 71.0161 53.4224C71.0161 55.627 72.8033 57.4142 75.008 57.4142Z" fill="#FFCC33"/>
    <path d="M3.99215 49.2075C6.19696 49.2075 7.98431 47.4839 7.98431 45.3579C7.98431 43.2318 6.19696 41.5083 3.99215 41.5083C1.78735 41.5083 0 43.2318 0 45.3579C0 47.4839 1.78735 49.2075 3.99215 49.2075Z" fill="#C1C1C1"/>
    <path d="M15.3984 49.2075C17.6032 49.2075 19.3906 47.4839 19.3906 45.3579C19.3906 43.2318 17.6032 41.5083 15.3984 41.5083C13.1936 41.5083 11.4062 43.2318 11.4062 45.3579C11.4062 47.4839 13.1936 49.2075 15.3984 49.2075Z" fill="#C1C1C1"/>
    <path d="M26.8047 49.2075C29.0095 49.2075 30.7968 47.4839 30.7968 45.3579C30.7968 43.2318 29.0095 41.5083 26.8047 41.5083C24.5998 41.5083 22.8125 43.2318 22.8125 45.3579C22.8125 47.4839 24.5998 49.2075 26.8047 49.2075Z" fill="#C1C1C1"/>
    <path d="M3.99215 60.7562C6.19696 60.7562 7.98431 59.0327 7.98431 56.9066C7.98431 54.7805 6.19696 53.057 3.99215 53.057C1.78735 53.057 0 54.7805 0 56.9066C0 59.0327 1.78735 60.7562 3.99215 60.7562Z" fill="#C1C1C1"/>
    <path d="M15.3984 60.7562C17.6032 60.7562 19.3906 59.0327 19.3906 56.9066C19.3906 54.7805 17.6032 53.057 15.3984 53.057C13.1936 53.057 11.4062 54.7805 11.4062 56.9066C11.4062 59.0327 13.1936 60.7562 15.3984 60.7562Z" fill="#C1C1C1"/>
    <path d="M26.8047 60.7562C29.0095 60.7562 30.7968 59.0327 30.7968 56.9066C30.7968 54.7805 29.0095 53.057 26.8047 53.057C24.5998 53.057 22.8125 54.7805 22.8125 56.9066C22.8125 59.0327 24.5998 60.7562 26.8047 60.7562Z" fill="#C1C1C1"/>
    <path d="M3.99215 72.3049C6.19696 72.3049 7.98431 70.5814 7.98431 68.4553C7.98431 66.3293 6.19696 64.6058 3.99215 64.6058C1.78735 64.6058 0 66.3293 0 68.4553C0 70.5814 1.78735 72.3049 3.99215 72.3049Z" fill="#C1C1C1"/>
    <path d="M15.3984 72.3049C17.6032 72.3049 19.3906 70.5814 19.3906 68.4553C19.3906 66.3293 17.6032 64.6058 15.3984 64.6058C13.1936 64.6058 11.4062 66.3293 11.4062 68.4553C11.4062 70.5814 13.1936 72.3049 15.3984 72.3049Z" fill="#C1C1C1"/>
    <path d="M26.8047 72.3049C29.0095 72.3049 30.7968 70.5814 30.7968 68.4553C30.7968 66.3293 29.0095 64.6058 26.8047 64.6058C24.5998 64.6058 22.8125 66.3293 22.8125 68.4553C22.8125 70.5814 24.5998 72.3049 26.8047 72.3049Z" fill="#C1C1C1"/>
  </svg>
);