import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import {
  CALL_STARTING,
  GET_ACTIVE_QUESTIONNAIRES,
  GET_ACTIVE_QUESTIONNAIRES_FAILED,
  GET_ACTIVE_QUESTIONNAIRES_SUCCESS,
  GET_HEALTH_PROFILE,
  GET_HEALTH_PROFILE_FAILED,
  GET_HEALTH_PROFILE_SUCCESS,
  HEALTH_PROFILE_REVIEW,
  HEALTH_PROFILE_REVIEW_FAILED,
  HEALTH_PROFILE_REVIEW_SUCCESS, SEND_RESCHEDULE_EMAIL, SEND_RESCHEDULE_EMAIL_FAILED, SEND_RESCHEDULE_EMAIL_SUCCESS,
} from '../actions';
import { WebAppState } from '../WebAppState';
import { HttpClient } from '../../service/HttpClient';
import { AnyAction } from 'redux';

export function* physicianSaga() {
  yield all([
    getActiveQuestionnairesWatcher(),
    getHealthProfileWatcher(),
    healthProfileReviewWatcher(),
    sendRescheduleEmailWatcher()
  ]);
}

function* getActiveQuestionnairesWatcher() {
  yield takeEvery(GET_ACTIVE_QUESTIONNAIRES, getActiveQuestionnaires);
}

function* getHealthProfileWatcher() {
  yield takeEvery(GET_HEALTH_PROFILE, getHealthProfile);
}

function* healthProfileReviewWatcher() {
  yield takeEvery(HEALTH_PROFILE_REVIEW, healthProfileReview);
}

function* sendRescheduleEmailWatcher() {
  yield takeEvery(SEND_RESCHEDULE_EMAIL, sendRescheduleEmail);
}

export function* getActiveQuestionnaires(): Generator<any, any, any> {
  yield put({type: CALL_STARTING, data: 'getActiveQuestionnaires'});
  const client = (yield select((state: WebAppState) => state.system.httpClient)) as HttpClient;
  try {
    const response = yield call(client.get, `healthProfiles`);
    yield put({type: GET_ACTIVE_QUESTIONNAIRES_SUCCESS, data: response});
  } catch (err) {
    yield put({type: GET_ACTIVE_QUESTIONNAIRES_FAILED, data: err});
  }
}

export function* getHealthProfile(getHealthProfileAction: AnyAction): Generator<any, any, any> {
  yield put({type: CALL_STARTING, data: 'getHealthProfile'});
  const client = (yield select((state: WebAppState) => state.system.httpClient)) as HttpClient;

  try {
    const response = yield call(client.get, `healthProfiles?questionnaireId=${getHealthProfileAction.data}`);
    yield put({type: GET_HEALTH_PROFILE_SUCCESS, data: response});
  } catch (err) {
    yield put({type: GET_HEALTH_PROFILE_FAILED, data: err});
  }
}

export function* healthProfileReview(reviewAction: AnyAction): Generator<any, any, any> {
  yield put({type: CALL_STARTING, data: 'healthProfileReview'});
  const client = (yield select((state: WebAppState) => state.system.httpClient)) as HttpClient;
  try {
    yield call(client.post, 'healthProfiles', reviewAction.data);
    yield put({ type: HEALTH_PROFILE_REVIEW_SUCCESS});
  } catch (err) {
    yield put ({ type: HEALTH_PROFILE_REVIEW_FAILED, data: err });
  }
  yield put({type: GET_ACTIVE_QUESTIONNAIRES});
}

export function* sendRescheduleEmail(sendRescheduleEmailAction: AnyAction): Generator<any, any, any> {
  yield put({type: CALL_STARTING, data: 'sendRescheduleEmail'});

  const client = (yield select((state: WebAppState) => state.system.httpClient)) as HttpClient;
  try {
    yield call(client.post, 'sendRescheduleEmail', sendRescheduleEmailAction.data);
    yield put({ type: SEND_RESCHEDULE_EMAIL_SUCCESS});
  } catch (err) {
    yield put ({ type: SEND_RESCHEDULE_EMAIL_FAILED, data: err });
  }
  yield put({type: GET_ACTIVE_QUESTIONNAIRES});
}