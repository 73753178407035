import React from "react";
import { ApiCallState } from "../../../redux/WebAppState";
import ErrorIcon from '@mui/icons-material/Error';
import circleCheck from "../../../assets/gray-circle-check.svg";
import { CircularProgress, styled } from "@mui/material";

export interface UploadStatusProps {
  filename: string;
  filenamePlaceholder: string;
  photoIdUpdateApi?: ApiCallState | undefined
}

export const UploadStatus = ({ filename, photoIdUpdateApi, filenamePlaceholder }: UploadStatusProps) => {
  if (!!photoIdUpdateApi) {
    return (
      <>
        { !filename && <FileLabel>{filenamePlaceholder}</FileLabel> }
        { !!filename && !photoIdUpdateApi.isLoading && !photoIdUpdateApi.apiError ?
          (
            <>
              <UploadedIcon src={circleCheck}/>
              <FileLabel>{`Updated to: ${filename}`}</FileLabel>
            </>
          ) : !!filename && photoIdUpdateApi.isLoading ? (
            <>
              <UploadSpinner size={25} />
              <FileLabel>{'Updating...'}</FileLabel>
            </>
          ) : !!filename && photoIdUpdateApi.apiError ? (
            <>
              <ErrorIcon />
              <FileLabel>{photoIdUpdateApi.apiError.message}</FileLabel>
            </>
          ) : undefined
        }
      </>
    );
  } else {
    return (
      <>
        <FileLabel>{filename || filenamePlaceholder}</FileLabel>
        { !!filename ? <UploadedIcon src={circleCheck}/> : undefined }
      </>
    )
  }
}

const FileLabel = styled('label')`
  margin-right: .75rem;
  font-style: italic;
`;

const UploadedIcon = styled('img')`
  margin-top: .5rem;
`;

const UploadSpinner = styled(CircularProgress)`
  margin-right: 1rem;
`;
