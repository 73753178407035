import {connect, MapDispatchToProps, MapStateToProps} from 'react-redux';
import { WebAppState } from '../../redux/WebAppState';
import {
  HealthGuideNavigationBanner,
  HealthGuideNavigationBannerDispatchProps,
  HealthGuideNavigationBannerProps
} from "../../components/healthguide/HealthGuideNavigationBanner";
import { SEARCH_HEALTH_GUIDE } from "../../redux/apiCalls";
import {APPLY_HEALTH_GUIDE_DOCUMENT_FILTER, CLEAR_PROGRAM_FILTER, RESTORE_PROGRAM_FILTER} from "../../redux/actions";

const mapStateToProps: MapStateToProps<HealthGuideNavigationBannerProps, {}, WebAppState> = state => ({
  contentTypes: sortContentTypes(state.healthGuide.contentTypes),
  filter: state.healthGuide.filter ?? ''
});

const mapDispatchToProps: MapDispatchToProps<HealthGuideNavigationBannerDispatchProps, {}> = (dispatch) => ({
  applyFilter: (filter: string) => dispatch({type: APPLY_HEALTH_GUIDE_DOCUMENT_FILTER, data: filter}),
  searchHealthGuideDocs: (term: string) => dispatch({ type: SEARCH_HEALTH_GUIDE, data: term}),
  clearProgram: () => dispatch({ type: CLEAR_PROGRAM_FILTER }),
  restoreProgram: () => dispatch({ type: RESTORE_PROGRAM_FILTER })
});

export const sortContentTypes = (contentTypes: string[]) => {
  let output = ['lifestyle', 'nutrition', 'workout'];
  output = [...new Set([...output,...contentTypes])];
  return output;
};

export default connect(mapStateToProps, mapDispatchToProps)(HealthGuideNavigationBanner);