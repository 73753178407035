import { connect, MapDispatchToProps, MapStateToProps } from "react-redux";

import { WebAppState } from "../redux/WebAppState";
import { GoalCapture, GoalCaptureDispatchProps, GoalCaptureProps } from "../components/checkout/GoalCapture";
import { UPDATE_GOAL_RESPONSES } from "../redux/actions";
import { Goal } from "../models/Goal";

const mapStateToProps: MapStateToProps<GoalCaptureProps, {}, WebAppState> = (state: WebAppState) => ({
  goalResponses: state.user.intake.goalResponses,
  goalsToCapture: state.user.selectedProduct?.displayInfo.goalsToCapture ?? []
});

const mapDispatchToProps: MapDispatchToProps<GoalCaptureDispatchProps, {}> = dispatch => ({
  updateGoalResponses: (goal: Goal, value: string | number) => {
    dispatch({type: UPDATE_GOAL_RESPONSES, data: {goal: goal, value: value}})
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(GoalCapture);