import Grid from '@mui/material/Grid';
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import React from "react";
import { Loading } from "../../shared/Loading";
import { CartCheckoutSummary, CartCheckoutSummaryProps } from "./CartCheckoutSummary";
import { Box } from "../CheckoutPage";
import OpenBoxIcon from "../../../assets/open-box-icon.svg";
import { TermsAndConditions, TermsAndConditionsProps } from "./TermsAndConditions";
import { Button } from "../../../style/styles";
import CartCheckoutSummaryContainer from "../../../containers/checkout/CartCheckoutSummaryContainer";

export interface CheckoutOrderDetailsProps {
  formError?: string;
  placeOrder: () => void;
}

export const CheckoutOrderDetails = ({
  productInCart,
  stateProduct,
  setProductOption,
  programInformation,
  formError,
  placeOrder,
  isLoading,
  tcPrismicDocState,
  documents,
  viewTCs, setViewTCs,
}: CartCheckoutSummaryProps & CheckoutOrderDetailsProps & TermsAndConditionsProps) => {
  return(
    <OrderDetailsSection item container xs={12} sm={4} direction={'column'} justifyContent={'center'} alignItems={'center'} spacing={2}>
      <OrderDetailsText>Order Details</OrderDetailsText>
      {
        !!productInCart
          ? (<CartCheckoutSummaryContainer productInCart={productInCart} stateProduct={stateProduct!} setProductOption={setProductOption} programInformation={programInformation} />)
          : (<Loading />)
      }
      <Box item container alignItems={'center'} spacing={2} wrap={'nowrap'}>
        <Grid item container xs direction={'column'} alignItems={'center'}>
          <AdjustOrCancelImage src={OpenBoxIcon} />
          <TermsTagline align={'center'}>Adjust or cancel anytime</TermsTagline>
        </Grid>
      </Box>
      <ErrorMessage>{formError}</ErrorMessage>
      <Grid item container direction={'column'} justifyContent={'center'} alignItems={'center'}>
        <PlaceOrderButton onClick={placeOrder}>{ isLoading ? <Loading/> : 'Place Order'}</PlaceOrderButton>
      </Grid>
      <TermsAndConditions
        isLoading={isLoading}
        tcPrismicDocState={tcPrismicDocState}
        documents={documents}
        viewTCs={viewTCs}
        setViewTCs={setViewTCs}
      />
    </OrderDetailsSection>
  );
}

const OrderDetailsSection = styled(Grid)``;

const OrderDetailsText = styled(Typography)`
  font-size: 2.4rem;
  font-weight: 500;
  line-height: 3.6rem;
  width: 100%;
  margin: 1.6rem 0;
  padding: 0;
`;

const AdjustOrCancelImage = styled('img')`
  height: 37px;
  width: 38px;
`;

const TermsTagline = styled(Typography)`
  font-size: 1.6rem;
  color: #666666;
`;

const PlaceOrderButton = styled(Button)`
  margin: 4rem;
`;

const ErrorMessage = styled(Typography)`
  color: red;
  margin: 2rem auto 0;
`;