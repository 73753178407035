import { FormControlLabel, Grid, Radio, RadioGroup, styled, Typography } from "@mui/material";
import React from "react";
import { ChangeEvent } from "react";

interface YesNoQuestionProps {
  label: string;
  value?: string;
  changeFunction: (event: ChangeEvent<HTMLInputElement>) => void;
  background?: string;
}

export const YesNoQuestion = ({ label, value, changeFunction, background }: YesNoQuestionProps) => (
  <QuestionGrid $background={background} item container alignItems={'center'} justifyContent={'space-between'}>
    <Grid item xs={6} sm={7} md={8}>
      <Typography>{label}</Typography>
    </Grid>
    <Grid item xs={6} sm={5} md={4} container justifyContent={'flex-end'}>
      <RadioGroup row value={value} onChange={changeFunction}>
        <FormControlLabel value={'yes'} control={<Radio />} label={'Yes'} />
        <FormControlLabel value={'no'} control={<Radio />} label={'No'} />
      </RadioGroup>
    </Grid>
  </QuestionGrid>
);

interface QuestionGridProps {
  $background?: string
}

const QuestionGrid = styled(Grid)<QuestionGridProps>`
  background-color: ${props => props.$background || 'inherit'};
`;