import React from 'react';
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {ApiCallState, WebAppState} from "../../redux/WebAppState";
import { HttpClient } from "../../service/HttpClient";

export const UnauthorizedRedirect = () => {
  const navigate = useNavigate();
  const apiCallWithUnauthorizedError = useSelector<WebAppState, ApiCallState | undefined>(state => {
    return Object.values(state.system.apiCalls).find(apiCall => !!apiCall.apiError && apiCall.apiError.message === HttpClient.MESSAGES.UNAUTHORIZED);
  });
  if (!!apiCallWithUnauthorizedError) {
    navigate('/');
  }

  return (
    <></>
  )
}