import React, {PropsWithChildren} from "react";
import Grid from '@mui/material/Grid';
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";

interface LineItemProps {
  item: string;
  price: string;
}

export const LineItem = ({item, price}: PropsWithChildren<LineItemProps>) => {
  return (
    <Grid item container>
      <Grid item xs container justifyContent="flex-start"><DetailText>{item}</DetailText></Grid>
      <Grid item xs container justifyContent="flex-end"><DetailText>{price}</DetailText></Grid>
    </Grid>
  )
}

const DetailText = styled(Typography)`
  font-size: 1.6rem;
  font-weight: 500;
`;