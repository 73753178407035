import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { PromoEntry, PromoEntryDispatchProps, PromoEntryProps } from '../../components/checkout/checkoutForm/PromoEntry';
import { WebAppState } from '../../redux/WebAppState';
import { GET_PROMO_VALUE } from '../../redux/actions';

const mapStateToProps: MapStateToProps<PromoEntryProps, {}, WebAppState> = (state: WebAppState) => ({
  promoErrorMessage: state.system.apiCalls.getPromoValue.apiError?.message || '',
});

const mapDispatchToProps: MapDispatchToProps<PromoEntryDispatchProps, {}> = dispatch => ({
  getPromoValue: ((code:string) => dispatch({ type: GET_PROMO_VALUE, data: code}))
});

export default connect(mapStateToProps, mapDispatchToProps)(PromoEntry);