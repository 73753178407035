import React from 'react';
import { ContentSection, RelativeGrid } from '../../style/styles';
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import background from '../../assets/all-product-background.png';
import tablet from '../../assets/tablet-health-guide.png';
import phone from '../../assets/phone-health-guide.png';
import { theme } from "../../style/theme";
import { ProductNames } from "./ProductNames";

export const HealthGuidePromo = () => (
  <HealthGuideSection $dark>
    <Grid container sx={{marginTop: '5rem', marginBottom: '1rem'}}>
      <RelativeGrid item xs={12} sm={7} xl={5}>
        <Background src={background} />
        <RelativeGrid>
          <Tablet src={tablet} />
          <Phone src={phone} />
        </RelativeGrid>
      </RelativeGrid>
      <Grid item xs={12} sm={5} xl={6} container direction={'column'} justifyContent={'flex-start'}>
        <ProductNames />
        <Typography variant={'h1'} sx={{marginBottom: '4rem'}}>Online Nutrition &amp;<br />Exercise Coaching</Typography>
        <Typography>Fad diets. Exercise trends. Bro science. With so much clutter in the wellness space, its hard to know what works. Each GoalsRx subscription comes with easy-to-follow nutrition, exercise & lifestyle guides that take all the guesswork out of getting healthy. All you have to do is commit to the script.</Typography>
      </Grid>
    </Grid>
  </HealthGuideSection>
);

const HealthGuideSection = styled(ContentSection)`
  overflow-y: hidden;
  
  ${theme.breakpoints.down('sm')} {
    padding-bottom: 6rem;
  }
`;

const Background = styled('img')`
  position: absolute;
  top: -10rem;
  left: -10rem;
  z-index: 2;
  max-width: 100%;
  
  ${theme.breakpoints.down('sm')} {
    top: -5rem;
    left: -5rem;
  }
`;

const Tablet = styled('img')`
  position: relative;
  z-index: 3;
  max-width: 75%;
`;

const Phone = styled('img')`
  position: absolute;
  top: 40%;
  left: 40%;
  z-index: 4;
  max-width: 40%;
  
  ${theme.breakpoints.down('sm')} {
    left: inherit;
    right: 2rem;
  }
`;