import Grid from '@mui/material/Grid';
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import React from "react";
import { Header, Input, Label } from "../CheckoutPage";
import SecureCheckoutIcon from "../../../assets/secure-checkout-icon.svg";

export interface CreditCardFormProps {
  cardNumber: string;
  setCardNumber: (value:string) => void;
  expiration: string;
  setExpiration: (value:string) => void;
  cvc: string;
  setCvc: (value:string) => void;
}

export const CreditCardInfoForm = ({
      cardNumber, setCardNumber,
      expiration, setExpiration,
      cvc, setCvc
    }: CreditCardFormProps) => {
  return (
    <>
      <Grid item container spacing={2} alignItems={'center'}>
        <Grid item><Header>Billing</Header></Grid>
        <Grid item><img src={SecureCheckoutIcon} /></Grid>
        <Grid item><SecureCheckout>Secure Checkout</SecureCheckout></Grid>
      </Grid>
      <Grid item container>
        <Label>Card Number</Label>
        <Input type="text" name="cardNumber" value={cardNumber} onChange={(e: any) => setCardNumber(e.target.value)}/>
      </Grid>
      <Grid item container spacing={2}>
        <Grid item container xs={6} sm={3}>
          <Label>Expiration</Label>
          <Input type="text" name="expiration" value={expiration} onChange={(e: any) => setExpiration(e.target.value)}/>
        </Grid>
        <Grid item container xs={6} sm={3}>
          <Label>CVC</Label>
          <Input type="text" name="cvc" value={cvc} onChange={(e: any) => setCvc(e.target.value)}/>
        </Grid>
      </Grid>
    </>
  );
}

const SecureCheckout = styled(Typography)`
  font-size: 1.6rem;
  font-weight: 500;
  color: #666666;
`;