import { HealthQuestionnaire } from '../models/HealthQuestionnaire';

interface Validator {
  isFormValid: (form: any) => ValidationResult;
}

interface ValidationResult {
  isValid: boolean;
  errors?: string[];
}

const MISSING_PHOTO_ID = 'No photo ID uploaded';
const AGE_TOO_YOUNG = 'Must be 18 years old';
const NEED_ALL_DOCUMENTS = 'All documents must be accepted';

export class HealthQuestionnaireValidator implements Validator {
  isFormValid(form: HealthQuestionnaire): ValidationResult {
    let response: ValidationResult = { isValid: true }

    if (form.photoId === undefined || form.photoId === null) {
      response.isValid = false;
      !!response.errors ? response.errors.push(MISSING_PHOTO_ID) : response.errors = [MISSING_PHOTO_ID];
    }

    if (this.getAge(form.birthDate) < 18) {
      response.isValid = false;
      !!response.errors ? response.errors.push(AGE_TOO_YOUNG) : response.errors = [AGE_TOO_YOUNG];
    }

    if (form.acceptedDocuments.length !== form.importantDocuments.length) {
      response.isValid = false;
      !!response.errors ? response.errors.push(NEED_ALL_DOCUMENTS) : response.errors = [NEED_ALL_DOCUMENTS];
    }

    return response;
  }

  getAge(birthdate: string): number {
    var today = new Date();
    var birthDate = new Date(birthdate);
    var yearDiff = today.getFullYear() - birthDate.getFullYear();
    var monthsDiff = today.getMonth() - birthDate.getMonth();
    if (monthsDiff < 0 || (monthsDiff === 0 && today.getDate() < birthDate.getDate())) {
        yearDiff--;
    }
    return yearDiff;
  }
}