import { styled } from "@mui/material/styles";
import { ContentSection, HealthGuideContentSectionLeft } from "../../../style/styles";
import Grid from "@mui/material/Grid";
import { HealthGuideContentWorkoutSlice } from "../../../models/HealthGuideDocument";
import Typography from "@mui/material/Typography";
import { PrismicRichText } from "@prismicio/react";
import { healthGuideTextRenderer, themes } from "../../..//utils/displayUtils";
import { EmbeddedYouTubeVideo } from "../../shared/embeddedVideo/EmbeddedYouTubeVideo";
import React from "react";
import { CoachingTip } from "./CoachingTip";
import { getTextFromRTF } from "../../../utils/PrismicUtils";
import { WorkoutDetailRow } from "./WorkoutDetailRow";
import { StaticViewportResolver } from "../../shared/StaticViewportResolver";

export interface WorkoutSectionProps {
  workoutSlice: HealthGuideContentWorkoutSlice;
  product: string;
  backgroundColor: string;
}

export const WorkoutSection = ({workoutSlice, product, backgroundColor}: WorkoutSectionProps) => {
  const productTheme = themes[product];
  const productPrimaryColor = productTheme.palette.primary.main;

  return (
    <StaticViewportResolver
      mobileView={mobileView(backgroundColor, workoutSlice, productPrimaryColor)}
      desktopView={desktopView(backgroundColor, workoutSlice, productPrimaryColor)}
    />
  )
}

const mobileView = (backgroundColor: string, workoutSlice: HealthGuideContentWorkoutSlice, productPrimaryColor: string) => {
  return (
    <WorkoutContentSection sx={{backgroundColor: backgroundColor}}>
      <Grid container direction={'column'}>
        <TextArea item xs={10}>
          {!!getTextFromRTF(workoutSlice.goal) &&
            <Goal container>
              <GoalText variant={'h4'}>GOAL - </GoalText>
              <PrismicRichText field={workoutSlice.goal} components={healthGuideTextRenderer}/>
            </Goal>
          }
          <Grid container direction={'column'}>
            <PrismicRichText field={workoutSlice.descriptionField} components={healthGuideTextRenderer}/>
          </Grid>
        </TextArea>
      </Grid>
      <Body>
        <WorkoutDetailRow workoutSlice={workoutSlice}/>
        <Grid xs={12} item container direction={'column'} justifyContent={'space-between'}>
          {!!workoutSlice.coachingTip && !!getTextFromRTF(workoutSlice.coachingTip) &&
            <CoachingTip color={productPrimaryColor} field={workoutSlice.coachingTip}/>}
          {!!workoutSlice.videoLink && !!workoutSlice.videoLink && <Grid item xs={6} container>
            <EmbeddedYouTubeVideo url={workoutSlice.videoLink.url}>
              <Typography variant={'h4'}>Technique: {getTextFromRTF(workoutSlice.titleString)}</Typography>
            </EmbeddedYouTubeVideo>
          </Grid>}
          {!!workoutSlice.items && workoutSlice.items.length > 0 &&
            <Grid item xs={12} container>
              {workoutSlice.items.map((item, index) => (
                <EmbeddedYouTubeVideo key={`workoutSection${index}`} url={!!item.video_link && item.video_link.url}>
                  {!!item.video_description && <PrismicRichText field={item.video_description} components={healthGuideTextRenderer}/> }
                </EmbeddedYouTubeVideo>
              ))}
            </Grid>
          }
        </Grid>
      </Body>
    </WorkoutContentSection>
  )
}

const desktopView = (backgroundColor: string, workoutSlice: HealthGuideContentWorkoutSlice, productPrimaryColor: string) => {
  return <WorkoutContentSection sx={{backgroundColor: backgroundColor}}>
  <Grid container>
    <Number container item xs={2} direction={'column'} justifyContent={'center'} alignItems={'center'}>
      <Typography variant={'h2'} sx={{fontWeight: 'bold'}}>{getTextFromRTF(workoutSlice.number)}</Typography>
    </Number>
    <TextArea item xs={10}>
      {!!getTextFromRTF(workoutSlice.goal) &&
        <Goal container>
          <GoalText variant={'h4'}>GOAL - </GoalText>
          <PrismicRichText field={workoutSlice.goal} components={healthGuideTextRenderer}/>
        </Goal>
      }
      <Grid container direction={'column'}>
        <PrismicRichText field={workoutSlice.titleString} components={healthGuideTextRenderer}/>
        <PrismicRichText field={workoutSlice.descriptionField} components={healthGuideTextRenderer}/>
      </Grid>
    </TextArea>
  </Grid>
  <Body>
    <WorkoutDetailRow workoutSlice={workoutSlice}/>
    <Grid xs={12} item container direction={'row'} justifyContent={'space-between'}>
      {!!workoutSlice.videoLink && !!workoutSlice.videoLink && <Grid item xs={6} container>
        <EmbeddedYouTubeVideo url={workoutSlice.videoLink.url}>
          <Typography variant={'h4'}>Technique: {getTextFromRTF(workoutSlice.titleString)}</Typography>
        </EmbeddedYouTubeVideo>
      </Grid>}
      {!!workoutSlice.coachingTip && !!getTextFromRTF(workoutSlice.coachingTip) &&
        <CoachingTip color={productPrimaryColor} field={workoutSlice.coachingTip}/>}
      { !!workoutSlice.items && workoutSlice.items.length > 0 &&
          <Grid item xs={12} container>
            {workoutSlice.items.map((item, index) => (
              <EmbeddedYouTubeVideo key={`workoutSection${index}`} url={!!item.video_link && item.video_link.url}>
                {!!item.video_description && <PrismicRichText field={item.video_description} components={healthGuideTextRenderer}/> }
              </EmbeddedYouTubeVideo>
            ))}
          </Grid>
      }
    </Grid>
  </Body>
</WorkoutContentSection>
}

const WorkoutContentSection = styled(HealthGuideContentSectionLeft)`
  padding: 0;
`;

const Body = styled(ContentSection)`
  align-items: start;
  margin-top: 0;
  width: 100%;
`;

const TextArea = styled(Grid)`
  margin-left: 2rem;
`;

const Goal = styled(Grid)`
  margin: 1.5rem 0rem;
`;

const GoalText = styled(Typography)`
  margin-right: 0.5rem;
`;

const Number = styled(Grid)`
  height: 'match-parent';
  max-width: 7.6rem;
  background: #C4C4C4;
`;