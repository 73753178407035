import { connect, MapDispatchToProps } from 'react-redux';
import { CreateAccountForm, CreateAccountFormDispatchProps } from '../../components/account/CreateAccountForm';
import { REPORT_ERROR, SET_TEMP_PASSWORD, SUBSCRIBE_NEWSLETTER } from '../../redux/actions';

const mapDispatchToProps: MapDispatchToProps<CreateAccountFormDispatchProps, any> = dispatch => ({
  reportError: (error: Error) => dispatch({type: REPORT_ERROR, data: error}),
  setTemporaryPassword: (password: string) => dispatch({type: SET_TEMP_PASSWORD, data: password}),
  subscribeToNewsletter: (email: string) => dispatch({ type: SUBSCRIBE_NEWSLETTER, data: email }),
});

export default connect(null, mapDispatchToProps)(CreateAccountForm);