import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import React, { useState } from 'react';
import { TextField } from '@mui/material';
import { parseDayOrMonth } from '../checkout/QuestionnairePage';

export interface DatePickerProps {
  label: string;
  maxDate: string;
  updateForm: (value: string) => void;
}

export function DatePicker({ label, maxDate, updateForm }: DatePickerProps) {
  const [value, setValue] = useState<Dayjs | null>(null);

  const handleChange = (newValue: Dayjs | null) => {
    setValue(newValue);
    if (newValue) {
      updateForm(`${newValue.year()}-${parseDayOrMonth(newValue.month()+1)}-${parseDayOrMonth(newValue.date())}`);
    }
  };

  // we want to not pre-fill the DOB field before the user makes a selection
  // AND to save the user time by not making them scroll past the first 18 years
  const onOpen = () => {
    const newValue = value ?? dayjs(maxDate);
    setValue(newValue);
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MobileDatePicker
        label={label}
        inputFormat={"MM/DD/YYYY"}
        value={value}
        maxDate={dayjs(maxDate)}
        onChange={handleChange}
        onOpen={onOpen}
        disableFuture={true}
        InputProps={{sx: {fontSize: 16}}}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>
  );
}