import React, { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';
import { AuthService } from '../../service/AuthService';
import { User } from '../../models/User';

interface AuthContextType {
  user?: User;
  signIn: (email: string, password: string) => Promise<void>;
  signOut: () => Promise<void>;
  signUp: (email: string, password: string) => Promise<void>;
  confirmSignUp: (email: string, code: string) => Promise<void>;
  resendVerificationCode: (email: string) => Promise<void>;
  initiatePasswordReset: (email: string) => Promise<void>;
  completePasswordReset: (email: string, verificationCode: string, newPassword: string) => Promise<string>;
  loaded: boolean;
}

export const AuthContext = createContext<AuthContextType>({} as AuthContextType);

interface AuthProviderProps {
  authService: AuthService;
  setGlobalUser: (user: User | undefined) => void;
  setUserProfile: () => void;
}

export const AuthProvider = ({children, authService, setGlobalUser, setUserProfile}: PropsWithChildren<AuthProviderProps>) => {
  const [user, setUser] = useState<User | undefined>(undefined);
  const [loaded, setLoaded] = useState(false);
  const setSuccessfulUser = (user: User | undefined) => {
    setUser(user);
    setGlobalUser(user);
  }

  const hydrateUser = (): Promise<void> => {
    return authService.getCurrentUser()
      .catch(() => undefined)
      .then(userToSet => setSuccessfulUser(userToSet))
      .then(() => setUserProfile())
      .then(() => setLoaded(true));
  }

  useEffect(() => {
    hydrateUser();
  }, []);

  const signIn = (email: string, password: string): Promise<void> => {
    return authService.signIn(email, password)
      .then(result => setSuccessfulUser(result));
  }

  const signOut = (): Promise<void> => {
    return authService.signOut()
      .then(result => setSuccessfulUser(undefined));
  }

  const signUp = (email: string, password: string) => {
    return authService.signUp(email, password)
      .then(result => setSuccessfulUser(result));
  }

  const confirmSignUp = (email: string, code: string) => {
    return authService.confirmAccount(email, code);
  }

  const resendVerificationCode = (email: string) => {
    return authService.resendVerificationCode(email);
  }

  const initiatePasswordReset = (email: string) => {
    return authService.initiatePasswordReset(email);
  }

  const completePasswordReset = (email: string, verificationCode: string, newPassword: string) => {
    return authService.completePasswordReset(email, verificationCode, newPassword);
  }

  const value = {user, signIn, signOut, signUp, confirmSignUp, resendVerificationCode, initiatePasswordReset, completePasswordReset, loaded};

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
};

export function useAuth() {
  return useContext<AuthContextType>(AuthContext);
}