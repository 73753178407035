import React, { useState } from 'react';
import { BoldText } from '../../style/styles';
import Grid from '@mui/material/Grid';
import { JSXMapSerializer, PrismicRichText, useAllPrismicDocumentsByUIDs } from '@prismicio/react';
import { PrismicDocument } from '@prismicio/types';
import Typography from '@mui/material/Typography';
import { Loading } from '../shared/Loading';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

export interface ImportantDocumentsProps {
  documentIds: string[];
}

export interface ImportantDocumentsDispatchProps {
  documentAcceptanceChanged: (documentId: string, accepted: boolean) => void;
}

export const ImportantDocuments = ({ documentIds, documentAcceptanceChanged }: ImportantDocumentsProps & ImportantDocumentsDispatchProps) => {
  const [selectedDocument, setSelectedDocument] = useState<PrismicDocument>();
  const [documents, { state }] = useAllPrismicDocumentsByUIDs('important_document', documentIds);

  const viewDocument = (document: PrismicDocument) => {
    if (selectedDocument !== document) {
      setSelectedDocument(document);
    }
  };

  const handleClose = () => {
    setSelectedDocument(undefined);
  }

  return (
    <Grid container direction={'column'} padding={1}>
      <BoldText gutterBottom>Check the boxes next to each document to confirm you've read & understand its contents. (You'll be able to access these in your profile any time.)</BoldText>
      {state === 'loaded' ?
        documents!.map(document =>
          <AcceptDocument
            key={document.id}
            document={document}
            viewDocument={() => viewDocument(document)}
            acceptDocument={accepted => documentAcceptanceChanged(document.uid!, accepted)}
          />)
        :
        <Loading />
      }
      {!!selectedDocument && <Dialog
        open={!!selectedDocument}
        onClose={handleClose}
        scroll={'paper'}
      >
        {/* These ts-ignores are because of a strange typing error with the PrismicRichText field attribute */}
        {/* @ts-ignore */}
        <DialogTitle><PrismicRichText field={selectedDocument.data.title} components={muiComponentMapping} /></DialogTitle>
        <DialogContent dividers sx={{padding: '1rem'}}>
          {/* @ts-ignore */}
          <PrismicRichText field={selectedDocument.data.document_content} />
        </DialogContent>
        <DialogActions>
          <Clickable onClick={() => handleClose()}>Close</Clickable>
        </DialogActions>
      </Dialog>}
    </Grid>
  );
};

interface AcceptDocumentProps {
  document: PrismicDocument;
  viewDocument: () => void;
  acceptDocument: (accepted: boolean) => void;
}

const AcceptDocument = ({ document, viewDocument, acceptDocument }: AcceptDocumentProps) => (
  <Grid container alignItems={'center'} wrap={'nowrap'}>
    <Checkbox onChange={(e) => acceptDocument(e.target.checked)} />
    <Clickable onClick={viewDocument}>
      {/* @ts-ignore */}
      <PrismicRichText field={document.data.title} components={muiComponentMapping} />
    </Clickable>
  </Grid>
);

const muiComponentMapping: JSXMapSerializer = {
  heading1: ({ children }) => (<Typography gutterBottom>{children}</Typography>),
  paragraph: ({children}) => (<Typography>{children}</Typography>),
};

const Clickable = styled(Link)`
  cursor: pointer;
`;