import Grid from '@mui/material/Grid';
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import Button from '@mui/material/Button';
import Input from '@mui/material/Input';
import React, { useState } from "react";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { ErrorMessage } from "../../../style/styles";

export interface PromoEntryProps {
  promoErrorMessage?: string;
}

export interface PromoEntryDispatchProps {
  getPromoValue: (code:string) => void;
}

export const PromoEntry = ({ promoErrorMessage, getPromoValue }: PromoEntryProps & PromoEntryDispatchProps) => {
  const [promoCode, setPromoCode] = useState('');

  const addPromo = (event:any) => {
    event.preventDefault();
    getPromoValue(promoCode);
    setPromoCode('');
  };

  return (
    <>
      <form onSubmit={addPromo}>
        <PromoLinkSection>
          <PromoLink>Promo Code</PromoLink>
          <Grid item container direction={'row'} justifyContent={'space-between'}>
            <Input type="text" name="promoCode" value={promoCode} onChange={(e: any) => setPromoCode(e.target.value)}/>
            <AddButton type={'submit'}>Add</AddButton>
          </Grid>
        </PromoLinkSection>
      </form>
      {promoErrorMessage &&
        <ErrorMessage>{promoErrorMessage}</ErrorMessage>
      }
    </>
  );
}

const PromoLink = styled(Typography)`
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 2.4rem;
  color: #1976d2;
`;

const PromoLinkSection = styled(Grid)`
  margin-top: 2.5rem;
  padding-left: 1.6rem;
`;

const AddButton = styled(Button)`
  background-color: #FFFFFF;
  color: rgba(0,0,0,0.87);
  font-weight: bold;
`;