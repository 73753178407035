import Grid from '@mui/material/Grid';
import { styled } from "@mui/material/styles";
import Modal from '@mui/material/Modal';
import React from "react";
import whiteLogo from '../../../../assets/grx-logo-white.svg';
import roundLogo from '../../../../assets/goalsrx-circle-logo.png'
import CloseIcon from '@mui/icons-material/Close';
import { BoxRow, Logo } from "../../../../style/styles";
import { MenuCoreContainer } from "../../../../containers/MenuCoreContainer";
import { HamburgerMenuProps } from "./HamburgerMenuParent";
import Box from "@mui/material/Box";

export const MobileHamburger = ({navItemClick, logOutClick, handleClose, menuOpen}: HamburgerMenuProps) => (
  <BlackBackdrop
      open={menuOpen!}
      disableAutoFocus={true}
    >
    <Grid width={'100%'}>
        <BoxRowOppositeEnds>
          <Grid item xs={2}/>
          <Logo src={whiteLogo}/>
          <Grid item container xs={2} justifyContent={'flex-end'} alignItems={'center'}>
              <CloseIconPadded fontSize={'large'} onClick={handleClose} sx={{cursor: 'pointer', color: 'white'}} />
          </Grid>
        </BoxRowOppositeEnds>

        <MenuCoreContainer
          navItemClick={navItemClick}
          logOutClick={logOutClick}
          logo={roundLogo}
        />
      </Grid>
  </BlackBackdrop>
);

export const BlackBackdrop = styled(Modal)`
  background-color: black;
  align-items: start;
  outline: none;
`;

const BoxRowOppositeEnds = styled(BoxRow)`
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 2rem;
  justify-content: space-between;
`;

const CloseIconPadded = styled(CloseIcon)`
  margin-right: 1.5rem;
`;