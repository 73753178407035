import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import React from "react";

const disclaimerText = 'Image is an example. Actual packaging may be different';

export const MobileDisclaimerText = () => {
  return (
    <DisclaimerTextPadded>{disclaimerText}</DisclaimerTextPadded>
  );
}

export const DesktopDisclaimerText = () => {
  return (
    <DisclaimerText>{disclaimerText}</DisclaimerText>
  )
}


const DisclaimerText = styled(Typography)`
  font-size: 1rem;
`;

const DisclaimerTextPadded = styled(DisclaimerText)`
  padding-left: 1rem;
  padding-bottom: 2rem;
`;;