import Grid from '@mui/material/Grid';
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import Link from '@mui/material/Link';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { JSXMapSerializer, PrismicClientHookState, PrismicRichText } from "@prismicio/react";
import React from "react";


export interface TermsAndConditionsProps {
  isLoading: boolean;
  tcPrismicDocState: PrismicClientHookState;
  documents?: any;
  viewTCs: boolean;
  setViewTCs: (value:boolean) => void;
}

export const TermsAndConditions = ({ tcPrismicDocState, documents, viewTCs, setViewTCs }:TermsAndConditionsProps) => {
  return (
    <>
      <Grid item container direction={'column'} justifyContent={'center'} alignItems={'center'}>
        <Typography align={'center'}>By clicking "Place order", I agree to GoalsRx's <Clickable onClick={() => setViewTCs(true)}>Terms and Conditions</Clickable>.</Typography>
      </Grid>
      {tcPrismicDocState === 'loaded' && <Dialog
        open={viewTCs}
        onClose={() => setViewTCs(false)}
        scroll={'paper'}
        >
        <DialogTitle><PrismicRichText field={documents[0].data.title} components={muiComponentMapping} /></DialogTitle>
        <DialogContent dividers sx={{padding: '1rem'}}>
          <PrismicRichText field={documents[0].data.document_content} />
        </DialogContent>
        <DialogActions>
          <Clickable onClick={() => setViewTCs(false)}>Close</Clickable>
        </DialogActions>
      </Dialog>}
    </>
  );
}

const muiComponentMapping: JSXMapSerializer = {
  heading1: ({ children }) => <Typography gutterBottom>{children}</Typography>
};

const Clickable = styled(Link)`
  cursor: pointer;
`;