import { Box, Grid, styled } from "@mui/material";

export const VideoTileMobile = styled(Box)`
  border-radius: 1rem;
  filter: drop-shadow(-1px 8px 15px rgba(0,0,0,0.25));
  background: white;
  margin-top: 4rem;
  overflow: hidden;
  width: 85vw;
`;

export const VideoWrapper = styled('div')`
  position: relative;
  padding-bottom: 56.25%;
`;

export const StyledIFrameMobile = styled('iframe')`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const TitleWrapper = styled('div')`
  padding: 1rem;
`;

export const StyledIFrame = styled('iframe')`
  position: absolute;
  top: 0;
  left: 0;
  width: 101%;
  height: 101%;
`;

export const VideoTile = styled(Grid)`
  border-radius: 1rem;
  filter: drop-shadow(-1px 8px 15px rgba(0,0,0,0.25));
  background: white;
  overflow: hidden;
  margin: 1rem;
`;
