import React, { useState } from "react";
import { PaddedCopyAllIcon, PaddingTaskAltIcon } from "../../style/styles";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";

export interface ShareDocumentButtonStateProps {
  documentId: string;
}

export const ShareDocumentButton = ({documentId}: ShareDocumentButtonStateProps) => {
  const [isCopied, setIsCopied] = useState(false);

  const copyClicked = () => {
    setIsCopied(true);
    navigator.clipboard.writeText(`${window.location.origin}/freeHealthGuide/${documentId}`);
    setTimeout(() => {
      setIsCopied(false);
    }, 750);
  }

  return <TopRightFloatingGrid container onClick={copyClicked}>
    <Typography>Share this Health Guide</Typography>
    {isCopied
      ? <PaddingTaskAltIcon/>
      : <PaddedCopyAllIcon/>
    }
  </TopRightFloatingGrid>;
}

const TopRightFloatingGrid = styled(Grid)`
  cursor: pointer;
  position: absolute;
  width: fit-content;
  top: 6.4rem;
  right: 4rem;
`;