import { styled } from "@mui/material";
import React, { ChangeEvent } from "react";
import { ElementType } from "react";
import { ImageUploadError } from "../../../models/ImageUploadError";
import { convertHeic } from "../../../utils/ConvertHeic";

export interface UploadInputProps {
  WrapperButton: ElementType;
  setFilename: (fileName: string) => void;
  fileResultHandler: (file: string) => void;
  buttonText: string;
  disabled: boolean;
}

export const UploadInput = ({ WrapperButton, setFilename, fileResultHandler, buttonText, disabled }: UploadInputProps) => {
  const fileInput = React.useRef<HTMLInputElement>(null);

  const handleFileSelection = async (event: ChangeEvent<HTMLInputElement>) => {
    if (!!event.target.files && event.target.files.length > 0) {
      setFilename(event.target.files[0].name);
      let uploadedFile = event.target.files[0];
      if (uploadedFile.name.toLowerCase().endsWith('.heic')) {
        uploadedFile = await convertHeic(uploadedFile);
      }

      let reader = new FileReader();
      reader.readAsDataURL(uploadedFile);

      reader.onload = (e) => {
        if (!!e.target && !!e.target.result) {
          let dataUrl: string;

          try {
            dataUrl = e.target.result as string;
          } catch {
            throw new ImageUploadError('DataUrl not a string');
          }

          fileResultHandler(dataUrl);
        } else {
          throw new ImageUploadError('No data url');
        }
      }
    } else {
      throw new ImageUploadError('No file found');
    }
  }
  
  return (
    <WrapperButton type={"button"} onClick={(e:InputEvent) => { fileInput.current?.click(); }}>
      {buttonText}
      <HiddenInput id={'imageUploader'} ref={fileInput} className={"custom-file-upload"} type="file" accept=".jpg,.jpeg,.png,.heic" name="photoUploader" onChange={handleFileSelection} disabled={disabled} />
    </WrapperButton>
  )
}

const HiddenInput = styled('input')`
  display: none;
`;
