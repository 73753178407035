import React, { PropsWithChildren } from 'react';
import { styled } from '@mui/material/styles';
import { ContentSection, grayBackground } from '../../style/styles';
import burnImage from '../../assets/square-lifestyle-burn.jpg';
import buildImage from '../../assets/square-lifestyle-build.jpg';
import thriveImage from '../../assets/square-lifestyle-thrive.jpg';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { theme } from '../../style/theme';
import ButtonBase from '@mui/material/ButtonBase';
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";

import buildIcon from '../../assets/build/build-icon.svg';
import burnIcon from '../../assets/burn/burn-icon.svg';
import thriveIcon from '../../assets/thrive/thrive-icon.png';

export const ProductSelection = () => (
  <ProductsSection id={'products'}>
    <Heading variant={'h1'}>Tell us your health goal.</Heading>
    <SubHeading variant={'h2'}>We’ll deliver a game plan.</SubHeading>
    <SelectionTiles />
  </ProductsSection>
);

const Heading = styled(Typography)`
  font-size: 3.6rem;
  
  ${theme.breakpoints.down('sm')} {
    font-size: 2.4rem;
  }
`;

const SubHeading = styled(Typography)`
  font-size: 2.4rem;
  color: #666666;
  
  ${theme.breakpoints.down('sm')} {
    font-size: 2rem;
  }
`;

const SelectionTiles = () => {
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'));

  return (
    <ProductTiles container justifyContent={isMobile ? 'center' : 'space-between'}>
      <ProductTile image={burnImage} title={'Burn'} to={'/product/burn'}>
        <TileText align={'left'} variant={'h2'}>Torch unhealthy fat fast &amp; keep it off for good.</TileText>
        <TileCTA align={'left'}>Start Burning</TileCTA>
        <Icon src={burnIcon} />
      </ProductTile>
      <ProductTile image={buildImage} title={'Build'} to={'/product/build'}>
        <TileText align={'left'} variant={'h2'}>Gain more healthy muscle mass in less time.</TileText>
        <TileCTA align={'left'}>Start Building</TileCTA>
        <Icon src={buildIcon} />
      </ProductTile>
      <ProductTile image={thriveImage} title={'Thrive'} to={'/product/thrive'}>
        <TileText align={'left'} variant={'h2'}>Boost cellular health &amp; age in reverse.</TileText>
        <TileCTA align={'left'}>Start Thriving</TileCTA>
        <Icon src={thriveIcon} />
      </ProductTile>
    </ProductTiles>
  );
}

interface ProductTileProps {
  image: string;
  title: string;
  to: string;
}

const ProductTile = ({image, title, to, children}: PropsWithChildren<ProductTileProps>) => {
  const navigate = useNavigate();

  return (
    <TileOutline onClick={() => navigate(to)}>
      <TileSizer>
        <TileContents $backgroundImage={image} container direction={'column'} alignItems={'flex-start'} justifyContent={'center'}>
          <TileTitle align={'left'} variant={'h1'}>{title}</TileTitle>
          {children}
        </TileContents>
      </TileSizer>
    </TileOutline>
  );
}

const TileOutline = styled(ButtonBase)`
  width: 30%;
  border-radius: 1rem;
  margin: 1rem 0;
  ${theme.breakpoints.only('xs')} {
    width: 90%;
  }
`;

interface TileProps {
  $backgroundImage: string;
}

const TileSizer = styled(Box)`
  position: relative;
  width: 100%;
  padding-bottom: 100%;
`;

const TileContents = styled(Grid)<TileProps>`
  border-radius: 1rem;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url(${props => props.$backgroundImage});
  background-size: cover;
  color: #FFFFFF;
  padding: 0 3rem;
`;

const ProductsSection = styled(ContentSection)`
  margin-top: 0;
  align-items: flex-start;
`;

const ProductTiles = styled(Grid)`
  margin-top: 3.2rem;
  margin-bottom: 4.8rem;
  width: 100%;
`;

const TileTitle = styled(Typography)`
  font-weight: 300;
  filter: drop-shadow(0 0.2rem 0.5rem black);
`;

const TileText = styled(Typography)`
  font-weight: 300;
  filter: drop-shadow(0 0.2rem 0.5rem black);
`;

const TileCTA = styled(Typography)`
  position: absolute;
  bottom: 2rem;
  font-size: 2rem;
  line-height: 3.6rem;
  filter: drop-shadow(0 0.2rem 0.5rem black);
  text-decoration: underline;
  text-transform: uppercase;
`;

const Icon = styled('img')`
  position: absolute;
  bottom: 2.5rem;
  right: 2.5rem;
  height: 2.5rem;
  width: 2.5rem;
`;