import React, { ReactElement, useState } from 'react';
import { styled } from '@mui/material/styles';
import { LeftArrow, RightArrow } from './Icons';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { SmallIcon } from '../../style/styles';
import { theme } from "../../style/theme";

export interface CarouselItem {
  image?: string | ReactElement;
  imageTitle?: string;
  imageSubtitle?: string;
  name?: string;
  icon?: string;
  content: ReactElement;
}

export interface CarouselProps {
  items: CarouselItem[];
}

// TODO: Allow mobile users to simply scroll through the carousel
export const Carousel = ({items}: CarouselProps) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const currentItem = items[currentIndex];

  return (
    <Grid container>
      <Grid item xs={12} sm={8} lg={6} container direction={'column'} alignItems={'center'}>
        {!!currentItem.image && typeof currentItem.image === 'string' && <LeftImage src={currentItem.image} />}
        {!!currentItem.image && typeof currentItem.image !== 'string' && currentItem.image}
        {!!currentItem.imageTitle && <Typography variant={'h1'} sx={{margin: '2rem 0'}}>{currentItem.imageTitle}</Typography>}
        {!!currentItem.imageSubtitle && <Typography>{currentItem.imageSubtitle}</Typography>}
      </Grid>
      <RightColumn item xs={12} sm={4} container direction={'column'}>
        <Grid container justifyContent={'space-between'}>
          {!!currentItem.name && <Typography variant={'h1'}>{currentItem.name}</Typography>}
          {!!currentItem.icon && <SmallIcon src={currentItem.icon} />}
        </Grid>
        <ControlBar itemCount={items.length} activeSegment={currentIndex} changeSelection={setCurrentIndex} />
        {currentItem.content}
      </RightColumn>
    </Grid>
  );
};

interface ControlBarProps {
  itemCount: number;
  activeSegment: number;
  changeSelection: (newIndex: number) => void;
}

interface ActiveSegmentProps {
  itemCount: number;
  activeSegment: number;
}

const ControlBar = ({itemCount, activeSegment, changeSelection}: ControlBarProps) => {
  const incrementSelection = () => changeSelection(Math.min((activeSegment + 1), itemCount - 1));
  const decrementSelection = () => changeSelection(Math.max((activeSegment - 1), 0));
  return (
    <ControlBarDiv>
      <Grid container>
        <ControlArrow onClick={decrementSelection}>
          <LeftArrow color={activeSegment==0 ? 'gray' : 'black'} />
        </ControlArrow>
        <ControlArrow onClick={incrementSelection}>
          <RightArrow color={activeSegment==itemCount-1 ? 'gray' : 'black'} />
        </ControlArrow>
      </Grid>
      <ControlBarLine>
        <ActiveControlBarSegment itemCount={itemCount} activeSegment={activeSegment} />
      </ControlBarLine>
    </ControlBarDiv>
  );
};

const ControlBarDiv = styled('div')`
  margin: 1.6rem 0 3.2rem 0;
`;

const ControlBarLine = styled('div')`
  height: 1px;
  background: #999999;
  width: 100%;
  position: relative;
`;

const ControlArrow = styled('div')`
  margin: 5px;
`;

const ActiveControlBarSegment = styled('div')<ActiveSegmentProps>`
  height: 3px;
  background: #000000;
  width: calc(100%/${props => props.itemCount});
  position: absolute;
  top: 0;
  left: calc((100%/${props => props.itemCount}) * ${props => props.activeSegment});
  transition: left 0.4s ease-in-out;
`;

const RightColumn = styled(Grid)`
  padding-top: 14rem;
  
  ${theme.breakpoints.down('sm')} {
    padding-top: 3rem;
  }
`;

const LeftImage = styled('img')`
  width: 95%;
  height: auto;
  max-width: 30rem;
  max-height: 30rem;
`;