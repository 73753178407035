import React from 'react';
import { styled } from '@mui/material/styles';
import whiteLogo from '../../assets/grx-logo-white.svg';
import { Link, useNavigate } from 'react-router-dom';
import Toolbar from '@mui/material/Toolbar';
import Grid from "@mui/material/Grid";
import { AuthDisplayContainer } from '../../containers/AuthDisplayContainer';
import { Logo, SiteHeader } from '../../style/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { theme } from '../../style/theme';
import { MOBILE_HEADER_BREAKPOINT } from "./Constants";

export const Header = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down(MOBILE_HEADER_BREAKPOINT));
  return (
    <>
      {isMobile
        ? <MobileHeader />
        : <DesktopHeader />
      }
    </>
  )
};

const DesktopHeader = () => (
  <SiteHeader>
    <Toolbar>
      <Grid container>
        <Grid item xs={2} paddingTop={'5px'}>
          <Link to={'/'}><Logo src={whiteLogo} alt={'GoalsRx Logo'} /></Link>
        </Grid>
        <LockHeight container item xs justifyContent={'flex-end'}>
          <AuthDisplayContainer />
        </LockHeight>
      </Grid>
    </Toolbar>
  </SiteHeader>
);

const MobileHeader = () => {
  const navigate = useNavigate();

  return (
    <SiteHeader>
      <Toolbar>
        <Grid container justifyContent={'space-between'}>
          <LogoWrapper item container xs justifyContent={'center'}>
            <MyLink to={'/'}><Logo src={whiteLogo} alt={'GoalsRx Logo'} width={'155'} height={'40'}/></MyLink>
          </LogoWrapper>
          <LockHeight container item xs={3} justifyContent={'flex-end'}>
            <AuthDisplayContainer/>
          </LockHeight>
        </Grid>
      </Toolbar>
    </SiteHeader>
  );
}

const HeaderLink = styled(Link)`
  color: white;
  text-decoration: none;
  font-weight: bold;
  font-size: 1.6rem;
  white-space: nowrap;
`;

const MyLink = styled(Link)`
  height: 8rem;
  width: 15.5rem;
`;

const LockHeight = styled(Grid)`
  min-height: 8rem;
  height: 8rem;
  max-height: 8rem;
`;

const LogoWrapper = styled(LockHeight)`
  width: 15.5rem;
  max-width: 15.5rem;
`;