import { Footer, FooterDispatchProps, FooterProps } from '../components/shared/Footer';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { WebAppState } from '../redux/WebAppState';
import { SUBSCRIBE_NEWSLETTER } from '../redux/apiCalls';
import { SUBSCRIBE_NEWSLETTER as SUBSCRIBE } from '../redux/actions';

const mapStateToProps: MapStateToProps<FooterProps, {}, WebAppState> = state => ({
  errorMessage: state.system.apiCalls[SUBSCRIBE_NEWSLETTER].apiError?.message,
})

const mapDispatchToProps: MapDispatchToProps<FooterDispatchProps, {}> = dispatch => ({
  subscribe: email => dispatch({ type: SUBSCRIBE, data: email }),
})

export default connect(mapStateToProps, mapDispatchToProps)(Footer);