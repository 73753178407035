import { Button } from "@mui/material";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

export const Line = styled('hr')`
  border-top-color: rgba(218, 218, 218, 0.6);
  border-width: 1px 0 0 0;
  opacity: 60%;
  width: 100%;
`;

export const RoundedContainer = styled(Grid)`
  background-color: white;
  border-radius: 2rem;
  padding: 5rem;
  margin-bottom: 5rem;
`;

export const Bold18 = styled(Typography)`
  font-weight: 500;
  font-size: 1.8rem;
`;

export const GrayBold18 = styled(Bold18)`
  color: #6E6E6E;
`;

export const Bold16 = styled(Typography)`
  font-weight: 500;
  font-size: 1.6rem;
`;

export const GrayBold16 = styled(Bold16)`
  color: #6E6E6E;
`;

export const Font16 = styled(Typography)`
  font-size: 1.6rem;
  font-weight: 400;
`;

export const Gray16 = styled(Font16)`
  color: #6E6E6E;
`;

export const Red16 = styled(Font16)`
  color: #FF0000;
`;

export const Font14 = styled(Typography)`
  font-size: 1.4rem;
  font-weight: 400;
`;

export const UserProfileButton = styled(Button)`
  background: #000000;
  color: #FFFFFF;
  cursor: pointer;
  font-weight: 500;
  padding: .5rem;
  text-transform: uppercase;
`;
