import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { HealthGuideContentSubSection } from '../../models/HealthGuideDocument';
import { WarmupSection } from './workout/WarmupSection';
import { PrismicRichText } from '@prismicio/react';
import { healthGuideTextRenderer } from '../../utils/displayUtils';
import { getTextFromRTF } from '../../utils/PrismicUtils';
import { WorkoutSection } from './workout/WorkoutSection';
import Box from '@mui/material/Box';
import { styled } from "@mui/material/styles";
import { useRef } from "react";
import { Product } from "../../models/Product";

export interface MobileSubSectionAccordionProps {
  subSection: HealthGuideContentSubSection;
  product: string;
}

export default function MobileSubSectionAccordion({subSection, product}: MobileSubSectionAccordionProps) {
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const scrollRefs = useRef<HTMLElement[]>([]);

  const executeScroll = (index: number) => {
    scrollRefs.current[index].scrollIntoView();
  }

  const handleChange = (panel: string, index: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
    window.setTimeout(() => executeScroll(index), 500);
  };

  return (
    <div>
      {!!subSection.warmup &&
        <div key={'outer-warmup'}>
          <ScrollDiv id={'warmup'} key={`scroll-warmup`} ref={section => {
            scrollRefs.current[0] = section!;
          }} />
          <Accordion expanded={expanded === 'warmup'} onChange={handleChange('warmup', 0)} sx={{backgroundColor: '#F2F2F2'}}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}><AccordionRow>
              <WorkoutNumber>{'W'}</WorkoutNumber>
              <Typography variant={'h2'}>WARM UP</Typography>
            </AccordionRow>
            </AccordionSummary>
            <AccordionDetails>
              <WarmupSection warmupSlice={subSection.warmup} />
            </AccordionDetails>
          </Accordion>
        </div>
      }
      {!!subSection.workouts
        ? subSection.workouts.map((workout, index) => {
            let id = `workout${getTextFromRTF(workout.number)}`;
            return (
              <div key={`outer-${id}`}>
                <ScrollDiv id={id} key={`scroll-${id}`} ref={section => {
                  scrollRefs.current[index + 1] = section!;    //dynamic ref
                }} />
                <Accordion key={id} expanded={expanded === id} onChange={handleChange(id, index+1)} sx={{backgroundColor: '#F2F2F2'}}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <AccordionRow>
                      <WorkoutNumber>{getTextFromRTF(workout.number)}</WorkoutNumber>
                      <PrismicRichText field={workout.titleString} components={healthGuideTextRenderer}/>
                    </AccordionRow>
                  </AccordionSummary>
                  <AccordionDetails>
                    <WorkoutSection workoutSlice={workout} product={product} backgroundColor={'#F2F2F2'}/>
                  </AccordionDetails>
                </Accordion>
              </div>
            );
          })
        : null
      }
    </div>
  );
}

const AccordionRow = styled(Box)`
  display: grid;
  grid-template-columns: 4rem auto;
  column-gap: 2rem;
  align-items: center;
`;

const WorkoutNumber = styled(Typography)`
  font-size: 2rem;
  background-color: #C4C4C4;
  text-align: center;
  font-weight: bold;
`;

const ScrollDiv = styled('div')`
  scroll-margin-top: 184px;
`;