import React from "react";
import { User } from "src/models/User";
import { LoginButton } from "./LoginButton";
import { ProfileIcon } from "./ProfileIcon";

export interface AuthDisplayProps {
  user: User | undefined,
  program?: string,
  loading: boolean
}

export const AuthDisplay = ({user, program, loading}: AuthDisplayProps) => {
  return (
    !user ?
      <LoginButton />
      : <ProfileIcon user={user} program={program} loading={loading} />
  );
}
