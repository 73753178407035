import React from 'react';
import { ContentSection, RelativeGrid } from '../../style/styles';
import { ProductNames } from "./ProductNames";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import medicine from '../../assets/3-products.png';
import { styled } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { theme } from "../../style/theme";

export const PrescriptionPromo = () => {
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'));
  return (
    <ContentSection>
      <RelativeGrid container justifyContent={'space-between'}>
        <Grid container direction={'column'} item xs={12} sm={5} xl={6}>
          <ProductNames />
          <Typography variant={'h1'} sx={{marginBottom: '4rem'}}>Results-Boosting Prescriptions & Supplements</Typography>
          <Typography>Peptides are powerful. They enhance muscle mass, boost immune health, reduce the look of wrinkles & so much more. Best of all, they are non-habit forming. Your GoalsRx medical practitioner will prescribe a custom variety of high-quality peptides & pharmaceutical-grade supplements to help you build momentum & achieve long-term results.</Typography>
        </Grid>
        <Grid item xs={12} sm={7} xl={5} container justifyContent={isMobile ? 'center' : 'flex-end'}>
          <BottleImage src={medicine} />
        </Grid>
      </RelativeGrid>
    </ContentSection>
  );
}

const BottleImage = styled('img')`
  max-width: 90%;
  height: auto;
  ${theme.breakpoints.down('sm')} {
    margin-top: 2rem;
  }
`;