import React, { PropsWithChildren } from "react";
import { styled } from '@mui/material/styles';
import headerDots from '../../assets/halftone-header-right.svg';
import { theme } from '../../style/theme';
import useMediaQuery from '@mui/material/useMediaQuery';

interface HeroSectionProps {
  backgroundImage: string;
  mobileBackgroundImage: string;
}

interface HeroBackgroundProps {
  image: string;
}

export const HeroSection = ({children, backgroundImage, mobileBackgroundImage}: PropsWithChildren<HeroSectionProps>) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const image = isMobile ? mobileBackgroundImage : backgroundImage;
  return (
    <MainContent>
      <HeroBackground image={image}>
        {children}
        <Dots src={headerDots} />
      </HeroBackground>
    </MainContent>
  );
}

const HeroBackground = styled('div')<HeroBackgroundProps>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45)), url(${props => props.image});
  background-size: cover;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  ${theme.breakpoints.down('sm')} {
    padding: 2rem;
  }
`;

const Dots = styled('img')`
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  opacity: 0.15;
`;

const MainContent = styled('section')`
  position: relative;
  width: 100%;
  ${theme.breakpoints.down('sm')} {
    padding-bottom: 190%;
  }
  ${theme.breakpoints.up('sm')} {
    padding-bottom: 56.25%;
  }
`;