import React, { ChangeEvent, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { Goal, GoalResponse } from "../../models/Goal";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

export interface GoalCaptureProps {
  goalResponses: GoalResponse[];
  goalsToCapture: Goal[];
}

export interface GoalCaptureDispatchProps {
  updateGoalResponses: (goal: Goal, value: number | string ) => void;
}

export const GoalCapture = ({ goalResponses, goalsToCapture, updateGoalResponses }: GoalCaptureProps & GoalCaptureDispatchProps) => {
  const renderElement = (goal: Goal) => {
    const updateResponse = (event: ChangeEvent<HTMLInputElement>) => {
      updateGoalResponses(goal, event.target.value);
    }

    const response = goalResponses.find(goalResponse => goalResponse.goal.key === goal.key);
    return <Grid key={goal.key} item xs container direction={'column'}>
      {goal.type === 'checkbox' && <FormControlLabel sx={{pt: 0}} control={<Checkbox checked={!!response} onChange={updateResponse}/>} label={goal.prompt} />}
      {goal.type !== 'checkbox' && <Grid item xs={12} container direction={'column'}>
        <Typography sx={{pt: '1rem'}}>{goal.prompt}</Typography>
        <PaddedTextField inputProps={{ 'data-testid': goal.key, maxLength: 255, min: 0 }} type={goal.type} label={goal.label} value={!!response ? response.value : ''} onChange={updateResponse} required />
      </Grid>}
    </Grid>
  }

  return <GoalCaptureWrapper item container direction={'column'}>
    <Heading variant={'h2'}>What are your goals?</Heading>
    { goalsToCapture.map(renderElement) }
  </GoalCaptureWrapper>
}

const GoalCaptureWrapper = styled(Grid)`
  margin: 3rem 0 2rem 0;
  padding: 0.8rem;
`;

const Heading = styled(Typography)`
  font-weight: 600;
  margin-bottom: 1rem;
`;

const PaddedTextField = styled(TextField)`
  margin-top: 1rem;
`;