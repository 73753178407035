import Grid from '@mui/material/Grid';
import { styled } from "@mui/material/styles";
import React from "react";
import { HamburgerMenuProps } from "./HamburgerMenuParent";
import { MenuCoreContainer } from "../../../../containers/MenuCoreContainer";
import { BlackBackdrop } from "./MobileHamburger";
import roundLogo from '../../../../assets/goalsrx-circle-logo.png'

export const DesktopHamburger = ({navItemClick, logOutClick, handleClose, menuOpen}: HamburgerMenuProps) => (
  <DesktopBlackBackdrop
      open={menuOpen!}
      disableAutoFocus={true}
      onClose={handleClose}
    >
    <Grid width={'100%'}>
    <MenuCoreContainer
      navItemClick={navItemClick}
      logOutClick={logOutClick}
      logo={roundLogo}
    />
    </Grid>
  </DesktopBlackBackdrop>
);

const DesktopBlackBackdrop = styled(BlackBackdrop)`
  left: unset;
  width: 350px;
  height: fit-content;
  padding-top: 2rem;
  padding-bottom: 3rem;
`;