import { FormControlLabel, Grid, Radio, RadioGroup } from "@mui/material";
import React from "react";
import { ChangeEvent } from "react";
import { BoldText } from "../../style/styles";

export interface InjectableSelectionProps {
  nonInjectableOption?: string;
}

export interface InjectableSelectionDispatchProps {
  setNonInjectableOption: (nonInjectableSelected?: boolean) => void;
}

export const InjectableSelection  = ({nonInjectableOption, setNonInjectableOption}: InjectableSelectionProps & InjectableSelectionDispatchProps) => {
  const updateNonInjectableSelection = (event: ChangeEvent<HTMLInputElement>) => {
    const selection = event.target.value == 'pill';
    setNonInjectableOption(selection);
  }

  return (
    <Grid container direction={'column'} padding={1}>
      <Grid item>
        <BoldText gutterBottom>Which version of the B12 vitamin do you prefer?</BoldText>
      </Grid>
      <RadioGroup row value={nonInjectableOption} onChange={updateNonInjectableSelection}>
        <FormControlLabel label={'Pill'} control={<Radio />} value={'pill'} />
        <FormControlLabel label={'Injectable'} control={<Radio />} value={'injectable'} />
      </RadioGroup>
    </Grid>
  );
}
