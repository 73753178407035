import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { WebAppState } from '../../redux/WebAppState';
import { LOAD_HEALTH_GUIDE } from "../../redux/actions";
import { HealthGuide, HealthGuideDispatchProps, HealthGuideProps } from "../../components/healthguide/HealthGuide";
import { HealthGuideDocumentMetadata } from "../../models/HealthGuideDocument";

const mapStateToProps: MapStateToProps<HealthGuideProps, {}, WebAppState> = state => ({
  isSubscriptionLoading: state.system.apiCalls.getUserProfile.isLoading,
  isLoading: state.system.apiCalls.getHealthGuide.isLoading,
  product: !!state.user.subscription && !!state.user.subscription.name ? state.user.subscription.name.toLowerCase() : 'free',
  healthGuideDocs: filterDocuments(state.healthGuide.documentList, state.healthGuide.programFilter, state.healthGuide.filter),
  filter: state.healthGuide.filter,
  programFilter: state.healthGuide.programFilter || 'all'
});

const mapDispatchToProps: MapDispatchToProps<HealthGuideDispatchProps, {}> = dispatch => ({
  loadHealthGuideDocs: () => dispatch({type: LOAD_HEALTH_GUIDE}),
});

function filterDocuments(documentList: HealthGuideDocumentMetadata[], programFilter?: string, filter?: string): HealthGuideDocumentMetadata[] {
  const program = programFilter === 'all' ? 'free' : programFilter?.toLowerCase();

  return documentList.filter(doc => !doc.tags || !doc.tags.length || !filter || !doc.tags || doc.tags.includes(filter))
    .filter(contentDoc => !contentDoc.programs || !program || contentDoc.programs.includes(program));
}

export default connect(mapStateToProps, mapDispatchToProps)(HealthGuide);