import Typography from "@mui/material/Typography";
import React from "react";
import { ContentSection } from "../../../style/styles";

export interface PrismicNotFoundProps {
    message: string;
}

export const PrismicNotFound = ({ message }: PrismicNotFoundProps) => (
    <ContentSection>
      <Typography variant={"h2"}>404</Typography>
      <Typography variant={"h3"}>{message}</Typography>
    </ContentSection>
  );