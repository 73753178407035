import React, {PropsWithChildren} from "react";
import { styled } from '@mui/material/styles';
import Grid from "@mui/material/Grid";
import headerDots from "../../assets/halftone-header-right.svg";
import { theme } from "../../style/theme";

interface HealthGuideHeaderProps {
  backgroundImage: string;
}

export const HealthGuideSubSectionHeader = ({children, backgroundImage}: PropsWithChildren<HealthGuideHeaderProps>) => {
  return (
    <MainContent>
      <BackgroundWrapper>
        <Mask/>
        <BannerImage src={backgroundImage}/>
        <Dots src={headerDots}/>
      </BackgroundWrapper>
      <Wrapper container direction={'column'}>
        {children}
      </Wrapper>
    </MainContent>
  );
}

const MainContent = styled('div')`
  position: relative;
`;

const Wrapper = styled(Grid)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`;

const BackgroundWrapper = styled('div')`
  height: 15rem;
  ${theme.breakpoints.down('sm')} {
    height: 12rem;
  }
  position: relative;
  overflow: hidden;
`;

const Mask = styled('div')`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: #000000;
  opacity: 0.6;
  z-index: -1;
`

const BannerImage = styled('img')`
  position: absolute;
  left: -10%;
  top: 0;
  width: 60%;
  z-index: -2;
`;

const Dots = styled('img')`
  position: absolute;
  right: 0;
  top: 0;
  width: 40%;
  opacity: 0.15;
  z-index: -2;
`;