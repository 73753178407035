import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React from 'react';
import { theme } from '../../style/theme';
import { Product } from '../../models/Product';
import { useNavigate } from 'react-router-dom';
import { ActionButton, ContentSection } from '../../style/styles';
import { styled } from '@mui/material/styles';
import HealthGuideButtonContainer from "../../containers/HealthGuideButtonContainer";

export const SHOPIFY_FAILED_MESSAGE = "Uh oh! We can't connect to our store right now. Please try again later!";

export interface ProductHeroSectionProps {
  product: Product;
}

export const ProductHeroSection = ({ product }: ProductHeroSectionProps) => {
  const props = product.displayInfo.heroSectionContent;
  const navigate = useNavigate();

  return (
    <Wrapper>
      <ContentDiv wrap={"nowrap"} container spacing={1}>
        <Grid item xs container direction={'column'}>
          <ZIndexWrapper item xs>
            <Grid item xs container direction={'column'}>
              <ProductTitle item xs container><img src={props.title} width={'50%'} /></ProductTitle>
              <ProductSubtitle variant={'h2'}>{props.subtitle}</ProductSubtitle>
            </Grid>
            {!!product.price
              ? <PriceDetail item xs container direction={'row'} alignItems={'flex-end'}>
                <Price>${product.price}</Price>
                <Month>month</Month>
              </PriceDetail>
              : <Typography>{SHOPIFY_FAILED_MESSAGE}</Typography>
            }
            {!!product.price
              ? <Terms>{props.terms}</Terms>
              : undefined
            }
            <Grid item container gap={3}>
              {!!product.price
                ? <ActionButton $light onClick={() => navigate('/purchase')}>BUY NOW</ActionButton>
                : undefined
              }
            <HealthGuideButtonContainer color={product.displayInfo.actionButtonColor} scaleText={true}/>
            </Grid>
          </ZIndexWrapper>
          <Thumbnail><img src={props.thumbnail} width={'100%'} /></Thumbnail>
          <Logo><img src={props.logo} width={'100%'} /></Logo>
        </Grid>
        <Detail item xs sx={{ display: {xs: 'none', sm: 'flex'} }}>
          <DetailText variant={'h1'}>{props.detailText}</DetailText>
        </Detail>
      </ContentDiv>
    </Wrapper>
  );
}

const Wrapper = styled(ContentSection)`
  align-items: flex-start;
  width: 100vw;
  overflow: hidden;
`;

const ZIndexWrapper = styled(Grid)`
  z-index: 2;
`;

const Thumbnail = styled('div')`
  position: absolute;
  ${theme.breakpoints.up('sm')} {
    top: 24.3%;
    left: 33.5%;
    width: 11.9%;
  }
  ${theme.breakpoints.down('sm')} {
    top: 15.3%;
    left: 52.5%;
    width: 40%;
  }
  z-index: 1;
`;

const Logo = styled('div')`
  position: absolute;
  ${theme.breakpoints.up('sm')} {
    top: 19.5%;
    left: 6.8%;
    width: 39.7%;
  }
  ${theme.breakpoints.down('sm')} {
    top: 6%;
    left: -26.4%;
    right: -26.4%;
  }
  z-index: 0;
`;

const ProductTitle = styled(Grid)`
  margin-bottom: .8rem;
`;

const ProductSubtitle = styled(Typography)`
  margin-bottom: 5rem;
  ${theme.breakpoints.down('xl')} {
    margin-bottom: 4rem;
  }
  ${theme.breakpoints.down('lg')} {
    margin-bottom: 3rem;
  }
  ${theme.breakpoints.down('md')} {
    margin-bottom: 1rem;
    font-size: 1.6rem;
  }
  ${theme.breakpoints.down('sm')} {
    margin-bottom: 5rem;
  }
`;

const PriceDetail = styled(Grid)`
  margin-bottom: 1rem;
  ${theme.breakpoints.down('md')} {
    margin-bottom: .5rem;
  }
`;

const Price = styled('p')`
  font-weight: 400;
  line-height: 0.8;
  margin-right: 0.5rem;

  font-size: 3.6rem;
  ${theme.breakpoints.down('xl')} {
    font-size: 3rem;
  }
  ${theme.breakpoints.down('lg')} {
    font-size: 2.4rem;
  }
  ${theme.breakpoints.down('md')} {
    font-size: 1.8rem;
  }
  ${theme.breakpoints.down('sm')} {
    font-size: 3.6rem;
  }
`;

const Month = styled('p')`
  font-weight: 300;
  line-height: 1;

  font-size: 1.4rem;
  ${theme.breakpoints.down('xl')} {
    font-size: 1.17rem;
  }
  ${theme.breakpoints.down('lg')} {
    font-size: .93rem;
  }
  ${theme.breakpoints.down('md')} {
    font-size: .7rem;
  }
  ${theme.breakpoints.down('sm')} {
    font-size: 1.4rem;
  }
`;

const Terms = styled('p')`
  font-weight: 300;
  color: #AFAFAF;
  margin-bottom: 2rem;
  
  font-size: 1.2rem;
  ${theme.breakpoints.down('xl')} {
    font-size: 1rem;
    margin-bottom: 1.5rem;
  }
  ${theme.breakpoints.down('lg')} {
    font-size: .8rem;
    margin-bottom: 1rem;
  }
  ${theme.breakpoints.down('md')} {
    font-size: .6rem;
    margin-bottom: .5rem;
  }
  ${theme.breakpoints.down('sm')} {
    font-size: 1.2rem;
    margin-bottom: 4rem;
  }
`;

const Detail = styled(Grid)`
  margin-top: 2.2rem;
  z-index: 2;
`;

const DetailText = styled(Typography)`
  padding-left: 5vw;
  ${theme.breakpoints.down('md')} {
    font-size: 1.8rem;
  }
`;

const ContentDiv = styled(Grid)`
  margin-top: 12rem;
`;