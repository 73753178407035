import React, { PropsWithChildren } from "react";
import { styled } from '@mui/material/styles';
import Grid from "@mui/material/Grid";
import {theme} from "../../style/theme";

interface HealthGuideHeaderProps {
  backgroundImage: string;
}

export const HealthGuideHeader = ({children, backgroundImage}: PropsWithChildren<HealthGuideHeaderProps>) => {
  return (
    <HeaderWrapper>
      <MainContent>
        <BackgroundWrapper>
          <Mask/>
          <BannerImage src={backgroundImage}/>
        </BackgroundWrapper>
        <Wrapper container direction={'column'}>
          {children}
        </Wrapper>
      </MainContent>
    </HeaderWrapper>
  );
}

const HeaderWrapper = styled('div')`
  position: fixed;
  width: 100%;
  background-color: #ffffff;
  z-index: 1;
`;

const MainContent = styled('div')`
  position: relative;
`;

const Wrapper = styled(Grid)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
`;

const BackgroundWrapper = styled('div')`
  position: relative;
  overflow: hidden;
  height: 16rem;
  ${theme.breakpoints.down('lg')} {
    height: 12rem;
  }
  ${theme.breakpoints.down('sm')} {
    height: 8rem;
  }
`;

const Mask = styled('div')`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: #0F0F0F;
  opacity: 0.46;
  z-index: 1;
`

const BannerImage = styled('img')`
  vertical-align: middle;
  max-width: 100%;
  width: 100%;
  min-height: 134%;
  transform: translateY(-25%);
  z-index: 0;
`;