import Grid from '@mui/material/Grid';
import { Button, ErrorText, Input, Label } from '../../style/styles';
import React, { FormEvent, useState } from 'react';
import { styled } from '@mui/material/styles';
import { useAuth } from '../shared/AuthContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { Loading } from '../shared/Loading';
import Typography from "@mui/material/Typography";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { theme } from '../../style/theme';
import Tooltip from "@mui/material/Tooltip";
import { Info } from "@mui/icons-material";

export interface CreateAccountFormDispatchProps {
  reportError: (error: Error) => void;
  setTemporaryPassword: (tempPass: string) => void;
  subscribeToNewsletter: (email: string) => void;
}

const PASSWORD_REQUIREMENTS = `Password must be at least 8 characters long and contain at least 1 number, 1 uppercase letter, 1 lowercase letter, and 1 special character (^ $ * . [ ] { } ( ) ? - " ! @ # % & / \\ , > < ' : ; | _ ~ \` + =)`;

// TODO: Bulk up error handling to provide useful information to users
export const CreateAccountForm = ({ reportError, setTemporaryPassword, subscribeToNewsletter }: CreateAccountFormDispatchProps) => {
  const [open, setOpen] = React.useState(false);
  const [password, setPassword] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [allowMarketing, setAllowMarketing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [failed, setFailed] = useState(false);
  const [errorText, setErrorText] = useState('');
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as {
    from?: Location;
  }
  const redirectPath = state?.from?.pathname || '/';

  const savePassword = (password: string) => {
    setPassword(password);
    setTemporaryPassword(password);
  }

  const isFormValid = (): boolean => {
    if (password !== repeatPassword) {
      setErrorText('Password and Confirm Password do not match');
      setFailed(true);
      return false;
    }

    return true;
  }

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();

    if (loading || !isFormValid()) return;

    setLoading(true);
    return auth.signUp(emailAddress, password)
      .then(() => {
        if(allowMarketing) {
          subscribeToNewsletter(emailAddress);
        }
      })
      .then(() => navigate('/confirmSignUp', { state: {redirectPath: redirectPath }}))
      .catch(err => {
        if (err.message.toLowerCase() === 'user already exists') {
          setErrorText('That username is taken');
        } else {
          reportError(err);
          setErrorText(err.message);
        }
        setFailed(true);
        setLoading(false);
      });
  };

  const toggleTooltip = () => {
    setOpen(!open);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container direction={'column'} alignItems={'center'}>
        <Typography variant={'h1'} gutterBottom sx={{ marginTop: '3rem' }}>Create Your Account</Typography>
        <Typography variant={'h2'} gutterBottom>Let's start crushing goals.</Typography>
        <Grid item xs={11} sm={6} md={4} lg={3} container direction={'column'} padding={1}>
          <Label>Email Address</Label>
          <Input data-testid="email-address" type={'text'} value={emailAddress} onChange={(e) => setEmailAddress(e.target.value)} />
        </Grid>
        <Grid item xs={11} sm={6} md={4} lg={3} container direction={'column'} padding={1}>
          <Grid item xs container alignItems={'center'}>
            <Label>Password</Label>
            <Tooltip
              PopperProps={{
                disablePortal: true,
              }}
              onClose={toggleTooltip}
              open={open}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title={PASSWORD_REQUIREMENTS}>
              <Info onClick={toggleTooltip}/>
            </Tooltip>
          </Grid>
          <Input type={'password'} value={password} onChange={(e) => savePassword(e.target.value)} />
        </Grid>
        <Grid item xs={11} sm={6} md={4} lg={3} container direction={'column'} padding={1}>
          <Label>Confirm Password</Label>
          <Input type={'password'} value={repeatPassword} onChange={(e) => setRepeatPassword(e.target.value)} />
        </Grid>
        {failed && <ErrorText>{errorText}. Please try again.</ErrorText>}
        <ConsentLabel
          control={<Checkbox data-testid="approve-marketing" checked={allowMarketing} onChange={e => setAllowMarketing(e.target.checked)}/>}
          label={'Give me science-backed health tips, life hacks, exclusive savings, and product updates.'} />
        <CreateAccountButton type={'submit'}>{loading ? <Loading /> : 'Create Account'}</CreateAccountButton>
      </Grid>
    </form>
  );
}

const CreateAccountButton = styled(Button)`
  margin: 2rem 0 0;
`;

const ConsentLabel = styled(FormControlLabel)`
  ${theme.breakpoints.down('sm')} {
    margin: 0 1.5rem;
  }
  margin-right: 0;
`;