import React from "react";
import { PrismicRichText } from "@prismicio/react";
import { DocumentNavBar, HealthGuideContentSectionLeft } from "../../../style/styles";
import { healthGuideTextRenderer } from "../../../utils/displayUtils";
import { styled, ThemeProvider } from "@mui/material/styles";
import { healthGuideDocumentTheme, theme } from "../../../style/theme";
import { HealthGuideDocument } from "src/models/HealthGuideDocument";
import { RichTextField } from "@prismicio/types";
import ToggleButtonBarContainer from "../../../containers/shared/ToggleButtonBarContainer";
import Grid from "@mui/material/Grid";
import PrismicSectionContainer from "../../../containers/healthguide/PrismicSectionContainer";
import { getTextFromRTF } from "../../../utils/PrismicUtils";
import PrismicSubSectionContainer from "./PrismicSubsectionContainer";
import ShareDocumentButtonContainer from "../../../containers/healthguide/ShareDocumentButtonContainer";

// TODO: Genericize the props as we add additional templates
export interface PrismicDocumentTemplateProps {
  document: HealthGuideDocument
}

export interface PrismicDocumentTemplateStateProps {
  sectionIndex: number;
}

export interface PrismicDocumentTemplateDispatchProps {
  setSectionIndex: (index: number) => void;
}

export const PrismicDocumentTemplate = ({ document, sectionIndex, setSectionIndex }: PrismicDocumentTemplateProps & PrismicDocumentTemplateStateProps & PrismicDocumentTemplateDispatchProps)  => {
  const handleSectionSelect = (event: any, newSectionIndex: number | null) => {
    if(newSectionIndex !== null) {
      setSectionIndex(newSectionIndex);
      window.scrollTo(0, 0);
    }
  }

  const isSingleSectionWorkout = () => {
    return document.sections.length === 1
      && document.sections[0].titleString === ''
      && getTextFromRTF(document.sections[0].content) === ''
      && document.sections[0].subSections.length > 0
  }

  const buttonOptions = () => {
    if (isSingleSectionWorkout()) {
      return ['overview', ...document.sections[0].subSections.map(subSection => subSection.titleString)]
    }
    return ['overview', ...document.sections.map(section => section.titleString)];
  }

  const isSingleSectionDocument = (document: HealthGuideDocument) => {
    return document.sections.length === 0
      || document.sections.length === 1
      && document.sections[0].subSections.length === 0;
  }

  return (
    <>
      <LeftDocumentNavBar>
        {!isSingleSectionDocument(document) &&
          <ToggleButtonBarWrapper justifyContent={'center'}>
            <ToggleButtonBarContainer
            options={buttonOptions()}
            selectedIndex={sectionIndex}
            selectHandler={handleSectionSelect}
            variant={'section'}
            />
          </ToggleButtonBarWrapper>
        }
      </LeftDocumentNavBar>
      <ThemeProvider theme={healthGuideDocumentTheme} >
        {/* determine if at root of document */}
        {sectionIndex === 0
          ? <>
              <DocumentRoot
                imageSource={document.metadata.tileImgSrc!}
                content={document.metadata.content!}
              />
              {isSingleSectionDocument(document) && !!document.sections[0] &&
                <PrismicSectionContainer section={document.sections[0]} />
              }
            </>
          :<>
          {!isSingleSectionWorkout()
            ? <PrismicSectionContainer section={document.sections[sectionIndex - 1]} />
            : <PrismicSubSectionContainer subSection={document.sections[0].subSections[sectionIndex -1]}/>
          }</>
        }
      </ThemeProvider>
    </>
  );
}

export interface DocumentRootProps {
  imageSource: string;
  content: RichTextField;
}

const DocumentRoot = ({imageSource, content}: DocumentRootProps) => {
  return (
    <HealthGuideContentSectionLeft>
      <ShareDocumentButtonContainer />
      <TitleImage src={imageSource} />
      <PrismicRichText field={content} components={healthGuideTextRenderer} />
    </HealthGuideContentSectionLeft>
  );
}

const TitleImage = styled('img')`
  max-height: 30vh;
  ${theme.breakpoints.down('sm')} {
    padding-top: 4rem;
  }
`;

const LeftDocumentNavBar = styled(DocumentNavBar)`
  justify-content: left;
  padding: 0;
  background-color: #ffffff;
  margin-top: 0;
`;

const ToggleButtonBarWrapper = styled(Grid)`
  display: flex;
  width: 100%;
`;