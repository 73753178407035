import React, { ReactElement } from 'react';

interface StaticViewportResolverProps {
  mobileView: ReactElement;
  desktopView: ReactElement;
}

export const StaticViewportResolver = ({mobileView, desktopView}: StaticViewportResolverProps) => {
  const width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
  const height = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
  const isMobile = width < 600 || height < 500;
  return (
    <>
      {isMobile ? mobileView : desktopView}
    </>
  );
}