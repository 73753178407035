import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { ProductPage, ProductPageDispatchProps, ProductPageProps } from '../components/product/ProductPage';
import { WebAppState } from '../redux/WebAppState';
import { SELECT_PRODUCT } from '../redux/actions';

const mapStateToProps: MapStateToProps<ProductPageProps, {}, WebAppState> = (state: WebAppState) => ({
  product: state.user.selectedProduct,
  isLoading: state.system.apiCalls.getProducts.isLoading,
  getStateProduct: (name:string) => getProductObject(name, state),
});

const mapDispatchToProps: MapDispatchToProps<ProductPageDispatchProps, {}> = (dispatch) => ({
  selectProduct: (name: string) => dispatch({type: SELECT_PRODUCT, name}),
});

const getProductObject = (name:string, state:WebAppState) => state.system.products.find(product => product.name?.toLowerCase() === name?.toLowerCase());

export default connect(mapStateToProps, mapDispatchToProps)(ProductPage);