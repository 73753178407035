import React from 'react';
import Typography from "@mui/material/Typography";
import { ContentSection } from "../../style/styles";
import { styled } from '@mui/material/styles';
import Grid from "@mui/material/Grid";
import { UserProfile } from "../../models/UserProfile";
import { Subscription } from "../../models/Subscription";
import { Loading } from "../shared/Loading";
import { ApiCallState } from "../../redux/WebAppState";
import { formatShortDate } from "../../utils/displayUtils";
import { UserInfo } from "./UserInfo";
import { OrderHistory } from "./OrderHistory";
import { SubscriptionDetail } from "./SubscriptionDetail";
import { AffiliateLinkDisplay } from "./AffiliateLinkDisplay";

export interface UserProfilePageStateProps {
  userProfile: UserProfile;
  userProfileApi: ApiCallState;
  photoIdUpdateApi: ApiCallState;
}

export interface UserProfilePageDispatchProps {
  updatePhotoId: (file:string) => void;
}

export const UserProfilePage = ({ userProfile, userProfileApi, updatePhotoId, photoIdUpdateApi }: UserProfilePageStateProps & UserProfilePageDispatchProps ) => {
  if (userProfileApi.isLoading) {
    return (
      <ContentSection>
        <Loading />
      </ContentSection>
    )
  }

  if (!userProfile.customer || !userProfile.customer.name || userProfile.customer.name.length === 0) {
    return (
      <GrayContentSection>
        <Typography variant={'h2'}>Please complete the health questionnaire to create a user profile.</Typography>
      </GrayContentSection>
    )
  }

  const currentSubscription = getMostRecentSubscription(userProfile.subscriptions);

  return (
    <GrayContentSection>
      <Typography variant={'h1'}>Welcome Back, {userProfile.customer.name}</Typography>
      <ProfileWrapper container justifyContent={'space-between'}>
        <UserInfo userProfile={userProfile}
                  startDate={!!currentSubscription && currentSubscription.status === 'active' ? formatShortDate(currentSubscription.startDate) : ''}
                  updatePhotoId={updatePhotoId} photoIdUpdateApi={photoIdUpdateApi}/>
        {!userProfile.subscriptions ? undefined :
          <OrderHistory orders={userProfile.subscriptions.flatMap((subscription) => subscription.orders || [])}/>}
      </ProfileWrapper>
      {userProfile.affiliateLink &&
        <AffiliateLinkDisplay affiliateLink={userProfile.affiliateLink} />
      }
      {!currentSubscription ? undefined :
        <SubscriptionDetail subscription={currentSubscription} />}
    </GrayContentSection>
  )
}

const getMostRecentSubscription = (subscriptions: Subscription[]): Subscription | undefined => {
  if (subscriptions.length === 0) { return; }
  let activeSub = subscriptions.find((subscription) => subscription.status === 'active');
  if (!activeSub) {
   activeSub = subscriptions.reduce((mostRecentSub, subscription) => {
      if (!mostRecentSub || Date.parse(mostRecentSub.endDate!) < Date.parse(subscription.endDate!)) {
        return subscription;
      }
      return mostRecentSub;
    })
  }
  return activeSub;
}

const GrayContentSection = styled(ContentSection)`
  align-items: start;
  background-color: #F5F5F5;
`;

const ProfileWrapper = styled(Grid)`
  margin-top: 5rem;
`;