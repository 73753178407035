import Grid from '@mui/material/Grid';
import { Button, ContentSection, ErrorText, Input, Label } from '../../style/styles';
import React, { FormEvent, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { useAuth } from '../shared/AuthContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { Loading } from "../shared/Loading";
import Typography from "@mui/material/Typography";
import Bugsnag from "@bugsnag/js";
import Link from "@mui/material/Link";

export interface ResetPasswordFormDispatchProps {
  loginUser: (login: Function, navigate: () => void) => void;
}

export interface ResetPasswordFormProps {
  isLoadingLogin?: boolean;
  isLoadingProfile?: boolean;
  error?: string;
}


export const ResetPasswordForm = ({ isLoadingLogin, isLoadingProfile, error, loginUser }: ResetPasswordFormProps & ResetPasswordFormDispatchProps) => {
  const [email, setEmail] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [formError, setFormError] = useState('');

  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const state = location.state as {
    from?: Location;
  }

  const trimVerificationCode = (code: string) => setVerificationCode(code.trim());
  const trimEmail = (email: string) => setEmail(email.trim());

  useEffect(() => {
    if (error) {
      Bugsnag.notify(error);
      setFormError(parseError(error));
    }
  }, [error]);

  const redirectPath = state?.from?.pathname || '/';

  const handleSubmit = () => (event: FormEvent) => {
    event.preventDefault();
    if (isLoadingLogin || isLoadingProfile) return;

    setFormError('');
    const validationResult = isFormValid(email, verificationCode, password, confirmPassword);

    if (!validationResult.isValid) {
      setFormError(validationResult.errors!.join('; '));
      return;
    }

    auth.completePasswordReset(email, verificationCode, password)
      .then(() => loginUser(() => auth.signIn(email, password), () => navigate(redirectPath, {replace: true})));
  }

  const isFormValid = (username: string, verificationCode: string, password: string, confirmPassword: string) => {
    const errors = [];

    if (!username) errors.push('E-mail Address cannot be empty');
    if (!verificationCode) errors.push('Verification Code cannot be empty');
    if (!password) errors.push('New Password is required');
    if (password !== confirmPassword) errors.push('New Password and Confirm Password must match');

    return {
      isValid: errors.length === 0,
      errors: errors
    };
  }

  const parseError = (error: string) => {
    if (error === 'Username cannot be empty') {
      return 'E-mail Address cannot be empty';
    }
    if (error === 'Confirmation code cannot be empty') {
      return 'Verification Code cannot be empty';
    }

    return error;
  }

  return (
    <form onSubmit={handleSubmit()}>
      <ContentSection>
        <Grid container item justifyContent={'center'} alignItems={'center'} direction={'column'} spacing={2}>
          <Typography variant={'h1'} gutterBottom sx={{ marginTop: '3rem' }}>Create your new password</Typography>
          <Grid item xs={11} sm={6} md={4} lg={3} container direction={'column'}>
            <Label>E-mail Address</Label>
            <Input data-testid='email-address' type={'text'} value={email} onChange={(e) => trimEmail(e.target.value)} />
          </Grid>
          <Grid item xs={11} sm={6} md={4} lg={3} container direction={'column'}>
            <Label>Verification Code</Label>
            <Input data-testid='verification-code' type={'text'} value={verificationCode} onChange={(e) => trimVerificationCode(e.target.value)} />
          </Grid>
          <Grid item xs={11} sm={6} md={4} lg={3} container direction={'column'}>
            <Label>New Password</Label>
            <Input data-testid='new-password' type={'password'} value={password} onChange={(e) => setPassword(e.target.value)} />
          </Grid>
          <Grid item xs={11} sm={6} md={4} lg={3} container direction={'column'}>
            <Label>Confirm Password</Label>
            <Input data-testid='confirm-password' type={'password'} value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
          </Grid>
          {formError && <ErrorText>{formError}</ErrorText>}
          <SubmitButton type={'submit'}>{isLoadingLogin || isLoadingProfile ? <Loading /> : 'Confirm'}</SubmitButton>
          <Grid item xs>
            <Typography variant={'h3'} align={'center'}>
              Didn't get a verification code? Check your spam folder. Still don't see it? Hit us up at <Link onClick={(e) => {window.location.href = 'mailto:support@goalsrx.com'; e.preventDefault();}}>support@goalsrx.com</Link>
            </Typography>
          </Grid>
        </Grid>
      </ContentSection>
    </form>
  );
};

const SubmitButton = styled(Button)`
  margin: 2rem 0;
`;
