import { PutObjectCommand, PutObjectCommandInput, S3Client } from '@aws-sdk/client-s3';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-provider-cognito-identity';
import { CognitoIdentityClient } from '@aws-sdk/client-cognito-identity';
import { v4 as createUuid } from 'uuid';

export interface StorageService {
  upload: (file: File) => Promise<string | undefined>;
}

export class AwsStorageService implements StorageService {
  readonly s3Client: S3Client;
  readonly s3Bucket = process.env.AWS_S3_BUCKET;

  constructor() {
    this.s3Client = new S3Client({
      region: 'us-east-1',
      credentials: fromCognitoIdentityPool({
        client: new CognitoIdentityClient({ region: 'us-east-1' }),
        identityPoolId: process.env.AWS_COGNITO_ID_POOL!,
      })
    })
  }

  upload(file: File): Promise<string | undefined> {
    const s3Key = createUuid();

    const putObjectCommandInput: PutObjectCommandInput = {
      Bucket: this.s3Bucket,
      Key: s3Key,
      Body: file,
    }

    return this.s3Client.send(new PutObjectCommand(putObjectCommandInput))
      .then(commandOutput => commandOutput.$metadata.httpStatusCode)
      .then(statusCode => statusCode === 200 ? s3Key : undefined);
  }
}