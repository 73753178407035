import React, { useEffect, useState } from "react";
import { HeroSection } from '../home/HeroSection';
import { ProductContentsResolver } from './ProductContentsResolver';
import { ProductHeroSection } from "./ProductHeroSection";
import { ProductDetail } from "./ProductDetail";
import { Product, ProductBase } from '../../models/Product';
import { Loading } from '../shared/Loading';
import { useParams } from 'react-router-dom';
import { TestimonialCarousel } from "../shared/TestimonialCarousel";
import { styled } from "@mui/material/styles";

export interface ProductPageProps {
  product?: Product;
  isLoading: boolean
}

export interface ProductPageDispatchProps {
  selectProduct: (name: string) => void;
}

export const ProductPage = ({product, isLoading, selectProduct}: ProductPageProps & ProductPageDispatchProps) => {
  const {productName} = useParams<{productName: string}>();

  useEffect(() => {
    selectProduct(productName!);
  }, [productName]);

  return (isLoading || !product ?
    <Loading /> :
    <NoOverflow>
      <HeroSection backgroundImage={product.displayInfo.image} mobileBackgroundImage={product.displayInfo.mobileImage}><ProductHeroSection product={product} /></HeroSection>
      <ProductDetail {...product.displayInfo.details} stateProductPrice={product.price?.toString()} />
      <ProductContentsResolver product={product}/>
      <TestimonialCarousel />
    </NoOverflow>
  );
}

const NoOverflow = styled('div')`
  overflow: hidden;
`;