import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { WebAppState } from '../../redux/WebAppState';
import { HEALTH_GUIDE_DOCUMENT_SET_SECTION_INDEX } from "../../redux/actions";
import { PrismicDocumentTemplate, PrismicDocumentTemplateDispatchProps, PrismicDocumentTemplateStateProps } from "../../components/shared/prismic/PrismicDocumentTemplate";

const mapStateToProps: MapStateToProps<PrismicDocumentTemplateStateProps, {}, WebAppState> = state => ({
  sectionIndex: state.healthGuide.navigation?.sectionIndex ?? 0
});

const mapDispatchToProps: MapDispatchToProps<PrismicDocumentTemplateDispatchProps, {}> = dispatch => ({
  setSectionIndex: (index) => dispatch({type: HEALTH_GUIDE_DOCUMENT_SET_SECTION_INDEX, data: index}),
});

export default connect(mapStateToProps, mapDispatchToProps)(PrismicDocumentTemplate);