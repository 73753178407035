import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery'
import { DesktopProductContents, ProductContentProps } from './DesktopProductContents';
import { MobileProductContents } from './MobileProductContents';
import { theme } from '../../style/theme';


export const ProductContentsResolver = ({ product }: ProductContentProps) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });
  return (
    <>
      {isMobile ? <MobileProductContents product={product} /> : <DesktopProductContents product={product} />}
    </>
  )
};