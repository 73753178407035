import React from 'react';
import { HealthGuideContentSubSection } from '../../../models/HealthGuideDocument';
import { PrismicContentBlock } from './PrismicContentBlock';
import { HealthGuideContentSection } from '../../../style/styles';
import { styled, ThemeProvider } from "@mui/material/styles";
import { PRODUCTS } from "../../../cms/ProductEntries";
import { WarmupSection } from "../../healthguide/workout/WarmupSection";
import { workoutDocumentTheme } from "../../../style/theme";
import { WorkoutSection } from "../../healthguide/workout/WorkoutSection";
import { StaticViewportResolver } from '../StaticViewportResolver';
import MobileSubSectionAccordion from '../../../components/healthguide/MobileSubSectionAccordion';
import { HealthGuideSubSectionHeader } from '../../healthguide/HealthGuideSubSectionHeader';

export interface PrismicSubSectionProps {
  subSection: HealthGuideContentSubSection;
}

export interface PrismicSubSectionStateProps {
  product: string;
}

export const PrismicSubSection = ({ product, subSection }: PrismicSubSectionProps & PrismicSubSectionStateProps) => {
  const backgroundImage = PRODUCTS[product].displayInfo.heroSectionContent.logo;

  return(
    <StaticViewportResolver
      mobileView={mobile(subSection, product, backgroundImage)}
      desktopView={desktop(subSection, product, backgroundImage)}
    />
  );
}

const desktop = (subSection: HealthGuideContentSubSection, product: string, backgroundImage: string) => {
  return <ThemeProvider theme={workoutDocumentTheme}>
  <MaxWidth>
    <HealthGuideSubSectionHeader backgroundImage={backgroundImage}>
      <HealthGuideContentSectionLightPadding>
        <WorkoutDescription>
          <PrismicContentBlock title={subSection.titleField} content={subSection.content} />
        </WorkoutDescription>
      </HealthGuideContentSectionLightPadding>
    </HealthGuideSubSectionHeader>
    { !!subSection.warmup && <WarmupSection warmupSlice={subSection.warmup} /> }
    { !!subSection.workouts && subSection.workouts.map((workout, index) => (
      <WorkoutSection key={`workoutSection${index}`} workoutSlice={workout} product={product} backgroundColor={index % 2 ? '#F2F2F2' : '#FFFFFF'}/>))}
  </MaxWidth>
</ThemeProvider>
}

const mobile = (subSection: HealthGuideContentSubSection, product: string, backgroundImage: string) => {
  return <ThemeProvider theme={workoutDocumentTheme}>
  <MaxWidth>
    <HealthGuideSubSectionHeader backgroundImage={backgroundImage}>
      <HealthGuideContentSectionLightPadding>
        <WorkoutDescription>
          <PrismicContentBlock title={subSection.titleField} content={subSection.content} />
        </WorkoutDescription>
      </HealthGuideContentSectionLightPadding>
    </HealthGuideSubSectionHeader>
    <MobileSubSectionAccordion subSection={subSection} product={product} />
  </MaxWidth>
</ThemeProvider>
}

const WorkoutDescription = styled('div')`
  color: white;
  text-align: center;
`;

const MaxWidth = styled(`div`)`
  width: 100%;
  margin-top: 1rem;
  position: relative;
  padding-top: 10.4rem;
`;

const HealthGuideContentSectionLightPadding = styled(HealthGuideContentSection)`
  padding: .5rem;
`;