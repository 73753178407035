import Grid from "@mui/material/Grid";
import React from "react";
import { Header, Input, Label } from "../CheckoutPage";
import { StateMenu } from "./StateMenu";

export interface AddressFormProps {
  formHeaderText?: string;
  firstName: string;
  setFirstName: (value:string) => void;
  lastName: string;
  setLastName: (value:string) => void;
  addressLineOne: string;
  setAddressLineOne: (value:string) => void;
  addressLineTwo: string;
  setAddressLineTwo: (value:string) => void;
  city: string;
  setCity: (value:string) => void;
  state: string;
  setState: (value:string) => void;
  zipcode: string;
  setZipcode: (value:string) => void;
}

export const AddressForm = ({
    formHeaderText,
    firstName, setFirstName,
    lastName, setLastName, 
    addressLineOne, setAddressLineOne,
    addressLineTwo, setAddressLineTwo,
    city, setCity,
    state, setState,
    zipcode, setZipcode}:AddressFormProps) => {
  return (
    <>
      {formHeaderText &&
        <Grid item container>
          <Header>{formHeaderText}</Header>
        </Grid>
      }
      <Grid item container spacing={2}>
        <Grid item container xs={12} sm direction={'column'}>
          <Label>First Name</Label>
          <Input type="text" name="firstName" value={firstName} onChange={(e: any) => setFirstName(e.target.value)}/>
        </Grid>
        <Grid item container xs={12} sm direction={'column'}>
          <Label>Last Name</Label>
          <Input type="text" name="lastName" value={lastName} onChange={(e: any) => setLastName(e.target.value)}/>
        </Grid>
      </Grid>
      <Grid item container spacing={2}>
        <Grid item container xs={12} sm direction={'column'}>
          <Label>Address Line 1</Label>
          <Input type="text" name="addressLineOne" value={addressLineOne} onChange={(e: any) => setAddressLineOne(e.target.value)}/>
        </Grid>
        <Grid item container xs={12} sm direction={'column'}>
          <Label>Address Line 2</Label>
          <Input type="text" name="addressLineTwo" value={addressLineTwo} onChange={(e: any) => setAddressLineTwo(e.target.value)}/>
        </Grid>
      </Grid>
      <Grid item container direction={'column'}>
        <Label>City</Label>
        <Input type="text" name="city" value={city} onChange={(e: any) => setCity(e.target.value)}/>
      </Grid>
      <Grid item container spacing={2}>
        <StateMenu addressValue={state} setAddressValue={setState} />
        <Grid item container xs direction={'column'}>
          <Label>Zip</Label>
          <Input type="text" name="zip" value={zipcode} onChange={(e: any) => setZipcode(e.target.value)}/>
        </Grid>
      </Grid>
    </>
  );
}