import Grid from "@mui/material/Grid";
import React from "react";
import { Input, Label } from "../CheckoutPage";

export interface ContactInfoFormProps {
  email: string;
  setEmail: (value:string) => void;
  phoneNumber: string;
  setPhoneNumber: (value:string) => void;
}

export const ContactInfoForm = ({ email, setEmail, phoneNumber, setPhoneNumber }:ContactInfoFormProps) => {
  return (
    <Grid item container spacing={2}>
      <Grid item container xs={12} sm>
        <Label>Email</Label>
        <Input type="text" name="email" value={email} onChange={(e: any) => setEmail(e.target.value)}/>
      </Grid>
      <Grid item container xs={12} sm>
        <Label>Phone Number</Label>
        <Input type="text" name="phoneNumber" value={phoneNumber} onChange={(e: any) => setPhoneNumber(e.target.value)}/>
      </Grid>
    </Grid>
  );
}
