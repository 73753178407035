import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { WebAppState } from 'src/redux/WebAppState';
import { USER_LOGIN } from '../../redux/actions';
import { ResetPasswordForm, ResetPasswordFormDispatchProps, ResetPasswordFormProps } from "../../components/account/ResetPasswordForm";

const mapDispatchToProps: MapDispatchToProps<ResetPasswordFormDispatchProps, {}> = dispatch => ({
  loginUser: (login: Function, navigate: () => void) => dispatch({type: USER_LOGIN, data: { login: login, navigate: navigate }})
})

const mapStateToProps: MapStateToProps<ResetPasswordFormProps, {}, WebAppState> = state => ({
  isLoadingLogin: state.system.apiCalls.userLogin.isLoading,
  isLoadingProfile: state.system.apiCalls.getUserProfile.isLoading,
  error: state.system.apiCalls.userLogin.apiError?.message
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordForm);