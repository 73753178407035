import React, { ReactElement, useState } from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import { Quote } from "./Quote";

export interface TimedCarouselItem {
  icon?: string;
  content: ReactElement;
}

export interface TimedCarouselProps {
  products: TimedCarouselItem[];
  timerDelay?: number;
}

export const TimedCarousel = ({products, timerDelay}: TimedCarouselProps) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const currentProduct = products[currentIndex];

  if (!!timerDelay) {
    React.useEffect(() => {
      const interval = setInterval(() => setCurrentIndex(current => (current + 1) % products.length ), timerDelay);
      return () => clearInterval(interval);
    }, []);
  }

  return (
    <Grid container justifyContent={'space-between'}>
      <Quote width={'3rem'} height={'3rem'} fill={'gray'} />
      <Grid item xs={12} sm={10}>{currentProduct.content}</Grid>
      <Grid item xs={12} sm={1} alignSelf={'flex-end'} container justifyContent={'flex-end'}>{!!currentProduct.icon && <SquareIcon src={currentProduct.icon} />}</Grid>
    </Grid>
  );
};

const SquareIcon = styled('img')`
  height: 2.5rem;
  width: 2.5rem;
`;