import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Loading } from "../shared/Loading";
import { ContentSection } from "../../style/styles";
import { HealthGuideDocument } from "src/models/HealthGuideDocument";
import { PrismicNotFound } from "../shared/prismic/PrismicNotFound";
import { ApiCallState } from "../../redux/WebAppState";
import styled from "styled-components";
import PrismicDocumentTemplateContainer from "../../containers/healthguide/PrismicDocumentTemplateContainer";

export interface HealthGuideContentProps {
  documentApi: ApiCallState;
  document?: HealthGuideDocument;
}

export interface HealthGuideContentDispatchProps {
  loadDocument: (docId: string) => void;
}

export const HealthGuideContent = ({documentApi, document, loadDocument}: HealthGuideContentProps & HealthGuideContentDispatchProps) => {
  const { docId } = useParams<{ docId: string }>();

  useEffect(() => {
    docId && loadDocument(docId);
  }, []);

  return(
    <HealthGuideContentWrapper>
      {documentApi.isLoading ? (
          <ContentSection><Loading /></ContentSection>
        ) : !!document ? (
          <PrismicDocumentTemplateContainer document={document}/>
        )
        : (
          <PrismicNotFound message={'Document not found'}/>
        )
      }
    </HealthGuideContentWrapper>);
}

const HealthGuideContentWrapper = styled('div')`
  position: relative;
`;