import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import {
  CALL_STARTING,
  LOAD_HEALTH_GUIDE,
  HEALTH_GUIDE_FAILED,
  HEALTH_GUIDE_RECEIVED,
  LOAD_HEALTH_GUIDE_DOCUMENT,
  HEALTH_GUIDE_DOCUMENT_FAILED,
  HEALTH_GUIDE_DOCUMENT_RECEIVED,
  SET_PROGRAM_FILTER_SUCCESS,
  SET_PROGRAM_FILTER,
  CLEAR_PROGRAM_FILTER,
  RESTORE_PROGRAM_FILTER
} from '../actions';
import { CmsService } from "../../service/CmsService";
import { WebAppState } from "../WebAppState";
import { AnyAction } from "redux";
import { GET_HEALTH_GUIDE, GET_HEALTH_GUIDE_DOCUMENT, SEARCH_HEALTH_GUIDE } from '../apiCalls';

export function* healthGuideSaga() {
  yield all([
    loadHealthGuideWatcher(),
    searchHealthGuideWatcher(),
    loadHealthGuideDocumentWatcher(),
    clearProgramFilterWatcher(),
    restoreProgramFilterWatcher()
  ]);
}

function* loadHealthGuideWatcher() {
  yield takeEvery(LOAD_HEALTH_GUIDE, loadHealthGuide);
}

function* searchHealthGuideWatcher() {
  yield takeEvery(SEARCH_HEALTH_GUIDE, searchHealthGuide);
}

function* loadHealthGuideDocumentWatcher() {
  yield takeEvery(LOAD_HEALTH_GUIDE_DOCUMENT, loadHealthGuideDocument);
}

function* clearProgramFilterWatcher() {
  yield takeEvery(CLEAR_PROGRAM_FILTER, clearProgramFilter);
}

function* restoreProgramFilterWatcher() {
  yield takeEvery(RESTORE_PROGRAM_FILTER, restoreProgramFilter);
}

export function* loadHealthGuide(): Generator<any, any, any> {
  yield put({type: CALL_STARTING, data: GET_HEALTH_GUIDE});

  const cmsService = (yield select((state: WebAppState) => state.system.cmsService)) as CmsService;
  const subscriptionName = (yield select((state: WebAppState) => state.user.subscription?.name)) as string;

  try {
    const response = yield call(() => cmsService.getProgramHealthGuideDocumentList());
    yield call(() => updateProgramFilter(subscriptionName));
    yield put({type: HEALTH_GUIDE_RECEIVED, data: response});
  } catch (err) {
    yield put({type: HEALTH_GUIDE_FAILED, data: err});
  }
}

export function* searchHealthGuide(searchHealthGuideAction: AnyAction): Generator<any, any, any> {
  yield put({type: CALL_STARTING, data: SEARCH_HEALTH_GUIDE});
  const cmsService = (yield select((state: WebAppState) => state.system.cmsService)) as CmsService;
  try {
    const response = yield call(() => cmsService.searchHealthGuideDocuments(searchHealthGuideAction.data));
    yield put({type: HEALTH_GUIDE_RECEIVED, data: response});
  } catch (err) {
    yield put({type: HEALTH_GUIDE_FAILED, data: err});
  }
}

export function* loadHealthGuideDocument(loadDocumentAction: AnyAction): Generator<any, any, any> {
  yield put({type: CALL_STARTING, data: GET_HEALTH_GUIDE_DOCUMENT});
  const cmsService = (yield select((state: WebAppState) => state.system.cmsService)) as CmsService;
  try {
    const response = yield call(() => cmsService.getHealthGuideDocument(loadDocumentAction.data));
    yield put({type: HEALTH_GUIDE_DOCUMENT_RECEIVED, data: response});
  } catch (err) {
    yield put({type: HEALTH_GUIDE_DOCUMENT_FAILED, data: err});
  }
}

export function* clearProgramFilter(): Generator<any, any, any> {
  yield call(() => updateProgramFilter('all'));
}

export function* restoreProgramFilter(): Generator<any, any, any> {
  const subscriptionName = (yield select((state: WebAppState) => state.user.subscription?.name)) as string;
  yield call(() => updateProgramFilter(subscriptionName));
}

function* updateProgramFilter(newFilter: string) {
  yield put({ type: SET_PROGRAM_FILTER, data: newFilter });
  yield put({ type: SET_PROGRAM_FILTER_SUCCESS });
}
