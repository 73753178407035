import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { WebAppState } from 'src/redux/WebAppState';
import { SignInForm, SignInFormDispatchProps, SignInFormProps } from '../../components/account/SignInForm';
import { SET_TEMP_PASSWORD, SET_USER_NAME, USER_LOGIN, RESET_LOGIN_ERROR } from '../../redux/actions';

const mapDispatchToProps: MapDispatchToProps<SignInFormDispatchProps, {}> = dispatch => ({
  loginUser: (login: Function, navigate: () => void) => dispatch({type: USER_LOGIN, data: { login: login, navigate: navigate }}),
  setTempUsernameAndPassword: (username: string, password: string) => {
    dispatch({type: SET_USER_NAME, data: username});
    dispatch({type: SET_TEMP_PASSWORD, data: password});
    dispatch({type: RESET_LOGIN_ERROR});
  }
})

const mapStateToProps: MapStateToProps<SignInFormProps, SignInFormProps, WebAppState> = state => ({
  isLoadingLogin: state.system.apiCalls.userLogin.isLoading,
  isLoadingProfile: state.system.apiCalls.getUserProfile.isLoading,
  error: state.system.apiCalls.userLogin.apiError
});

export default connect(mapStateToProps, mapDispatchToProps)(SignInForm);