import React, { MouseEvent, useState } from 'react';
import { DocumentNavBar, Input } from '../../style/styles';
import ToggleButtonBarContainer from '../../containers/shared/ToggleButtonBarContainer';
import { styled, ThemeProvider } from "@mui/material/styles";
import { theme } from "../../style/theme";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

export interface HealthGuideNavigationBannerProps {
  contentTypes: string[];
  filter: string;
}

export interface HealthGuideNavigationBannerDispatchProps {
  applyFilter: (filter: string) => void;
  searchHealthGuideDocs: (searchTerm: string) => void;
  clearProgram: () => void;
  restoreProgram: () => void;
}

export const HealthGuideNavigationBanner = ({ contentTypes, filter, clearProgram, restoreProgram, applyFilter, searchHealthGuideDocs }: HealthGuideNavigationBannerProps & HealthGuideNavigationBannerDispatchProps) => {
  const [selectedDocumentTypeIndex, setSelectedDocumentTypeIndex] = useState(contentTypes.indexOf(filter));
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = () => {
    searchHealthGuideDocs(searchTerm);
  }

  const handleChange = (event: any, newSelectedDocumentTypeIndex: number) => {
    setSelectedDocumentTypeIndex(newSelectedDocumentTypeIndex);
    applyFilter(contentTypes[newSelectedDocumentTypeIndex]);
  }

  return (
    <DocumentNavBar alignItems={'center'}>
      <Grid sx={{width: 'auto'}} item container direction={'column'} alignContent={'center'}>
        <ToggleButtonBarContainer
          options={contentTypes}
          selectedIndex={selectedDocumentTypeIndex}
          selectHandler={handleChange}
          variant={'content'}
          />
        <Grid item container justifyContent={'space-between'}>
          <ProgramViewToggle clearProgram={clearProgram} restoreProgram={restoreProgram} />
          <Grid xs={12} sm={7} sx={{p: '0 2rem 2rem 2rem'}} item container wrap={'nowrap'} justifyContent={'flex-end'}>
            <Search data-testid='search-term' type={'text'} value={searchTerm} placeholder={'Enter search term'} onChange={(e) => setSearchTerm(e.target.value)} />
            <Button onClick={handleSearch}>Search</Button>
          </Grid>
        </Grid>
      </Grid>
    </DocumentNavBar>
  );
}

interface ToggleProps {
  clearProgram: () => void;
  restoreProgram: () => void;
}

const ProgramViewToggle = ({ clearProgram, restoreProgram }: ToggleProps) => {
  const [documentOption, setDocumentOption] = useState('For You');
  const handleChange = (event: MouseEvent<HTMLElement>) => {
    const target = event.target as HTMLInputElement;

    setDocumentOption(target.value);

    if (target.value === 'All') {
      clearProgram();
    } else {
      restoreProgram();
    }
  }

  return (<ProgramToggleSection>
      <ThemeProvider theme={theme}>
        <ToggleButtonGroupShadow
          color={'primary'}
          value={documentOption}
          onChange={handleChange}
          exclusive
        >
          <ToggleButtonOverride value={'All'}>All</ToggleButtonOverride>
          <ToggleButtonOverride value={'For You'}>For You</ToggleButtonOverride>
        </ToggleButtonGroupShadow>
      </ThemeProvider>
    </ProgramToggleSection>
  )
};

const ProgramToggleSection = styled(Grid)`
  position: relative;
  z-index: 2;
  align-items: flex-start;
  background: white;
  margin: 0;
  padding: 0 2rem 2rem 2rem;
  min-width: 240px;
  max-width: 240px;
`;

const ToggleButtonGroupShadow = styled(ToggleButtonGroup)`
  box-shadow: 1px 0px 2px 1px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
`;

const ToggleButtonOverride = styled(ToggleButton)`
  border-radius: 5px;
  font-size: 1.3rem;
  font-weight: 500;
  width: 10rem;
`;

const Search = styled(Input)`
  width: 100%;
  margin-right: 0.5rem;
`;