export const GET_PRODUCTS = 'getProducts';
export const GET_PRODUCTS_SUCCESS = 'getProductsSuccess';
export const GET_PRODUCTS_FAILED = 'getProductsFailed'
export const SELECT_PRODUCT = 'selectProduct';
export const SET_SELECTED_PRODUCT = 'setSelectedProduct';
export const SET_NONINJECTABLE_SELECTION = 'setNonInjectableSelection';
export const SET_TEMP_PASSWORD = 'setTemporaryPassword';
export const CLEAR_TEMP_PASSWORD = 'clearTemporaryPassword';
export const CREATE_USER = 'createUser';
export const CREATE_USER_FAILED = 'createUserFailed';
export const CREATE_USER_SUCCESS = 'createUserSuccess';
export const UPDATE_QUESTIONNAIRE = 'updateQuestionnaire';
export const UPDATE_GOAL_RESPONSES = 'updateGoalResponses';
export const SUBMIT_QUESTIONNAIRE_SUCCESS = 'submitQuestionnaireSuccess';
export const SUBMIT_QUESTIONNAIRE_FAILED = 'submitQuestionnaireFailed';
export const CLEANUP_QUESTIONNAIRE = 'cleanupQuestionnaire';
export const PURCHASE_PRODUCT = 'purchaseProduct'; // to trigger Shopify handoff
export const PURCHASE_PRODUCT_FAILED = 'purcaseProductFailed';
export const CALL_STARTING = 'callStarting';
export const HEALTH_PROFILE_REVIEW_SUCCESS = 'healthProfileReviewSuccess';
export const HEALTH_PROFILE_REVIEW_FAILED = 'healthProfileReviewFailed';
export const SEND_RESCHEDULE_EMAIL_SUCCESS = 'sendRescheduleEmailSuccess';
export const SEND_RESCHEDULE_EMAIL_FAILED = 'sendRescheduleEmailFailed';
export const USER_LOGIN = 'loginUser';
export const USER_LOGIN_SUCCESS = 'loginUserSuccess';
export const USER_LOGIN_FAILED = 'loginUserFailed';
export const USER_LOGOUT = 'logoutUser';
export const GET_USER_PROFILE = 'getUserProfile';
export const USER_PROFILE_SUCCESS = 'userProfileSuccess';
export const USER_PROFILE_FAILED = 'userProfileFailed';
export const USER_PROFILE_SKIPPED = 'userProfileSkipped';
export const UPDATE_PHOTO_ID = 'updatePhotoId';
export const UPDATE_PHOTO_ID_SUCCESS = 'updatePhotoIdSuccess';
export const UPDATE_PHOTO_ID_FAIL = 'updatePhotoIdFailed';
export const SET_USER = 'setUser';
export const SET_USER_NAME = 'setUserName';
export const SET_SUBSCRIPTION = 'setSubscription';
export const SET_CMS_CLIENT = 'setCmsClient';
export const SET_CMS_SERVICE = 'setCmsService';
export const SET_HTTP_CLIENT = 'setHttpClient';
export const SET_STORAGE_SERVICE = 'setStorageService';
export const SET_SHOPIFY_CLIENT = 'setShopifyClient';
export const CHECKOUT_SUCCESS = 'checkoutSuccess';
export const CHECKOUT_FAILED = 'checkoutFailed';
export const SET_ERROR_HANDLER = 'setErrorHandler';
export const REPORT_ERROR = 'reportError';

export const GET_PROMO_VALUE = 'getPromoValue';
export const GET_PROMO_VALUE_SUCCESS = 'getPromoValueSuccess';
export const GET_PROMO_VALUE_FAILED = 'getPromoValueFailed';

export const UPDATE_PURCHASE_PRICE = 'updatePurchasePrice';
export const SET_PURCHASE_PRICE = 'setPurchasePrice';

export const SUBMIT_QUESTIONNAIRE = 'submitQuestionnaire';
export const SUBMIT_CHECKOUT = 'submitCheckout';
export const SUBSCRIBE_NEWSLETTER = 'subscribeNewsletter';
export const SUBSCRIBE_NEWSLETTER_SUCCESS = 'subscribeNewsletterSuccess';
export const SUBSCRIBE_NEWSLETTER_FAILED = 'subscribeNewsletterFailed';
export const GET_ACTIVE_QUESTIONNAIRES = 'getActiveQuestionnaires';
export const GET_ACTIVE_QUESTIONNAIRES_SUCCESS = 'getActiveQuestionnairesSuccess';
export const GET_ACTIVE_QUESTIONNAIRES_FAILED = 'getActiveQuestionnairesFailed';
export const GET_HEALTH_PROFILE = 'getHealthProfile';
export const GET_HEALTH_PROFILE_SUCCESS = 'getHealthProfileSuccess';
export const GET_HEALTH_PROFILE_FAILED = 'getHealthProfileFailed';
export const HEALTH_PROFILE_REVIEW = 'healthProfileReview';
export const SEND_RESCHEDULE_EMAIL = 'sendRescheduleEmail';
export const LOAD_HEALTH_GUIDE = 'loadHealthGuide';
export const HEALTH_GUIDE_RECEIVED = 'healthGuideReceived';
export const HEALTH_GUIDE_FAILED = 'healthGuideFailed';
export const LOAD_HEALTH_GUIDE_DOCUMENT = 'loadHealthGuideDocument';
export const HEALTH_GUIDE_DOCUMENT_RECEIVED = 'healthGuideDocumentReceived';
export const HEALTH_GUIDE_DOCUMENT_FAILED = 'healthGuideDocumentFailed';
export const APPLY_HEALTH_GUIDE_DOCUMENT_FILTER = 'applyHealthGuideDocumentFilter';
export const HEALTH_GUIDE_DOCUMENT_SET_SECTION_INDEX = 'healthGuideDocumentSetSectionIndex';
export const HEALTH_GUIDE_DOCUMENT_SET_SUB_SECTION_INDEX = 'healthGuideDocumentSetSubSectionIndex';
export const CANCEL_SUBSCRIPTION = 'cancelSubscription';
export const CANCEL_SUBSCRIPTION_SUCCESS = 'cancelSubscriptionSuccess';
export const CANCEL_SUBSCRIPTION_FAILED = 'cancelSubscriptionFailed';
export const RESET_LOGIN_ERROR = 'resetLoginError';

export const CLEAR_PROGRAM_FILTER = 'clearProgramFilter';
export const RESTORE_PROGRAM_FILTER = 'restoreProgramFilter';
export const SET_PROGRAM_FILTER = 'setProgramFilter';
export const SET_PROGRAM_FILTER_SUCCESS = 'setProgramFilterSuccess';
