import { applyMiddleware, createStore } from 'redux';
import { rootReducer } from './reducers/rootReducer';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import { rootSaga } from "./sagas/rootSaga";
import { ErrorHandler } from "../service/ErrorHandler";

export const initializeStore = (errorHandler: ErrorHandler) => {
  const sagaMiddleware = createSagaMiddleware();

  const composedEnhancer = composeWithDevTools(applyMiddleware(sagaMiddleware));

  const store = createStore(rootReducer, composedEnhancer);

  sagaMiddleware.run(rootSaga, errorHandler);

  return store;
}