import { all, call, put, select, take, takeEvery } from "redux-saga/effects";
import {
  CALL_STARTING,
  GET_PRODUCTS,
  GET_PRODUCTS_FAILED,
  GET_PRODUCTS_SUCCESS,
  PURCHASE_PRODUCT,
  PURCHASE_PRODUCT_FAILED,
  SELECT_PRODUCT,
  SET_SELECTED_PRODUCT
} from "../actions";
import { AnyAction } from 'redux';
import { WebAppState } from "../WebAppState";
import { Product, ProductBase } from "../../models/Product";
import { PRODUCTS } from "../../cms/ProductEntries";
import { CheckoutError } from '../../models/CheckoutError';

export function* productSaga() {
  yield all([
    getProductsWatcher(),
    purchaseProductWatcher(),
    selectProductWatcher()
  ]);
}

function* getProductsWatcher() {
  yield takeEvery(GET_PRODUCTS, getProducts);
}

function* purchaseProductWatcher() {
  yield takeEvery(PURCHASE_PRODUCT, purchaseProduct);
}

function* selectProductWatcher() {
  yield takeEvery(SELECT_PRODUCT, selectProduct)
}

export function* selectProduct(selectProductAction: AnyAction): Generator<any, any, any> {
  const cmsProductName = selectProductAction.name as string;
  const cmsProduct = PRODUCTS[cmsProductName.toLowerCase()];

  let waitForShopify = (yield select((state: WebAppState) => state.system.apiCalls.getProducts.isLoading));
  if (waitForShopify) {
    yield take([GET_PRODUCTS_SUCCESS, GET_PRODUCTS_FAILED]);
  }
  const shopifyProduct = (yield select((state: WebAppState) => state.system.products.find(p => cmsProduct.name?.includes(p.name!)))) as Product;

  const selectedProduct = {
    ...cmsProduct,
    ...shopifyProduct
  }

  if (!!shopifyProduct) {
    selectedProduct.variants = shopifyProduct.variants;
  }

  yield put({ type: SET_SELECTED_PRODUCT, data: selectedProduct })
}

export function* purchaseProduct(purchaseProductAction: AnyAction): Generator<any, any, any> {
  try {
    yield put({type: CALL_STARTING, data: purchaseProductAction.type});

    const selectedProduct = (yield select((state: WebAppState) => state.user.selectedProduct)) as Product;
    const email = yield select((state: WebAppState) => state.user.user?.username);
    const birthdate = yield select((state: WebAppState) => state.user.birthdate);
    const referralCode = localStorage.getItem('referralCode') || 'none';

    if (selectedProduct.variants && selectedProduct.variants.length > 1) {
      const nonInjectable = (yield select((state: WebAppState) => state.user.nonInjectableSelected)) as boolean;
      const variant = getVariant(nonInjectable, selectedProduct);

      yield call(() => addToCart(email, birthdate, referralCode, variant));
    } else {
      yield call(() => addToCart(email, birthdate, referralCode, selectedProduct.variants![0]));
    }
  } catch (err) {
    // @ts-ignore
    const error = new CheckoutError(err.message);
    yield put({type: PURCHASE_PRODUCT_FAILED, data: error})
  }
}

export function getVariant(isNonInjectable: boolean, product: Product) {
  let variant: ProductBase;

  if (isNonInjectable) {
    variant = product.variants!.find(variant => variant.name?.toLowerCase().includes('non-injectable')) as ProductBase;
  } else {
    variant = product.variants!.find(variant => !variant.name?.toLowerCase().includes('non-injectable')) as ProductBase;
  }

  return variant;
}

function* addToCart(emailAddress: string, birthdate: string, referral: string, product?: ProductBase) {
  if (!!product && !!product.id) {
    const id = product.id;
    //double encoding because Shopify double decodes and while this isn't normally a problem, they also eat + signs when they do. Not @ though.
    const encodedEmail = encodeURIComponent(encodeURIComponent(emailAddress));
    const encodedDOB = encodeURIComponent(birthdate);
    const encodedReferralCode = encodeURIComponent(referral);

    /*
      Story time:
        We need some way of matching an order from the CreateOrder webhook to a customer in GoalsRx. Ideally we'd use
        the customer's email address since it is unique, but within Shopify's checkout experience, the user is free to
        enter any email address that they'd like. Shopify supports custom attributes during checkout, which come back to
        us on the CreateOrder webhook. We are leveraging this functionality to map orders to customers in the webhook.

        - Because we are using subscription-only products, we are unable to use Shopify's GraphQL API to generate
          carts/checkouts.
        - The shopify-buy library is based on the GraphQL API, thus we are unable to use it here.
        - Using Shopify's checkout URL seems to ignore custom attributes.
        - The SEAL quick checkout link API doesn't allow us to use the custom attributes we want to use, at least not in
          a terribly clear way.

        After much conversation with SEAL, the following link was created.
        - This link uses Shopify's Cart API and takes a variant id, a selling plan id, the user's GoalsRx username
          (email address), and date of birth.
        - This directs the user to Shopify's checkout page with the correct product in the cart, and the appropriate
          custom attributes for matching the webhook.

        The one major caveat here is that we are unable to default the user's GoalsRx email into the checkout email.

        When this inevitably breaks again, don't even bother with this approach. Before we redirect to step four from
        Shopify, we are able to expose pretty much everything that would be in the webhook to the redirect link. We
        should pass something back in the redirect, and have that page call a lambda to store the necessary information
        to map the CreateOrder webhook to the user.
     */
    window.location.assign(`https://${process.env.SHOPIFY_DOMAIN}/cart/clear?return_to=/cart/add?items[][id]=${id}%26items[][quantity]=1%26items[][selling_plan]=${process.env.SELLING_PLAN_ID}%26attributes[GoalsRxUserEmail]=${encodedEmail}%26[attributes][CustomerBirthdate]=${encodedDOB}%26[attributes][ReferralCode]=${encodedReferralCode}%26return_to=/checkout`);
  } else {
    throw new Error('No product or product id');
  }
}

export function* getProducts(getProductAction: AnyAction): Generator<any, any, any> {
  yield put({type: CALL_STARTING, data: getProductAction.type});

  const shopifyClient = (yield select((state: WebAppState) => state.system.shopifyClient)) as ShopifyBuy.Client;

  try {
    const response = yield call(() => shopifyClient.product.fetchAll());
    const products = convertProducts(response);

    yield put({ type: GET_PRODUCTS_SUCCESS, data: products });
  } catch (err) {
    yield put({type: GET_PRODUCTS_FAILED, data: err});
  }
}

const convertProducts = (products: ShopifyBuy.Product[]) => {
  const returnArray = [] as Product[];

  for (const product of products) {
    const combinedProduct = {} as Product;
    combinedProduct.name = product.title;

    const cmsProduct = PRODUCTS[combinedProduct.name.toLowerCase()];
    combinedProduct.displayInfo = cmsProduct.displayInfo;

    const variants = [] as ProductBase[];
    product.variants.forEach(variant => {
      const idBase = variant.id as string;
      const id = idBase.replace('gid://shopify/ProductVariant/', '');
      const name = variant.title ?? '';
      const price = Number(variant.price);
      if (name) {
        variants.push({
          id: id,
          name: name,
          price: price
        });
      } else {
        variants.push({
          id: id,
          name: '',
          price: price
        });
      }
    });
    combinedProduct.variants = variants;
    // get the minimum "starting at..." price for display
    combinedProduct.price = Math.min(...variants.filter(item => !!item.price).map(item => item.price!))
    returnArray.push(combinedProduct);
  }
  return returnArray;
}