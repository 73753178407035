import React, { PropsWithChildren } from 'react';
import { useAuth } from './AuthContext';
import { Navigate, useLocation } from 'react-router-dom';
import { Loading } from './Loading';

export const RequireAuth = ({children}: PropsWithChildren<any>) => {
  const auth = useAuth();
  const location = useLocation();

  if(!auth.loaded) {
    return (<Loading />);
  } else if (!auth.user) {
    return (<Navigate to={'/login'} state={{from: location}} replace />);
  } else {
    return children;
  }
};