import React from 'react';
import { HealthGuideContentSection, HealthGuideContentSubSection } from '../../../models/HealthGuideDocument';
import ToggleButtonBarContainer from '../../../containers/shared/ToggleButtonBarContainer';
import { PrismicContentBlock } from './PrismicContentBlock';
import { StaticDocumentNavBar, HealthGuideContentSectionLeft } from '../../../style/styles';
import PrismicSubSectionItemContainer from './PrismicSubsectionContainer';
import styled from 'styled-components';
import { getTextFromRTF } from "../../../utils/PrismicUtils";

const hasContent = (section: HealthGuideContentSection) => {
  return getTextFromRTF(section.content).length > 0;
}

export interface PrismicSectionProps {
  section: HealthGuideContentSection;
}

export interface PrismicSectionStateProps {
  subsectionIndex: number;
}

export interface PrismicSectionDispatchProps {
  setSelectedIndex: (index: number) => void;
}

export const PrismicSection = ({ section, subsectionIndex, setSelectedIndex }: PrismicSectionProps & PrismicSectionStateProps & PrismicSectionDispatchProps) => {
  const handleSelect = (event: any, newSubSectionIndex: number | null) => {
    if (newSubSectionIndex !== null) {
      setSelectedIndex(newSubSectionIndex);
      window.scrollTo(0, 0);
    }
  }

  const sectionHasContent = hasContent(section);

  return(
    <>
      <RenderNavBar subSections={section.subSections} handleSelect={handleSelect} selectedIndex={subsectionIndex} showOverview={sectionHasContent}/>
      <RenderContent selectedIndex={subsectionIndex} section={section} hasContent={sectionHasContent}/>
    </>
  );
};

interface RenderNavBarProps {
  subSections: HealthGuideContentSubSection[];
  handleSelect: (event: any, newSectionIndex: number | null) => void;
  selectedIndex: number;
  showOverview: boolean;
}

const RenderNavBar = ({ subSections, handleSelect, selectedIndex, showOverview }: RenderNavBarProps) => {
  const options = showOverview
    ? ['overview', ...subSections.map(sub => `Day ${sub.dayNumber}`)]
    : [...subSections.map(sub => `Day ${sub.dayNumber}`)];

  if (subSections.length > 0) {
    return (
      <DocumentSectionNavBar>
        <ToggleButtonBarContainer
          selectHandler={handleSelect}
          options={options}
          selectedIndex={selectedIndex}
          variant={'subsection'}
        />
      </DocumentSectionNavBar>
    );
  } else {
    return null;
  }
}

interface RenderContentProps {
  selectedIndex: number;
  section: HealthGuideContentSection;
  hasContent: boolean;
}

const RenderContent = ({ selectedIndex, section, hasContent }:RenderContentProps) => {
  if (hasContent && selectedIndex == 0) {
    return (
      <DocumentSectionLeftContent>
        <PrismicContentBlock title={section.titleField} content={section.content}/>
      </DocumentSectionLeftContent>
    );
  } else if (section.subSections.length > 0) {
    const offset = hasContent ? 1 : 0;
    return <PrismicSubSectionItemContainer subSection={section.subSections[selectedIndex - offset]} />;
  } else {
    return null;
  }
}

const DocumentSectionNavBar = styled(StaticDocumentNavBar)`
  height: 4rem;
  padding-bottom: 2.4rem;
  align-items: center;
  background-color: #ffffff;
`;

const DocumentSectionLeftContent = styled(HealthGuideContentSectionLeft)`
  position: relative;
  padding-top: 10.4rem;
`;