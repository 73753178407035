import React from 'react';
import { burnColor, thriveAlternateColor } from "../../style/styles";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

export const ProductNames = () => (
  <Grid container direction={'row'} sx={{marginBottom: '2rem'}}>
    <ProductName $color={'black'}>Build</ProductName>
    <ProductName $color={burnColor}>Burn</ProductName>
    <ProductName $color={thriveAlternateColor}>Thrive</ProductName>
  </Grid>
);

interface ProductNameProps {
  $color: string;
}

const ProductName = styled(Typography)<ProductNameProps>`
  text-transform: uppercase;
  color: ${props => props.$color};
  margin-right: 2rem;
`;