import React from 'react';
import { ContentSection, } from "../../style/styles";
import { HealthGuidePromo } from "./HealthGuidePromo";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { PrescriptionPromo } from "./PrescriptionPromo";
import AddIcon from '@mui/icons-material/Add';
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { theme } from "../../style/theme";
import { Founders } from "./Founders";

export const AboutPage = () => (
  <>
    <ContentSection>
      <Grid item xs={12} sm={9} lg={6} xl={5} container direction={'column'} alignItems={'center'}>
        <Typography variant={'h1'} gutterBottom align={'center'}>Everything you need to crush your health goals.</Typography>
        <Typography align={'center'}>Engineered by doctors, health experts & tech nerds, GoalsRx leverages a science-backed mix of cutting-edge medications, supplements, nutrition & movement to get you lasting, life-changing results.</Typography>
      </Grid>
    </ContentSection>
    <HealthGuidePromo />
    <Box sx={{position: 'relative'}}>
      <PlusIcon />
    </Box>
    <PrescriptionPromo />
    <Founders />
  </>
);

const PlusIcon = styled(AddIcon)`
  position: absolute;
  width: 15rem;
  height: 15rem;
  color: #626262;
  top: -7.5rem;
  left: calc(50% - 7.5rem);
  
  ${theme.breakpoints.down('sm')} {
    width: 10rem;
    height: 10rem;
    top: -5rem;
    left: calc(50% - 5rem);
  }
`;