import React, { FormEvent, useEffect, useState } from 'react';
import { BoldText, Button, ContentSection, Label } from "../../style/styles";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { HealthProfile } from "../../models/HealthProfile";
import { useAuth } from "../shared/AuthContext";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import { Loading } from '../shared/Loading';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { ApiCallState } from "../../redux/WebAppState";
import { ActiveQuestionnaire } from "../../models/ActiveQuestionnaire";

export interface PhysicianPageProps {
  activeQuestionnaires: ActiveQuestionnaire[];
  healthProfile?: HealthProfile;
  getActiveQuestionnairesApi: ApiCallState;
  getHealthProfileApi: ApiCallState;
  reviewApi: ApiCallState;
  rescheduleApi: ApiCallState;
  semaglutide: boolean;
}

export interface PhysicianPageDispatchProps {
  getActiveQuestionnaires: () => void;
  getHealthProfile: (questionnaireId: number) => void;
  submitHealthProfileReview: (questionnaireId: number, physicianName: string, notes: string, approved: boolean) => void;
  sendRescheduleEmail: (questionnaireId: number) => void;
}

export const PhysicianPage = ({activeQuestionnaires, healthProfile, getActiveQuestionnairesApi, getHealthProfileApi, reviewApi, rescheduleApi, semaglutide, getActiveQuestionnaires, getHealthProfile, submitHealthProfileReview, sendRescheduleEmail}: PhysicianPageProps & PhysicianPageDispatchProps) => {
  const [notes, setNotes] = useState('');
  const [approved, setApproved] = useState('no');
  const [successOpen, setSuccessOpen] = React.useState(false);
  const [failureOpen, setFailureOpen] = React.useState(false);
  const [rescheduledOpen, setRescheduledOpen] = React.useState(false);
  const [apiError, setApiError] = React.useState('');

  const [wasLoading, setWasLoading] = React.useState(false);
  const [rescheduled, setRescheduled] = React.useState(false);

  const auth = useAuth();

  useEffect(() => {
    getActiveQuestionnaires();
  }, [])

  useEffect(() => {
    if (!reviewApi.isLoading && wasLoading) {
      window.scrollTo(0, 0);
      if (!!reviewApi.apiError) {
        setApiError(reviewApi.apiError.message);
        setFailureOpen(true);
      } else {
        setSuccessOpen(true);
      }
    }
    setWasLoading(reviewApi.isLoading);
  }, [reviewApi.isLoading]);

  useEffect(() => {
    if (!rescheduleApi.isLoading && rescheduled) {
      window.scrollTo(0, 0);
      if (!!rescheduleApi.apiError) {
        setApiError(rescheduleApi.apiError.message);
        setFailureOpen(true);
      } else {
        setRescheduledOpen(true);
      }
    }
    setRescheduled(rescheduleApi.isLoading);
  }, [rescheduleApi.isLoading]);

  useEffect(() => {
    if (!!getHealthProfileApi.apiError) {
      setApiError(getHealthProfileApi.apiError.message);
      setFailureOpen(true);
    }
  }, [getHealthProfileApi.isLoading]);

  useEffect(() => {
    if (!!getActiveQuestionnairesApi.apiError) {
      setApiError(getActiveQuestionnairesApi.apiError.message);
      setFailureOpen(true);
    }
  }, [getActiveQuestionnairesApi.isLoading]);

  const handleReview = (e: FormEvent) => {
    e.preventDefault();
    if ( reviewApi.isLoading ) return;
    if (!!auth.user && !!healthProfile && !!healthProfile.questionnaire) {
      submitHealthProfileReview(healthProfile.questionnaire.id, auth.user.username, notes, approved === 'yes');
      return;
    }
  }

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setSuccessOpen(false);
    setFailureOpen(false);
    setRescheduledOpen(false);
  };

  return (
    <>
      { getActiveQuestionnairesApi.isLoading || getHealthProfileApi.isLoading || reviewApi.isLoading || !activeQuestionnaires ?
        <ContentSection>
          <Loading />
        </ContentSection>
        :
        <ContentSection>
          <Snackbar open={successOpen} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="success" sx={{width: '100%'}}>
              Health profile review saved!
            </Alert>
          </Snackbar>
          <Snackbar open={failureOpen} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="error" sx={{width: '100%'}}>
              {apiError}
            </Alert>
          </Snackbar>
          <Snackbar open={rescheduledOpen} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="success" sx={{width: '100%'}}>
              Reschedule e-mail sent!
            </Alert>
          </Snackbar>
          {!healthProfile &&
            <>
              <Typography variant={'h1'}>Customers to Review</Typography>
              <TableWrapper item xs={12}>
                <TableContainer sx={{width: '75vw'}} component={Paper}>
                  <Table size="medium">
                    <TableHead>
                      <TableRow>
                        <StyledTableHeaderCell>Name</StyledTableHeaderCell>
                        <StyledTableHeaderCell>Date of Birth</StyledTableHeaderCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {!!activeQuestionnaires
                        ? activeQuestionnaires.map((row) => (
                          <ClickableTableRow hover key={row.questionnaireId} sx={{'&:last-child td, &:last-child th': {border: 0}}} onClick={() => getHealthProfile(row.questionnaireId)}>
                            <StyledTableCell>{row.name}</StyledTableCell>
                            <StyledTableCell>{row.dob}</StyledTableCell>
                          </ClickableTableRow>
                        ))
                        : undefined
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              </TableWrapper>
            </>
          }
          {!!healthProfile &&
            <>
              <Grid container padding={2}>
                <TableWrapper item xs={7}>
                  <TableContainer component={Paper}>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <StyledTableHeaderCell>Submission Date</StyledTableHeaderCell>
                          <StyledTableHeaderCell>Result</StyledTableHeaderCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {healthProfile.submissionLog.map((row) => (
                          <TableRow key={row.submittedDate} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <StyledTableCell>{row.submittedDate}</StyledTableCell>
                            <StyledTableCell>{row.result}</StyledTableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </TableWrapper>
                <Grid item xs={5}>
                  {healthProfile.photoId && <PhotoId src={healthProfile.photoId}/>}
                </Grid>
                <Grid item container xs={12}>
                  <Typography sx={{m: '0 0 2rem 0'}} variant={'h2'}>Program: {healthProfile.questionnaire.shopifyProductName}</Typography>
                </Grid>
                <Grid item xs={12} lg={5} padding={1}>
                  <TextField InputProps={{readOnly: true}} type={"text"} label={"Full Name"} fullWidth
                             value={healthProfile.questionnaire.fullName}/>
                </Grid>
                <Grid item xs={12} lg={2} padding={1}>
                  <TextField InputProps={{readOnly: true}} type={"text"} label={"Biological Sex"} fullWidth
                             value={healthProfile.questionnaire.gender}/>
                </Grid>
                <Grid item xs={12} lg={5} padding={1}>
                  <TextField InputProps={{readOnly: true}} type={"date"} label={"Date of Birth"} fullWidth
                             value={healthProfile.questionnaire.birthDate}/>
                </Grid>
                <Grid item xs={12} md={7} xl={6} padding={1} container direction={"column"}>
                  <Typography>Height</Typography>
                  <Grid item container>
                    <PaddedTextField InputProps={{readOnly: true}} type={"number"} label={"ft"}
                                     value={healthProfile.questionnaire.heightFeet}/>
                    <PaddedTextField InputProps={{readOnly: true}} type={"number"} label={"in"} sx={{marginLeft: "1rem"}}
                                     value={healthProfile.questionnaire.heightInches}/>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={5} xl={6} padding={1} container direction={"column"}>
                  <Typography>Weight</Typography>
                  <PaddedTextField InputProps={{readOnly: true}} type={"number"} label={"lbs"} sx={{maxWidth: "250px"}}
                                   value={healthProfile.questionnaire.weight}/>
                </Grid>
              </Grid>
              <Grid container padding={2} item xs={12}>
                <Grid item md={4} xs={6} container alignItems={"center"} padding={1}>
                  <TextField InputProps={{readOnly: true}} fullWidth label={"Are you pregnant or breast feeding?"}
                             value={healthProfile.questionnaire.pregnant}/>
                </Grid>
                <Grid container direction={"column"}>
                  <Grid item>
                    <BoldText gutterBottom>Have you ever had any of the following medical conditions?</BoldText>
                  </Grid>
                  <Grid item container direction={"row"} padding={1} spacing={2}>
                    <Grid item md={4} xs={6}><TextField InputProps={{readOnly: true}} fullWidth label={"Type 1 Diabetes"}
                                                        value={healthProfile.questionnaire.t1Diabetes}/></Grid>
                    <Grid item md={4} xs={6}><TextField InputProps={{readOnly: true}} fullWidth label={"Type 2 Diabetes"}
                                                        value={healthProfile.questionnaire.t2Diabetes}/></Grid>
                    <Grid item md={4} xs={6}><TextField InputProps={{readOnly: true}} fullWidth
                                                        label={"Are you insulin dependent?"}
                                                        value={healthProfile.questionnaire.insulinDependent}/></Grid>
                    <Grid item md={4} xs={6}><TextField InputProps={{readOnly: true}} fullWidth label={"Cancer (current)"}
                                                        value={healthProfile.questionnaire.cancerCurrent}/></Grid>
                    <Grid item md={4} xs={6}><TextField InputProps={{readOnly: true}} fullWidth
                                                        label={"Cancer (in remission for less than 7 years)"}
                                                        value={healthProfile.questionnaire.cancerLessThan7Years}/></Grid>
                    <Grid item md={4} xs={6}><TextField InputProps={{readOnly: true}} fullWidth
                                                        label={"Cancer (in remission for more than 7 years)"}
                                                        value={healthProfile.questionnaire.cancerMoreThan7Years}/></Grid>
                    <Grid item md={4} xs={6}><TextField InputProps={{readOnly: true}} fullWidth label={"Cardiovascular Disease"}
                                                        value={healthProfile.questionnaire.cardio}/></Grid>
                    <Grid item md={4} xs={6}><TextField InputProps={{readOnly: true}} fullWidth label={"Stroke"}
                                                        value={healthProfile.questionnaire.stroke}/></Grid>
                    <Grid item md={4} xs={6}><TextField InputProps={{readOnly: true}} fullWidth label={"Liver Problems"}
                                                        value={healthProfile.questionnaire.liver}/></Grid>
                    <Grid item md={4} xs={6}><TextField InputProps={{readOnly: true}} fullWidth label={"Gallbladder Problems"}
                                                        value={healthProfile.questionnaire.gallbladder}/></Grid>
                    <Grid item md={4} xs={6}><TextField InputProps={{readOnly: true}} fullWidth label={"Kidney Problems"}
                                                        value={healthProfile.questionnaire.kidney}/></Grid>
                    <Grid item md={4} xs={6}><TextField InputProps={{readOnly: true}} fullWidth label={"Thyroid Problems"}
                                                        value={healthProfile.questionnaire.thyroid}/></Grid>
                    { semaglutide &&
                      <>
                        <Grid item md={4} xs={6}><TextField InputProps={{readOnly: true}} fullWidth
                                                            label={"Medullary Thyroid Cancer"}
                                                            value={healthProfile.questionnaire.medullaryThyroidCancer}/></Grid>
                        <Grid item md={4} xs={6}><TextField InputProps={{readOnly: true}} fullWidth
                                                            label={"Multiple Endocrine Neoplasia"}
                                                            value={healthProfile.questionnaire.multipleEndocrineNeoplasia}/></Grid>
                      </>
                    }
                  </Grid>
                </Grid>
                { semaglutide &&
                  <Grid container direction={"column"}>
                    <Grid item>
                      <BoldText gutterBottom>Has anyone in your family ever had any of the following medical conditions?</BoldText>
                    </Grid>
                    <Grid item container direction={"row"} padding={1} spacing={2}>
                      <Grid item md={4} xs={6}><TextField InputProps={{readOnly: true}} fullWidth
                                                          label={"Medullary Thyroid Cancer"}
                                                          value={healthProfile.questionnaire.familyMedullaryThyroidCancer}/></Grid>
                      <Grid item md={4} xs={6}><TextField InputProps={{readOnly: true}} fullWidth
                                                          label={"Multiple Endocrine Neoplasia"}
                                                          value={healthProfile.questionnaire.familyMultipleEndocrineNeoplasia}/></Grid>
                    </Grid>
                  </Grid>
                }
                <Grid container direction={"column"}>
                  <Grid item>
                    <BoldText gutterBottom>Have you been diagnosed with any of the following mental health conditions?</BoldText>
                  </Grid>
                  <Grid item container direction={"row"} padding={1} spacing={2}>
                    <Grid item md={4} xs={6}><TextField InputProps={{readOnly: true}} fullWidth label={"Depression"}
                                                        value={healthProfile.questionnaire.depression}/></Grid>
                    <Grid item md={4} xs={6}><TextField InputProps={{readOnly: true}} fullWidth label={"Anxiety"}
                                                        value={healthProfile.questionnaire.anxiety}/></Grid>
                    <Grid item md={4} xs={6}><TextField InputProps={{readOnly: true}} fullWidth label={"Bipolar Disorder"}
                                                        value={healthProfile.questionnaire.bipolar}/></Grid>
                    <Grid item md={4} xs={6}><TextField InputProps={{readOnly: true}} fullWidth label={"Schizophrenia"}
                                                        value={healthProfile.questionnaire.schizophrenia}/></Grid>
                    <Grid item md={4} xs={6}><TextField InputProps={{readOnly: true}} fullWidth
                                                        label={"Borderline Personality Disorder"}
                                                        value={healthProfile.questionnaire.borderline}/></Grid>
                  </Grid>
                </Grid>
                { semaglutide &&
                  <Grid container direction={"column"} padding={1}>
                    <Grid item>
                      <BoldText gutterBottom>Are you currently using any other GLP-1 Peptides?</BoldText>
                    </Grid>
                    <Grid item>
                      <Grid item md={4} xs={6}><TextField InputProps={{readOnly: true}} fullWidth label={"GLP-1 Peptides"}
                                                          value={healthProfile.questionnaire.glp1Peptides}/></Grid>
                    </Grid>
                  </Grid>
                }
                <Grid container direction={"column"} padding={1}>
                  <Grid item>
                    <BoldText gutterBottom>Are you currently taking any prescription or over-the-counter medications?</BoldText>
                  </Grid>
                  <Grid item>
                    <Grid item md={4} xs={6}><TextField InputProps={{readOnly: true}} fullWidth label={"Medications"}
                                                        value={healthProfile.questionnaire.medications}/></Grid>
                    <Grid item xs={12}><PaddedTextField InputProps={{readOnly: true}} fullWidth multiline
                                                        label={"Please list medications"}
                                                        value={healthProfile.questionnaire.medicationDetail}/></Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Notes container padding={3}>
                <Form onSubmit={handleReview}>
                  <Grid container justifyContent={"space-between"} alignItems={"bottom"} spacing={2}>
                    <Grid item xs={12}><TextField label={"Notes"} fullWidth value={notes} multiline={true}
                                                  onChange={(e) => setNotes(e.target.value)}/></Grid>
                    <Grid item xs={4}>
                      <Label>Approve</Label>
                      <RadioGroup row value={approved} onChange={(e) => setApproved(e.target.value)}>
                        <FormControlLabel label={"Yes"} control={<Radio/>} value={"yes"}/>
                        <FormControlLabel label={"No"} control={<Radio/>} value={"no"}/>
                      </RadioGroup>
                    </Grid>
                    <Grid item xs={2}><Button sx={{pt: reviewApi.isLoading ? 0 : '1.5rem', pb: reviewApi.isLoading ? 0 : '1.5rem'}} type={"submit"}>{reviewApi.isLoading ? <Loading/> : "Submit"}</Button></Grid>
                    <Grid item xs={2}><LightButton sx={{pt: reviewApi.isLoading ? 0 : '1.5rem', pb: reviewApi.isLoading ? 0 : '1.5rem'}} $light type={"reset"} onClick={getActiveQuestionnaires}>{reviewApi.isLoading ? <Loading/> : "Cancel"}</LightButton></Grid>
                    <Grid item xs={2}><RedButton sx={{pt: rescheduleApi.isLoading ? 0 : '1.5rem', pb: rescheduleApi.isLoading ? 0 : '1.5rem'}} $light type={"reset"} onClick={() => sendRescheduleEmail(healthProfile.questionnaire.id)}>{rescheduleApi.isLoading ? <Loading/> : "Reschedule"}</RedButton></Grid>
                  </Grid>
                </Form>
              </Notes>
            </>
          }
        </ContentSection>
      }
    </>
  );
};

const Form = styled('form')`
  width: 100%;
`;

const Notes = styled(Grid)`
  border-top: 1px;
  border-color: #CCCCCC;
  border-top-style: solid;
`;

const PaddedTextField = styled(TextField)`
  margin-top: 1rem;
`;

const TableWrapper = styled(Grid)`
  padding: .8rem;
  margin: 2rem 0;
`;

const StyledTableHeaderCell = styled(TableCell)`
  font-size: 1.6rem;
  font-weight: 500;
`;

const StyledTableCell = styled(TableCell)`
  font-size: 1.6rem;
  font-weight: 400;
`;

const PhotoId = styled('img')`
  height: auto;
  width: 100%;
`;

const ClickableTableRow = styled(TableRow)`
  cursor: pointer;
`;

const LightButton = styled(Button)`
  border: 2px solid black;
  height: 5.4rem;
`;

const RedButton = styled(Button)`
  border: 2px solid red;
  color: red;
  height: 5.4rem;
`;
