import React, { useEffect, useState } from 'react';
import { Product } from '../../models/Product';
import { ContentSection, SmallIcon, SupplementalFacts } from '../../style/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { ItemDetail } from '../../models/ItemDetail';
import Fade from "@mui/material/Fade";
import { styled } from '@mui/material/styles';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { DesktopDisclaimerText } from './ImageDisclaimer';

export interface ProductContentProps {
  product: Product;
}

export const DesktopProductContents = ({ product }: ProductContentProps) => {
  const [ingredientIndex, setIngredientIndex] = useState(0);

  useEffect(() => {
    setIngredientIndex(0);
  }, [product]);

  return (
    <ProductContentsContentSection>
      <Grid container padding={1}>
        <SmallIcon src={product.displayInfo.productContents.icon} />
      </Grid>
      <Grid container>
        <Grid item sm container direction={'column'} padding={1}>
          <SectionTitle variant={'h1'}>What's Inside</SectionTitle>
          <ContentList detailItems={product.displayInfo.productContents.details} setIngredientIndex={setIngredientIndex} />
        </Grid>
        <ItemDetailDisplay detail={product.displayInfo.productContents.details[ingredientIndex]} />
      </Grid>
    </ProductContentsContentSection>
  );
};

interface ContentListProps {
  detailItems: ItemDetail[];
  setIngredientIndex: (index: number) => void;
}

const ContentList = ({ detailItems, setIngredientIndex }: ContentListProps) => (
  <ul>
    {detailItems.map((item, index) => (
      <li key={`${item.linkText}-${index}`}>
        <ItemDetailLink onClick={() => setIngredientIndex(index)}>
          <ArrowForwardIosIcon fontSize={'small'} sx={{ paddingTop: '1rem'}} />
          {detailItems[index].linkText}
        </ItemDetailLink>
      </li>
    ))}
  </ul>
);

export interface ItemDetailDisplayProps {
  detail: ItemDetail;
}

const ItemDetailDisplay = ({ detail }: ItemDetailDisplayProps) => {
  const [factsShown, setFactsShown] = useState(false);

  useEffect(() => setFactsShown(false), [detail.linkText]);

  return (
    <>
      <Grid item sm container justifyContent={'center'} alignItems={'center'} padding={1}>
        <Grid container justifyContent={'center'} sx={{ position: 'relative' }} onClick={() => setFactsShown(!!detail.supplementFactImage && !factsShown)}>
          <Fade in={!factsShown}>
            <ItemDetailImage src={detail.image} />
          </Fade>
          <Fade in={factsShown}>
            <SupplementalFacts src={detail.supplementFactImage} />
          </Fade>
        </Grid>
        <DesktopDisclaimerText />
        {!!detail.supplementFactImage && <Typography>Click the image to view {factsShown ? 'Supplement' : 'Supplement Facts'}.</Typography>}
      </Grid>
      <Grid item sm container direction={'column'} padding={1} justifyContent={'flex-start'}>
        <DescriptionSection>
          <Typography variant={'h1'}>{detail.title}</Typography>
        </DescriptionSection>
        <DescriptionSection>
          <Typography variant={'h2'}>{detail.subtitle}</Typography>
        </DescriptionSection>
        <DescriptionSection>
          {detail.shortDescription}
        </DescriptionSection>
        <DescriptionSection>
          {detail.fullDescription}
        </DescriptionSection>
      </Grid>
    </>
  );
}

const ItemDetailLink = styled(Typography)`
  margin: 1.5rem 0;
  font-weight: 300;
  cursor: pointer;
`;

const SectionTitle = styled(Typography)`
  margin: 2rem 0 1.5rem 0;
`;

const DescriptionSection = styled(Grid)`
  margin-bottom: 2.5rem;
`;

const ItemDetailImage = styled('img')`
  max-width: 100%;
  height: auto;
  background: linear-gradient(transparent, transparent 83%, #F2F2F2 83%), radial-gradient(circle at bottom, rgba(0, 0, 0, 0.35), transparent 35%);
`;

export const ProductContentsContentSection = styled(ContentSection)`
  background: #F2F2F2;
`;