import React from "react";
import { HeroSection } from './HeroSection';
import { ProductSelection } from './ProductSelection';
import homeBackground from '../../assets/home/home-header.jpg';
import mobileHomeBackground from '../../assets/home/home-header-mobile.jpg';
import { TestimonialCarousel } from "../shared/TestimonialCarousel";
import { HomePageHeroSection } from "./HomePageHeroSection";
import { HomePageRevamp } from "./HomePageRevamp";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import { theme } from "../../style/theme";

export const HomePage = () => (
  <>
    <HeroSection backgroundImage={homeBackground} mobileBackgroundImage={mobileHomeBackground}>
      <HomePageHeroSection/>
    </HeroSection>
    <Padding>
      <HomePageRevamp/>
      <ProductSelection/>
    </Padding>
    <TestimonialCarousel/>
  </>
);


const Padding = styled(Grid)`
  margin-top: 0;
  padding-top: 12rem;
  ${theme.breakpoints.down('sm')} {
    padding-top: 6rem;
  }
`;