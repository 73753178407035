import Grid from '@mui/material/Grid';
import { Button, ErrorText, Input, Label } from '../../style/styles';
import React, { FormEvent, useState } from 'react';
import { styled } from '@mui/material/styles';
import { useAuth } from '../shared/AuthContext';
import { useNavigate } from 'react-router-dom';
import Typography from "@mui/material/Typography";

export const ForgotPasswordForm = () => {
  const [username, setUsername] = useState('');
  const [error, setError] = useState('');

  const auth = useAuth();
  const navigate = useNavigate();

  const handleSubmit = () => (event: FormEvent) => {
    event.preventDefault();
    setError('');

    if (!username) {
      setError('E-mail Address cannot be empty');
      return;
    }

    auth.initiatePasswordReset(username)
      .then(() => navigate('/resetPassword', {replace: true}));
  };

  return (
    <form onSubmit={handleSubmit()}>
      <Grid container direction={'column'} alignItems={'center'}>
        <Typography variant={'h1'} gutterBottom sx={{ marginTop: '3rem' }}>Forgot your password?</Typography>
        <Typography variant={'h2'} gutterBottom align={'center'}>Enter your e-mail & we'll send you a verification code.</Typography>
        <Grid item xs={11} sm={6} md={4} lg={3} container direction={'column'} padding={1}>
          <Label>E-mail Address</Label>
          <Input type={'text'} value={username} onChange={(e) => setUsername(e.target.value)} />
        </Grid>
        {error && <ErrorText>{error}</ErrorText>}
        <SubmitButton type={'submit'}>Submit</SubmitButton>
      </Grid>
    </form>
  );
};

const SubmitButton = styled(Button)`
  margin: 2rem 0 0;
`;
