import React from 'react';
import { ContentSection } from '../../style/styles';
import { styled } from '@mui/material/styles';
import { CAROUSEL_TESTIMONIES } from '../../cms/CarouselEntries';
import { TimedCarousel } from "./TimedCarousel";
import Typography from "@mui/material/Typography";

export const TestimonialCarousel = () => (
  <CarouselContent>
    <Typography variant={'h1'}>Reviews from real goal-getters.</Typography>
    <CarouselDiv>
      <TimedCarousel products={CAROUSEL_TESTIMONIES} timerDelay={15000}/>
    </CarouselDiv>
  </CarouselContent>
);

const CarouselContent = styled(ContentSection)`
  margin-top: 0;
  align-items: flex-start;
  background: #000000;
  color: #FFFFFF;
`;

const CarouselDiv = styled('div')`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

