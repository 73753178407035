import { styled } from "@mui/material/styles";
import { theme } from "../../style/theme";
import Grid from "@mui/material/Grid";
import { ThreeIcons } from "../shared/ThreeIcons";
import Typography from "@mui/material/Typography";
import clipboard from "../../assets/clipboard.png";
import calendar from "../../assets/calendar.png";
import closedBox from "../../assets/closed-box.png";
import React from "react";

export function FourSimpleSteps() {
  return <Grid item xs={12} lg={8} container rowSpacing={2}>
    <Grid item xs={12} container padding={1.5} alignItems={"center"}>
      <Grid item xs={3}>
        <Circle><WhyIsThisATSX><ThreeIcons/></WhyIsThisATSX></Circle>
      </Grid>
      <Grid item container xs={9} direction={"column"}>
        <Typography variant={"h3"}>1. Choose your goal</Typography>
        <Typography variant={"body1"}>We currently offer three plans: Burn, Build, and Thrive.</Typography>
      </Grid>
    </Grid>
    <Grid item xs={12} container padding={1.5} alignItems={"center"}>
      <Grid item xs={3}>
        <Circle><CenteredImage src={clipboard} width={60}/></Circle>
      </Grid>
      <Grid item container xs={9} direction={"column"}>
        <Typography variant={"h3"}>2. Tell us a bit about your health history</Typography>
        <Typography variant={"body1"}>Create an account and fill out the medical form. It should only take you five
          minutes.</Typography>
      </Grid>
    </Grid>
    <Grid item xs={12} container padding={1.5} alignItems={"center"}>
      <Grid item xs={3}>
        <Circle><CenteredImage src={calendar} width={70}/></Circle>
      </Grid>
      <Grid item container xs={9} direction={"column"}>
        <Typography variant={"h3"}>3. Speak with a GoalsRx telehealth physician online</Typography>
        <Typography variant={"body1"}>Once your order has been processed, you will be able to schedule a telehealth
          appointment at your own convenience.</Typography>
      </Grid>
    </Grid>
    <Grid item xs={12} container padding={1.5} alignItems={"center"}>
      <Grid item xs={3}>
        <Circle><CenteredImage src={closedBox} width={90}/></Circle>
      </Grid>
      <Grid item container xs={9} direction={"column"}>
        <Typography variant={"h3"}>4. Get your custom Rx kit delivered to your door</Typography>
        <Typography variant={"body1"}>Digital wellness plan and custom Rx kit delivered to your door.
          Doctor-prescribed medications and supplements - fulfilled by a trusted U.S.-based pharmacy.</Typography>
      </Grid>
    </Grid>
  </Grid>;
}

const Circle = styled('div')`
  position: relative;
  border-radius: 50%;
  height: 12rem;
  width: 12rem;
  z-index: -1;

  ${theme.breakpoints.down('sm')} {
    margin-left: -3rem;
  }
`;
const WhyIsThisATSX = styled('div')`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
const CenteredImage = styled('img')`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;