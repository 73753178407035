import Bugsnag, { Client, NotifiableError } from '@bugsnag/js';
import React, { ComponentType, PropsWithChildren } from 'react';
import BugsnagPluginReact from '@bugsnag/plugin-react';

export interface ErrorHandler {
  getErrorBoundary(): ComponentType;
  reportError(error: Error | string): void;
}

export class BugsnagErrorHandler implements ErrorHandler {
  private client: Client;

  constructor() {
    this.client = Bugsnag.start({
      apiKey: 'c802c64d0931ae6dc68c936b80e482c3',
      plugins: [new BugsnagPluginReact()],
      releaseStage: process.env.NODE_ENV,
    });
  }

  getErrorBoundary(): ComponentType {
    try {
      const boundary = this.client.getPlugin('react')?.createErrorBoundary(React);
      return boundary!;
    } catch (error) {
      this.client.notify(error as NotifiableError);
      return () => (<></>);
    }
  }

  reportError(error: Error | string): void {
    this.client.notify(error);
  }
}

export class DummyErrorHandler implements ErrorHandler {
  getErrorBoundary(): React.ComponentType {
    return ({children}: PropsWithChildren<any>) => (<>{children}</>);
  }

  reportError(error: Error | string) {
    console.log(error);
  }
}