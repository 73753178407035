import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import React from "react";
import { BoxRowCenteredItem } from "../../../../style/styles";
import allProductsIcon from "../../../../assets/three-products-icon.png";
import { PRODUCTS } from "../../../../cms/ProductEntries";

export interface ProgramBlockProps {
  program?: string;
}

export const getProgramIcon = (program?: string) => {
  return (!!program) ? PRODUCTS[program.toLocaleLowerCase()]?.displayInfo.productContents.icon ?? allProductsIcon : allProductsIcon;
};

export const ProgramBlock = ({ program }: ProgramBlockProps) => {

  if (!!program) {
    const icon = getProgramIcon(program);

    return  <SubHeaderRow>
              <SubHeaderIcon src={icon} />
              <ProgramText>{program} program</ProgramText>
            </SubHeaderRow>
  } else {
    return null;
  }
}

const SubHeaderIcon = styled('img')`
  height: 2rem;
  width: 2rem;
`

const SubHeaderRow = styled(BoxRowCenteredItem)`
  height: 5px;
  margin-top: .5rem;
`

const CopyText = styled(Typography)`
  font-size: 1.5rem;
  color: white;
`;

const ProgramText = styled(CopyText)`
  padding-left: .5rem;
`;
