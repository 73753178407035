import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { WebAppState } from '../../redux/WebAppState';
import { CartCheckoutSummary, CartCheckoutSummaryDispatchProps, CartCheckoutSummaryStateProps } from '../../components/checkout/checkoutForm/CartCheckoutSummary';
import { UPDATE_PURCHASE_PRICE } from '../../redux/actions';

const mapStateToProps: MapStateToProps<CartCheckoutSummaryStateProps, {}, WebAppState> = (state: WebAppState) => ({
  promo: state.user.promo,
  promoLoading: state.system.apiCalls.getPromoValue.isLoading
});

const mapDispatchToProps: MapDispatchToProps<CartCheckoutSummaryDispatchProps, {}> = dispatch => ({
  updatePurchasePrice: ((price:number) => dispatch({ type: UPDATE_PURCHASE_PRICE, data: price}))
});

export default connect(mapStateToProps, mapDispatchToProps)(CartCheckoutSummary);