import { WebAppState } from '../WebAppState';
import { combineReducers, Reducer } from 'redux';
import { physicianReducer } from "./physicianReducer";
import { systemReducer } from "./systemReducer";
import { userReducer } from "./userReducer";
import { healthGuideReducer } from "./healthGuideReducer";

export const rootReducer: Reducer<WebAppState> = combineReducers({
  physician: physicianReducer,
  user: userReducer,
  healthGuide: healthGuideReducer,
  system: systemReducer,
});
