import Grid from "@mui/material/Grid";
import doubleQuote from "../../../assets/double-quote.png";
import Typography from "@mui/material/Typography";
import { PrismicRichText } from "@prismicio/react";
import { healthGuideTextRenderer } from "../../../utils/displayUtils";
import React from "react";
import { styled } from "@mui/material/styles";
import { RichTextField } from "@prismicio/types";
import Box from "@mui/material/Box";

export const CoachingTip = (props: { color: string, field: RichTextField }) => {
  return (
    <TipGrid item container xs={5} direction={"column"}>
      <ImgWrapper src={doubleQuote}/>
      <Bump item container direction={"column"}>
        <Typography sx={{fontSize: "2.1rem", fontWeight: 500, color: props.color}}>COACHING TIP</Typography>
        <Box sx={{ marginRight: "3.25rem"}}>
          <PrismicRichText field={props.field} components={healthGuideTextRenderer}/>
        </Box>
      </Bump>
    </TipGrid>
  );
}

const TipGrid = styled(Grid)`
  margin-top: 4rem;
`;

const ImgWrapper = styled('img')`
  position: absolute;
  width: 4.08rem;
  height: 3.28rem;
`;

const Bump = styled(Grid)`
  margin-left: 3rem;
`;