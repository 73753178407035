import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

export interface LoaderProps {
  button?: boolean;
}

export const Loading = ({button} :LoaderProps) => {
  if (button) {
    return <CircularProgress size={16}/>
  } else {
    return <CircularProgress />
  }
};