import React, { MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../style/styles';

export const LoginButton = () => {
  const navigate = useNavigate();

  const handleClick = (e: MouseEvent) => {
    e.preventDefault();
    navigate('/login', {replace: true});
  }

  return <Button onClick={(e) => handleClick(e)}>Login</Button>;
};