import { styled } from '@mui/material/styles';
import { theme } from './theme';
import React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CopyAllIcon from "@mui/icons-material/CopyAll";
import TaskAltIcon from "@mui/icons-material/TaskAlt";

export const burnColor = 'rgb(232, 154, 74)';
export const thriveAlternateColor = '#D9AC27';
export const grayBackground = '#f2f2f2';

export interface ButtonProps {
  $light?: boolean;
}

export const RelativeGrid = styled(Grid)`
  position: relative;
`;

export const SiteHeader = styled(AppBar)`
  height: 8rem;
  background-color: #000000;
  position: fixed;
  justify-content: center;
  top: 0;
  left: 0;
  z-index: 10;
`;

export const Button = styled('button')<ButtonProps>`
  background: ${props => props.$light ? '#FFFFFF': '#000000'};
  border: none;
  text-transform: uppercase;
  color: ${props => props.$light ? '#000000': '#FFFFFF'};
  padding: 1.5rem 2.5rem;
  font-weight: 500;
  font-size: 16px;
  font-family: Poppins, sans-serif;
  cursor: pointer;
  
  ${theme.breakpoints.down('sm')} {
    padding: 1rem 2.5rem;
  }
`;

export const ActionButton = styled(Button)`
  font-weight: 500;
  border: none;
  margin-bottom: 8rem;
  white-space: nowrap;

  font-size: 2.4rem;
  ${theme.breakpoints.down('lg')} {
    font-size: 1.07rem;
  }
  ${theme.breakpoints.down('md')} {
    font-size: 0.8rem;
  }
  ${theme.breakpoints.down('sm')} {
    font-size: 1.6rem;
    margin-bottom: 0;
  }
`;

interface SectionProps {
  $dark?: boolean
}

export const ContentSection = styled('section')<SectionProps>`
  margin-top: 5rem;
  padding: 6.4rem 15rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
  ${props => props.$dark ? `background-color: ${grayBackground};` : ''}
  
  ${theme.breakpoints.down('lg')} {
    padding: 4rem;
  }
  ${theme.breakpoints.down('sm')} {
    padding: 2rem;
  }
`;

export const HealthGuideContentSection = styled(ContentSection)<SectionProps>`
  margin-top: 0;
  padding-top: 0;
`;

export const HealthGuideContentSectionLeft = styled(HealthGuideContentSection)`
  align-items: start;
  padding-top: 6.4rem;
`;

export const SmallIcon = styled('img')`
  max-width: 4rem;
  max-height: 4rem;
`;

export const Label = styled(Typography)`
  font-size: 1.4rem;
  font-weight: 500;
  color: #999999;
  text-transform: uppercase;
`;

export const Input = styled('input')`
  font-size: 1.8rem;
  line-height: 2.7rem;
  background: #F7F7F7;
  border-radius: .3rem;
  border: none;
  padding: 1.2rem;
`;

export const ErrorText = styled(Typography)`
  color: red;
`;

export const ErrorMessage = styled(Typography)`
  color: red;
  margin: 2rem auto 0;
`;

export const BoldText = styled(Typography)`
  font-weight: 600;
`;

export const TitleGrid = styled(Grid)`
  padding-bottom: 1rem;
  border-bottom: 1px solid gray;
  margin-bottom: 1rem;
`;

export const SupplementalFacts = styled('img')`
  position: absolute;
  top: 0;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  z-index: 2;
`;

interface ShapeProps {
  $backgroundColor?: string;
}

export const Circle = styled('div')<ShapeProps>`
  border-radius: 50%;
  background: ${props => props.$backgroundColor || '#E9E9E9'};
  margin-bottom: 3.2rem;
  width: 16vw;
  height: 16vw;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DocumentNavBar = styled(Grid)`
  position: fixed;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-content: center;
  width: 100%;
  margin-top: 16rem;
  ${theme.breakpoints.down('lg')} {
    margin-top: 12rem;
  }
  ${theme.breakpoints.down('sm')} {
    margin-top: 8rem;
  }
  background-color: #ffffff;
`;

export const StaticDocumentNavBar = styled(DocumentNavBar)`
  margin-top: 6.4rem;
`;

export const Logo = styled('img')`
  padding: 2rem 0;
  height: 8rem;
  width: 15.5rem;
`;

export const H2Text = styled(Typography)`
  font-size: 23px;
  line-height: 24px;
`;

export const BoxRow = styled(Box)`
  display: flex;
  width: 100%;
  padding: 1rem;
  flex-direction: row;
  align-items: center;
`;

export const BoxRowCenteredItem = styled(BoxRow)`
  justify-content: center;
`;

export const PaddedCopyAllIcon = styled(CopyAllIcon)`
  margin-left: 1rem;
  &:hover {
    background-color: #F5F5F5;
  }
`;

export const PaddingTaskAltIcon = styled(TaskAltIcon)`
  margin-left: 1rem;
`;
