import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from "react";
import { ActionButton, ContentSection } from "../../style/styles";
import {theme} from "../../style/theme";
import { useNavigate } from 'react-router-dom';
import { styled } from "@mui/material/styles";
import { DesktopDisclaimerText, MobileDisclaimerText } from './ImageDisclaimer';

const FALLBACK_PRICE = '349';

export interface ProductDetailProps {
  title: string;
  subtitle: string;
  detailText: string;
  price: string;
  terms: string;
  productImage: string;
  stateProductPrice?: string; // optional to keep parallel product types in sync
}

export const ProductDetail = (props: ProductDetailProps) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });
  const navigate = useNavigate();
  return (
    <>
      <Content>
        <Grid wrap={'nowrap'} container>
          <Grid item md={4} container direction={'column'} wrap={'nowrap'}>
            <ProductTitle sx={{fontWeight: 600}}>{props.title}</ProductTitle>
            <ProductTitle sx={{marginBottom: '3.6rem'}}>{props.subtitle}</ProductTitle>
            <DetailText>{props.detailText}</DetailText>
            { isMobile &&
              <Grid container flexDirection={'column'} justifyContent={'center'} alignItems={'center'} padding={1}>
                <ProductImage src={props.productImage}/>
                <MobileDisclaimerText />
              </Grid>
            }
            <Grid item container>
                <Grid item xs sm={12} container direction={'column'}>
                  <PriceDetail item xs container direction={'row'} alignItems={'flex-end'} wrap={'nowrap'}>
                    <Price>${props.stateProductPrice ?? FALLBACK_PRICE}</Price>
                    <Month>month</Month>
                  </PriceDetail>
                  <Terms>{props.terms}</Terms>
                </Grid>
                <Grid item xs sm={12}>
                  <ActionButton onClick={() => navigate('/purchase')}>BUY NOW</ActionButton>
                </Grid>
              </Grid>
          </Grid>
          { !isMobile &&
            <Grid item md={8} container direction={'column'} justifyContent={'center'} alignItems={'center'}>
              <ProductImage src={props.productImage}/>
              <DesktopDisclaimerText />
            </Grid>
          }
        </Grid>
      </Content>
    </>
  );
}

const Content = styled(ContentSection)`
  padding-top: 12rem;
`;

const ProductTitle = styled(Typography)`
  font-size: 3.6rem;
  font-weight: 300;
  line-height: 1.2;
`;

const DetailText = styled(Typography)`
  font-size: 1.6rem;
  font-weight: 300;
  line-height: 2.4rem;
  max-width: 27.5rem;
  margin-bottom: 5rem;
`;

const PriceDetail = styled(Grid)`
  margin-bottom: 1rem;
`;

const Price = styled('p')`
  font-size: 3.6rem;
  line-height: 1;
  font-weight: 400;
`;

const Month = styled('p')`
  font-size: 1.4rem;
  line-height: 1;
  font-weight: 300;
`;

const Terms = styled('p')`
  font-size: 1.2rem;
  font-weight: 300;
  line-height: 1.4rem;
  max-width: 20rem;
  color: #939393;
  margin-bottom: 4rem;
`;

const BuyNow = styled('button')`
  background: #000000;
  border: none;
  color: #FFFFFF;
  font-size: 1.6rem;
  font-weight: 500;
  height: 4.1rem;
  width: 13.5rem;
`;

const ProductImage = styled('img')`
  max-width: 100%;
  height: auto;
  max-height: 100%;
  margin-bottom: 5%;
`;