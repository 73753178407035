import React from 'react';
import { ContentSection } from "../../style/styles";
import Typography from "@mui/material/Typography";

export const IneligiblePage = () => (
  <>
    <ContentSection>
      <Typography variant={'h1'} marginBottom={4} align={'center'}>Your health &amp; safety is the only thing that matters.</Typography>
      <Typography align={'center'}>Unfortunately, due to your medical history, you're not an eligible candidate for this program.  If you'd like to discuss further, call us at 855-462-5779.</Typography>
    </ContentSection>
  </>
);