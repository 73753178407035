import React, { useState } from 'react';
import { Button, ContentSection } from '../../style/styles';
import whiteLogo from "../../assets/grx-logo-white.svg";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { theme } from '../../style/theme';
import { Link } from 'react-router-dom';
import { styled } from "@mui/material/styles";


export interface FooterProps {
  errorMessage?: string;
}

export interface FooterDispatchProps {
  subscribe: (email: string) => void;
}

//TODO: Sagafy the call in this file
export const Footer = ({errorMessage, subscribe}: FooterProps & FooterDispatchProps) => {
  const [emailAddress, setEmailAddress] = useState('');

  const isMobile = useMediaQuery(theme.breakpoints.only('xs'));

  return (
    <FooterSection>
      <Grid container>
        <HalfColumn item xs={12} sm={6} container direction={'column'}>
          <SEBoilerplateHeading>Introducing, the science of success.</SEBoilerplateHeading>
          <SEBoilerplateCopy>Goal-crushing prescriptions. Easy-to-follow wellness guides. Life-changing results. Try GoalsRx risk-free today.</SEBoilerplateCopy>
        </HalfColumn>
        <HalfColumn item xs={12} sm={6} container direction={'column'}>
          <EmailSignupHeading>Goal-getters love our emails.</EmailSignupHeading>
          <EmailSignupCopy>Sign up for science-backed health tips, life hacks, exclusive savings, and product updates.</EmailSignupCopy>
          <Email container item>
            <EmailInput type="text" placeholder="Email address"  onChange={(e: any) => setEmailAddress(e.target.value)}/>
            <EmailButton onClick={() => subscribe(emailAddress)}>Sign Up</EmailButton>
            <ErrorMessage>{errorMessage}</ErrorMessage>
          </Email>
        </HalfColumn>
      </Grid>
      <Grid container>
        <HalfColumn item xs={12} md={6} container direction={'column'}>
          <GrayHeader>CONTACT</GrayHeader>
          <Detail container direction={'column'}>
            855-GoalsRx<br />
            <br />
            4975 Royal Gulf Cir<br />
            Suite 101<br />
            Fort Myers, FL<br />
            33966<br />
          </Detail>
          <Detail>
            <a href='mailto:support@goalsrx.com'>support@goalsrx.com</a>
          </Detail>
        </HalfColumn>
        <HalfColumn item xs={12} md={3} container direction={'column'}>
          <GrayHeader>PRODUCTS</GrayHeader>
          <Detail container direction={'column'}>
            <SiteLink to="/product/burn">Burn Fat</SiteLink>
            <SiteLink to="/product/build">Build Muscle</SiteLink>
            <SiteLink to="/product/thrive">Thrive Every Day</SiteLink>
          </Detail>
        </HalfColumn>
        <HalfColumn item xs={12} md={3} container direction={'column'}>
          <GrayHeader>THE DETAILS</GrayHeader>
          <Detail container direction={'column'}>
            <SiteLink to="/about">What is GoalsRx?</SiteLink>
            <SiteLink to="/faq">FAQ & Help</SiteLink>
            <SiteLink to="/static/privacy-policy">Privacy Policy</SiteLink>
            <SiteLink to="/static/terms">Terms & Conditions</SiteLink>
          </Detail>
        </HalfColumn>
      </Grid>
      <Branding container justifyContent={'space-between'}>
        <Grid item xs={6} sm={12} md={3} lg={6}><Logo src={whiteLogo} /></Grid>
        <SocialMedia item xs={6} sm={12} md={9} lg={6} container justifyContent={'space-between'} padding={1}>
          <Grid item xs={12} sm={2} container justifyContent={isMobile ? 'flex-start' : 'flex-end'}><SocialMediaLink href="https://www.instagram.com/goalsrx">Instagram</SocialMediaLink></Grid>
          <Grid item xs={12} sm={2} container justifyContent={isMobile ? 'flex-start' : 'flex-end'}><SocialMediaLink href="https://twitter.com/goalsrx">Twitter</SocialMediaLink></Grid>
          <Grid item xs={12} sm={2} container justifyContent={isMobile ? 'flex-start' : 'flex-end'}><SocialMediaLink href="https://www.facebook.com/GoalsRx">Facebook</SocialMediaLink></Grid>
          <Grid item xs={12} sm={2} container justifyContent={isMobile ? 'flex-start' : 'flex-end'}><SocialMediaLink href="https://www.youtube.com/channel/UCb_K8akysE09ADUEtfcVrqw">YouTube</SocialMediaLink></Grid>
        </SocialMedia>
      </Branding>
      <Copyright>© 2022 GoalsRx.com All rights reserved. The U.S. and international copyright laws protect GoalsRx.com and the GoalsRx logo.</Copyright>
      <Disclaimer>DISCLAIMER. This website is an educational service that provides general health and weight loss information only. It’s not intended to diagnose, treat or cure any
        health-related condition. Please always consult a physician regarding your health before starting any health or diet program. Results vary from patient to patient. The Food
        and Drug Administration has not evaluated these statements. These products are not intended to diagnose, treat, cure, or prevent any disease.</Disclaimer>
    </FooterSection>
  );
}

const FooterSection = styled(ContentSection)`
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 0;
`;

const HalfColumn = styled(Grid)`
  padding: 8px;
  margin-bottom: 77px;
  
  ${theme.breakpoints.down('sm')} {
    padding: 8px 0;
    margin-bottom: 2rem;
  }
`;

const SEBoilerplateHeading = styled('div')`
  margin-bottom: 14px;
  font-weight: 500;
`;

const SEBoilerplateCopy = styled('div')`
  font-weight: 300;
`;

const EmailSignupHeading = styled('div')`
  color: #00B2FF;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  margin-bottom: 8px;
`;

const EmailSignupCopy = styled('div')`
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 24px;
`;

const Email = styled(Grid)`
  height: 45px;
`;

const EmailInput = styled('input')`
  border: 1px solid #CCCCCC;
  font-size: 16px;
  line-height: 24px;
  margin: 0px 12px;
  width: calc(100% - 117px);
  &:focus {
    outline: none;
  }
`;

const EmailButton = styled(Button)`
  width: 75px;
  font-size: 14px;
  height: 31px;
  line-height: 31px;
  margin: 7px;
  padding: 0;
`;

const GrayHeader = styled('div')`
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  color: #999999;
`;

const Detail = styled(Grid)`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
`;

const Branding = styled(Grid)`
  border-top: 1px;
  border-color: #333333;
  border-top-style: solid;
`;

const Logo = styled('img')`
  margin-top: 28px;
  width: 100px;
  height: 24.53px;
`;

const SocialMedia = styled(Grid)`
  margin-top: 2rem;
  margin-left: auto;
`;

const SiteLink = styled(Link)`
  color: #000000;
  text-decoration: none;
  padding: .4rem 0;
`;

const SocialMediaLink = styled('a')`
  color: #000000;
  font-weight: 400;
  font-size: 12px;
  text-decoration: none;
`;

const Copyright = styled(Typography)`
  margin-top: 40px;
  margin-bottom: 16px;
  font-size: 11px;
  font-weight: 300;
`;

const Disclaimer = styled('div')`
  font-size: 11px;
  font-weight: 300;
  width: 100%;
`;

const ErrorMessage = styled(Typography)`
  color: red;
  margin: 2rem auto 0;
`;