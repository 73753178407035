import { AnyAction, Reducer } from "redux";
import { DEFAULT_APP_STATE, PhysicianState } from "../WebAppState";
import * as Actions from "../actions";

export const physicianReducer: Reducer<PhysicianState> = ((state = DEFAULT_APP_STATE.physician, action: AnyAction): PhysicianState => {
  switch (action.type) {
    case Actions.GET_ACTIVE_QUESTIONNAIRES_SUCCESS:
      return {
        ...state,
        activeQuestionnaires: action.data,
        healthProfile: undefined
      }
    case Actions.GET_HEALTH_PROFILE_SUCCESS:
      return {
        ...state,
        activeQuestionnaires: [],
        healthProfile: action.data
      }
    case Actions.GET_ACTIVE_QUESTIONNAIRES_FAILED:
    case Actions.GET_HEALTH_PROFILE_FAILED:
    case Actions.HEALTH_PROFILE_REVIEW_SUCCESS:
    case Actions.HEALTH_PROFILE_REVIEW_FAILED:
      return {
        ...state,
        activeQuestionnaires: {
          ...state.activeQuestionnaires
        }
      }
    case Actions.USER_LOGOUT:
      return {
        ...DEFAULT_APP_STATE.physician
      }
    default:
      return state;
  }
});