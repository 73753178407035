import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import {
  QuestionnaireDispatchProps,
  QuestionnairePage,
  QuestionnaireProps
} from '../components/checkout/QuestionnairePage';
import { WebAppState } from '../redux/WebAppState';
import {
  CLEANUP_QUESTIONNAIRE,
  PURCHASE_PRODUCT,
  SUBMIT_QUESTIONNAIRE,
  UPDATE_QUESTIONNAIRE
} from '../redux/actions';

const mapStateToProps: MapStateToProps<QuestionnaireProps, {}, WebAppState> = (state: WebAppState) => ({
  responses: state.user.intake.questionnaireResponses,
  formSubmitted: state.user.intake.questionnaireSubmitted,
  formSuccess: state.user.intake.success,
  submitError: state.system.apiCalls.submitQuestionnaire.apiError,
  productVariants: !!state.user.selectedProduct?.variants ? state.user.selectedProduct?.variants.length > 1 : false,
  semaglutide: !!state.user.selectedProduct?.semaglutide,
  purchasingCall: state.system.apiCalls.purchaseProduct
});

const mapDispatchToProps: MapDispatchToProps<QuestionnaireDispatchProps, {}> = dispatch => ({
  updateResponses: (field, value: string | string[] | File) => {
    dispatch({type: UPDATE_QUESTIONNAIRE, data: {[field]: value}})
  },
  submitForm: () => dispatch({type: SUBMIT_QUESTIONNAIRE}),
  cleanup: () => dispatch({type: CLEANUP_QUESTIONNAIRE}),
  checkout: () => dispatch({ type: PURCHASE_PRODUCT})
});

export default connect(mapStateToProps, mapDispatchToProps)(QuestionnairePage);
