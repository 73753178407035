import { Amplify } from 'aws-amplify';

export class AwsInitializer {
  static initialize() {
    Amplify.configure({
      Auth: {
        region: process.env.AWS_COGNITO_REGION,
        identityPoolId: process.env.AWS_COGNITO_ID_POOL,
        userPoolId: process.env.AWS_COGNITO_USER_POOL,
        userPoolWebClientId: process.env.AWS_COGNITO_APP_ID,
        mandatorySignIn: false,
        cookieStorage: {
          domain: this.calculateCookieDomain(),
          expires: 30,
        },
        authenticationFlowType: 'USER_PASSWORD_AUTH'
      }
    });
  }

  static calculateCookieDomain() {
    switch (process.env.NODE_ENV) {
      case 'local':
      case 'unit-test':
      case 'development':
        return 'localhost';
      case 'staging':
        return 'staging.goalsrx.com';
      default:
        return 'goalsrx.com';
    }
  }
}