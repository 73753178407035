import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import {
  GET_HEALTH_PROFILE,
  HEALTH_PROFILE_REVIEW,
  GET_ACTIVE_QUESTIONNAIRES,
  SEND_RESCHEDULE_EMAIL
} from '../redux/actions';
import { PhysicianPage, PhysicianPageDispatchProps, PhysicianPageProps } from '../components/admin/PhysicianPage';
import { WebAppState } from '../redux/WebAppState';

const mapStateToProps: MapStateToProps<PhysicianPageProps, {}, WebAppState> = state => ({
  activeQuestionnaires: state.physician.activeQuestionnaires,
  healthProfile: state.physician.healthProfile,
  getActiveQuestionnairesApi: state.system.apiCalls.getActiveQuestionnaires,
  getHealthProfileApi: state.system.apiCalls.getHealthProfile,
  reviewApi: state.system.apiCalls.healthProfileReview,
  rescheduleApi: state.system.apiCalls.sendRescheduleEmail,
  semaglutide: !!state.physician.healthProfile && !!state.physician.healthProfile.questionnaire.shopifyProductName && state.physician.healthProfile.questionnaire.shopifyProductName.includes('Burn'),
});

const mapDispatchToProps: MapDispatchToProps<PhysicianPageDispatchProps, {}> = dispatch => ({
  getActiveQuestionnaires: () => dispatch({type: GET_ACTIVE_QUESTIONNAIRES}),
  getHealthProfile: (questionnaireId: number) => dispatch({type: GET_HEALTH_PROFILE, data: questionnaireId}),
  submitHealthProfileReview: (questionnaireId, physicianName, notes, approved) => dispatch({type: HEALTH_PROFILE_REVIEW, data: {questionnaireId, physicianName, notes, approved}}),
  sendRescheduleEmail: (questionnaireId) => dispatch({type: SEND_RESCHEDULE_EMAIL, data: {questionnaireId}})
});

export default connect(mapStateToProps, mapDispatchToProps)(PhysicianPage);
