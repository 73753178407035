import { OrderDetail } from "../../models/OrderDetail";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { PRODUCTS } from "../../cms/ProductEntries";
import React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { Font14, Line } from "./userProfileStyles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { theme } from "../../style/theme";

interface OrderHistoryProps { orders: OrderDetail[] }
export const OrderHistory = ({ orders }: OrderHistoryProps) => {
  // using 'md' breakpoint in order to work around MUI Grid structure
  const noScroll = useMediaQuery(theme.breakpoints.down('md'));
  return (<Grid item container md={3.83} xs={12}>
    {noScroll
    ? <StaticView>
        <Typography variant={"h2"}>Order History</Typography>
        { orders.map((order) => {
          if (!PRODUCTS[order.productName.toLowerCase()]) {
            return undefined;
          }
          return (<OrderDetailComponent key={order.id} orderDetail={order}/>)
        }) }
      </StaticView>
    : <ScrollView>
        <Typography variant={"h2"}>Order History</Typography>
        { orders.map((order) => {
          if (!PRODUCTS[order.productName.toLowerCase()]) {
            return undefined;
          }
          return (<OrderDetailComponent key={order.id} orderDetail={order}/>)
        }) }
      </ScrollView>
    }
  </Grid>)
}

interface OrderDetailProps { orderDetail: OrderDetail }
const OrderDetailComponent = ({ orderDetail }: OrderDetailProps) => {
  const product = PRODUCTS[orderDetail.productName.toLowerCase()];
  if (!product) {
    return (<></>)
  }
  return (
    <Grid sx={{mt: 3.9}}>
      <Line/>
      <Grid container gap={1}>
        <Grid item container justifyContent={'space-between'} sx={{margin: '2rem 0'}}>
          <Black14>{product.name} by GoalsRx</Black14>
          {/* <Black14>Status: {orderDetail.orderStatus}</Black14> */} {/* to be uncommented when order updates webhook is configured */}
        </Grid>
        <Grid item container justifyContent={'space-between'}>
          <Grid xs={4.8} item container><OrderProductImage src={product.displayInfo.details.productImage}/></Grid>
          <Grid xs={6.9} item container direction={'column'} gap={0.5}>
            <Gray14>{product.name} by GoalsRx</Gray14>
            { !!orderDetail.paid ? <Gray14>${orderDetail.paid}.00</Gray14> : undefined }
            <Grid item container>
              <Gray14>Placed on:</Gray14>
              <Gray14 sx={{marginLeft: '0.4rem'}}>{orderDetail.orderDate}</Gray14>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

const ScrollView = styled(Box)`
  background-color: white;
  border-radius: 2rem;
  margin-bottom: 5rem;
  padding: 5rem 3rem;

  height: 56rem;
  overflow-y: auto;
`;

const StaticView = styled(ScrollView)`
  height: unset;
  overflow-y: none;
`;

const Gray14 = styled(Font14)`
  color: #6E6E6E;
`;

const Black14 = styled(Font14)`
  color: black;
`;

const OrderProductImage = styled('img')`
  width: 100%;
`;