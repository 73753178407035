import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import React from "react";
import { Label } from "../CheckoutPage";

export interface StateMenuProps {
  addressValue: string;
  setAddressValue: (value:string) => void;
}

export const StateMenu = ({ addressValue, setAddressValue }:StateMenuProps) => {
  return (
    <Grid item container xs direction={'column'}>
      <Label>State</Label>
      <StateSelect type="text" name="state" value={addressValue} onChange={(e: any) => setAddressValue(e.target.value)}>
        <MenuItem value={"AK"}>AK</MenuItem>
        <MenuItem value={"AZ"}>AZ</MenuItem>
        <MenuItem value={"CO"}>CO</MenuItem>
        <MenuItem value={"CT"}>CT</MenuItem>
        <MenuItem value={"DE"}>DE</MenuItem>
        <MenuItem value={"DC"}>DC</MenuItem>
        <MenuItem value={"FL"}>FL</MenuItem>
        <MenuItem value={"GA"}>GA</MenuItem>
        <MenuItem value={"ID"}>ID</MenuItem>
        <MenuItem value={"IL"}>IL</MenuItem>
        <MenuItem value={"IA"}>IA</MenuItem>
        <MenuItem value={"KS"}>KS</MenuItem>
        <MenuItem value={"KY"}>KY</MenuItem>
        <MenuItem value={"LA"}>LA</MenuItem>
        <MenuItem value={"ME"}>ME</MenuItem>
        <MenuItem value={"MD"}>MD</MenuItem>
        <MenuItem value={"MA"}>MA</MenuItem>
        <MenuItem value={"MS"}>MS</MenuItem>
        <MenuItem value={"MO"}>MO</MenuItem>
        <MenuItem value={"MT"}>MT</MenuItem>
        <MenuItem value={"NV"}>NV</MenuItem>
        <MenuItem value={"NH"}>NH</MenuItem>
        <MenuItem value={"NM"}>NM</MenuItem>
        <MenuItem value={"NY"}>NY</MenuItem>
        <MenuItem value={"NC"}>NC</MenuItem>
        <MenuItem value={"ND"}>ND</MenuItem>
        <MenuItem value={"OH"}>OH</MenuItem>
        <MenuItem value={"OK"}>OK</MenuItem>
        <MenuItem value={"OR"}>OR</MenuItem>
        <MenuItem value={"PA"}>PA</MenuItem>
        <MenuItem value={"PR"}>PR</MenuItem>
        <MenuItem value={"RI"}>RI</MenuItem>
        <MenuItem value={"SC"}>SC</MenuItem>
        <MenuItem value={"SD"}>SD</MenuItem>
        <MenuItem value={"TN"}>TN</MenuItem>
        <MenuItem value={"TX"}>TX</MenuItem>
        <MenuItem value={"UT"}>UT</MenuItem>
        <MenuItem value={"VT"}>VT</MenuItem>
        <MenuItem value={"VA"}>VA</MenuItem>
        <MenuItem value={"WA"}>WA</MenuItem>
        <MenuItem value={"WV"}>WV</MenuItem>
        <MenuItem value={"WI"}>WI</MenuItem>
        <MenuItem value={"WY"}>WY</MenuItem>
      </StateSelect>
    </Grid>
  )
}

const StateSelect = styled(Select)`
  font-size: 1.8rem;
  line-height: 1;
  background: #F7F7F7;
  height: 5.1rem;
`;