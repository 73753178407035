import { User } from "../models/User";
import { CognitoUserSession } from "amazon-cognito-identity-js";
import Bugsnag from "@bugsnag/js";

export class HttpClient {
  public static MESSAGES = {
    UNAUTHORIZED: 'Unauthorized',
  };

  readonly baseUrl: string;

  constructor(readonly user?: User) {
    if (!process.env.API_BASE_URL || process.env.API_BASE_URL === '') {
      const err = new Error('No Base URL found when creating HttpClient');
      Bugsnag.notify(err);
      throw err;
    }

    this.baseUrl = process.env.API_BASE_URL;
  }

  public post = (endpoint: string, body: any): Promise<Response> => {
    function buildRequestInit(headers: HeadersInit): RequestInit {
      return {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        headers,
        body: JSON.stringify(body)
      };
    }

    return this.buildRequestHeaders(this.user)
      .then(buildRequestInit)
      .then(request => this.processRequest(endpoint, request));
  }

  public postData = (endpoint: string, body: any, files: File[]): Promise<Response> => {
    const formData = new FormData();
    this.deconstructFile(files, formData);

    formData.append('form', JSON.stringify(body));

    const init: RequestInit = {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      body: formData,
    };

    return this.processRequest(endpoint, init);
  }

  private deconstructFile(files: File[], formData: FormData) {
    const ONE_MB = 1048576;
    const photoId = files[0].toString();
    const iterations = Math.ceil(photoId.length / ONE_MB);
    for (let i = 0; i < iterations; i++) {
      formData.append('photoId' + i, photoId.substr(i * ONE_MB, ONE_MB));
    }

    formData.append('photoIdCount', `${iterations}`);
  }

  public get = (endpoint: string): Promise<any> => {
    function buildRequestInit(headers: HeadersInit): RequestInit {
      return {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        headers
      };
    }

    return this.buildRequestHeaders(this.user)
      .then(buildRequestInit)
      .then(request => this.processRequest(endpoint, request));
  }

  private processRequest(endpoint: string, request: RequestInit): Promise<any> {
    return fetch(`${this.baseUrl}${endpoint}`, request)
      .then(response => {
        if (response.ok) {
          if (!!response.body && Number(response.headers.get('content-length')) > 0 ) {
            return response.json();
          }
        } else {
          // some lambda functions still return { message: 'error' }
          // some return new Result400('error')
          return response.json().then(result => {
            if (result.message) {
              throw new Error(result.message);
            } else {
              throw new Error(result);
            }
          });
        }
      });
  }

  private buildRequestHeaders(user: User | undefined): Promise<HeadersInit> {
    if (!!user) {
      return this.getIdToken(user)
        .then(token => ({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        }))
        .catch(() => ({ 'Content-Type': 'application/json' }));
    } else {
      return Promise.resolve({'Content-Type': 'application/json'});
    }
  }

  private getIdToken(user: User): Promise<string> {
    return new Promise((resolve, reject) => {
      user.nativeUser.getSession((err: Error | null, session: CognitoUserSession | null) => {
        if (err) {
          reject(err);
        }
        resolve(session!.getIdToken().getJwtToken());
      })
    });
  }
}