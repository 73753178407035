import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import burnIcon from "../../assets/burn/burn-icon.svg";
import buildIcon from "../../assets/build/build-icon.svg";
import thriveIcon from "../../assets/thrive/thrive-icon.png";
import Typography from "@mui/material/Typography";
import phone from "../../assets/phone-health-guide.png";
import products from "../../assets/3-products.png";
import React from "react";
import { styled } from "@mui/material/styles";
import { Button, ContentSection, SmallIcon } from "../../style/styles";
import { theme } from "../../style/theme";
import HealthGuideButtonContainer from "../../containers/HealthGuideButtonContainer";

export const HomePageHeroSection = () => {
  const navigate = useNavigate();

  return (
    <Wrapper>
      <ContentDiv container justifyContent={'space-between'} alignItems={'baseline'}>
        <Floating><HealthGuideButtonContainer color={'rgba(0,0,0,0.5)'}/></Floating>
        <MainTextGrid item sm={7} xs={12}>
          <Grid>
            <HeadlineIcon src={burnIcon} />
            <HeadlineIcon src={buildIcon} />
            <HeadlineIcon src={thriveIcon} />
          </Grid>
          <BigLightFont variant={'h1'}>Biohack your way<br />to a healthier you.</BigLightFont>
          <Typography variant={'h2'}>Build healthy habits & transform your life for good with technology-driven nutrition, exercise & medication plans, tailored to your goals.</Typography>
        </MainTextGrid>
        <Grid item sm={4} xs={12} container direction={'column'}>
          <GoalDiv item container direction={'column'}>
            <MediumSubtext>What’s your goal?</MediumSubtext>
            <GoalButton onClick={() => navigate('/product/burn')}>Burn unhealthy fat</GoalButton>
            <GoalButton onClick={() => navigate('/product/build')}>Build muscle mass</GoalButton>
            <GoalButton onClick={() => navigate('/product/thrive')}>Thrive every day</GoalButton>
          </GoalDiv>
        </Grid>
      </ContentDiv>
      <ImageContainer>
        <PhoneImage src={phone} alt={'GoalsRx Health Guide'} />
        <ProductsImage src={products} alt={'GoalsRx Products'} />
      </ImageContainer>
    </Wrapper>
  );
}


const HeadlineIcon = styled(SmallIcon)`
  margin: 0 1rem;
  
  &:first-child {
    margin-left: 0;
  }
  
  &:last-child {
    margin-right: 0;
  }
`;

const MainTextGrid = styled(Grid)`
  margin-bottom: 2rem;
`;

const Wrapper = styled(ContentSection)`
  margin-top: 5;
  ${theme.breakpoints.down('sm')} {
    margin-top: 0;
  }
  align-items: flex-start;
`;

const ContentDiv = styled(Grid)`
  z-index: 3;
`;

const ImageContainer = styled('div')`
  position: absolute;
  bottom: -16%;
  left: 7%;
  width: 33%;
  height: auto;
  z-index: 2;
  max-width: 40rem;
  
  ${theme.breakpoints.up('lg')} {
    bottom: -10.8rem;
  }
  
  ${theme.breakpoints.down('sm')} {
    bottom: -6%;
    left: 17%;
    width: 66%;
    overflow-x: hidden;
  }
`;

const ProductsImage = styled('img')`
  width: 57.5%;
  height: 90%;
`;

const PhoneImage = styled('img')`
  width: 42.5%;
  height: 100%;
`;

const GoalDiv = styled(Grid)`
  max-height: 370px;
  margin-bottom: 10rem;
  ${theme.breakpoints.up('xl')} {
    margin-bottom: -5.5rem;
  }
  ${theme.breakpoints.down('xl')} {
    margin-bottom: 17.5rem;
  }
  ${theme.breakpoints.down('lg')} {
    margin-bottom: 10.5rem;
  }
  ${theme.breakpoints.down('md')} {
    margin-bottom: 7.5rem;
  }
  ${theme.breakpoints.down('sm')} {
    margin-bottom: 14rem;
  }
`;

const BigLightFont = styled(Typography)`
  margin-bottom: 5rem;
  
  ${theme.breakpoints.down('lg')} {
    margin-bottom: 2rem;
  }
  
  ${theme.breakpoints.down('sm')} {
    font-size: 3rem;
  }
`;

const MediumSubtext = styled(Typography)`
  font-weight: 600;
  font-size: 2.4rem;
  margin-bottom: 1rem;
  
  ${theme.breakpoints.down('md')} {
    font-size: 2rem;
  }
  
  ${theme.breakpoints.down('sm')} {
    font-size: 1.8rem;
  }
`;

const GoalButton = styled(Button)`
  color: #FFFFFF;
  background: rgba(0,0,0,0.5);
  margin: 1rem 0;
  max-width: 100%;
  font-weight: 300;
  font-size: 2.4rem;
  text-decoration-color: white;
  display: list-item;
  list-style-type: disc;
  list-style-position: inside;
  
  ${theme.breakpoints.down('lg')} {
    font-size: 2rem;
  }
  
  ${theme.breakpoints.down('md')} {
    font-size: 1.6rem;
  }
  
  ${theme.breakpoints.down('sm')} {
    font-size: 1.8rem;
  }
`;

const Floating = styled('div')`
  position: absolute;
  right: 1rem;
  top: 1rem;
  max-width: 50%;
`;