import React, { useState } from 'react';
import { ProductContentProps, ProductContentsContentSection } from './DesktopProductContents';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { ItemDetail } from '../../models/ItemDetail';
import { SmallIcon, SupplementalFacts } from '../../style/styles';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Fade from "@mui/material/Fade";
import { styled } from "@mui/material/styles";
import { MobileDisclaimerText } from './ImageDisclaimer';

export const MobileProductContents = ({ product }: ProductContentProps) => {
  const [expandedItem, setExpandedItem] = useState({} as ItemDetail);
  const [factsShown, setFactsShown] = useState(false);

  const handleChange = (item: ItemDetail) => {
    if (expandedItem === item) {
      setExpandedItem({} as ItemDetail);
    } else {
      setExpandedItem(item);
      setFactsShown(false);
    }
  }

  return (
    <ProductContentsContentSection>
      <Grid container direction={'column'}>
        <WhatsInsideGrid container justifyContent={'space-between'}>
          <Typography variant={'h1'}>What's Inside</Typography>
          <SmallIcon src={product.displayInfo.productContents.icon} />
        </WhatsInsideGrid>
        <Grid container direction={'column'}>
          {product.displayInfo.productContents.details.map(item => (
            <Accordion expanded={!!expandedItem && item.linkText === expandedItem.linkText} onChange={() => handleChange(item)} key={item.linkText}>
              <AccordionSummary expandIcon={<ExpandMore />}><Title>{item.linkText}</Title></AccordionSummary>
              <AccordionDetails>
                <Grid container direction={'column'}>
                  <Grid container sx={{position: 'relative'}} onClick={() => setFactsShown(!!item.supplementFactImage && !factsShown)}>
                    <Fade in={!factsShown}>
                      <ScaledImage src={item.image} />
                    </Fade>
                    <Fade in={factsShown}>
                      <SupplementalFacts src={item.supplementFactImage} />
                    </Fade>
                    {!!item.supplementFactImage && <TextOverlay>{factsShown ? 'Supplement' : 'Supplement Facts'}</TextOverlay>}
                    <MobileDisclaimerText />
                  </Grid>
                  <MobileItemHeading variant={'h1'}>{item.title}</MobileItemHeading>
                  <SubTitle>{item.subtitle}</SubTitle>
                  <ContentGrid>{item.shortDescription}</ContentGrid>
                  <ContentGrid>{item.fullDescription}</ContentGrid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          ))}
        </Grid>
      </Grid>
    </ProductContentsContentSection>
  );
}

const TextOverlay = styled(Typography)`
  position: absolute;
  top: 0.5rem;
  right: 0;
  padding: 1rem;
  background-color: #AAAAAA;
  color: #FFFFFF;
  border-radius: 1rem;
  z-index: 3;
  cursor: pointer;
`;

const Title = styled('p')`
  font-weight: 300;
  font-size: 1.8rem;
`;

const SubTitle = styled('p')`
  font-weight: 500;
  font-size: 1.6rem;
  margin-bottom: 1rem;
`;

const ContentGrid = styled(Grid)`
  margin-bottom: 1rem;
`;

const ScaledImage = styled('img')`
  max-width: 22rem;
`;

const MobileItemHeading = styled(Typography)`
  margin-bottom: 1.5rem;
`;

const WhatsInsideGrid = styled(Grid)`
  padding: 0 0 1.6rem 0;
`;