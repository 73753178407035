import { AnyAction, Reducer } from "redux";
import { DEFAULT_APP_STATE, SystemState } from "../WebAppState";
import * as Actions from '../actions';
import { mapActionToApiCall } from "../apiCalls";

export const systemReducer: Reducer<SystemState> = ((state = DEFAULT_APP_STATE.system, action: AnyAction): SystemState => {
  switch (action.type) {
    case Actions.CALL_STARTING:
      return {
        ...state,
        apiCalls: {
          ...state.apiCalls,
          [action.data]: {
            apiError: undefined,
            isLoading: true
          }
        }
      }
    case Actions.SUBMIT_QUESTIONNAIRE_FAILED:
    case Actions.CHECKOUT_FAILED:
    case Actions.HEALTH_GUIDE_FAILED:
    case Actions.USER_PROFILE_FAILED:
    case Actions.USER_LOGIN_FAILED:
    case Actions.GET_ACTIVE_QUESTIONNAIRES_FAILED:
    case Actions.GET_HEALTH_PROFILE_FAILED:
    case Actions.HEALTH_PROFILE_REVIEW_FAILED:
    case Actions.HEALTH_GUIDE_DOCUMENT_FAILED:
    case Actions.GET_PRODUCTS_FAILED:
    case Actions.SUBSCRIBE_NEWSLETTER_FAILED:
    case Actions.GET_PROMO_VALUE_FAILED:
    case Actions.CREATE_USER_FAILED:
    case Actions.UPDATE_PHOTO_ID_FAIL:
    case Actions.SEND_RESCHEDULE_EMAIL_FAILED:
    case Actions.PURCHASE_PRODUCT_FAILED:
      return {
        ...state,
        apiCalls: {
          ...state.apiCalls,
          [mapActionToApiCall(action.type)]: {
            apiError: action.data,
            isLoading: false
          }
        }
      };
    case Actions.SUBMIT_QUESTIONNAIRE_SUCCESS:
    case Actions.CHECKOUT_SUCCESS:
    case Actions.HEALTH_GUIDE_RECEIVED:
    case Actions.USER_PROFILE_SUCCESS:
    case Actions.USER_LOGIN_SUCCESS:
    case Actions.USER_PROFILE_SKIPPED:
    case Actions.GET_ACTIVE_QUESTIONNAIRES_SUCCESS:
    case Actions.GET_HEALTH_PROFILE_SUCCESS:
    case Actions.HEALTH_PROFILE_REVIEW_SUCCESS:
    case Actions.HEALTH_GUIDE_DOCUMENT_RECEIVED:
    case Actions.SUBSCRIBE_NEWSLETTER_SUCCESS:
    case Actions.GET_PROMO_VALUE_SUCCESS:
    case Actions.CREATE_USER_SUCCESS:
    case Actions.UPDATE_PHOTO_ID_SUCCESS:
    case Actions.SEND_RESCHEDULE_EMAIL_SUCCESS:
      return {
        ...state,
        apiCalls: {
          ...state.apiCalls,
          [mapActionToApiCall(action.type)]: {
            apiError: undefined,
            isLoading: false
          }
        }
      };
    case Actions.GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        apiCalls: {
          ...state.apiCalls,
          [mapActionToApiCall(action.type)]: {
            apiError: undefined,
            isLoading: false
          }
        },
        products: action.data
      };
      case Actions.CLEANUP_QUESTIONNAIRE:
        return {
          ...state,
          apiCalls: {
            ...state.apiCalls,
            submitQuestionnaire: {
              apiError: undefined,
              isLoading: false
            }
          }
        };
    case Actions.SET_ERROR_HANDLER:
      return {
        ...state,
        errorHandler: action.data
      }
    case Actions.SET_HTTP_CLIENT:
      return {
        ...state,
        httpClient: action.data
      };
    case Actions.SET_CMS_CLIENT:
      return {
        ...state,
        cmsClient: action.data
      };
    case Actions.SET_CMS_SERVICE:
      return {
        ...state,
        cmsService: action.data
      };
    case Actions.SET_STORAGE_SERVICE:
      return {
        ...state,
        storageService: action.data,
      };
    case Actions.SET_SHOPIFY_CLIENT:
      return {
        ...state,
        shopifyClient: action.data,
      };
    case Actions.RESET_LOGIN_ERROR:
      return {
        ...state,
        apiCalls: {
          ...state.apiCalls,
          userLogin: {
            isLoading: false
          }
        }
      }
    default:
      return state;
  }
});