import { RichTextField } from "@prismicio/types";

export const getTextFromRTF = (rtf: RichTextField): string => {
  let text = '';
  //@ts-ignore
  rtf.map(item => text += item.text);

  return text;
}

