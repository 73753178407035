import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import {
  CALL_STARTING,
  CANCEL_SUBSCRIPTION,
  CANCEL_SUBSCRIPTION_FAILED,
  CANCEL_SUBSCRIPTION_SUCCESS,
  CHECKOUT_FAILED,
  CHECKOUT_SUCCESS,
  CREATE_USER,
  CREATE_USER_FAILED,
  CREATE_USER_SUCCESS,
  GET_PROMO_VALUE,
  GET_PROMO_VALUE_FAILED,
  GET_PROMO_VALUE_SUCCESS,
  GET_USER_PROFILE,
  SET_PURCHASE_PRICE,
  SET_SUBSCRIPTION,
  SET_USER_NAME,
  SUBMIT_CHECKOUT,
  SUBMIT_QUESTIONNAIRE,
  SUBMIT_QUESTIONNAIRE_FAILED,
  SUBMIT_QUESTIONNAIRE_SUCCESS,
  SUBSCRIBE_NEWSLETTER,
  SUBSCRIBE_NEWSLETTER_FAILED,
  SUBSCRIBE_NEWSLETTER_SUCCESS,
  UPDATE_PHOTO_ID,
  UPDATE_PHOTO_ID_FAIL,
  UPDATE_PHOTO_ID_SUCCESS,
  UPDATE_PURCHASE_PRICE,
  USER_LOGIN,
  USER_LOGIN_FAILED,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_PROFILE_FAILED,
  USER_PROFILE_SKIPPED,
  USER_PROFILE_SUCCESS
} from '../actions';
import { WebAppState } from '../WebAppState';
import { HttpClient } from '../../service/HttpClient';
import { AnyAction } from 'redux';
import { HealthQuestionnaire } from '../../models/HealthQuestionnaire';
import { StorageService } from '../../service/StorageService';
import { GoalResponse } from "../../models/Goal";
import { ImageUploadError } from '../../models/ImageUploadError';
import { resizeImage } from "../../utils/ImageResize";

export function* userSaga() {
  yield all([
    createUserWatcher(),
    submitQuestionnaireWatcher(),
    submitCheckoutWatcher(),
    userLoginWatcher(),
    getUserProfileWatcher(),
    userLogoutWatcher(),
    subscribeNewsletterWatcher(),
    getPromoValueWatcher(),
    updatePurchasePriceWatcher(),
    cancelSubscriptionWatcher(),
    updatePhotoIdWatcher()
  ]);
}

function* createUserWatcher() {
  yield takeEvery(CREATE_USER, createUser);
}

function* submitQuestionnaireWatcher() {
  yield takeEvery(SUBMIT_QUESTIONNAIRE, submitQuestionnaire);
}

function* submitCheckoutWatcher() {
  yield takeEvery(SUBMIT_CHECKOUT, submitCheckout);
}

function* userLoginWatcher() {
  yield takeEvery(USER_LOGIN, userLogin);
}

function* getUserProfileWatcher() {
  yield takeEvery(GET_USER_PROFILE, getUserProfile);
}

function* userLogoutWatcher() {
  yield takeEvery(USER_LOGOUT, userLogout);
}

function* subscribeNewsletterWatcher() {
  yield takeEvery(SUBSCRIBE_NEWSLETTER, subscribeForNewsletter);
}

function* getPromoValueWatcher() {
  yield takeEvery(GET_PROMO_VALUE, getPromoValue);
}

function* updatePurchasePriceWatcher() {
  yield takeEvery(UPDATE_PURCHASE_PRICE, updatePurchasePrice);
}

function* cancelSubscriptionWatcher() {
  yield takeEvery(CANCEL_SUBSCRIPTION, cancelSubscription);
}

function* updatePhotoIdWatcher() {
  yield takeEvery(UPDATE_PHOTO_ID, updatePhotoId);
}

function parseGoals(goals: GoalResponse[]) {
  return goals.map((goalResponse) => {
    if (goalResponse.goal.type === 'checkbox') {
      return {key: goalResponse.goal.key, value: 'true'}
    }
    return {key: goalResponse.goal.key, value: goalResponse.value}
  });
}

export function* createUser(createUserAction: AnyAction): Generator<any, any, any> {
  yield put({type: CALL_STARTING, data: 'createUser'});
  const client = (yield select((state: WebAppState) => state.system.httpClient)) as HttpClient;

  try {
    yield call(client.post, 'createUser', createUserAction.data);
    yield put({type: CREATE_USER_SUCCESS});
  } catch (err) {
    yield put({type: CREATE_USER_FAILED, data: err});
  }
}

export function* submitQuestionnaire(): Generator<any, any, any> {
  yield put({type: CALL_STARTING, data: 'submitQuestionnaire'});
  const request = (yield select((state: WebAppState) => state.user.intake.questionnaireResponses)) as HealthQuestionnaire;
  const goals = (yield select((state: WebAppState) => state.user.intake.goalResponses)) as GoalResponse[];
  const client = (yield select((state: WebAppState) => state.system.httpClient)) as HttpClient;
  const storageService = (yield select((state: WebAppState) => state.system.storageService)) as StorageService;
  const username = yield select((state: WebAppState) => state.user.user?.username);
  const productName = yield select((state: WebAppState) => state.user.selectedProduct?.name);
  try {
    const imageFile = yield call(() => resizeImage(request.photoId!, {maxWidth: 675,  maxHeight: 425, contentType: 'file', quality: 1}));
    const photoKey = yield call(() => storageService.upload(imageFile.url));
    if (photoKey === undefined) {
      yield put({ type: SUBMIT_QUESTIONNAIRE_FAILED, data: new Error('Photo ID Upload Failed')});
      return;
    }
    const preparedRequest = {username, productName: productName, form: {...request, goals: parseGoals(goals), licenseUri: photoKey, photoId: undefined}};
    const response = yield call(client.post, 'process-questions', preparedRequest);
    if (response.result === 'pass') {
      yield put({type: SUBMIT_QUESTIONNAIRE_SUCCESS, data: request.birthDate});
    } else {
      yield put({type: SUBMIT_QUESTIONNAIRE_FAILED, data: new Error(response.message)});
    }
  } catch (err) {
    yield put({type: SUBMIT_QUESTIONNAIRE_FAILED, data: err});
  }
}

export function* submitCheckout(checkoutAction: AnyAction): Generator<any, any, any> {
  yield put({type: CALL_STARTING, data: 'checkout'});
  const client = (yield select((state: WebAppState) => state.system.httpClient)) as HttpClient;
  const { request, navigate } = checkoutAction.data;
  try {
    yield call(client.post, 'purchase', request);
    yield put({type: CHECKOUT_SUCCESS, data: parseFloat(request.amount)});
    navigate('/orderComplete');
  } catch (err) {
    yield put({type: CHECKOUT_FAILED, data: err});
  }
}

export function* userLogin(loginAction: AnyAction): Generator<any, any, any> {
  yield put({ type: CALL_STARTING, data: 'userLogin' });

  try {
    const { login, navigate } = loginAction.data;
    yield call(login);
    yield put({ type: USER_LOGIN_SUCCESS });
    const action = { type: null, data: navigate}
    yield* getUserProfile(action);
  } catch (err) {
    yield put ({ type: USER_LOGIN_FAILED, data: err});
  }
}

export function* subscribeForNewsletter(subscribeAction: AnyAction): Generator<any, any, any> {
  //This is supposedly the RFC 5322 format
  let regex = new RegExp("([!#-'*+/-9=?A-Z^-~-]+(\.[!#-'*+/-9=?A-Z^-~-]+)*|\"\(\[\]!#-[^-~ \t]|(\\[\t -~]))+\")@([!#-'*+/-9=?A-Z^-~-]+(\.[!#-'*+/-9=?A-Z^-~-]+)*|\[[\t -Z^-~]*])", 'i');

  if (!subscribeAction.data) {
    yield put({ type: SUBSCRIBE_NEWSLETTER_FAILED, data: new Error('Email address cannot be blank.')});
    return;
  }

  if (!subscribeAction.data.match(regex)) {
    yield put({ type: SUBSCRIBE_NEWSLETTER_FAILED, data: new Error('Invalid email address.')});
    return;
  }

  const client = (yield select((state: WebAppState) => state.system.httpClient)) as HttpClient;

  try {
    yield put({ type: CALL_STARTING, data: 'subscribeNewsletter' });
    yield call(client.post, 'subscribe', {emailAddress: subscribeAction.data});
    yield put({ type: SUBSCRIBE_NEWSLETTER_SUCCESS });
  } catch (err) {
    yield put({ type: SUBSCRIBE_NEWSLETTER_FAILED, data: err });
  }
}

export function* getUserProfile(getProfileAction: AnyAction): Generator<any, any, any> {
  yield put({type: CALL_STARTING, data: 'getUserProfile'});
  const client = (yield select((state: WebAppState) => state.system.httpClient)) as HttpClient;
  try {
    if (client.user) {
      const response = yield call(client.get, 'userProfile');

      yield put({ type: SET_USER_NAME, data: !!response.customer ? response.customer.name : '' });
      if (response.product.length > 0) { yield put({ type: SET_SUBSCRIPTION, data: { name: response.product }}); }
      yield put({ type: USER_PROFILE_SUCCESS, data: response });
      const navigate = getProfileAction.data;
      if (!!navigate) {
        navigate();
      }
    } else {
      yield put({ type: USER_PROFILE_SKIPPED });
    }
  } catch (err) {
    yield put({ type: USER_PROFILE_FAILED, data: err });
  }
}

export function* cancelSubscription(cancelSubscriptionAction: AnyAction): Generator<any, any, any> {
  yield put({ type: CALL_STARTING, data: 'cancelSubscription' });
  const client = (yield select((state: WebAppState) => state.system.httpClient)) as HttpClient;
  try {
    const response = yield call(client.post, 'cancelSubscription', { subscriptionId: cancelSubscriptionAction.data });
    yield put({ type: CANCEL_SUBSCRIPTION_SUCCESS, data: response });
    yield put({ type: GET_USER_PROFILE })
  } catch (err) {
    yield put({ type: CANCEL_SUBSCRIPTION_FAILED, data: err });
  }
}

export function* userLogout(logoutAction: AnyAction): Generator<any, any, any> {
  const { logout, navigate } = logoutAction.data;
  if (!!navigate) {
    navigate();
  }
  yield call(logout);
}

export function* getPromoValue(getPromoAction: AnyAction): Generator<any, any, any> {
  yield put({type: CALL_STARTING, data: 'getPromoValue'});
  const client = (yield select((state: WebAppState) => state.system.httpClient)) as HttpClient;

  try {
    const promoCode = getPromoAction.data;
    const promoValue = yield call(client.get, `promotion?code=${getPromoAction.data}`);
    yield put({ type: GET_PROMO_VALUE_SUCCESS, data: { code: promoCode.toUpperCase(), value: promoValue} });
  } catch (err) {
    yield put ({ type: GET_PROMO_VALUE_FAILED, data: err});
  }
}

export function* updatePurchasePrice(setPurchasePriceAction: AnyAction): Generator<any, any, any> {
  const newPrice = setPurchasePriceAction.data;
  yield put({type: SET_PURCHASE_PRICE, data: newPrice });
}

export function* updatePhotoId(updatePhotoIdAction: AnyAction): Generator<any, any, any> {
  yield put({type: CALL_STARTING, data: 'updatePhotoId'});

  const storageService = (yield select((state: WebAppState) => state.system.storageService)) as StorageService;

  try {
    const imageFile = yield call(() => resizeImage(updatePhotoIdAction.data, {maxWidth: 675,  maxHeight: 425, contentType: 'file', quality: 1}));
    const photoKey = yield call(() => storageService.upload(imageFile.url));
    if (!photoKey) {
      throw new ImageUploadError('Image failed to upload');
    }

    const client = (yield select((state: WebAppState) => state.system.httpClient)) as HttpClient;
    yield call(client.post, 'photoId', { photoStorageId: photoKey });

    yield put({ type: UPDATE_PHOTO_ID_SUCCESS });
  } catch (err) {
    yield put({ type: UPDATE_PHOTO_ID_FAIL, data: err });
  }
}
