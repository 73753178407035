import { connect, MapDispatchToProps } from "react-redux";
import { GET_USER_PROFILE, SET_USER, GET_PRODUCTS } from "../redux/actions";
import { App, AppProps } from "../components/App";


const mapDispatchToProps: MapDispatchToProps<AppProps, {}> = dispatch => ({
  setUser: user => dispatch({type: SET_USER, data: user}),
  setUserProfile: () => dispatch({ type: GET_USER_PROFILE }),
  setProducts: () => dispatch({ type: GET_PRODUCTS })
});

export const AppContainer = connect(null, mapDispatchToProps)(App);