import { TimedCarouselItem } from '../components/shared/TimedCarousel';
import burnIcon from '../assets/burn/burn-icon.svg';
import buildIcon from '../assets/build/build-icon.svg';
import thriveIcon from '../assets/thrive/thrive-icon.png';
import coleHeadshot from '../assets/headshot-cole.png';
import bobHeadshot from '../assets/headshot-bob.png';
import React from 'react';
import { TestimonialBlock } from "../components/shared/TestimonialBlock";
import { CarouselItem } from "../components/shared/Carousel";
import Typography from '@mui/material/Typography';
import { Circle } from "../style/styles";
import { styled } from "@mui/material/styles";
import { Quote } from "../components/shared/Quote";

const BurnTestimony = {
  testimonial: 'After having 3 children it has been a struggle to regain my pre-pregnancy body. Nothing I tried worked and I was starting to lose hope. I’ve been using the GoalsRx system for 3 months and am almost back to my pre-pregnancy weight! I am so grateful to the Goals team. This has truly changed my life.',
  signature: '–Ellen M.'
}

const BuildTestimony = {
  testimonial: 'I do CrossFit and have been dominating everyone at my gym since I started Build. Endurance for days and strength is way up. I want to tell everyone about it, but I think I’ll keep it as my secret weapon.',
  signature: '–Lauren B.'
}

const ThriveTestimony = {
  testimonial: 'As a doctor, I think this is a unique and highly scientific approach to cellular health. I frequently recommend it to friends, family and patients who are looking to age gracefully.',
  signature: '–Addison S.'
}

export const CAROUSEL_TESTIMONIES: TimedCarouselItem[] = [
  {
    icon: burnIcon,
    content: (<TestimonialBlock props={BurnTestimony} />),
  },
  {
    icon: buildIcon,
    content: (<TestimonialBlock props={BuildTestimony} />),
  },
  {
    icon: thriveIcon,
    content: (<TestimonialBlock props={ThriveTestimony} />),
  },
];

const Headshot = ({image}: HeadshotProps) => (
  <HeadshotCircle $backgroundColor={'#FFFFFF'}>
    <HeadshotImage src={image} />
  </HeadshotCircle>
);

const cole: CarouselItem = {
  image: (<Headshot image={coleHeadshot} />),
  imageTitle: 'Dr. Cole Streets',
  imageSubtitle: 'Board Certified Doctor of Natural Medicine',
  content: (<>
    <Quote width={'3rem'} height={'3rem'} fill={'#ABABAB'} />
    <Typography>I’ve dedicated my lifelong studies and career to health and human performance. It’s my passion to help people of all ages look, feel, and perform their best. I’m confident that our programs will help you achieve your wellness goals.</Typography>
  </>),
}

const bob: CarouselItem = {
  image: (<Headshot image={bobHeadshot} />),
  imageTitle: 'Bob Myers',
  imageSubtitle: 'Venture Capitalist,\n Technology Disrupter',
  content: (<>
    <Quote width={'3rem'} height={'3rem'} fill={'#ABABAB'} />
    <Typography>I am living proof that GoalsRx works.  At nearly 300lbs, I knew I needed real help. I searched high & low for a sustainable, technology-based approach to weight loss & overall health.  It didn’t exist. So we created GoalsRx.  If you use the tools & commit to a lifestyle change, you will achieve your goals.  Plain & simple.</Typography>
  </>),
}

interface HeadshotProps {
  image: string;
}

const HeadshotImage = styled('img')`
  width: 100%;
  height: auto;
  max-width: 30rem;
  max-height: 30rem;
`;

const HeadshotCircle = styled(Circle)`
  max-width: 30rem;
  max-height: 30rem;
  overflow: hidden;
`;

export const FOUNDERS_CAROUSEL_ITEMS: CarouselItem[] = [
  cole,
  bob
];
