import React from 'react';
import { useParams } from 'react-router-dom';
import { PrismicRichText, usePrismicDocumentByUID } from '@prismicio/react';
import { PrismicDocument } from '@prismicio/types';
import Grid from '@mui/material/Grid';
import { Loading } from '../shared/Loading';
import { ContentSection, TitleGrid } from '../../style/styles';
import { prismicToMuiRenderer } from '../../utils/displayUtils';

export const StaticContentPage = () => {
  const { documentUid } = useParams<{ documentUid: string }>();

  const [document, { state }] = usePrismicDocumentByUID('important_document', documentUid ?? '');

  return (
    <>
      <ContentSection>
        <Grid container justifyContent={'center'}>
          <Grid item xs={10} container justifyContent={'center'} alignItems={'center'}>
            {state !== 'loaded' ?
              <Loading /> :
              <DocumentDisplay document={document!} />
            }
          </Grid>
        </Grid>
      </ContentSection>
    </>
  );
}

interface DocumentDisplayProps {
  document: PrismicDocument;
}

const DocumentDisplay = ({ document }: DocumentDisplayProps) => {
  return (
    <Grid item xs={11} lg={10} container direction={'column'}>
      <TitleGrid item xs={12}>
        {/* @ts-ignore */}
        <PrismicRichText field={document.data.title} components={prismicToMuiRenderer} />
      </TitleGrid>
      <Grid item xs={12}>
        {/* @ts-ignore */}
        <PrismicRichText field={document.data.document_content} components={prismicToMuiRenderer} />
      </Grid>
    </Grid>
  );
}