import React from "react";
import { styled, Theme, ThemeProvider } from "@mui/material/styles";
import { burnTheme, buildTheme, thriveTheme, freeTheme, navbarTheme } from "../../style/theme";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

interface ThemeIndex {
    [name: string]: Theme
  }
  
const themes: ThemeIndex = {
    burn: burnTheme,
    build: buildTheme,
    thrive: thriveTheme,
    free: freeTheme
}

export interface ToggleButtonBarProps {
    selectHandler: (Event: React.MouseEvent, newIndex: number) => void;
    options: string[];
    selectedIndex: number;
    variant: string;
}

export interface ToggleButtonBarStateProps {
    subscriptionName: string;
}

export const ToggleButtonBar = ({ subscriptionName, selectHandler, options, selectedIndex, variant }: ToggleButtonBarStateProps & ToggleButtonBarProps) => {
  if ( variant === 'section'  || variant === 'subsection' ) {
    return(
      <ThemeProvider theme={navbarTheme}>
        <ToggleButtonGroupFrame2
          color={'primary'}
          value={selectedIndex}
          exclusive
          onChange={selectHandler}
        >
          {options.map(option => {
            return(
              <SubSectionButton key={options.indexOf(option)} value={options.indexOf(option)}>
                {option}
              </SubSectionButton>
            )
          })}
        </ToggleButtonGroupFrame2>
    </ThemeProvider>
    );
  } else {
    return(
      <ThemeProvider theme={themes[subscriptionName]}>
        <ToggleButtonGroupFrame
          color={'primary'}
          value={selectedIndex}
          exclusive
          onChange={selectHandler}
        >
          {options.map(option => {
            return (
              <FilterButton key={options.indexOf(option)} value={options.indexOf(option)}>
                {option}
              </FilterButton>
            )
          })}
        </ToggleButtonGroupFrame>
      </ThemeProvider>
    );
  }
}

const ToggleButtonGroupFrame = styled(ToggleButtonGroup)`
  display: flex;
  overflow-x: auto;
  max-width: 100vw;
`;

const ToggleButtonGroupFrame2 = styled(ToggleButtonGroup)`
  display: flex;
  overflow-x: auto;
  width: fit-content;
  white-space: nowrap;
`;

const FilterButton = styled(ToggleButton)`
  height: 5.8rem;
  width: 17.7rem;
  min-width: 8.85rem;
  margin: 2rem;
  background-color: #DFDFDF;
`;

const SubSectionButton = styled(ToggleButton)`
  height: 2.4rem;
  width: fit-content;
  max-width: fit-content;
  min-width: fit-content;
  margin: 2rem 0;
`;