import { initSystem } from "./systemSaga";
import { all } from 'redux-saga/effects';
import { userSaga } from './userSaga';
import { physicianSaga } from './physicianSaga';
import { healthGuideSaga } from "./healthGuideSaga";
import { productSaga } from "./productSaga";
import { ErrorHandler } from "../../service/ErrorHandler";

export function* rootSaga(handler: ErrorHandler) {
  yield all([
    initSystem(handler),
    userSaga(),
    physicianSaga(),
    healthGuideSaga(),
    productSaga()
  ]);
}