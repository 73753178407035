import { UserProfile } from "../../models/UserProfile";
import Typography from "@mui/material/Typography";
import { formatAddress, formatPhoneNumber } from "../../utils/displayUtils";
import { PRODUCTS } from "../../cms/ProductEntries";
import Grid from "@mui/material/Grid";
import ProfileIcon from "../../assets/profile-icon.svg";
import React, { useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { theme } from "../../style/theme";
import { UserProfileButton, Bold18, GrayBold16, GrayBold18, Line, RoundedContainer } from "./userProfileStyles";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import { ImageUploader } from "../shared/imageUploader/ImageUploader";
import { ApiCallState } from "../../redux/WebAppState";
import { ProfileTextRow } from "./ProfileRow";

interface UserInfoProps { userProfile: UserProfile, startDate: string, photoIdUpdateApi: ApiCallState }
export interface UserInfoDispatchProps { updatePhotoId: (file: string) => void }
export const UserInfo = ({ userProfile, startDate, updatePhotoId, photoIdUpdateApi }: UserInfoProps & UserInfoDispatchProps) => {

  return (
    <RoundedContainer item container md={7.67} xs={12} direction={"column"} gap={3}>
      <Grid container direction={'row'}>
        <Typography variant={"h2"}>My Profile</Typography>
      </Grid>
      <Line/>
      <UserOverview username={userProfile.customer.name} program={!!userProfile.product ? userProfile.product : 'None'} startDate={startDate} />
      <Line/>
      <UserDemographics dateOfBirth={userProfile.customer.birthDate} email={userProfile.customer.emailAddress} phone={formatPhoneNumber(userProfile.customer.phoneNumber, true)} shippingAddress={formatAddress(userProfile.customer.shippingAddress)} updatePhotoId={updatePhotoId} photoIdUpdateApi={photoIdUpdateApi}/>
    </RoundedContainer>
  )
}

interface UserOverviewProps { username: string, program: string, startDate: string }
const UserOverview = ({ username, program, startDate }: UserOverviewProps) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const product = PRODUCTS[program.toLowerCase()];
  const direction = isMobile ? 'column' : 'row';
  const navigate = useNavigate();

  return (
    <Grid item container alignItems={"center"} direction={direction} gap={3}>
      <ProfilePicture src={ProfileIcon}/>
      <Grid item container xs direction={"column"} gap={1}>
        <Bold18>{username}</Bold18>
        <Grid container item alignItems={"center"} gap={1}>
          <GrayBold18>Program </GrayBold18>
          {!!product
            ? <>
              <Bold18 sx={{color: product.displayInfo.primaryColor}}>{product.name}</Bold18>
              <ProgramIcon src={product.displayInfo.productContents.icon}/>
              <WelcomeGuideButton sx={{borderColor: product.displayInfo.primaryColor, color: product.displayInfo.primaryColor}} onClick={() => navigate(`/healthGuide/${product.name.toLowerCase()}`)}>Welcome Guide</WelcomeGuideButton>
            </>
            : <GrayBold18>None</GrayBold18>
          }
        </Grid>
        {!!startDate ? <GrayBold18>Start Date {startDate}</GrayBold18> : undefined }
      </Grid>
    </Grid>
  )
}

interface UserDemographicsProps { dateOfBirth: string, email: string, phone: string, shippingAddress: string, photoIdUpdateApi: ApiCallState }
interface UserDemographicsDispatchProps { updatePhotoId: (file: string) => void }
const UserDemographics = ({ dateOfBirth, email, phone, shippingAddress, updatePhotoId, photoIdUpdateApi }: UserDemographicsProps & UserDemographicsDispatchProps) => {
  const [filename, setFilename] = useState('');

  return (
    <Grid item container direction={"column"} gap={3}>
      <ProfileTextRow rowLabel={'Date of birth'} rowContent={dateOfBirth} />
      <ProfileTextRow rowLabel={'E-mail'} rowContent={email} />
      <ProfileTextRow rowLabel={'Phone Number'} rowContent={phone} />
      <ProfileTextRow rowLabel={'Shipping'} rowContent={shippingAddress} />
      <Grid item container justifyContent={'space-between'}>
        <GrayBold16>Photo ID</GrayBold16>
        <ImageUploader
          WrapperButton={UserProfileButton}
          buttonText={'Change'}
          filename={filename}
          filenamePlaceholder={'Photo ID on File'}
          fileResultHandler={updatePhotoId}
          setFilename={setFilename}
          disabled={photoIdUpdateApi.isLoading}
          justifyRight
          photoIdUpdateApi={photoIdUpdateApi}
        />
      </Grid>
    </Grid>
  );
}

const ProgramIcon = styled('img')`
  height: 1.7rem;
  width: 1.7rem;
  margin-right: 2rem;
`;

const ProfilePicture = styled('img')`
  height: 13.8rem;
  width: 13.8rem;
  border-radius: 50%;
`;

const WelcomeGuideButton = styled(Button)`
  border: 1px solid black;
  border-radius: 0;
  background-color: transparent;
  width: fit-content;
  font-size: 1.2rem;
  font-weight: 500;
`;
