import { ContentSection } from "../../style/styles";
import { styled, ThemeProvider } from "@mui/material/styles";
import { saneTheme, theme } from "../../style/theme";
import Typography from "@mui/material/Typography";
import { WhatIsIt } from "./WhatIsIt";
import { FourSimpleSteps } from "./FourSimpleSteps";
import React from "react";
import Grid from "@mui/material/Grid";

export const HomePageRevamp = () => (
  <SquishedContentSection>
    <ThemeProvider theme={saneTheme}>
      <Grid container direction={'column'} spacing={6}>
        <Grid item container direction={'column'} spacing={2}>
          <Grid item><Typography variant={'h1'}>What will you get?</Typography></Grid>
          <Grid item><Typography variant={'h2'}>Our Telehealth Online Clinic offers custom-tailored plans with medications,
            supplements, diet, and exercise online coaching.</Typography></Grid>
          <Grid item><WhatIsIt/></Grid>
        </Grid>
        <Grid item container direction={'column'} spacing={2}>
          <Grid item><Typography variant={'h1'}>How does it work?</Typography></Grid>
          <Grid item><Typography variant={'h2'}>Getting started GoalsRx with our one-on-one online support, monthly Rx kit delivery,
            and online coaching requires only four simple steps.</Typography></Grid>
          <Grid item><FourSimpleSteps/></Grid>
        </Grid>
      </Grid>
    </ThemeProvider>
  </SquishedContentSection>
);

const SquishedContentSection = styled(ContentSection)`
  padding-top: 0;
  padding-bottom: 0;
  ${theme.breakpoints.down('sm')} {
    padding-bottom: 3rem;
  }
`;