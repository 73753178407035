import { HealthGuideContentWarmupSlice } from "../../../models/HealthGuideDocument";
import Typography from "@mui/material/Typography";
import { PrismicRichText } from "@prismicio/react";
import { healthGuideTextRenderer } from "../../../utils/displayUtils";
import Grid from "@mui/material/Grid";
import { EmbeddedYouTubeVideo } from "../../shared/embeddedVideo/EmbeddedYouTubeVideo";
import { styled } from "@mui/material/styles";
import { HealthGuideContentSectionLeft } from "../../../style/styles";
import React from "react";
import { StaticViewportResolver } from "../../shared/StaticViewportResolver";
import Box from "@mui/material/Box";

export interface WarmupSectionProps {
  warmupSlice: HealthGuideContentWarmupSlice | undefined
}

export const WarmupSection = ({ warmupSlice }: WarmupSectionProps) => {
  return (
    <StaticViewportResolver
      mobileView={mobile(warmupSlice!)}
      desktopView={desktop(warmupSlice!)}
    />
  );
}

const mobile = (warmupSlice: HealthGuideContentWarmupSlice) => {
  return (
    <HealthGuideContentSectionLeftGrayMobile>
      <PrismicRichText field={warmupSlice!.description} components={healthGuideTextRenderer}/>
      <Box sx={{pt: 1}} flexDirection={'column'} justifyContent={'space-between'} alignContent={'center'} justifyItems={'center'} alignSelf={'center'}>
        { warmupSlice!.items.map((item, index) => (
          <EmbeddedYouTubeVideo key={`warmupSection${index}`} url={!!item.video_link && item.video_link.url}>
            {!!item.video_description && <PrismicRichText field={item.video_description} components={healthGuideTextRenderer}/> }
          </EmbeddedYouTubeVideo>
        ))}
      </Box>
    </HealthGuideContentSectionLeftGrayMobile>
  );
}

const desktop = (warmupSlice: HealthGuideContentWarmupSlice) => {
  return (
    <HealthGuideContentSectionLeftGray>
      <Typography variant={'h2'}>Warm Up</Typography>
      <PrismicRichText field={warmupSlice!.description} components={healthGuideTextRenderer}/>
      <Grid sx={{pt: 1}} container justifyContent={'space-between'}>
        { warmupSlice!.items.map((item, index) => (
          <EmbeddedYouTubeVideo key={`warmupSection${index}`} url={!!item.video_link && item.video_link.url}>
            {!!item.video_description && <PrismicRichText field={item.video_description} components={healthGuideTextRenderer}/> }
          </EmbeddedYouTubeVideo>
        ))}
      </Grid>
    </HealthGuideContentSectionLeftGray>
  );
}

const HealthGuideContentSectionLeftGray = styled(HealthGuideContentSectionLeft)`
  padding-top: 3rem;
  background-color: #F2F2F2;
`;

const HealthGuideContentSectionLeftGrayMobile = styled(HealthGuideContentSectionLeft)`
  background-color: #F2F2F2;
`;