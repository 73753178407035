import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { CheckoutPage, CheckoutPageDispatchProps, CheckoutPageProps } from '../../components/checkout/CheckoutPage';
import { WebAppState } from '../../redux/WebAppState';
import { SUBMIT_CHECKOUT } from '../../redux/actions';

const mapStateToProps: MapStateToProps<CheckoutPageProps, {}, WebAppState> = (state: WebAppState) => ({
  cart: state.user.selectedProduct,
  formError: state.system.apiCalls.checkout.apiError?.message || '',
  isLoading: state.system.apiCalls.checkout.isLoading,
  purchasePrice: state.user.purchasePrice,
  promo: state.user.promo,
  getStateProduct: (name:string) => getProductObject(name, state),
});

const mapDispatchToProps: MapDispatchToProps<CheckoutPageDispatchProps, {}> = dispatch => ({
  placeOrder: ((request, navigate) => dispatch({type: SUBMIT_CHECKOUT, data: {request, navigate}}))
})
const getProductObject = (name:string, state:WebAppState) => state.system.products.find(product => product.name?.toLowerCase() === name?.toLowerCase());

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutPage);