import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { SignUpConfirmationPage, SignUpConfirmationPageDispatchProps, SignUpConfirmationPageProps } from '../../components/account/signUpConfirmation/SignUpConfirmationPage';
import { WebAppState } from '../../redux/WebAppState';
import { CLEAR_TEMP_PASSWORD, CREATE_USER, REPORT_ERROR, USER_LOGIN, USER_LOGIN_SUCCESS } from '../../redux/actions';

const mapStateToProps: MapStateToProps<SignUpConfirmationPageProps, {}, WebAppState> = state => ({
  temporaryPassword: state.user.temporaryPassword || '',
  username: state.user.user?.name || ''
});

const mapDispatchToProps: MapDispatchToProps<SignUpConfirmationPageDispatchProps, {}> = dispatch => ({
  loginUser: (login: Function, navigate: () => void) => dispatch({type: USER_LOGIN, data: { login: login, navigate: navigate }}),
  reportError: (error: Error) => dispatch({type: REPORT_ERROR, data: error}),
  clearTemporaryPassword: () => dispatch({type: CLEAR_TEMP_PASSWORD}),
  createUser: (username: string, referralCode?: string) => dispatch({type: CREATE_USER, data: { username: username, referralCode: referralCode }}),
  dispatchLoginSuccess: () => dispatch({type: USER_LOGIN_SUCCESS})
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUpConfirmationPage);