import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import closedBox from "../../assets/closed-box.png";
import Typography from "@mui/material/Typography";
import React from "react";

export const WhatIsIt = () => (
  <Grid container alignItems={"center"}>
    <BoxWrapper item xs={12} sm={6}>
      <BoxImageBox src={closedBox}/>
      <BoxImage src={closedBox}/>
    </BoxWrapper>
    <Grid item container xs={12} sm={6} rowSpacing={2} direction={"column"}>
      <Grid item><Typography variant={"h2"}>Starting at $349/month</Typography></Grid>
      <Grid item><Typography>Free shipping. Cancel anytime.</Typography></Grid>
      <Grid item>
        <List>
          <ListItem>One-On-One Online Support</ListItem>
          <ListItem>Monthly Rx Kit Delivered To Your Door</ListItem>
          <ListItem>Online Exercise Coaching</ListItem>
          <ListItem>Easy-To-Follow Nutrition Plans</ListItem>
        </List>
      </Grid>
    </Grid>
  </Grid>
);

const BoxWrapper = styled(Grid)`
  position: relative;
`;

const BoxImageBox = styled('img')`
  width: 100%;
  height: auto;
  max-width: 30rem;
  max-height: 30rem;
  visibility: hidden;
`;
const BoxImage = styled('img')`
  width: 100%;
  height: auto;
  max-width: 30rem;
  max-height: 30rem;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
const List = styled('ul')`
  list-style: disc inside;
`;
const ListItem = styled('li')`
  font-size: 1.6rem;
`;