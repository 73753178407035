{
  "_args": [
    [
      "@aws-sdk/client-cognito-identity@3.121.0",
      "/home/runner/work/goalsrx-react/goalsrx-react"
    ]
  ],
  "_from": "@aws-sdk/client-cognito-identity@3.121.0",
  "_id": "@aws-sdk/client-cognito-identity@3.121.0",
  "_inBundle": false,
  "_integrity": "sha512-g+6XDjGtgehJ89rYtG7mPS+Jl80+WhHzV+arSAJrAsKBB3CnH5eo6kRu6/uBd+uqd9uCdFlIZ46dd8VR8OflpA==",
  "_location": "/@aws-sdk/client-cognito-identity",
  "_phantomChildren": {
    "@aws-sdk/middleware-recursion-detection": "3.110.0",
    "@aws-sdk/types": "3.110.0",
    "@aws-sdk/util-defaults-mode-browser": "3.110.0",
    "@aws-sdk/util-defaults-mode-node": "3.110.0",
    "@aws-sdk/util-locate-window": "3.47.1",
    "@aws-sdk/util-middleware": "3.110.0",
    "bowser": "2.11.0",
    "entities": "2.2.0",
    "fast-xml-parser": "3.19.0",
    "uuid": "8.3.2"
  },
  "_requested": {
    "type": "version",
    "registry": true,
    "raw": "@aws-sdk/client-cognito-identity@3.121.0",
    "name": "@aws-sdk/client-cognito-identity",
    "escapedName": "@aws-sdk%2fclient-cognito-identity",
    "scope": "@aws-sdk",
    "rawSpec": "3.121.0",
    "saveSpec": null,
    "fetchSpec": "3.121.0"
  },
  "_requiredBy": [
    "/",
    "/@aws-sdk/credential-provider-cognito-identity"
  ],
  "_resolved": "https://registry.npmjs.org/@aws-sdk/client-cognito-identity/-/client-cognito-identity-3.121.0.tgz",
  "_spec": "3.121.0",
  "_where": "/home/runner/work/goalsrx-react/goalsrx-react",
  "author": {
    "name": "AWS SDK for JavaScript Team",
    "url": "https://aws.amazon.com/javascript/"
  },
  "browser": {
    "./dist-es/runtimeConfig": "./dist-es/runtimeConfig.browser"
  },
  "bugs": {
    "url": "https://github.com/aws/aws-sdk-js-v3/issues"
  },
  "dependencies": {
    "@aws-crypto/sha256-browser": "2.0.0",
    "@aws-crypto/sha256-js": "2.0.0",
    "@aws-sdk/client-sts": "3.121.0",
    "@aws-sdk/config-resolver": "3.110.0",
    "@aws-sdk/credential-provider-node": "3.121.0",
    "@aws-sdk/fetch-http-handler": "3.110.0",
    "@aws-sdk/hash-node": "3.110.0",
    "@aws-sdk/invalid-dependency": "3.110.0",
    "@aws-sdk/middleware-content-length": "3.110.0",
    "@aws-sdk/middleware-host-header": "3.110.0",
    "@aws-sdk/middleware-logger": "3.110.0",
    "@aws-sdk/middleware-recursion-detection": "3.110.0",
    "@aws-sdk/middleware-retry": "3.118.1",
    "@aws-sdk/middleware-serde": "3.110.0",
    "@aws-sdk/middleware-signing": "3.110.0",
    "@aws-sdk/middleware-stack": "3.110.0",
    "@aws-sdk/middleware-user-agent": "3.110.0",
    "@aws-sdk/node-config-provider": "3.110.0",
    "@aws-sdk/node-http-handler": "3.118.1",
    "@aws-sdk/protocol-http": "3.110.0",
    "@aws-sdk/smithy-client": "3.110.0",
    "@aws-sdk/types": "3.110.0",
    "@aws-sdk/url-parser": "3.110.0",
    "@aws-sdk/util-base64-browser": "3.109.0",
    "@aws-sdk/util-base64-node": "3.55.0",
    "@aws-sdk/util-body-length-browser": "3.55.0",
    "@aws-sdk/util-body-length-node": "3.55.0",
    "@aws-sdk/util-defaults-mode-browser": "3.110.0",
    "@aws-sdk/util-defaults-mode-node": "3.110.0",
    "@aws-sdk/util-user-agent-browser": "3.110.0",
    "@aws-sdk/util-user-agent-node": "3.118.0",
    "@aws-sdk/util-utf8-browser": "3.109.0",
    "@aws-sdk/util-utf8-node": "3.109.0",
    "tslib": "^2.3.1"
  },
  "description": "AWS SDK for JavaScript Cognito Identity Client for Node.js, Browser and React Native",
  "devDependencies": {
    "@aws-sdk/client-iam": "3.121.0",
    "@aws-sdk/service-client-documentation-generator": "3.58.0",
    "@tsconfig/recommended": "1.0.1",
    "@types/chai": "^4.2.11",
    "@types/mocha": "^8.0.4",
    "@types/node": "^12.7.5",
    "concurrently": "7.0.0",
    "downlevel-dts": "0.7.0",
    "rimraf": "3.0.2",
    "typedoc": "0.19.2",
    "typescript": "~4.6.2"
  },
  "engines": {
    "node": ">=12.0.0"
  },
  "files": [
    "dist-*"
  ],
  "homepage": "https://github.com/aws/aws-sdk-js-v3/tree/main/clients/client-cognito-identity",
  "license": "Apache-2.0",
  "main": "./dist-cjs/index.js",
  "module": "./dist-es/index.js",
  "name": "@aws-sdk/client-cognito-identity",
  "react-native": {
    "./dist-es/runtimeConfig": "./dist-es/runtimeConfig.native"
  },
  "repository": {
    "type": "git",
    "url": "git+https://github.com/aws/aws-sdk-js-v3.git",
    "directory": "clients/client-cognito-identity"
  },
  "scripts": {
    "build": "concurrently 'yarn:build:cjs' 'yarn:build:es' 'yarn:build:types'",
    "build:cjs": "tsc -p tsconfig.cjs.json",
    "build:docs": "typedoc",
    "build:es": "tsc -p tsconfig.es.json",
    "build:types": "tsc -p tsconfig.types.json",
    "build:types:downlevel": "downlevel-dts dist-types dist-types/ts3.4",
    "clean": "rimraf ./dist-* && rimraf *.tsbuildinfo",
    "test:e2e": "ts-mocha test/**/*.ispec.ts && karma start karma.conf.js"
  },
  "sideEffects": false,
  "types": "./dist-types/index.d.ts",
  "typesVersions": {
    "<4.0": {
      "dist-types/*": [
        "dist-types/ts3.4/*"
      ]
    }
  },
  "version": "3.121.0"
}
