import React, { PropsWithChildren } from "react";
import { StaticViewportResolver } from "../StaticViewportResolver";
import { StyledIFrame, StyledIFrameMobile, TitleWrapper, VideoTile, VideoTileMobile, VideoWrapper } from "./embeddedVideoStyles";

export interface VideoLinkProps {
  url: string
}

export const EmbeddedVideo = ({children, url}: PropsWithChildren<VideoLinkProps>) => {
  if ( !url ) return null;

  return (
    <StaticViewportResolver
      mobileView={mobileView(url, children)}
      desktopView={desktopView(url, children)}
    />
  );
}

const desktopView = (url: string, children: React.ReactNode) => {
  return <VideoTile key={url} item xs>
      <VideoWrapper>
        <StyledIFrame src={url} allowFullScreen />
      </VideoWrapper>
    { !!children ? <TitleWrapper>{children}</TitleWrapper> : null }
    </VideoTile>
}

const mobileView = (url: string, children: React.ReactNode) => {
  return <VideoTileMobile key={url}>
      <VideoWrapper>
        <StyledIFrameMobile src={url} allowFullScreen />
      </VideoWrapper>
    { !!children ? <TitleWrapper>{children}</TitleWrapper> : null }
    </VideoTileMobile>
}
