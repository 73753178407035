import React, { useEffect } from "react";
import { HomePage } from './home/HomePage';
import { Header } from './shared/Header';
import {
  BrowserRouter,
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams
} from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { RequireAuth } from './shared/RequireAuth';
import { AuthProvider } from './shared/AuthContext';
import { getAuthService } from '../service/AuthService';
import ProductPageContainer from '../containers/ProductPageContainer';
import CheckoutPageContainer from '../containers/checkout/CheckoutPageContainer';
import { CreateAccountPage } from '../components/account/CreateAccountPage';
import SignUpConfirmationPageContainer from '../containers/account/SignUpConfirmationPageContainer';
import { client } from '../service/CmsService';
import { PrismicProvider } from '@prismicio/react';
import QuestionnairePageContainer from '../containers/QuestionnairePageContainer';
import { StaticContentPage } from './static/StaticContentPage';
import { FaqPage } from './static/FaqPage';
import PhysicianPageContainer from "../containers/PhysicianPageContainer";
import { User } from "../models/User";
import { UnauthorizedRedirect } from "./shared/UnauthorizedRedirect";
import { IneligiblePage } from "./checkout/IneligiblePage";
import { AboutPage } from "./about/AboutPage";
import { styled } from "@mui/material/styles";
import HealthGuideContainer from "../containers/healthguide/HealthGuideContainer";
import HealthGuideContentContainer from "../containers/healthguide/HealthGuideContentContainer";
import RequireProductContainer from "../containers/RequireProductContainer";
import TagManager from "react-gtm-module";
import FooterContainer from '../containers/FooterContainer';
import { isFeatureEnabled, USER_PROFILE } from "../utils/FeatureUtils";
import UserProfilePageContainer from "../containers/userProfile/UserProfilePageContainer";
import ResetPasswordFormContainer from "../containers/account/ResetPasswordFormContainer";
import { PaymentSuccessPage } from "./checkout/PaymentSuccessPage";

export interface AppProps {
  setUser: (user: User | undefined) => void;
  setUserProfile: () => void;
  setProducts: () => void;
}

const tagManagerArgs = {
  gtmId: process.env.GTM_ID ?? '',
  auth: process.env.GTM_ENV,
  preview: process.env.GTM_PREVIEW
}

export const App = ({ setUser, setUserProfile, setProducts }: AppProps) => {
  TagManager.initialize(tagManagerArgs);
  const authService = getAuthService();

  setProducts();

  return (
    <AuthProvider authService={authService} setGlobalUser={setUser} setUserProfile={setUserProfile}>
      <PrismicProvider client={client}>
        <CssBaseline/>
        <BrowserRouter>
          <UnauthorizedRedirect/>
          <ScrollToHashOrTop/>
          <ReferralCapture/>
          <RelativePosition>
            <Header/>
            <Wrapper>
              <Routes>
                <Route path={'/'} element={<HomePage/>}/>
                <Route path={'/product/:productName'} element={<ProductPageContainer/>}/>
                <Route path={'/createAccount'} element={<CreateAccountPage/>}/>
                <Route path={'/confirmSignUp'} element={<SignUpConfirmationPageContainer/>}/>
                <Route path={'/purchase'} element={<RequireAuth><RequireProductContainer><Outlet /></RequireProductContainer></RequireAuth>}>
                  <Route index element={<QuestionnairePageContainer/>}/>
                  <Route path={'checkout'} element={<CheckoutPageContainer/>}/>
                </Route>
                <Route path={'/login'} element={<CreateAccountPage/>}/>
                <Route path={'/resetPassword'} element={<ResetPasswordFormContainer/>}/>
                <Route path={'/physician'} element={<RequireAuth><PhysicianPageContainer/></RequireAuth>}/>
                <Route path={'/orderComplete'} element={<PaymentSuccessPage/>}/>
                <Route path={'/faq'} element={<FaqPage/>}/>
                <Route path={'/about'} element={<AboutPage/>}/>
                <Route path={'/static/:documentUid'} element={<StaticContentPage/>}/>
                <Route path={'/ineligible'} element={<IneligiblePage/>}/>
                <Route path={'/healthGuide'} element={<Outlet />}>
                  <Route index element={<HealthGuideContainer />}/>
                  <Route path={':docId'} element={<HealthGuideContentContainer />}/>
                </Route>
                <Route path={'/freeHealthGuide'} element={<Outlet />}>
                  <Route path={':docId'} element={<HealthGuideContentContainer />}/>
                </Route>
                {isFeatureEnabled(USER_PROFILE) &&
                  <Route path={'/profile'} element={<RequireAuth><UserProfilePageContainer /></RequireAuth>} />
                }
                <Route path={':referralCode'} element={<ReferralRedirect/>} />
              </Routes>
              <FooterContainer />
            </Wrapper>
          </RelativePosition>
        </BrowserRouter>
      </PrismicProvider>
    </AuthProvider>
  );
};

const Wrapper = styled('div')`
  margin-top: 8rem;
`;

const ScrollToHashOrTop = () => {
  const { pathname, hash } = useLocation();
  useEffect(() => {
    if (!hash || hash.replace('#', '').length === 0) {
      window.scrollTo(0, 0);
    } else {
      window.setTimeout(() => {
        const element = document.getElementById(hash.replace('#', ''));
        const y = element?.offsetTop || 0;
        window.scrollTo(0, y);
      }, 0);
    }
  }, [pathname, hash]);

  return null;
};

const ReferralCapture = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const referralId = searchParams.get('utm_source');
    const campaignMatch = searchParams.get('utm_id') === 'affiliate';
    const mediumMatch = searchParams.get('utm_medium') === 'referral';

    if (!!referralId && campaignMatch && mediumMatch) {
      localStorage.setItem('referralCode', referralId);
    }
  }, [searchParams]);

  return null;
}

export const ReferralRedirect = () => {
  const { referralCode } = useParams<{ referralCode: string }>();
  const navigate = useNavigate();

  useEffect(() => {
    if (!!referralCode) {
      navigate({ pathname: '/', search: `?utm_source=${referralCode}&utm_id=affiliate&utm_medium=referral`});
    }
  }, []);

  return (
    <></>
  )
}

const RelativePosition = styled('div')`
  position: relative;
  min-height: 100vh;
`;