import React, { SyntheticEvent, useState } from 'react';
import { PrismicRichText, usePrismicDocumentsByType } from '@prismicio/react';
import { ContentSection, TitleGrid } from '../../style/styles';
import Grid from '@mui/material/Grid';
import { Loading } from '../shared/Loading';
import { PrismicDocument } from '@prismicio/types';
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { prismicToMuiRenderer } from '../../utils/displayUtils';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMore from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionDetails';
import Accordion from '@mui/material/Accordion';
import Link from "@mui/material/Link";

export const FaqPage = () => {
  const [records, { state }] = usePrismicDocumentsByType('faq');

  const document = records?.results[0];

  return (
    <>
      <ContentSection>
        <Grid container justifyContent={'center'}>
          <Grid item xs={12} sm={11} md={10} container justifyContent={'center'} alignItems={'center'}>
            {state !== 'loaded' ?
              <Loading /> :
              <DocumentDisplay document={document!} />
            }
          </Grid>
        </Grid>
      </ContentSection>
    </>
  );
}

interface DocumentDisplayProps {
  document: PrismicDocument;
}

const DocumentDisplay = ({ document }: DocumentDisplayProps) => {
  const [value, setValue] = useState(0);
  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  }

  return (
    <Grid item xs={12} container justifyContent={'center'}>
      <TitleGrid item xs={12} container justifyContent={'center'}>
        <Typography variant={'h1'} gutterBottom>Frequently Asked Questions</Typography>
      </TitleGrid>
      <Grid item xs={12}>
        <Tabs value={value} onChange={handleChange} variant={'scrollable'} scrollButtons={'auto'}>
          {/*@ts-ignore*/}
          {document.data.body!.map(slice => <Tab key={slice.primary.category_name} label={slice.primary.category_name} />)}
        </Tabs>
      </Grid>
      <Grid item xs={12} paddingTop={2}>
        {/*@ts-ignore*/}
        {document.data.body!.map((slice, index) => <div key={index} hidden={value !== index}><SliceDisplay slice={slice} /></div>)}
      </Grid>
      <Typography sx={{pt: '2rem'}} variant={'h6'} align={'center'}>
        Didn't find what you were looking for? Ask us your questions at <Link sx={{cursor: 'pointer'}} onClick={(e) => {window.location.href = 'mailto:questions@goalsrx.com'; e.preventDefault();}}>questions@goalsrx.com</Link>
      </Typography>
    </Grid>
  );
}

interface SliceDisplayProps {
  slice: {
    primary: { category_name: string };
    items: { question: string; answer: string; }[];
  }
}

const SliceDisplay = ({ slice }: SliceDisplayProps) => {
  const [expandedItem, setExpandedItem] = useState<number>();
  const handleChange = (index: number) => {
    if (expandedItem !== index) {
      setExpandedItem(index);
    } else {
      setExpandedItem(undefined);
    }
  }

  return (
    <>
      {slice.items.map((item, index) => (
        <Accordion key={`${slice.primary.category_name}-${index}`} expanded={expandedItem === index} onChange={() => handleChange(index)}>
          {/*@ts-ignore*/}
          <AccordionSummary expandIcon={<ExpandMore />}><PrismicRichText field={item.question} components={prismicToMuiRenderer} /></AccordionSummary>
          <AccordionDetails>
            {/*@ts-ignore*/}
            <PrismicRichText field={item.answer} components={prismicToMuiRenderer} />
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
}