export const resizeImage = async (
  url,
  options = {
    maxWidth: 1024,
    maxHeight: 768,
    contentType: "image/jpeg",
    quality: 0.7
  }
) => {
  const calculateSize = (img) => {
    let width = img.width,
      height = img.height;
    if (width > height) {
      if (width > options.maxWidth) {
        height = Math.round((height * options.maxWidth) / width);
        width = options.maxWidth;
      }
    } else {
      if (height > options.maxHeight) {
        width = Math.round((width * options.maxHeight) / height);
        height = options.maxHeight;
      }
    }
    return [width, height];
  };

  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = url;
    img.onerror = function () {
      URL.revokeObjectURL(this.src);
      reject(new Error('Unable to process image'));
    };
    img.onload = function () {
      URL.revokeObjectURL(this.src);
      const [newWidth, newHeight] = calculateSize(
        img,
        options.maxWidth,
        options.maxHeight
      );
      const canvas = document.createElement("canvas");
      canvas.width = newWidth;
      canvas.height = newHeight;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, newWidth, newHeight);

      const resultUrl = canvas.toDataURL(options.contentType, options.quality),
        result = {
          url: resultUrl,
          contentType: resultUrl.match(/^data:([^;]+);base64,/im)[1] || "",
          b64: resultUrl.replace(/^data:([^;]+);base64,/gim, "")
        };

      canvas.toBlob(
        (blob) => {
          result.size = blob.size;
          resolve(result);
        },
        options.contentType,
        options.quality
      );
    };
  });
};