import React from "react";
import { PrismicRichText } from "@prismicio/react";
import { RichTextField } from "@prismicio/types";
import { healthGuideTextRenderer } from "../../../utils/displayUtils";

export interface PrismicContentBlockProps {
  title: RichTextField;
  content: RichTextField;
}

export const PrismicContentBlock = ({ title, content }: PrismicContentBlockProps) => {
  return(
    <>
      <PrismicRichText field={title} components={healthGuideTextRenderer}/>
      <PrismicRichText field={content} components={healthGuideTextRenderer} />
    </>
  );
}