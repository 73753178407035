import React from 'react';
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Button, ContentSection } from "../../style/styles";
import { Carousel } from "../shared/Carousel";
import { FOUNDERS_CAROUSEL_ITEMS } from '../../cms/CarouselEntries';
import { useNavigate } from "react-router-dom";

export const Founders = () => {
  const navigate = useNavigate();
  return (
    <ContentSection $dark>
      <Grid container direction={'column'}>
        <Typography variant={'h1'} gutterBottom>Founded by industry experts.</Typography>
        <Typography variant={'h2'} gutterBottom>Say hello.</Typography>
        <Carousel items={FOUNDERS_CAROUSEL_ITEMS} />
        <Grid container>
          <Grid item xs={12} lg={10} container justifyContent={'flex-end'} sx={{marginTop: '2rem'}}>
            <Button type={'button'} onClick={() => navigate('/#products')}>Get Started</Button>
          </Grid>
        </Grid>
      </Grid>
    </ContentSection>
  );
}

