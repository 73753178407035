import { AnyAction, Reducer } from "redux";
import { DEFAULT_APP_STATE, HealthGuideState } from "../WebAppState";
import * as Actions from "../actions";

export const healthGuideReducer: Reducer<HealthGuideState> = ((state = DEFAULT_APP_STATE.healthGuide, action: AnyAction): HealthGuideState => {
  switch (action.type) {
    case Actions.HEALTH_GUIDE_RECEIVED:
      return {
        ...state,
        ...action.data,
      };
    case Actions.APPLY_HEALTH_GUIDE_DOCUMENT_FILTER:
      return {
        ...state,
        filter: action.data
      };
    case Actions.HEALTH_GUIDE_DOCUMENT_RECEIVED:
      return {
        ...state,
        selectedDocument: action.data,
        navigation: {
          sectionIndex: 0,
          subSectionIndex: 0
        }
      };
    case Actions.HEALTH_GUIDE_FAILED:
      return {
        ...state,
        contentTypes: [],
        documentList: [],
      };
    case Actions.HEALTH_GUIDE_DOCUMENT_FAILED:
      return {
        ...state,
        selectedDocument: undefined
      };
    case Actions.HEALTH_GUIDE_DOCUMENT_SET_SECTION_INDEX:
      return {
        ...state,
        navigation: {
          ...state.navigation,
          sectionIndex: action.data,
          subSectionIndex: 0
        }
      }
    case Actions.HEALTH_GUIDE_DOCUMENT_SET_SUB_SECTION_INDEX:
      return {
        ...state,
        navigation: {
          ...state.navigation,
          subSectionIndex: action.data
        }
      }
    case Actions.USER_LOGOUT:
      return {
        ...DEFAULT_APP_STATE.healthGuide
      };
    case Actions.SET_PROGRAM_FILTER:
      return {
        ...state,
        programFilter: action.data
      }
    default:
      return state;
  }
});