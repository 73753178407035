import * as prismic from '@prismicio/client';
import {
  HealthGuideDocumentMetadata,
  HealthGuideContentSection,
  HealthGuideContentSubSection,
  HealthGuideDocument,
  HealthGuideContentWarmupSlice,
  HealthGuideContentWorkoutSlice
} from "../models/HealthGuideDocument";

export const repositoryName = 'goalsrx-website';
const endpoint = prismic.getEndpoint(repositoryName);

export const client = prismic.createClient(endpoint, {});
export const HEALTH_GUIDE_DOCUMENT_QUERY = `{
  health-guide-entry {
    title
    description
    tile_image
  }
}`;

export class CmsService {
  private prismicClient: prismic.Client;
  constructor(prismicClient: prismic.Client) {
    this.prismicClient = prismicClient;
  }

  public async getProgramHealthGuideDocumentList() {
    const response = await this.prismicClient.dangerouslyGetAll(
      {
        predicates: [
          prismic.predicate.at("document.type", "health-guide-entry")
        ],
        orderings: {
          field: 'my.health-guide-entry.sort_order',
          direction: 'desc'
        },
        'graphQuery': HEALTH_GUIDE_DOCUMENT_QUERY
    });

    return this.parseResponseIntoHealthGuideDocumentMetadataList(response);
  }

  public async searchHealthGuideDocuments(term: string) {
    const response = await this.prismicClient.dangerouslyGetAll(
      {
        predicates: [
          prismic.predicate.fulltext( 'document', term ),
          prismic.predicate.at("document.type", "health-guide-entry")
        ],
        orderings: {
          field: 'my.health-guide-entry.sort_order',
          direction: 'desc'
        },
        'graphQuery': HEALTH_GUIDE_DOCUMENT_QUERY
      }
    );

    return this.parseResponseIntoHealthGuideDocumentMetadataList(response);
  }

  public async parseResponseIntoHealthGuideDocumentMetadataList(response: any[]) {
    const documentList: HealthGuideDocumentMetadata[] = [];
    const contentTypes: string[] = [];

    for (let item of response) {
      const metaData: HealthGuideDocumentMetadata = {
        id: item.uid,
        title: item.data.title,
        description: item.data.description,
        tileImgSrc: item.data.tile_image.url,
        mobileTileImgSrc: !!item.data.tile_image.mobile && !!item.data.tile_image.mobile.url ? item.data.tile_image.mobile.url : item.data.tile_image.url,
        tags: item.tags.map((tag: string) => tag.split('-'))
          .filter((parsedTag: string[]) => parsedTag[0] == 'c')
          .map((parsedTag: string) => parsedTag[1]),
        programs: item.tags.map((tag: string) => tag.split('-'))
          .filter((parsedTag: string[]) => parsedTag[0] == 'p')
          .map((parsedTag: string) => parsedTag[1])
      };

      documentList.push(metaData);

      for(let tag of item.tags) {
        const parsedTag = tag.split('-');
        const tagType = parsedTag[0];
        const contentType = parsedTag[1];

        if (tagType == 'c' && !contentTypes.includes(contentType)) {
          contentTypes.push(contentType);
        }
      }
    }

    return {
      contentTypes: contentTypes,
      documentList: documentList
    };
  }

  public async getHealthGuideDocument(docId: string) {
    const response = await this.prismicClient.getByUID('health-guide-entry', docId);
    return this.parseResponseIntoHealthGuideDocument(response);
  }

  private parseResponseIntoHealthGuideDocument(response: any): HealthGuideDocument {
    return {
      metadata: {
        id: response.uid,
        tags: response.tags,
        title: response.data.title,
        description: response.data.description,
        content: response.data.content,
        tileImgSrc: response.data.tile_image.url
      },
      sections: this.parseSections(response.data)
    }
  }

  private parseSections(data: any) {
    const warmupSlices: HealthGuideContentWarmupSlice[] = [];
    const workoutSlices: HealthGuideContentWorkoutSlice[] = [];
    const subSections: HealthGuideContentSubSection[] = [];
    const sections: HealthGuideContentSection[] = [];

    const createWarmupSlice = (slice: any) => {
      return {
        parentId: slice.primary.subsection_id,
        parentIdentifier: slice.primary.subsection_identifier,
        description: slice.primary.description1,
        items: slice.items
      };
    }

    const createWorkoutSlice = (slice: any): HealthGuideContentWorkoutSlice => {
      return {
        parentId: slice.primary.subsection_id,
        parentIdentifier: slice.primary.subsection_identifier,
        number: slice.primary.workout_number,
        titleString: slice.primary.workout_title,
        descriptionField: slice.primary.workout_description,
        goal: slice.primary.workout_goal,
        sets: slice.primary.workout_sets,
        reps: slice.primary.workout_reps,
        rpe: slice.primary.workout_rpe,
        percent: slice.primary.workout_percent,
        tempo: slice.primary.workout_tempo,
        rest: slice.primary.workout_rest,
        coachingTip: slice.primary.workout_coaching_tip,
        videoLink: slice.primary.workout_video_link,
        items: slice.items
      }
    }

    const createSlice = (slice: any) => {
      switch (slice.slice_type) {
        case 'workout_warm-up':
          warmupSlices.push(createWarmupSlice(slice));
          break;
        case 'workout_table':
          workoutSlices.push(createWorkoutSlice(slice));
          break;
        default:
          break;
      }
    }

    const createSubSection = (subSection: any) => {
      subSections.push({
        identifier: subSection.subsection_identifier,
        parentId: subSection.section_id,
        parentIdentifier: subSection.section_identifier,
        dayNumber: subSection.day_number,
        titleString: subSection.subsection_title[0].text,
        titleField: subSection.subsection_title,
        content: subSection.subsection_content,
        warmup: warmupSlices.find(slice => belongsToSubsection(slice, subSection)),
        workouts: workoutSlices.filter(slice => belongsToSubsection(slice, subSection))
      });
    }

    const createSection = (section: any) => {
      sections.push({
        id: section.section_id,
        identifier: section.section_identifier,
        titleString: section.section_title[0].text,
        titleField: section.section_title,
        content: section.section_content,
        subSections: subSections.filter(subSection =>
          !!subSection.parentId && subSection.parentId === section.section_id ||
          !!subSection.parentIdentifier && subSection.parentIdentifier === section.section_identifier
        )
      });
    }

    !!data.body && data.body.map(createSlice);
    data.subsections.map(createSubSection);
    data.sections.map(createSection);

    return sections;
  }
}

export const belongsToSubsection = (slice: HealthGuideContentWarmupSlice | HealthGuideContentWorkoutSlice, subSection: any) => {
  if (!!slice.parentIdentifier) {
    return slice.parentIdentifier === subSection.subsection_identifier;
  } else {
    return !!slice.parentId && slice.parentId === subSection.subsection_id;
  }
}